import React, { useContext, useEffect, useState } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { DefaultButton, DetailsList, IColumn, IconButton, loadTheme, MessageBar, MessageBarType, Modal,PrimaryButton, SelectionMode, Spinner, SpinnerSize, TextField, getTheme } from '@fluentui/react';
import { _Styles } from '../Page.styles';
import { AdminContext } from '../context/ControlEditor';
import { authProvider } from '../../authProvider';
import { contentStyles, dragOptions, iconButtonStyles } from '../Styles/Dialog/CommonControlEditor';
import { ChangeLog } from '../Interface/IAdminTable';
import { controlEditorTheme } from '../Themes/ControlEditorTheme';

const theme = getTheme();
const columns: IColumn[] = [
    {
        key: 'FeatureName',
        name: 'Feature Name',
        fieldName: 'FeatureName',
        minWidth: 20,
        isPadded: false,
        maxWidth: 200,
        isResizable: true
    },
    {
        key: 'ControlID',
        name: 'Control ID',
        fieldName: 'ControlID',
        minWidth: 20,
        isPadded: false,
        maxWidth: 200,
        isResizable: true
    }
];

export const EnableOrDisableButton: React.FunctionComponent<{}> = () => {
    loadTheme(controlEditorTheme);
    const RuntimeConfigurationInitial = window.__UI_CONFIGURATION_INITIAL__;
    const ScannerAPI = RuntimeConfigurationInitial.webAPIConf ? RuntimeConfigurationInitial.webAPIConf["AZURE"] : RuntimeConfigurationInitial.webAPI;

    const {modelOpenToEnable,toggleModelOpenToEnable,isEnabled,selectedRow, bulkEditRows} = useContext(AdminContext);
    const [displayTable, setDisplayTable] = useState([]);
    const [spinner, setSpinner] = useState<boolean>(false);
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [showMessageError, setShowMessageError] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [comments, setComments] = useState('');
    const [enable,setEnable] = useState('');
    const [label,setLabel] = useState('');
    const [version, setVersion] = useState<string>("");

    useEffect(() => {
        if(isEnabled)
        {
            setEnable("enabled");
            setLabel("Enabling the controls using bulk enable...");
        }
        else
        {
            setEnable("disabled");
            setLabel("Disabling the controls using bulk disable...");
        }

        FetchSchema();
    },
    [isEnabled]);

    const SuccessMessage = () => (
        <MessageBar
          messageBarType={MessageBarType.success}
          isMultiline={true}
        >  
            <div>
                The controls have been {enable} successfully.
            </div>
            <div>
                Future scan results will show the updated controls.
            </div>
        </MessageBar>
    );

    const ErrorMessage = () => (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={true}
        >  
             <div>
            Could not process the request. Please refresh the page and try again.
            </div>
        </MessageBar>
    );

    const FetchSchema = async () => {
        const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
        let body = {
            ResourceType: selectedRow["FeatureName"],
            Control: selectedRow["ControlId"],
            Schema: true
        }
        fetch((ScannerAPI + '/controleditor/fetchschema/'), {
            headers: !authToken.accessToken ? {} : {
                'Authorization': `Bearer ${authToken.accessToken}`,
                'u_Id': sessionStorage.getItem("u_Id"),
                'Content-Type': 'application/json'},
            method: 'POST',
            body: JSON.stringify(body)
        }).then(response => {
            if (response.ok) {
                response.json().then(data => {
                    setVersion(data["version"]);
                });
            }
        });
    }

    function submitData(): void {
        const BulkUpdate = async () => {
        setSpinner(true);
        let body = {}
        let featureControlMap = {};
        bulkEditRows.forEach(element => {
            try
            {
                let check = featureControlMap[element.FeatureName];
                check["Controls"] = [...check["Controls"],element.Id];
                featureControlMap[element.FeatureName] = check;
            }
            catch (e)
            {
                let newFeature = {};
                newFeature["Feature"] = element.FeatureName;
                newFeature["Controls"] = [element.Id];
                featureControlMap[element.FeatureName] = newFeature;
            }
        });
    
        let featureControlList = [];
        for (let key in featureControlMap)
        {
            featureControlList = [...featureControlList,featureControlMap[key]]
        }

        var changelog : ChangeLog[] = [];
        changelog = changelog.concat({
            user: "",
            date: "",
            comment: comments.length===0? label:comments
        });
    
            body["FeatureControlMap"] = featureControlList;
            body["ChangeLog"] = changelog;
            body["IsEnabled"] = isEnabled ? "true":"false";
            body["Version"] = version;
                
            const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
            fetch((ScannerAPI + '/controleditor/bulkenableordisable/'), {
                headers: !authToken.accessToken ? {} : {
                'Authorization': `Bearer ${authToken.accessToken}`,
                'u_Id': sessionStorage.getItem("u_Id"),
                'Content-Type': 'application/json'},
                method: 'POST',
                body: JSON.stringify(body)
            }).then(response => {
                if (response.ok) {
                    setSpinner(false);
                    setSubmitted(true);
                    setComments("");
                    setShowMessage(true);
                }
                else
                {
                    setSpinner(false);
                    setShowMessageError(true);
                }
            });
        }
        BulkUpdate();
    }

    useEffect(() => {
        var tableDisplayList = [];
        var increment = 0;
        bulkEditRows.forEach(element => {
            tableDisplayList = tableDisplayList.concat({
                "key": increment,
                "FeatureName": element.FeatureName,
                "ControlID": element.ControlId
            });
        });           
        setDisplayTable(tableDisplayList);
    },
    // eslint-disable-next-line
    [modelOpenToEnable]);

    
    const onChangeText = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if(newValue.length <= 1000) {
            setComments(newValue);
        }
    };

    const onDismissModal = (): void => {
        toggleModelOpenToEnable();
        if(submitted){
            setShowMessage(false);
            setComments("");
        }
        setSpinner(false);
        setSubmitted(false);     
        setShowMessage(false);
        setShowMessageError(false);   
    }

    return (
        <div>
            <Modal
                isOpen={modelOpenToEnable}
                containerClassName={contentStyles.container2}
                onDismiss={onDismissModal}
                dragOptions={dragOptions}
            >
                <div className={contentStyles.header}>
                    {isEnabled && 
                        <span>Enable Controls</span>
                    }

                    {!isEnabled && 
                        <span style={{color:theme.palette.redDark}} >Disable Controls</span>
                    }
                    
                    <IconButton
                        style={{outline:"none"}}
                        styles={iconButtonStyles}
                        iconProps={{iconName:'Cancel'}}
                        ariaLabel="Close popup modal"
                        onClick={onDismissModal}
                    />
                </div>

                <div className={contentStyles.body}>
                    { isEnabled && 
                        <div>
                            The following controls have been selected to enable:
                        </div>
                    }

                    { !isEnabled && 
                        <div>
                            The following controls have been selected to disable:
                        </div>
                    }

                    <DetailsList
                        items={displayTable}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                    />
                    <div className={_Styles.rowGap} />
                    <TextField
                        label={"Comments"}
                        multiline
                        value={comments}
                        errorMessage={comments ? comments.length <= 1000 ? "" : "Character limit reached" : ""}
                        rows={3}
                        onChange={onChangeText}
                    />
                    <div className={_Styles.rowGap} />
                    <div className={_Styles.rowGap} />
                    <div>
                        {(showMessage) && <div><SuccessMessage/><div className={_Styles.rowGap} /></div>}
                    </div>
                    <div>
                        {(showMessageError) && <div><ErrorMessage/><div className={_Styles.rowGap} /></div>}
                    </div>
                    <Stack {...{horizontal: true, verticalAlign: 'center'}} horizontalAlign={"space-between"}
                        reversed style={{padding: 0, marginTop: 0}} tokens={{childrenGap: 10}}>
                        <Stack horizontal reversed tokens={{childrenGap: 10}}>
                            <DefaultButton onClick={onDismissModal} text="Close" />
                            <PrimaryButton onClick={submitData} text="Confirm" disabled={submitted} />
                        </Stack>
                        {spinner && <Spinner size={SpinnerSize.medium} label={label} labelPosition="right" /> }
                    </Stack>
                </div>    
                <div className={_Styles.rowGap} />     
            </Modal>
        </div>
    );
}