import * as React from 'react';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import {Text} from "@fluentui/react"
import { PanelContext } from '../context/PanelContext';
import { useContext } from 'react';
import { panelClassNames } from './PersonaPanel';

// Component for Did You Know panel
export const DidYouKnowPanel: React.FunctionComponent = () => {
  const RuntimeConfigurationExtended = window.__UI_CONFIGURATION_EXTENDED_AZURE__;

  const {didYouKnowPanel, textDUKAzureArray, textDUKDevOpsArray, toggleDidYouKnowPanel, setTextDUKArray} = useContext(PanelContext);

  // Check if EGRC is enabled, add message if enabled.
  React.useEffect(() => {
    if(localStorage.getItem("scanner") === "azure" && RuntimeConfigurationExtended.exceptionFeatureConfiguration?.isEnabled === true && 
      RuntimeConfigurationExtended.exceptionFeatureConfiguration?.helpPageUrl?.length > 0){
        var DUKArray = textDUKAzureArray;
        DUKArray = DUKArray.concat('To know more about exceptions, visit ' + RuntimeConfigurationExtended.exceptionFeatureConfiguration?.helpPageUrl);
        setTextDUKArray(DUKArray);
    }
  },
  // eslint-disable-next-line
  []);

  return (
    <div>
      <Panel
        isOpen={didYouKnowPanel}
        isLightDismiss
        type={PanelType.custom}
        headerClassName={panelClassNames.headerClass}
        customWidth={'35%'}
        hasCloseButton={false}
        onDismiss={toggleDidYouKnowPanel}
        headerText="Did you Know ?"
        // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
        closeButtonAriaLabel="Close"
      >
        <ul>
        { localStorage.getItem("scanner") === "azure" && 
            textDUKAzureArray.length > 0 &&
            textDUKAzureArray.map((log, index) => {
                return (
                    // key is required for the children to have an unique identifier.
                    <li>
                      <Text>{textDUKAzureArray[index]}</Text>
                    </li>
                );
            })
        }
        { localStorage.getItem("scanner") === "devops" && 
            textDUKDevOpsArray.length > 0 &&
            textDUKDevOpsArray.map((log, index) => {
                return (
                    // key is required for the children to have an unique identifier.
                    <li>
                      <Text>{textDUKDevOpsArray[index]}</Text>
                    </li>
                );
            })
        }
        </ul>
      </Panel>
    </div>
  );
};
