import React, { Component, createContext } from 'react';

export const AdminContext = createContext();

export class AdminContextProvider extends Component {
  state = {
    resource: [],
    control: [],
    scanSource: [],
    controlFiltered: [],
    controlPanel: false,
    selectedRow: [],
    bulkEditRows: [],
    changedData : {},
    tableVisible : false,
    loadingTable : false,
    modelOpenUpdate : false,
    modelBulkOpen: false,
    modelOpenAddField : false,
    mdcModelOpenUpdate : false,
    isEnabled: false,
    modelOpenToEnable : false
  }

  setLoadingTable= (value) => {
    this.setState({
      loadingTable: value
    })
  }

  setBulkEditRows = (value) => {
    this.setState({
      bulkEditRows: value
    })
  }

  toggleModelOpenUpdate = () => {
    this.setState({
      modelOpenUpdate : !this.state.modelOpenUpdate
    })
  }

  toggleModelBulkOpen = () => {
    this.setState({
      modelBulkOpen : !this.state.modelBulkOpen
    })
  }

  toggleModelOpenAddField = () => {
    this.setState({
      modelOpenAddField : !this.state.modelOpenAddField
    })
  }

  toggleMDCModelOpenUpdate = () => {
    this.setState({
        mdcModelOpenUpdate: !this.state.mdcModelOpenUpdate
    })
  }
  
  setTableVisible = (value) => {
    this.setState({
      tableVisible: value
    })
  } 
  setChangedData = (value) => {
    this.setState({
      changedData: value
    })
  } 

  setFormDetails = (resources, controls,scanSource) => {
    this.setState({
      resource: resources,
      control: controls,
      scanSource: scanSource
    })
  }

  setControlFilteredDetails = (controls) => {
    this.setState({
      controlFiltered: controls,
    })
  }

  setSelectedRow = (value) => {
    this.setState({
      selectedRow: value
    })
  }

  toggleControlPanel = () => {
    this.setState({
      controlPanel: !this.state.controlPanel
    })
  }

  setIsEnabled = (value) => {
    this.setState({
      isEnabled: value
    })
  }

  toggleModelOpenToEnable = () => {
    this.setState({
      modelOpenToEnable : !this.state.modelOpenToEnable
    })
  }

  render() { 
    return (
      <AdminContext.Provider value={{
        ...this.state, 
        setFormDetails: this.setFormDetails,
        toggleControlPanel: this.toggleControlPanel,
        setSelectedRow: this.setSelectedRow,
        setChangedData: this.setChangedData,
        setTableVisible: this.setTableVisible,
        setLoadingTable : this.setLoadingTable,
        toggleModelOpenUpdate: this.toggleModelOpenUpdate,
        toggleModelOpenAddField: this.toggleModelOpenAddField,
        toggleMDCModelOpenUpdate: this.toggleMDCModelOpenUpdate,
        toggleModelBulkOpen: this.toggleModelBulkOpen,
        setControlFilteredDetails: this.setControlFilteredDetails,
        setBulkEditRows: this.setBulkEditRows,
        setIsEnabled : this.setIsEnabled,
        toggleModelOpenToEnable: this.toggleModelOpenToEnable
      }}
      >
        {this.props.children}
      </AdminContext.Provider>
    );
  }
}
 
export default AdminContextProvider;