import React, { useContext } from "react";
import { DirectionalHint, HoverCard, HoverCardType, IColumn, Icon, IPlainCardProps, Link } from "@fluentui/react";
import { ControlAttestedIcon, CriticalIcon, DefaultIcon, DefaultTimeIcon, ExemptedIcon, FailedIcon, HighIcon,
    HighIconTime, iconNames, LowIcon, MedIcon, MedIconTime, PassedIcon, UnknownIcon, VerifyIcon } from './../../Styles/Table/IconStyles';
import { IControlObject, ITableIndividualRowState, IResourceObject, IStatusObject, IStatusReasonObject } from '../../Interface/ITable';
import { onRenderPlainCardControl, onRenderPlainCardJustification, onRenderPlainCardReason, onRenderPlainCardResource, onRenderPlainCardResourceGroup } from '../../Styles/Table/HoverCard';
import { PanelContext } from "../../context/PanelContext";

export function RenderItemColumn(item: ITableIndividualRowState, index: Number, column: IColumn) : JSX.Element {
    const RuntimeConfigurationExtended = window.__UI_CONFIGURATION_EXTENDED_AZURE__;
    const targetRef = React.useRef<HTMLElement | null>(null);
    const fieldContent = item[column.fieldName as keyof ITableIndividualRowState];
    const {toggleSidePanel, setControlRow, setPanelMode} = usePanel()
    switch (column.key) {
        case 'control':
            if(fieldContent === "Control Name") {
                return (<span>{fieldContent}</span>);
            }

            if(RuntimeConfigurationExtended.controlWikiBaseURL.length > 0) {
                return (<Link href={RuntimeConfigurationExtended.controlWikiBaseURL + fieldContent} target="_blank">{fieldContent}</Link>);
            }

            return (<span>{fieldContent}</span>);

        case 'controlObject':
            var controlObj = fieldContent as IControlObject;
            const plainCardPropsControl: IPlainCardProps = {
                onRenderPlainCard: onRenderPlainCardControl,
                renderData: controlObj
            };

            if(RuntimeConfigurationExtended.controlWikiBaseURL.length > 0) {
                return (
                    <HoverCard cardOpenDelay={100} plainCardProps={plainCardPropsControl} instantOpenOnClick type={HoverCardType.plain}>
                        <Link onClick={()=>{setControlRow(controlObj);toggleSidePanel(); setPanelMode("Control");}} target="_blank">{controlObj.displayName}</Link>
                    </HoverCard>
                );
            }

            return (
                <HoverCard cardOpenDelay={100} plainCardProps={plainCardPropsControl} instantOpenOnClick type={HoverCardType.plain}>
                    <span>{controlObj.displayName}</span>
                </HoverCard>
            );   

        case 'status':
            var statusObject = fieldContent as IStatusObject;
            
            if(statusObject.status === "Failed") {
                return (
                    <span>
                        <FailedIcon />
                        {statusObject.status}
                    </span>
                );
            }
            else if(statusObject.status === "Passed") {
                return (
                    <span>
                        <PassedIcon />
                        {statusObject.status}
                    </span>
                );
            }
            else if(statusObject.status === "ExtScanned"){
                return (
                    <span>
                        <UnknownIcon />
                        {statusObject.status}
                    </span>
                );
            }
            else if(statusObject.status === "Passed*"){
                return (
                    <span>
                        <ControlAttestedIcon />
                        {statusObject.status}
                    </span>
                );
            }

            else if(statusObject.status === "Exempted") {
                return (
                    <span>
                        <ExemptedIcon />
                        {statusObject.status}
                    </span>
                );
            }
            else if(statusObject.status === "Verify") {
                return (
                    <span>
                        <VerifyIcon />
                        {statusObject.status}
                    </span>
                );
            }  
            else if(statusObject.status === "Not Scanned") {
                return (
                    <span>
                        <DefaultIcon />
                        {statusObject.status}
                    </span>
                );
            }

            return (<span>{statusObject.status}</span>);
        
        case 'statusReason':
            var statusReasonObject = fieldContent as IStatusReasonObject;
            const plainCardPropsReason: IPlainCardProps = {
                onRenderPlainCard: onRenderPlainCardReason,
                renderData: statusReasonObject.statusReason
            };
            

            if(statusReasonObject.statusReason) {
                return (
                    <span style={{whiteSpace:"nowrap",overflow:"scroll", textOverflow:"ellipsis" }}>
                        <HoverCard cardOpenDelay={100} plainCardProps={plainCardPropsReason} instantOpenOnClick type={HoverCardType.plain} >               
                            <span> 
                                {statusReasonObject.statusReason}
                            </span>        
                        </HoverCard>                                 
                    </span>
                );
            }
            
            return (<span></span>);
        
        case 'justification':
            const plainCardPropsJustification: IPlainCardProps = {
                onRenderPlainCard: onRenderPlainCardJustification,
                renderData: fieldContent
            };

            if(fieldContent) {
                return (
                    <span>
                        <HoverCard cardOpenDelay={100} plainCardProps={plainCardPropsJustification} instantOpenOnClick type={HoverCardType.plain}>
                            <div>
                                <Icon 
                                    iconName="MessageFill" 
                                    className={iconNames.justification}
                                />
                                {fieldContent} 
                            </div>
                        </HoverCard>                                       
                    </span>
                );
            }

            return(<span></span>);

        case 'expiry':
            if(fieldContent === "") {
                return (<span>NA</span>);
            }
            else if(Number(fieldContent) <= 7) {
                return (
                    <span>
                        <HighIconTime />
                        {fieldContent + " days"}
                    </span>
                );
            }
            else if(Number(fieldContent) <= 30) {
                return (
                    <span>
                        <MedIconTime />
                        {fieldContent + " days"}
                    </span>
                );
            }
            else if(Number(fieldContent) > 30) {
                return (
                    <span>
                        <DefaultTimeIcon />
                        {fieldContent + " days"}
                    </span>
                );
            }

            return (<span>{fieldContent}</span>);

        case 'severity':
            if(fieldContent === "Low") {
                return (
                    <span>
                        <LowIcon />
                        {fieldContent}
                    </span>
                );
            }
            else if(fieldContent === "High") {
                return (
                    <span>
                        <HighIcon />
                        {fieldContent}
                    </span>
                );
            }
            else if(fieldContent === "Critical") {
                return (
                    <span>
                        <CriticalIcon />
                        {fieldContent}
                    </span>
                );
            }
            else if(fieldContent === "Medium") {
                return (
                    <span>
                        <MedIcon />
                        {fieldContent}
                    </span>
                );
            }

            return (<span>{fieldContent}</span>);

        case 'resource':
            var dataObject = fieldContent as IResourceObject;
            const plainCardPropsResource: IPlainCardProps = {
                onRenderPlainCard: onRenderPlainCardResource,
                renderData: dataObject,
                directionalHint: DirectionalHint.rightCenter,
                styles:{
                    root: {
                        boxShadow:'none !important',
                    }
                }
            };

            if(RuntimeConfigurationExtended.azureAPI.length > 0 )
            {
                return(
                    
                    <HoverCard cardOpenDelay={50} plainCardProps={plainCardPropsResource} instantOpenOnClick type={HoverCardType.plain} target={targetRef.current}
                    ><span ref={targetRef}><Link href={RuntimeConfigurationExtended.azureAPI + "#@" + dataObject.parentName + "/resource" + dataObject.resourceID} target="_blank">{dataObject.resource}</Link></span>
                    </HoverCard>
                    
                );
            };

            return (
                <span>
                    <HoverCard cardOpenDelay={50} plainCardProps={plainCardPropsResource} instantOpenOnClick type={HoverCardType.plain} target={targetRef.current}>
                    <span ref={targetRef}>{dataObject.resource}</span>
                    </HoverCard>
                </span>
            );

        case 'rg' :
            const plainCardPropsResourceGroup: IPlainCardProps = {
                onRenderPlainCard: onRenderPlainCardResourceGroup,
                directionalHint: DirectionalHint.rightCenter,
                renderData:fieldContent,
                styles:{
                    root: {
                        boxShadow:'none !important',
                    }
                }

            };
            return (
                <HoverCard cardOpenDelay={50} plainCardProps={plainCardPropsResourceGroup} instantOpenOnClick type={HoverCardType.plain} target={targetRef.current}>               
                <span ref={targetRef}>{fieldContent}</span>
                </HoverCard> 
            );

        default:
            return <span>{fieldContent.toString()}</span>;
    }
}


export const usePanel = () =>
{
    const { toggleSidePanel ,setResourceRow, setControlRow,setPanelMode} = useContext(PanelContext);
    return {toggleSidePanel,setResourceRow, setControlRow,setPanelMode,}
}