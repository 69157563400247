import React, { useContext, useState }  from 'react';
import { IButtonProps, IconButton } from '@fluentui/react/lib/Button';
import { ITeachingBubbleStyles, Stack, TeachingBubble } from '@fluentui/react';
import { TeachingContext } from './context/TeachingContext';

const teachingAzure = [
    "This tour will guide you on how to use this tool.",
    "Use this section to refine your filter criteria.",
    "This table allows you to view records according to filter criteria.",
    "This action button will allow you to perform actions like requesting subscriptions for scan."
];

const teachingDevOps = [
    "This tour will guide you on how to use this tool.",
    "Use this section to refine your filter criteria.",
    "This table allows you to view records according to filter criteria.",
    "This action button will allow you to download scan results"
];

export const InfoTable: React.FunctionComponent = () => {
  const RuntimeConfigurationExtended = window.__UI_CONFIGURATION_EXTENDED_AZURE__;

  const {teachingBubbleVisibleTable, toggleTeachingBubbleVisibleTable} = useContext(TeachingContext);
  const {teachingBubbleVisibleAction, toggleTeachingBubbleVisibleAction} = useContext(TeachingContext);
  const {teachingBubbleVisibleInitial, toggleTeachingBubbleVisibleInitial} = useContext(TeachingContext);
  const {teachingBubbleVisibleFilter, toggleTeachingBubbleVisibleFilter} = useContext(TeachingContext);
  const [teachingArray, setTeachingArray] = useState(teachingAzure);

  const initialNext: IButtonProps = React.useMemo(
      () => ({
          children: 'Next',
          onClick: function() {
              toggleTeachingBubbleVisibleInitial();
              toggleTeachingBubbleVisibleFilter();            
          },
      }),
      // eslint-disable-next-line
      [],
  );

  const filterPrevious: IButtonProps = React.useMemo(
    () => ({
        children: 'Previous',
        onClick: function() {
            toggleTeachingBubbleVisibleInitial();
            toggleTeachingBubbleVisibleFilter();            
        },
    }),
    // eslint-disable-next-line
    [],
  );

  window.addEventListener('storage', () => {
    if(localStorage.getItem("scanner") === "azure"){
        setTeachingArray(teachingAzure);
    }
    else{
        setTeachingArray(teachingDevOps);
    }
  });

  const filterNext: IButtonProps = React.useMemo(
      () => ({
          children: 'Next',
          onClick: function() {
              toggleTeachingBubbleVisibleFilter(); 
              toggleTeachingBubbleVisibleTable();           
          },
      }),
      // eslint-disable-next-line
      [],
  );

    
  const tablePrevious: IButtonProps = React.useMemo(
      () => ({
          children: 'Previous',
          onClick: function() {
              toggleTeachingBubbleVisibleFilter();
              toggleTeachingBubbleVisibleTable();            
          },
      }),
      // eslint-disable-next-line
      [],
  );

  const tableNext: IButtonProps = React.useMemo(
      () => ({
          children: 'Next',
          onClick: function() {
              toggleTeachingBubbleVisibleAction();
              toggleTeachingBubbleVisibleTable();            
          },
      }),
      // eslint-disable-next-line
      [],
  );    

  const actionPrevious: IButtonProps = React.useMemo(
      () => ({
          children: 'Previous',
          onClick: function() {
              toggleTeachingBubbleVisibleAction();
              toggleTeachingBubbleVisibleTable();            
          },
      }),
      // eslint-disable-next-line
      [],
  );

  const actionNext: IButtonProps = React.useMemo(
      () => ({
          children: 'Finish',
          onClick: function() {
            toggleTeachingBubbleVisibleAction();         
          },
      }),
      // eslint-disable-next-line
      [],
  );

  const teachingStyles : Partial<ITeachingBubbleStyles> = {
      root: {
          transition:'0.5s'
      }
  }

  return (
    <>
      <Stack>
        <IconButton 
          style={{outline:"none"}} 
          id="table-info" 
          iconProps={{iconName:'Info'}}
          aria-label="Table Info"
          onClick={toggleTeachingBubbleVisibleTable}
        />

        {teachingBubbleVisibleInitial && (
            <TeachingBubble
                target="#initial-info"
                headline="Welcome to the Scanning Tool"
                hasCondensedHeadline={true}
                styles={teachingStyles}
                hasCloseButton={true}
                footerContent="1 of 4"
                primaryButtonProps={initialNext}
                onDismiss={toggleTeachingBubbleVisibleInitial}
                closeButtonAriaLabel='Close Info'
            >
                {teachingArray[0]}
            </TeachingBubble>
        )} 

        {teachingBubbleVisibleFilter && (
            <TeachingBubble
                target="#filter-info"
                headline="Select your services"
                hasCondensedHeadline={true}
                styles={teachingStyles}
                hasCloseButton={true}
                footerContent="2 of 4"
                primaryButtonProps={filterNext}
                secondaryButtonProps={filterPrevious}
                onDismiss={toggleTeachingBubbleVisibleFilter}
                closeButtonAriaLabel='Close Info'
            >
                {teachingArray[1]} 
            </TeachingBubble>
        )}


        {teachingBubbleVisibleTable && (
            <TeachingBubble
                target="#table-info"
                headline="View results according to criteria"
                hasCondensedHeadline={true}
                hasCloseButton={true}
                styles={teachingStyles}
                primaryButtonProps={tableNext}
                secondaryButtonProps={tablePrevious}
                footerContent="3 of 4"
                onDismiss={toggleTeachingBubbleVisibleTable}
                closeButtonAriaLabel='Close Info'
            >
                {teachingArray[2]}
                {localStorage.getItem("scanner") === "azure" &&
                    RuntimeConfigurationExtended.exceptionFeatureConfiguration?.isEnabled === true &&
                    (<p>Use the Exception Toggle to view the controls eligible for exception requests.</p>)
                }
            </TeachingBubble>
        )}     

        {teachingBubbleVisibleAction && (
            <TeachingBubble
                target="#toggle-info"
                headline="Get familiar with the Action Button"
                hasCondensedHeadline={true}
                styles={teachingStyles}
                hasCloseButton={true}
                primaryButtonProps={actionNext}
                secondaryButtonProps={actionPrevious}
                footerContent="4 of 4"
                onDismiss={toggleTeachingBubbleVisibleAction}
                closeButtonAriaLabel='Close Info'
            >
                {teachingArray[3]}
                {localStorage.getItem("scanner") === "azure" &&
                    RuntimeConfigurationExtended.exceptionFeatureConfiguration?.isEnabled === true
                    ? (<span> This button can also be used for adding, clearing and renewing controls for exception.</span>)
                    : (<span>.</span>)
                }
            </TeachingBubble>
        )} 
      </Stack>
    </>
  );
};