import React, { Component, createContext } from 'react';

export const TeachingContext = createContext();

export class TeachingContextProvider extends Component {
  state = {
    teachingBubbleVisibleInitial: false,
    teachingBubbleVisibleFilter: false,
    teachingBubbleVisibleTable: false,
    teachingBubbleVisibleAction: false,
  }

  toggleTeachingBubbleVisibleInitial = () => {
    this.setState({
      teachingBubbleVisibleInitial: !this.state.teachingBubbleVisibleInitial
    })
  }
  
  toggleTeachingBubbleVisibleFilter = () => {
    this.setState({
      teachingBubbleVisibleFilter: !this.state.teachingBubbleVisibleFilter
    })
  }

  toggleTeachingBubbleVisibleSub = () => {
    this.setState({
      teachingBubbleVisibleSub: !this.state.teachingBubbleVisibleSub
    })
  }

  toggleTeachingBubbleVisibleControl = () => {
    this.setState({
      teachingBubbleVisibleControl: !this.state.teachingBubbleVisibleControl
    })
  }

  toggleTeachingBubbleVisibleTable = () => {
    this.setState({
      teachingBubbleVisibleTable: !this.state.teachingBubbleVisibleTable
    })
  }

  toggleTeachingBubbleVisibleAction = () => {
    this.setState({
      teachingBubbleVisibleAction: !this.state.teachingBubbleVisibleAction
    })
  }
  
  render() { 
    return (
      <TeachingContext.Provider value={{
        ...this.state, 
        toggleTeachingBubbleVisibleInitial: this.toggleTeachingBubbleVisibleInitial, 
        toggleTeachingBubbleVisibleFilter: this.toggleTeachingBubbleVisibleFilter, 
        toggleTeachingBubbleVisibleTable: this.toggleTeachingBubbleVisibleTable, 
        toggleTeachingBubbleVisibleAction: this.toggleTeachingBubbleVisibleAction, 
      }}
      >
        {this.props.children}
      </TeachingContext.Provider>
    );
  }
}
 
export default TeachingContextProvider;