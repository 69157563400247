import * as React from 'react';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { useContext, useState } from 'react';
import { panelClassNames } from './PersonaPanel';
import { ActionButton, DefaultButton, IStackTokens, Link, loadTheme, PrimaryButton, Stack, Text } from '@fluentui/react';
import { _Styles, jsonTreeTheme } from '../Page.styles';
import { defaultTheme } from '../Themes/DefaultTheme';
import { PanelContext } from '../context/PanelContext';
import CopyToClipboard from 'react-copy-to-clipboard';
import { authProvider } from '../../authProvider';
/*import ReactJson from 'react-json-view'*/
import { JSONTree } from 'react-json-tree';


const theme = loadTheme(defaultTheme);
export const ControlDetailsPanel: React.FunctionComponent = () => {

    const RuntimeConfigurationInitial = window.__UI_CONFIGURATION_INITIAL__;
    const RuntimeConfigurationExtended = window.__UI_CONFIGURATION_EXTENDED_AZURE__;
    const ScannerAPI = RuntimeConfigurationInitial.webAPIConf ? RuntimeConfigurationInitial.webAPIConf["AZURE"] : RuntimeConfigurationInitial.webAPI;
    const { sidePanel, toggleSidePanel, controlRow, panelMode, setPanelMode } = useContext(PanelContext);

    const [copy1, setCopy1] = useState<string>("Copy");
    const [copy2, setCopy2] = useState<string>("Copy");

    const containerStackTokens: IStackTokens = { childrenGap: 25 };
    const containerStackTokensChild: IStackTokens = { childrenGap: 0 };
    const statusReason: IStackTokens = { childrenGap: 5 };
    const removeRedirectionLinkRegExp = /redirectionLink /gi;
    let additionalInfo = controlRow.additionalInformation;

    try {
        if (additionalInfo) {
            //checking if additional info contains detection. If true, append redirectionLink to url property
            (
                additionalInfo.Detections ?? additionalInfo["Truncated Data"]?.Detections
            )?.forEach((detection) =>
                addRedirectionLink(detection, "ExposureLocationId")
            );
    
            //checking if additional info contains alerts. If true, append redirectionLink to url property
            (
                additionalInfo.Alerts ?? additionalInfo["Truncated Data"]?.Alerts
            )?.forEach((alert) => addRedirectionLink(alert, "AlertUri"));
        }
    } catch (error) {
        console.log(error.toString());
    }

    function addRedirectionLink(item, key) {
        try {
            if (item && item[key]) {
                item[key] =
                    item[key]?.includes("https://portal.azure.com/") &&
                        !item[key]?.includes("redirectionLink")
                        ? `redirectionLink ${item[key]}`
                        : item[key];
            }
        } catch (error) {
            console.log(error.toString());
        }
    }
    
    const onRenderHeader = React.useCallback(
        () => (
            <Stack style={{ marginBottom: 30 }}>
                <Stack style={{ marginLeft: 25, marginRight: 25 }} tokens={{ childrenGap: -5 }}>
                    <Stack horizontal tokens={{ childrenGap: 15 }} horizontalAlign='space-between' >
                        <Stack horizontal tokens={{ childrenGap: 0 }}>

                            <Text variant='xxLarge'>
                                Control Evaluation Details
                            </Text>

                        </Stack>
                    </Stack>
                    <Text variant='large' style={{ color: theme.palette.themePrimary, fontWeight: 600 }} nowrap>
                        {controlRow.name}
                    </Text>

                </Stack>
            </Stack>
        ),
        // eslint-disable-next-line
        [panelMode, controlRow]
    );

    const onRenderFooterContent = React.useCallback(
        () => (
            <Stack horizontal horizontalAlign="space-between">
                <PrimaryButton
                    iconProps={{ iconName: "BookAnswers" }}
                    text="Go to Remediation Guide"
                    onClick={() => {
                        window.open(
                            RuntimeConfigurationExtended.controlWikiBaseURL +
                            controlRow.name,
                            "_blank"
                        );
                    }}

                //style={{padding:0, marginTop:15, outline:'none', background: theme.palette.neutralLighter}}
                />
                <PrimaryButton
                    text="Enforcement Policy"
                    disabled={!(controlRow.name as string).includes("_KR51")}
                    onClick={() => {
                        window.open(
                            RuntimeConfigurationExtended.policiesConfiguration.policiesRedirectionLink + "/" + controlRow.name + "/" + controlRow.featureName
                        )
                    }}
                />
            </Stack>
        ),
        [RuntimeConfigurationExtended.controlWikiBaseURL, RuntimeConfigurationExtended.policiesConfiguration.policiesRedirectionLink, controlRow.name, controlRow.featureName]
    );

    function _pad2(n: number): string {
        return n < 10 ? '0' + n.toString() : n.toString();
    }



    function _getDate(): string {
        var date = new Date();
        return (date.getFullYear().toString() +
            _pad2(date.getMonth() + 1) +
            _pad2(date.getDate()) +
            _pad2(date.getHours()) +
            _pad2(date.getMinutes()) +
            _pad2(date.getSeconds()));
    }
    function download() {
        if (controlRow.additionalInformation != undefined && controlRow.additionalInformation["Truncated Data"] != undefined) {
            const CallAPI = async () => {
                const authToken = await authProvider.getAccessToken({ scopes: ["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"] });

                let body = {
                    ResourceId: controlRow.resourceId,
                    ControlId: controlRow.name
                };

                fetch(ScannerAPI + `/subscription/${encodeURIComponent(controlRow.subscriptionId)}/scanResultAdditionalInformation`, {
                    headers: !authToken.accessToken ? {} : {
                        'Authorization': `Bearer ${authToken.accessToken}`,
                        'Content-Type': 'application/json',
                        'u_Id': sessionStorage.getItem("u_Id")
                    },
                    method: 'POST',
                    body: JSON.stringify(body)
                })
                    .then(response => {
                        if (response.ok) {
                            response.json()
                                .then(data => {
                                    downloadAction(new Blob([(JSON.stringify(data, null, 4).replace(removeRedirectionLinkRegExp, ""))], { type: "text/json" }));
                                })
                                .catch(_ => {
                                    downloadAction(_getBlob());
                                });
                        }
                        else {
                            downloadAction(_getBlob());
                        }
                    })
                    .catch(_ => {
                        downloadAction(_getBlob());
                    });
            };
            CallAPI();
        }
        else {
            downloadAction(_getBlob());
        }
    }

    function downloadAction(blob) {
        var blob_url = URL.createObjectURL(blob);
        var link = document.createElement("a");
        link.href = blob_url;
        var timestamp = _getDate();
        link.download = controlRow.name + timestamp + ".json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function _getBlob(): Blob {
        return new Blob([(JSON.stringify(controlRow.additionalInformation, null, 4).replace(removeRedirectionLinkRegExp, ""))], { type: "text/json" });
    }

    const togglePanel = () => {
        toggleSidePanel();
        setCopy1("Copy");
        setCopy2("Copy");
    }
    return (
        <div >
            <Panel
                isOpen={sidePanel}
                isLightDismiss
                type={PanelType.custom}
                headerClassName={panelClassNames.headerClass}
                customWidth={'40%'}
                hasCloseButton={false}
                onDismiss={togglePanel}
                isFooterAtBottom={true}
                onRenderFooterContent={onRenderFooterContent}
                onRenderHeader={onRenderHeader}
                //headerText={selectedcontrolRow.name}
                // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                closeButtonAriaLabel="Close"
            >
                <Stack tokens={containerStackTokens} >
                    {localStorage.getItem("scanner") === "azure" &&
                        <Stack tokens={containerStackTokensChild}>
                            <Text style={{ fontWeight: 600 }} variant='large'>Display Name</Text>
                            <Link href={RuntimeConfigurationExtended.controlWikiBaseURL + controlRow.name} target="_blank">{controlRow.actualDisplayName}</Link>
                        </Stack>
                    }

                    {localStorage.getItem("scanner") === "azure" &&
                        <Stack tokens={containerStackTokensChild}>
                            <Text style={{ fontWeight: 600 }} variant='large'>Description</Text>
                            <Text>{controlRow.description}</Text>
                        </Stack>
                    }

                    {localStorage.getItem("scanner") === "azure" &&
                        <Stack tokens={containerStackTokensChild}>
                            <Text style={{ fontWeight: 600 }} variant='large'>Category</Text>
                            <Text>{controlRow.category}</Text>
                        </Stack>
                    }

                    {localStorage.getItem("scanner") === "azure" &&
                        <Stack tokens={containerStackTokensChild}>
                            <Text style={{ fontWeight: 600 }} variant='large'>ResourceGroup Name</Text>
                            <Text>{controlRow.rg}</Text>
                        </Stack>
                    }

                    <Stack tokens={containerStackTokensChild}>
                        <Text style={{ fontWeight: 600 }} variant='large'>Resource Name</Text>
                        <Text>{controlRow.resource}</Text>
                    </Stack>

                    {controlRow.statusReason &&
                        <Stack tokens={containerStackTokensChild}>
                            <Stack horizontal horizontalAlign='space-between'>
                                <Text style={{ fontWeight: 600 }} variant='large'>Status Reason</Text>
                                <CopyToClipboard text={controlRow.statusReason}>
                                    <ActionButton
                                        iconProps={copy2 === "Copy" ? { iconName: 'Copy' } : { iconName: 'CheckMark' }}
                                        text={copy2}
                                        onClick={() => {
                                            setCopy2("Copied");
                                            setTimeout(() => {
                                                setCopy2("Copy")
                                            }, 3000);
                                        }
                                        }
                                        style={{ marginTop: -5, outline: "none" }}
                                    />
                                </CopyToClipboard>
                            </Stack>
                            <Stack className={copy2 === "Copy" ? _Styles.scrollablePanelStyles : _Styles.scrollablePanelStylesCopied}>
                                <Text>{controlRow.statusReason}</Text>
                            </Stack>

                        </Stack>
                    }

                    {JSON.stringify(controlRow.additionalInformation) !== "{}" &&
                        <Stack tokens={statusReason}>
                            <Stack horizontal horizontalAlign='space-between'>
                                <Text style={{ fontWeight: 600 }} variant='large'>Json Status Reason</Text>
                                <ActionButton
                                    onClick={download}
                                    iconProps={{ iconName: 'Download' }}
                                    text="Download"
                                    style={{ marginTop: -5, outline: "none" }}
                                />

                            </Stack>
                            <Stack className={_Styles.scrollablePanelStylesJson}>

                                <JSONTree data={additionalInfo} hideRoot={true} theme={jsonTreeTheme} invertTheme={false}
                                    labelRenderer={([key]) => <em style={{ color: "#002b36" }}>{key}</em>}
                                    valueRenderer={(raw: any) => {
                                        let href = null;

                                        if (typeof (raw) == 'string' && raw.indexOf("redirectionLink ") > -1) {
                                            href = raw.split("redirectionLink ")[1].split(`"`)[0]; //.split(`"`)[0]
                                        }

                                        return href ? <a href={href} style={{ color: "#cb4b16" }} target='_blank'>{href}</a> : <span>{raw}</span>
                                    }}
                                />
                            </Stack>
                        </Stack>
                    }

                    {controlRow.justification &&
                        <Stack tokens={containerStackTokensChild}>
                            <Stack horizontal horizontalAlign='space-between'>
                                <Text style={{ fontWeight: 600 }} variant='large'>Justification</Text>
                                <CopyToClipboard text={controlRow.justification}>
                                    <ActionButton
                                        iconProps={copy1 === "Copy" ? { iconName: 'Copy' } : { iconName: 'CheckMark' }}
                                        text={copy1}
                                        onClick={() => {
                                            setCopy1("Copied");
                                            setTimeout(() => {
                                                setCopy1("Copy")
                                            }, 3000);
                                        }
                                        }
                                        style={{ marginTop: -5, outline: "none" }}
                                    />
                                </CopyToClipboard>
                            </Stack>
                            <Stack className={copy1 === "Copy" ? _Styles.scrollablePanelStyles : _Styles.scrollablePanelStylesCopied}>
                                <Text >{controlRow.justification}</Text>
                            </Stack>
                        </Stack>
                    }
                </Stack>
            </Panel>
        </div>
    );
};

