import { authProvider } from './../../authProvider';
import { useEffect } from 'react';

export const ConfigHelper = (props) => {
    const RuntimeConfigurationInitial = window.__UI_CONFIGURATION_INITIAL__;

    useEffect(() => {
        const fetchUIConfiguration = async (scannerName: string, scannerUrl: string) => {
            const authToken = (await authProvider.getAccessToken({
                scopes: ["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]})).accessToken;
            fetch(scannerUrl + "/getconfig", {
                cache: 'no-cache',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {
                if (response.ok) {
                  return response.json();
                }
                return Promise.reject("Response code: " + response.status + ", message: " + (response.statusText === "" ? "NA" : response.statusText));
            })
            .then((config) => {
                props.configFetchCallback(config, null, scannerName);
            })
            .catch((error) => {
                props.configFetchCallback(null, error, scannerName);
            });
        }

        if(RuntimeConfigurationInitial["webAPIConf"]){
            for(var key in RuntimeConfigurationInitial["webAPIConf"]){
                fetchUIConfiguration(key,RuntimeConfigurationInitial["webAPIConf"][key]);
            }
        }
        else{
            fetchUIConfiguration("AZURE",RuntimeConfigurationInitial.webAPI);
        }
        
        
    });

  return null;
}