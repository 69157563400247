import React, { CSSProperties, useContext }  from 'react';
import { DefaultButton, IconButton } from '@fluentui/react/lib/Button';
import { ContextualMenu, IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { Stack } from '@fluentui/react';
import { FormContext } from '../context/FormContext';
import { DialogContext } from '../context/DialogContext';
import { TeachingContext } from '../context/TeachingContext';
import { getTheme, mergeStyles } from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import { ClearDialog } from '../Modals/ClearDialog';
import { ExportDialogDevOps } from './ExportDialogDevOps';
import { SubmitDialogDevOps } from './SubmitDialogDevOps';
import { AddDialog } from '../Modals/AddDialog';

const theme = getTheme();

const rightButtonConf: CSSProperties = {
    boxShadow: theme.effects.elevation4,
    outline: "none",
}

const hideActionItemCSS = mergeStyles({display: 'none'});

export const DialogCartDevOps: React.FunctionComponent = () => {
    const RuntimeConfigurationExtended = window.__UI_CONFIGURATION_EXTENDED_AZURE__;

    const {exceptionDataAdd, exceptionDataClear, selectedWorkItems} = useContext(FormContext);
    const {toggleTeachingBubbleVisibleAction} = useContext(TeachingContext);
    const {toggleAdd, toggleClear, toggleSubmit, toggleExport} = useContext(DialogContext);
    
    const menuProps: IContextualMenuProps = {
          items: [
              // {
              //   key: 'submitScan',
              //   text:  "Submit for Scan",
              //   secondaryText: selectedWorkItems.length ? selectedWorkItems.length + " selected" : "",
              //   iconProps: { iconName: 'SiteScan', style: { color: SharedColors.blueMagenta10 } },
              //   disabled: selectedWorkItems.length ? false : true,
              //   onClick: toggleSubmit,
              // },
              {
                key: 'exportCSV',
                text:  "Export to CSV",
                secondaryText: selectedWorkItems.length ? selectedWorkItems.length + " selected" : "",
                iconProps: { iconName: 'Download', style: { color: SharedColors.red10 } },
                disabled: selectedWorkItems.length ? false : true,
                onClick: toggleExport,
              },
              // {
              //   key: 'addExceptions',
              //   text: "Add/Renew Exceptions",
              //   title: "Add/Renew Exceptions",
              //   secondaryText: exceptionDataAdd.length ? exceptionDataAdd.length + " selected" : "",
              //   iconProps: { iconName: 'AppIconDefaultAdd', style: { color: SharedColors.orangeYellow20 } },
              //   disabled: exceptionDataAdd.length ?  false : true,
              //   onClick: toggleAdd,
              //   className: RuntimeConfigurationExtended.exceptionFeatureConfiguration?.isEnabled !== true ? hideActionItemCSS : ""
              // },
              // {
              //   key: 'clearExceptions',
              //   text: "Clear Exceptions",
              //   title: "Clear Exceptions",
              //   secondaryText: exceptionDataClear.length ? exceptionDataClear.length + " selected" : "",
              //   disabled: exceptionDataClear.length ?  false : true,
              //   iconProps: { iconName: 'EraseTool', style: { color: SharedColors.green10 } },
              //   onClick: toggleClear,
              //   className: RuntimeConfigurationExtended.exceptionFeatureConfiguration?.isEnabled !== true ? hideActionItemCSS : ""
              // }
          ],
          directionalHintFixed: false,
      };

    function _getMenu(props: IContextualMenuProps): JSX.Element {
      // Customize contextual menu with menuAs
      return <ContextualMenu {...props} />;
    }

   return ( 
    <>
      <Stack horizontal reversed tokens={{childrenGap : 0}}  horizontalAlign="space-between">
        <IconButton 
          style={{outline:"none"}} 
          id="toggle-info" 
          aria-label="Action Info"
          iconProps={{iconName:'Info'}}
          onClick={toggleTeachingBubbleVisibleAction}
        />
        
        <DefaultButton id="submit-button" 
          iconProps={{iconName: 'LightningBolt'}} 
          disabled={!((selectedWorkItems ? selectedWorkItems.length : 0 ) + exceptionDataAdd.length + exceptionDataClear.length)}
          menuProps={menuProps}
          menuAs={_getMenu}
          text={"Action"} 
          persistMenu={true}
          style={rightButtonConf} 
        />      
      </Stack>

      { RuntimeConfigurationExtended.exceptionFeatureConfiguration?.isEnabled === true && (<ClearDialog />) }
      <ExportDialogDevOps />     
      <SubmitDialogDevOps />
      { RuntimeConfigurationExtended.exceptionFeatureConfiguration?.isEnabled === true && (<AddDialog />) }
    </>
  );
};
