import React from 'react';
import {Link, Stack, Text} from "@fluentui/react"
import { PanelContext } from '../context/PanelContext';


// State object for ReactTextRotator
export interface ITextRotateState {
  textIdx: number;
  isVisibleDUKPanel: boolean;
}



// Time after which text changes
const intervalForChange = 15000;

// Component to rotate Did you know text after interval
class ReactTextRotator extends React.Component<{}, ITextRotateState> {
  timeout: number;
  static contextType = PanelContext;

  constructor(props?) {
    super(props);
    this.state = { 
      textIdx: 0,
      isVisibleDUKPanel: false
    };

  }

  componentDidMount() {
    // Change text after interval
    var textDUKArray = []
    if(localStorage.getItem("scanner") === "devops"){
      textDUKArray = this.context.textDUKDevOpsArray
    }
    else{
      textDUKArray = this.context.textDUKAzureArray
    }
    this.timeout = window.setInterval(() => {
      let currentIdx = this.state.textIdx 
      this.setState({ textIdx: (currentIdx + 1)% textDUKArray.length});
    }, intervalForChange);
  }

  componentDidUnmount() {
    clearInterval(this.timeout);
  }

  render() {
    var textDUKArray = []
    if(localStorage.getItem("scanner") === "devops"){
      textDUKArray = this.context.textDUKDevOpsArray
    }
    else{
      textDUKArray = this.context.textDUKAzureArray
    }
    let textThatChanges = textDUKArray[this.state.textIdx % textDUKArray.length];

    return (
      <div>
        <Stack horizontal tokens={{childrenGap:10}}>
            <Text variant='medium' style={{fontWeight: 600}}>Did you know ?</Text>
            <Link onClick={this.context.toggleDidYouKnowPanel}>See all</Link>                                            
        </Stack>
        <Text variant="smallPlus" nowrap={false}>{textThatChanges}</Text>
      </div>
      
    )
  }
}

export default ReactTextRotator;