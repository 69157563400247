import React, { useContext, useEffect } from 'react';
import { Text, Stack,  getTheme, DefaultButton,IDropdownOption, Dropdown, IStackTokens, PrimaryButton, Separator, CommandBarButton, mergeStyleSets, Spinner, MessageBarType, MessageBar, IIconProps, IStackStyles, NeutralColors, TextField, ComboBox, IComboBox, IComboBoxOption, DialogFooter, Dialog, DialogType} from '@fluentui/react';
import { authProvider } from '../../authProvider';
import { toast } from 'react-toastify';
import { ComplianceInitiative } from '../context/ComplianceInitiativeMappingEditor';
import { _Styles ,ComplianceInitiativeStyle, ComplianceInitiativeFilterStyle, ComplianceInitiativeHeaderStyle} from '../Page.styles';
import {PanelContext} from '../context/PanelContext';
import { ChangeLog } from '../Interface/IAdminTable';
import { ErrorAPIMessage, SuccessExampleException, SuccessMessage } from './MessageBar';
import { AddMG, CITitleAndTag, DeleteMGTagLabel, FetchMG, FetchMGTag, FetchTenant, MGLevel1, MGLevel2, MGLevel3, MGLevel4, MGLevel5, MGLevel6, MGLevelRoot, SubsTag, TenantLevel } from './Constants';
enum FilterLevel{
    Level0 = 0,
    Level1 = 1,
    Level2 = 2,
    Level3 = 3,
    Level4 = 4,
    Level5 = 5,
    Level6 = 6,
    Level7 = 7,
    LevelRoot = 8
}

enum actionType{
    None = 0,
    Save = 1,
    Add = 2,
    Delete = 3

}

const RuntimeConfigurationInitial = window.__UI_CONFIGURATION_INITIAL__;
const ScannerAPI = RuntimeConfigurationInitial.webAPIConf ? RuntimeConfigurationInitial.webAPIConf["AZURE"] : RuntimeConfigurationInitial.webAPI;
  
export const TreeComplainceInitiativeEditor: React.FunctionComponent = () => {

    const RuntimeConfigurationExtended = window.__UI_CONFIGURATION_EXTENDED_AZURE__;
    const {complianceInitiativePanel, toggleComplianceInitiativePanel,setControlTagList,setComplianceInitiativeDescription,setComplianceInitiativeId,setDefaultControlTagFilter,setShowControlDisplayName,complianceInitiativeId,panelMode,setPanelMode,logs,setLogs,imageURL,setImageURL,controlTagList,visibleLogs,setVisibleLogs} = useContext(PanelContext);
    const [LoadingFilter, setLoadingFilter] = React.useState<boolean>(false);
    const [LoadingFilter1, setLoadingFilter1] = React.useState<boolean>(false);
    const [LoadingFilter2, setLoadingFilter2] = React.useState<boolean>(false);
    const [LoadingFilter3, setLoadingFilter3] = React.useState<boolean>(false);
    const [LoadingFilterTenant, setLoadingFilterTenant] = React.useState<boolean>(false);
    const [ManagementGroupName, setManagementGroupName] = React.useState<string>(RuntimeConfigurationInitial.tenantId);
    const [MGTenantId, setMGTenantId] = React.useState<string>("");
    const [Level ,setLevel] = React.useState<number>(RuntimeConfigurationExtended.isMultiTenantSetup ? FilterLevel.Level0:FilterLevel.Level1);
    const {tagsList, tag,setTag,setTagList,initialTag, setInitialTag,setManagementGroupId,setAction,ManagementGroupId,action,setComplianceInitiativeList,complianceInitiativeList,setComplianceInitiativeMappingList,complianceInitiativeMappingList,setLocalTag,setCIState,ciState,setMasterTagList,masterTagList,setDefaultCITagList,defaultCITagList} = useContext(ComplianceInitiative);
    const [DropDown1, setDropDown1] = React.useState<boolean>(true);
    const [DropDownRoot, setDropDownRoot] = React.useState<boolean>(false);
    const [DropDown2, setDropDown2] = React.useState<boolean>(false);
    const [DropDown3, setDropDown3] = React.useState<boolean>(false);
    const [DropDown4, setDropDown4] = React.useState<boolean>(false);
    const [DropDown5, setDropDown5] = React.useState<boolean>(false);
    const [DropDown6, setDropDown6] = React.useState<boolean>(false);
    const [DropDown7, setDropDown7] = React.useState<boolean>(false);
    const [Selection1, setSelection1] = React.useState<string>(RuntimeConfigurationExtended.isMultiTenantSetup ? "": RuntimeConfigurationInitial.tenantId);
    const [Selection2, setSelection2] = React.useState<string>("");
    const [SelectionRoot, setSelectionRoot] = React.useState<string>("");
    const [Selection3, setSelection3] = React.useState<string>("");
    const [Selection4, setSelection4] = React.useState<string>("");
    const [Selection5, setSelection5] = React.useState<string>("");
    const [Selection6, setSelection6] = React.useState<string>("");
    const [Selection7, setSelection7] = React.useState<string>("");
    const [Options1, setOptions1] = React.useState<IComboBoxOption[]>(RuntimeConfigurationExtended.isMultiTenantSetup ? []:[{key: RuntimeConfigurationInitial.tenantId, text:RuntimeConfigurationInitial.tenantId}]);
    const [Options2, setOptions2] = React.useState<IComboBoxOption[]>([]);
    const [OptionsRoot, setOptionsRoot] = React.useState<IComboBoxOption[]>([]);
    const [Options3, setOptions3] = React.useState<IComboBoxOption[]>([]);
    const [Options4, setOptions4] = React.useState<IComboBoxOption[]>([]);
    const [Options5, setOptions5] = React.useState<IComboBoxOption[]>([]);
    const [Options6, setOptions6] = React.useState<IComboBoxOption[]>([]);
    const [Options7, setOptions7] = React.useState<IComboBoxOption[]>([]);
    const [TenantList, setTenantList] = React.useState<[]>([]);
    const [PanelVisible, setPanelVisible] = React.useState<boolean>(false);
    const [isPopupVisible, setIsPopupVisible] = React.useState<boolean>(false);
    const [showMessage, setShowMessage] = React.useState<boolean>(false);
    const [showMessageError, setShowMessageError] = React.useState<boolean>(false);
    const [errorResponse, setErrorResponse] = React.useState<string>("");
    const [successResponse, setSuccessResponse] = React.useState<string>("");
    const [successResponse1, setSuccessResponse1] = React.useState<string>("");
    const [showSuccessMessage, setShowSuccessMessage] = React.useState<boolean>(false);
    const [localCId , setLocalCId] = React.useState<"">();
    const [showerror,setShowError] = React.useState<boolean>(false);

    const stackTokens: IStackTokens = { childrenGap: 20,};
    const dropdownStyles = { dropdown: { width: 300 } };
    const containerStackTokensChild: IStackTokens = { childrenGap: 3 };
    const addIcon: IIconProps = { iconName: 'Add' };
    const saveIcon: IIconProps = { iconName: 'Save' };
    const logIcon: IIconProps = { iconName: 'OpenFile' };
    const deleteIcon: IIconProps = { iconName: 'Delete' };
    const dependencyIcon: IIconProps = { iconName: 'DependencyAdd' };
    
    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Delete Tag',
        closeButtonAriaLabel: 'Close',
        subText: "Are you sure you want to delete the '" + initialTag + "' tag?",
    };
    
    const stackStyles: Partial<IStackStyles> = { root: { height: 44 } };
    useEffect(() => {  
        setPanelVisible(false);
        if(RuntimeConfigurationExtended.isMultiTenantSetup)
        {
            GetMultiTenantList();            
        }
        else
        {
            GetChildMG("Tenant");   
        }

        GetComplianceInitiative();
        GetControlTags();
    },
    // eslint-disable-next-line
    []);   

    useEffect(()=>{
        setComplianceInitiativeId("");
        setComplianceInitiativeDescription("");
        setLocalTag("");
        setDefaultControlTagFilter("");
        setShowControlDisplayName("");
        if(!complianceInitiativePanel)
        {
            setPanelMode("");
            GetComplianceInitiative();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[complianceInitiativePanel])

    useEffect(()=>{
        setComplianceInitiativeId("");
        setComplianceInitiativeDescription("");
        setShowSuccessMessage(false);
        setShowMessage(false);
        setSuccessResponse("");
        setSuccessResponse1("");
        setShowSuccessMessage(false);
        setErrorResponse("");
        setShowMessageError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ManagementGroupId])

    useEffect(() => {  
        if(Level !==FilterLevel.Level0)
        {
            if(Level === FilterLevel.Level1)
            {
                GetChildMG("Tenant");
            }
            else
            {
                GetChildMG("MG");
            }
            
        }

        if(PanelVisible)
        {
            setShowMessageError(false);
            setShowMessage(false);
            setPanelVisible(false);
            setTag("");
            setComplianceInitiativeId("");
        }
    },
    // eslint-disable-next-line
    [Level]);

    useEffect(()=>{
        setShowError(false);
        setShowSuccessMessage(false);
        setShowMessage(false);
        setErrorResponse("");
        setShowMessageError(false);
        setCIState("");
    },[complianceInitiativeId, setCIState]);

    useEffect(()=>{

    },[showerror]);

    useEffect(() => {  
        if(action=== actionType.Save && tag !=="")
        {
            
            AddMGTag();
            if(ciState==="Update")
            {
                UpdateComplianceInitiative();
            }
            setShowMessage(false);
            setTag("");
            
        }

        if(action === actionType.Delete)
        {
            setIsPopupVisible(true);
            setShowSuccessMessage(false);
            setSuccessResponse1("");
        }

        if(action===actionType.Add && tag!=="")
        {
            setShowMessage(true);
            setShowSuccessMessage(false);
        }
        if(action===actionType.Add && tag==="")
        {
            setShowMessage(false);
            setShowSuccessMessage(false);
        }
        setShowMessageError(false);
    },
    // eslint-disable-next-line
    [action,initialTag,tag,ciState]);

    useEffect(() => {
        if(panelMode === 'changelog') {
            const getAuthToken = async () => {
                let headers = new Headers();
                let token = (await authProvider.getAccessToken()).accessToken;
                headers.append('Authorization', token.toString());
                headers.append('x-content-type-options', 'nosniff');
                headers.append('content-type', 'application/json');
                return headers;
            };
            getAuthToken().then((headers) => {
                var request_batch = [];
                var body = {};
                var increment = 1;
                var userDict = {};
                var reverseUserDict = {};
                logs.forEach(element => {
                    if(imageURL[element['user']] === undefined && reverseUserDict[element['user']] === undefined) {
                        var individual_request = {};
                        individual_request['id'] = increment.toString();
                        individual_request['method'] = 'GET';
                        individual_request['url'] = '/users/'+ element['user'] + '/photo/$value';
                        request_batch = request_batch.concat(individual_request);
                        userDict[increment] = element['user'];
                        reverseUserDict[element['user']] = increment;
                        increment += 1;
                    }
                });
                body['requests'] = request_batch;

                // TODO: Move all HTTP calls to a helper.
                if(request_batch.length > 0) {
                    fetch(`${RuntimeConfigurationExtended.graphAPI}/v1.0/$batch`, {
                        headers: headers,
                        method: 'POST',
                        body: JSON.stringify(body)
                    })
                    .then(response => response.json())
                    .then(data => {
                        var copyImageUrl = JSON.parse(JSON.stringify(imageURL));
                        data["responses"].forEach(element => {
                            if(element['status'] === 200) {
                                const byteCharacters = atob(element['body']);
                                const byteNumbers = new Array(byteCharacters.length);
                                for (let i = 0; i < byteCharacters.length; i++) {
                                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                                }
                                const byteArray = new Uint8Array(byteNumbers);
                                let image = new Blob([byteArray], { type: 'image/jpeg' });
                                let imageUrl = URL.createObjectURL(image);
                                copyImageUrl[userDict[element['id']]] = imageUrl;
                            }
                        });
                        setImageURL(copyImageUrl);
                    });
                }
            })
        }
        setTag("");
        setComplianceInitiativeId("");
        setShowMessage(false);
    },
    // eslint-disable-next-line
    [panelMode]);

    

    const processChildMG = (dataString : string) => {
        var data = JSON.parse(dataString);
        var option : IDropdownOption[] = [];
        for(var i=0; i<data.length; i++)
        {
            var name = "";
            if(data[i].managementGroupName)
            {
                name = data[i].managementGroupName;
            }
            else
            {
                name = data[i].managementGroupId;
            }
            option = option.concat({key:data[i].managementGroupId, text: name+"(" + data[i].managementGroupId+")"})
        }
        if(Level===FilterLevel.Level1)
        {
            setOptionsRoot(option);
        }
        if(Level===FilterLevel.LevelRoot)
        {
            setOptions2(option);
        }
        if(Level===FilterLevel.Level2)
        {
            setOptions3(option);
        }
        if(Level===FilterLevel.Level3)
        {
            setOptions4(option);
        }
        if(Level===FilterLevel.Level4)
        {
            setOptions5(option);
        }
        if(Level===FilterLevel.Level5)
        {
            setOptions6(option);
        }
        if(Level===FilterLevel.Level6)
        {
            setOptions7(option);
        }

        setLevel(FilterLevel.Level0);
        setLoadingFilter(false);
    }

    const processCITags = (complianceInitiativeId:string) => {
        var option : IComboBoxOption[] = [];
        for(var i=0; i<complianceInitiativeList.length; i++)
        {
            if(complianceInitiativeList[i].ComplianceInitiativeId === complianceInitiativeId)
            {
                complianceInitiativeList[i].SubscriptionTags.split(',').forEach((element: string) => {
                    option = option.concat({key:element, text: element});
                });
            }
            
        }
        setShowSuccessMessage(false);
        setShowMessage(false);
        setTagList(option);
        setTag("");
    }

    const processControlTags = (data:string) => {
        var tags = JSON.parse(data);
        var option : IDropdownOption[] = [];
        for(var i=0; i<tags.length; i++)
        {
            option = option.concat({key:tags[i], text: tags[i]})
        }
        setControlTagList(option);
    }
    const processMultiTenantList = (data:string) => {
        var tenantList = JSON.parse(data);
        var option : IComboBoxOption[] = [];
        for(var i=0; i<tenantList.tenants.length; i++)
        {
            if(tenantList.tenants[i].managementGroupId)
            {
                option = option.concat({key:tenantList.tenants[i].tenantId, text:tenantList.tenants[i].tenantName +"("+tenantList.tenants[i].tenantId +")"})
            }
            
        }
        setOptions1(option);
        setTenantList(tenantList);
        setLoadingFilterTenant(false);
    }

    const processGetTag = (data:string) => {
        var datafinal = JSON.parse(data);
        if(datafinal.tags !=="")
        {
            setInitialTag(datafinal.tags);
            setLocalCId(datafinal.complianceInitiativeId)
        }
    }

    const createLogs = async (comments:string) => {
        var changelog : ChangeLog[] = [];
            changelog = changelog.concat({
                user: "",
                date: "",
                comment: comments
            });
    
        const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
        fetch((ScannerAPI + '/complianceinitiatives/changelogs/update'), {
            headers: !authToken.accessToken ? {} : { 
                'Authorization': `Bearer ${authToken.accessToken}`,
                'u_Id': sessionStorage.getItem("u_Id"),
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(changelog)
        })
        .then(response => {
            if (response.ok) {
              console.log("Added logs..");
            }
            else {
                response.text().then(errorMsg => {
                    setErrorResponse(errorMsg.slice(0, 500));
                    setShowMessageError(true);
                });
            }
        });
    }

    const ProcessGetComplianceInitiative = (data:string) => {
        var complianceInitiative = JSON.parse(data);
        var masterList = [];
        var defaultCItags = [];
        if(complianceInitiative.length > 0)
        {
            var CIList = [];
            var CIMapping : IDropdownOption[] = [];
            for(var i=0;i<complianceInitiative.length;i++)
            {
                CIList.push({
                ComplianceInitiativeId : complianceInitiative[i].complianceInitiativeId,
                ComplianceInitiativeDescription: complianceInitiative[i].complianceInitiativeDescription,
                SubscriptionTags:complianceInitiative[i].subscriptionTags,
                DefaultControlTagFilter: complianceInitiative[i].defaultControlTagFilter,
                ShowControlDisplayName: complianceInitiative[i].showControlDisplayName
                })
                complianceInitiative[i].subscriptionTags.split(",").forEach(element=>{
                   if(element!=="")
                   {
                        masterList.push(element);
                   }
                    
                })
                defaultCItags.push(complianceInitiative[i].defaultControlTagFilter);
                CIMapping.push({key:complianceInitiative[i].complianceInitiativeId,text:complianceInitiative[i].complianceInitiativeId +"/" + complianceInitiative[i].defaultControlTagFilter});
            }
            setComplianceInitiativeList(CIList);
            setComplianceInitiativeMappingList(CIMapping);
            setMasterTagList(masterList);
            setDefaultCITagList(defaultCItags);
        } 
    }

    const UpdateComplianceInitiative = async () => {
        var tags = '';
        for(var i=0;i< tagsList.length;i++)
        {
            if(tags ==="")
            {
                tags = tagsList[i].key.toString();
            }
            else
            {
                tags = tags + "," +tagsList[i].key.toString();
            }
            
        }
        for(var i=0; i<complianceInitiativeList.length; i++)
        {
            if(complianceInitiativeList[i].ComplianceInitiativeId === complianceInitiativeId)
            {
                var body = {
                    ComplianceInitiativeId : complianceInitiativeList[i].ComplianceInitiativeId,
                    complianceInitiativeDescription: complianceInitiativeList[i].ComplianceInitiativeDescription,
                    SubscriptionTags:tags,
                    DefaultControlTagFilter: complianceInitiativeList[i].DefaultControlTagFilter,
                    ShowControlDisplayName: Boolean(complianceInitiativeList[i].ShowControlDisplayName)
                }
            }   
        }
        
        const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
        fetch((ScannerAPI + '/complianceinitiatives/update'), {
            headers: !authToken.accessToken ? {} : { 
                'Authorization': `Bearer ${authToken.accessToken}`,
                'u_Id': sessionStorage.getItem("u_Id"),
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(body)
        })
        .then(response => {
            if (response.ok) {
                setLoadingFilter1(false);
                setSuccessResponse1("Successfully updated compliance Initiative.");
                setCIState("");
                setShowSuccessMessage(true);
                createLogs("[Updated compliance initiative:"+body.ComplianceInitiativeId +"]");
            }
            else {
                response.text().then(errorMsg => {
                    setLoadingFilter(false);
                    setLevel(FilterLevel.Level0);
                    setLoadingFilter1(false);
                    toast(errorMsg.slice(0, 500));
                    setCIState("");
                });
            }
        });
    }

    
    const GetComplianceInitiative = async () => {
 
            const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
            fetch((ScannerAPI + '/complianceinitiatives/list'), {
                headers: !authToken.accessToken ? {} : { 
                    'Authorization': `Bearer ${authToken.accessToken}`,
                    'u_Id': sessionStorage.getItem("u_Id"),
                    'Content-Type': 'application/json'
                },
                method: 'GET'
            })
            .then(response => {
                if (response.ok) {
                    response.json().then(data => {
                     ProcessGetComplianceInitiative(JSON.stringify(data)); 
                    });
                }
                else {
                    response.text().then(errorMsg => {
                        setLoadingFilter(false);
                        //setFilterFetchError(true);
                        toast(errorMsg.slice(0, 500));
                    });
                }
            });
    }

    const GetChildMG = async (mode:string) => {
        setLoadingFilter(true);

        var tenantId: string;
        var ManagementGroupIdLocal = ManagementGroupId;
        if (mode === 'Tenant') {
            setMGTenantId(ManagementGroupIdLocal.toString());
            tenantId = ManagementGroupIdLocal;
        }
        else {
            tenantId = MGTenantId;
        }
        
        const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
        fetch((ScannerAPI + '/managementgroups/' + (ManagementGroupIdLocal) + '/descendants/' + (mode) + '/Tenant/' + (tenantId)), {
            headers: !authToken.accessToken ? {} : { 
                'Authorization': `Bearer ${authToken.accessToken}`,
                'u_Id': sessionStorage.getItem("u_Id"),
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
        .then(response => {
            if (response.ok) {
                response.json().then(data => {
                 processChildMG(JSON.stringify(data)); 
                });
            }
            else {
                response.text().then(errorMsg => {
                    setLoadingFilter(false);
                    setLevel(FilterLevel.Level0);
                    toast(errorMsg.slice(0, 500));
                });
            }
        });
    }

    const GetMGTag = async () => {
        setLoadingFilter3(true);
        var tenantId = MGTenantId;
        const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
        fetch((ScannerAPI + '/managementgroups/' + (ManagementGroupId) + '/tag' + '/Tenant/' + (tenantId)), {
            headers: !authToken.accessToken ? {} : { 
                'Authorization': `Bearer ${authToken.accessToken}`,
                'u_Id': sessionStorage.getItem("u_Id"),
                'Content-Type': 'application/json'
            },
            method: 'GET',
        })
        .then(response => {
            if (response.ok) {
                response.json().then(data => {
                    setAction(actionType.None);
                    processGetTag(JSON.stringify(data)); 
                    setLoadingFilter3(false);
                 
                });
            }
            else {
                response.text().then(errorMsg => {
                    setLoadingFilter3(false);
                    setErrorResponse(errorMsg.slice(0, 500));
                    setShowMessageError(true);
                    
                    setAction(actionType.None);
                });
            }
        });
    }

    const AddMGTag = async () => {
        var body = {
            ManagementGroupId : ManagementGroupId,
            Tags: tag,
            ComplianceInitiativeId: complianceInitiativeId,
            TenantId: MGTenantId
        }
        setLoadingFilter1(true);
        const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
        fetch((ScannerAPI + '/managementgroups/tag'), {
            headers: !authToken.accessToken ? {} : { 
                'Authorization': `Bearer ${authToken.accessToken}`,
                'u_Id': sessionStorage.getItem("u_Id"),
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(body)
        })
        .then(response => {
            if (response.ok) {
                setInitialTag(tag); 
                setAction(actionType.None);
                setTag("");
                setLoadingFilter1(false);
                setSuccessResponse("Successfully added the tag.");
                setShowSuccessMessage(true);
                setLocalCId(complianceInitiativeId);
                createLogs("[Added ("+tag+") tag for MG:"+ManagementGroupName +"]");
            }
            else {
                response.text().then(errorMsg => {
                    setTag("");
                    setLoadingFilter1(false);
                    toast(errorMsg.slice(0, 500));
                    setShowSuccessMessage(false);
                });
            }
        });
    }

    const getLogs = async () => {
        setVisibleLogs(false);
        const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
        fetch((ScannerAPI + '/complianceinitiatives/changelogs/'), {
            headers: !authToken.accessToken ? {} : { 
                'Authorization': `Bearer ${authToken.accessToken}`,
                'u_Id': sessionStorage.getItem("u_Id"),
                'Content-Type': 'application/json'
            },
            method: 'GET',
        })
        .then(response => {
            if (response.ok) {
                response.json().then(data => {
                setLogs(data.reverse());
                setVisibleLogs(true);
                 
                });
            }
            else {
                response.text().then(errorMsg => {
                    setVisibleLogs(true);
                });
            }
        });
    }

    const DeleteMGTag = async () => {
        var body = {
            ManagementGroupId : ManagementGroupId,
            Tags: initialTag, 
            TenantId : MGTenantId
        }
        setLoadingFilter2(true);
        const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
        fetch((ScannerAPI + '/managementgroups/tag' ), {
            headers: !authToken.accessToken ? {} : { 
                'Authorization': `Bearer ${authToken.accessToken}`,
                'u_Id': sessionStorage.getItem("u_Id"),
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify(body)
        })
        .then(response => {
            if (response.ok) {
                setAction(actionType.None);
                setIsPopupVisible(false);
                setComplianceInitiativeId("");
                createLogs("[Removed ("+initialTag+") tag from MG:"+ManagementGroupName +"]");
                setTag("");
                setInitialTag(""); 
                setLoadingFilter2(false);
                setSuccessResponse("Successfully deleted the tag.");
                setShowSuccessMessage(true);
            }
            else {
                response.text().then(errorMsg => {
                    toast(errorMsg.slice(0, 500));
                    setLoadingFilter2(false);
                    setAction(actionType.None);
                });
            }
        });
    }

    const GetControlTags = async () => {
        const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
        fetch((ScannerAPI + '/complianceinitiatives/allcontroltags/' ), {
            headers: !authToken.accessToken ? {} : { 
                'Authorization': `Bearer ${authToken.accessToken}`,
                'u_Id': sessionStorage.getItem("u_Id"),
                'Content-Type': 'application/json'
            },
            method: 'GET',
        })
        .then(response => {
            if (response.ok) {
                response.json().then(data => {
                    processControlTags(JSON.stringify(data)); 
                });
            }
            else {
                response.text().then(errorMsg => {
                    toast(errorMsg.slice(0, 500));
                });
            }
        });
    }

    const GetMultiTenantList = async () => {
        setLoadingFilterTenant(true);
        var body = {Tenants:[]};
        const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
        fetch((ScannerAPI + '/complianceInitiatives/tenantdetails'), {
            headers: !authToken.accessToken ? {} : { 
                'Authorization': `Bearer ${authToken.accessToken}`,
                'u_Id': sessionStorage.getItem("u_Id"),
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(body)
        })
        .then(response => {
            if (response.ok) {
                response.json().then(data => {
                    processMultiTenantList(JSON.stringify(data)); 
                });
            }
            else {
                response.text().then(errorMsg => {
                    toast(errorMsg.slice(0, 500));
                });
            }
        });
    }


    const change1 = (ev: React.FormEvent<IComboBox>, selectedOption?: IComboBoxOption): void =>
    {
       if(selectedOption)
        {
           setManagementGroupId(selectedOption.key.toString());
           setManagementGroupName(selectedOption.text.toString());
           setMGTenantId(selectedOption.key.toString())
            setSelection1(selectedOption.key.toString());
            setLevel(FilterLevel.Level1);
            setDropDown1(true);
            setSelectionRoot("");
            setSelection2("");
            setSelection3("");
            setSelection4("");
            setSelection5("");
            setSelection6("");
            setSelection7("");
            setDropDownRoot(false);
            setDropDown2(false);
            setDropDown3(false);
            setDropDown4(false);
            setDropDown5(false);
            setDropDown6(false);
            var option : IDropdownOption[] = [];
            setOptionsRoot(option);
            setOptions2(option);
            setOptions3(option);
            setOptions4(option);
            setOptions5(option);
            setOptions6(option);
            setOptions7(option);
        }
    
    }

    const changeRoot = (ev: React.FormEvent<IComboBox>, selectedOption?: IComboBoxOption): void =>
    {
       if(selectedOption)
        {
           setManagementGroupId(selectedOption.key.toString());
           setManagementGroupName(selectedOption.text.toString());
           setMGTenantId(selectedOption.key.toString())
            setSelectionRoot(selectedOption.key.toString());
            setLevel(FilterLevel.LevelRoot);
            setDropDownRoot(true);
            setSelection2("");
            setSelection3("");
            setSelection4("");
            setSelection5("");
            setSelection6("");
            setSelection7("");
            setDropDown2(false);
            setDropDown3(false);
            setDropDown4(false);
            setDropDown5(false);
            setDropDown6(false);
            var option : IDropdownOption[] = [];
            setOptions2(option);
            setOptions3(option);
            setOptions4(option);
            setOptions5(option);
            setOptions6(option);
            setOptions7(option);
        }
    
    }

    const change2 = (ev: React.FormEvent<IComboBox>, selectedOption?: IComboBoxOption): void =>
    {
       if(selectedOption)
        {
            setManagementGroupId(selectedOption.key.toString());
            setManagementGroupName(selectedOption.text.toString());
            setSelection2(selectedOption.key.toString());
            setLevel(FilterLevel.Level2);
            setDropDown2(true);
            setSelection3("");
            setSelection4("");
            setSelection5("");
            setSelection6("");
            setSelection7("");
            setDropDown3(false);
            setDropDown4(false);
            setDropDown5(false);
            setDropDown6(false);
            var option : IDropdownOption[] = [];
            setOptions3(option);
            setOptions4(option);
            setOptions5(option);
            setOptions6(option);
            setOptions7(option);
        }
    
    }

    const change3 = (ev: React.FormEvent<IComboBox>, selectedOption?: IComboBoxOption): void =>
    {
        if(selectedOption)
        {
            setManagementGroupId(selectedOption.key.toString());
            setManagementGroupName(selectedOption.text.toString());
            setSelection3(selectedOption.key.toString());
            setLevel(FilterLevel.Level3);
            setDropDown3(true);
            setSelection4("");
            setSelection5("");
            setSelection6("");
            setSelection7("");
            setDropDown4(false);
            setDropDown5(false);
            setDropDown6(false);
            var option : IDropdownOption[] = [];
            setOptions4(option);
            setOptions5(option);
            setOptions6(option);
            setOptions7(option);
        }

    }

    const change4 = (ev: React.FormEvent<IComboBox>, selectedOption?: IComboBoxOption): void =>
    {
        if(selectedOption)
        {
            setManagementGroupId(selectedOption.key.toString());
            setManagementGroupName(selectedOption.text.toString());
            setSelection4(selectedOption.key.toString());
            setLevel(FilterLevel.Level4);
            setDropDown4(true);
            setSelection5("");
            setSelection6("");
            setSelection7("");
            setDropDown5(false);
            setDropDown6(false);
            var option : IDropdownOption[] = [];
            setOptions5(option);
            setOptions6(option);
            setOptions7(option);
        }
    
    }

    const change5 = (ev: React.FormEvent<IComboBox>, selectedOption?: IComboBoxOption): void =>
    {
        if(selectedOption)
        {
            setManagementGroupId(selectedOption.key.toString());
            setManagementGroupName(selectedOption.text.toString());
            setSelection5(selectedOption.key.toString());
            setLevel(FilterLevel.Level5);
            setSelection6("");
            setSelection7("");
            setDropDown5(true);
            setDropDown6(false);
            var option : IDropdownOption[] = [];
            setOptions6(option);
            setOptions7(option);
        }
    }

    const change6 = (ev: React.FormEvent<IComboBox>, selectedOption?: IComboBoxOption): void =>
    {
        if(selectedOption)
        {
            setManagementGroupId(selectedOption.key.toString());
            setManagementGroupName(selectedOption.text.toString());
            setSelection6(selectedOption.key.toString());
            setLevel(FilterLevel.Level6);
            setSelection7("");
            setDropDown6(true);
            var option : IDropdownOption[] = [];
            setOptions7(option);
        }
    }

    const change7 = (ev: React.FormEvent<IComboBox>, selectedOption?: IComboBoxOption): void =>
    {
        if(selectedOption)
        {
            setManagementGroupId(selectedOption.key.toString());
            setManagementGroupName(selectedOption.text.toString());
            setSelection7(selectedOption.key.toString());
        }
        else
        {
            setSelection7("");
        }
    }

 
    const changeComplianceInitiative = (event: React.FormEvent<HTMLDivElement>, selectedOption?: IDropdownOption): void =>
    {
        setComplianceInitiativeId(selectedOption.key);
        processCITags(selectedOption.key.toString());
    }

    const changeTags = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
        setShowError(false);
        setTag("");
        if (!option && value) {
            if(masterTagList.map((a) => { return a.toLowerCase() }).includes(value.toLowerCase()))
            {
                setShowError(true);
            }
            else
            {
                option = { key: value, text: value };
                var TagsList = tagsList
                TagsList = TagsList.concat(option);
                setTagList(TagsList);
                setCIState("Update");
                setTag("");
            }            
        }
        if (option)
        {
            setTag(option.key);
        } 
    }

    return( 
         
        <Stack horizontal styles={ComplianceInitiativeStyle} >

            <Stack tokens={stackTokens} styles={ComplianceInitiativeFilterStyle} > 
                <Stack.Item align='center'>
                    <h5>Filters</h5>
                </Stack.Item>
                
                <Stack.Item>
                    <ComboBox
                        label={RuntimeConfigurationExtended.isMultiTenantSetup? TenantLevel:"Tenant Id"}
                        selectedKey={Selection1}
                        id="ManagementGroup"
                        autoComplete="on"
                        options={Options1}
                        useComboBoxAsMenuWidth={true}
                        onChange={change1}
                    />
                    
                </Stack.Item>

                <Stack.Item>
                    { DropDown1 && Selection1 !== "" && OptionsRoot.length > 0 &&
                
                        <ComboBox
                            label={MGLevelRoot}
                            selectedKey={SelectionRoot}
                            onChange={changeRoot}
                            autoComplete="on"
                            options={OptionsRoot}
                            allowFreeform={true}
                            useComboBoxAsMenuWidth={true}
                            placeholder = {"Select an Option"}
                        />
                    }
                </Stack.Item>

                <Stack.Item>
                    { DropDownRoot && SelectionRoot !== "" && Options2.length > 0 &&
                
                        <ComboBox
                            label={MGLevel1}
                            selectedKey={Selection2}
                            onChange={change2}
                            autoComplete="on"
                            options={Options2}
                            allowFreeform={true}
                            useComboBoxAsMenuWidth={true}
                            placeholder = {"Select an Option"}
                        />
                    }
                </Stack.Item>

                <Stack.Item>
                    { DropDown2 && Selection2 !== "" && Options3.length > 0  &&
                
                        <ComboBox
                            label={MGLevel2}
                            selectedKey={Selection3}
                            onChange={change3}
                            autoComplete="on"
                            options={Options3}
                            allowFreeform={true}
                            useComboBoxAsMenuWidth={true}
                            placeholder = {"Select an Option"}
                        />
                    }
                </Stack.Item>

                <Stack.Item>
                    { DropDown3 && Selection3 !== "" && Options4.length > 0  &&
                
                        <ComboBox
                            label={MGLevel3}
                            selectedKey={Selection4}
                            onChange={change4}
                            autoComplete="on"
                            options={Options4}
                            allowFreeform={true}
                            useComboBoxAsMenuWidth={true}
                            placeholder = {"Select an Option"}
                        />
                    }
                </Stack.Item>

                <Stack.Item>
                    { DropDown4 && Selection4 !== "" && Options5.length > 0  &&
                
                        <ComboBox
                            label={MGLevel4}
                            selectedKey={Selection5}
                            onChange={change5}
                            autoComplete="on"
                            options={Options5}
                            allowFreeform={true}
                            useComboBoxAsMenuWidth={true}
                            placeholder = {"Select an Option"}
                        />
                    }
                </Stack.Item>

                <Stack.Item>
                    { DropDown5 && Selection5 !== "" && Options6.length > 0  &&
                
                        <ComboBox
                            label={MGLevel5}
                            selectedKey={Selection6}
                            onChange={change6}
                            autoComplete="on"
                            options={Options6}
                            allowFreeform={true}
                            useComboBoxAsMenuWidth={true}
                            placeholder = {"Select an Option"}
                        />
                    }
                </Stack.Item>

                <Stack.Item>
                    { DropDown6 && Selection6 !== "" && Options7.length > 0  &&

                        <ComboBox
                            label={MGLevel6}
                            selectedKey={Selection7}
                            onChange={change7}
                            autoComplete="on"
                            options={Options7}
                            allowFreeform={true}
                            useComboBoxAsMenuWidth={true}
                            placeholder = {"Select an Option"}
                        />
                    }
                </Stack.Item>

                <Stack.Item  align='center'>
                    { LoadingFilter &&
                        <Spinner label={FetchMG} labelPosition="right"/>
                    }
                    { LoadingFilterTenant &&
                        <Spinner label={FetchTenant} labelPosition="right"/>
                    }
                </Stack.Item>
                
                <Stack.Item align='center'>
                    {  (DropDownRoot||DropDown2 || DropDown3 || DropDown4 || DropDown5 || DropDown6 || DropDown7) &&
                
                        <PrimaryButton
                        text="Apply"
                        onClick={() => {setPanelVisible(true);
                            setInitialTag("");
                            setAction(actionType.None);
                            setTag("");
                            GetMGTag();
                            setShowSuccessMessage(false);
                            setSuccessResponse1("");
                            }}
                        />
                    }
                </Stack.Item>

            </Stack>

            <Stack style={{width:"1%"}}>
                <Separator vertical/>
            </Stack>

            <Stack style={{width:"65%"}}>
                <Stack styles={ComplianceInitiativeHeaderStyle} horizontal horizontalAlign="space-between">
                    <Stack.Item>
                        <h4 id="initial-info">Compliance Initiative Editor Tool </h4>
                        <p>  Use this tool to edit compliance initiative metadata.</p>
                    </Stack.Item>
                        <PrimaryButton
                            text="Logs"
                            iconProps={logIcon}
                            onClick={()=>{
                                getLogs();
                                setPanelMode("changelog");
                                toggleComplianceInitiativePanel();
                            }}
                        />
                </Stack>
                <div className={_Styles.rowGap} />
                <div className={_Styles.rowGap} />
            
                <Stack>
                    <Separator />
                </Stack>

                <Stack>
                <Stack.Item align='start' style={{ marginLeft: 10, backgroundColor: NeutralColors.gray10 }}>

                    <Stack horizontal styles={stackStyles}>
                        <CommandBarButton
                            iconProps={saveIcon}
                            text="Save"
                            style={{ backgroundColor: NeutralColors.gray20 }}
                            disabled={action !== actionType.Add || tag === "" || !PanelVisible}
                            onClick={() => { setAction(actionType.Save); }}
                        />

                        <CommandBarButton
                            iconProps={addIcon}
                            text="New Tag"
                            disabled={initialTag !== '' || !PanelVisible}
                            style={{ backgroundColor: NeutralColors.gray20 }}
                            onClick={() => { setAction(actionType.Add); }}
                        />

                        <CommandBarButton
                            iconProps={deleteIcon}
                            text="Delete Tag"
                            disabled={initialTag === '' || !PanelVisible}
                            style={{ backgroundColor: NeutralColors.gray20 }}
                            onClick={() => { setAction(actionType.Delete); }}
                        />

                        <CommandBarButton
                            iconProps={dependencyIcon}
                            text="New Compliance Initiative"
                            disabled={false}
                            style={{ backgroundColor: NeutralColors.gray20 }}
                            onClick={() => {
                                setPanelMode("complianceinitiative");
                                toggleComplianceInitiativePanel();
                                setComplianceInitiativeId("");
                                setComplianceInitiativeDescription("");
                                setTag("");
                                setDefaultControlTagFilter("");
                                setShowControlDisplayName("");
                                var whitelist: IDropdownOption[] = [];
                                if (defaultCITagList.length > 0 && controlTagList.length > 0) {

                                    for (var i = 0; i < controlTagList.length; i++) {
                                        if (!(defaultCITagList.includes(controlTagList[i].key) || masterTagList.includes(controlTagList[i].key))) {
                                            whitelist.push({ key: controlTagList[i].key, text: controlTagList[i].key });
                                        }

                                    }
                                    setControlTagList(whitelist);
                                }
                            }
                            }
                        />

                        <CommandBarButton
                            iconProps={deleteIcon}
                            text="Delete Compliance Initiative"
                            disabled={!(complianceInitiativeList.length > 0)}
                            style={{ backgroundColor: NeutralColors.gray20 }}
                            onClick={() => {
                                setPanelMode("DeleteComplianceInitiative");
                                toggleComplianceInitiativePanel();
                                setComplianceInitiativeId("");
                            }}
                        />
                    </Stack>
                    </Stack.Item>
                </Stack>

                {PanelVisible &&
                    <Stack>
                        <Stack.Item align="start" tokens={stackTokens} style={{marginTop:25,marginLeft:30}}>

                            <Stack style={{marginTop:20}} tokens={containerStackTokensChild}>
                    
                                <Text style={{fontWeight: 600}} variant="medium">Management Group</Text>
                                <TextField style={{width:300}} readOnly value={ManagementGroupName}/>
                            </Stack>

                            <Stack tokens={stackTokens}>
                                <Stack.Item>
                                    { initialTag !=="" &&
                                        <div>
                                            
                                            <Stack style={{marginTop:20}} tokens={containerStackTokensChild}>
                                                <Text style={{fontWeight: 600}} variant="medium">Compliance Initiative</Text>
                                                <TextField style={{width:300}}readOnly value={localCId}/>
                                            </Stack>
                        
                                            <Stack style={{marginTop:20}} tokens={containerStackTokensChild}>
                                                <Text style={{fontWeight: 600}} variant="medium">Subscription Tag</Text>
                                                <TextField style={{width:300}} readOnly value={initialTag}/>
                                            </Stack>
                                        </div>
                                    }
                                </Stack.Item>

                                <Stack.Item>
                    
                                    {initialTag ==="" && action ===actionType.Add &&
                                        <div>
                                            <Dropdown
                                                placeholder="Select an option"
                                                ariaLabel='Please select the Compliance Initiative'
                                                label={CITitleAndTag}
                                                options={complianceInitiativeMappingList}
                                                selectedKey={complianceInitiativeId}
                                                onChange={changeComplianceInitiative}
                                                styles={dropdownStyles}
                                            />
                            
                                            <div className={_Styles.rowGap}></div><div className={_Styles.rowGap}></div>
                                                { complianceInitiativeId !== "" &&

                                                    <ComboBox
                                                        label={SubsTag}
                                                        selectedKey={tag}
                                                        options={tagsList}
                                                        autoComplete="on"
                                                        allowFreeform={true}
                                                        onChange={changeTags}
                                                        useComboBoxAsMenuWidth={true}
                                                        errorMessage={showerror ? "tag already exists":undefined}
                                                    />
                                                }
                                        </div>
                                    }
                                </Stack.Item> 

                                <Stack.Item>
                                    <div className={_Styles.rowGap}></div>
                                    { LoadingFilter1 &&
                                        <Spinner label={AddMG} labelPosition="right"/>
                                    }

                                    { LoadingFilter2 &&
                                        <Spinner label={DeleteMGTagLabel} labelPosition="right"/>
                                    }

                                    { LoadingFilter3 &&
                                        <Spinner label={FetchMGTag} labelPosition="right"/>
                                    }

                                    {(showMessage) && <div><SuccessExampleException/></div>}
                                    {(showSuccessMessage) && <div><SuccessMessage successResponse={successResponse} successResponse1={successResponse1}/></div>}
                                    {(showMessageError) && <div><ErrorAPIMessage errorResponse={errorResponse}/></div>}
                                
                                </Stack.Item>

                            </Stack>
                        </Stack.Item>

                        {isPopupVisible && 
                    
                            <Dialog
                                hidden = {!isPopupVisible}
                                onDismiss={()=> {setIsPopupVisible(false);}}
                                dialogContentProps={dialogContentProps}
                                
                            >
                                <DialogFooter>
                                    <PrimaryButton text="Delete" onClick={()=>{DeleteMGTag();}} />
                                    <DefaultButton onClick={()=> {setIsPopupVisible(false); setAction(actionType.None);}}>Cancel</DefaultButton>
                                </DialogFooter>
                            </Dialog>
                        }
                        
                    </Stack>
                }
            </Stack>
        </Stack>            
    )
};
