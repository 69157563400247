import { DefaultButton, IconButton, Modal, PrimaryButton, Stack } from "@fluentui/react";
import React, { useContext, useEffect } from "react";
import NewFeatureConfetti from "./NewFeatureConfetti";
import {NewFeatureContext} from "../context/NewFeatureContext"
import { _Styles } from "../Page.styles";
import { contentStyles, dragOptions, iconButtonStyles } from "../Styles/Dialog/Common";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

export const NewFeatureModal: React.FunctionComponent = () => {

    const RuntimeConfigurationExtended = window.__UI_CONFIGURATION_EXTENDED_AZURE__;

    const {setConfettiVisible, featureModalVisible, setFeatureModalVisible} = useContext(NewFeatureContext);
    const [markdown, setMarkdown] = React.useState<string>("");

    const onDismiss = (): void => {
        setFeatureModalVisible(false);
        setConfettiVisible(false);

        // If recurring is not set, saves feature version in localStorage.
        // This shows modal only the first time user visits.
        if(!RuntimeConfigurationExtended.newFeatureModalConfiguration.isRecurring){
            localStorage.setItem("New-Feature-Version",RuntimeConfigurationExtended.newFeatureModalConfiguration.version);
        }
        
    }

    // Run when page is loaded.
    useEffect(() => {
        if(window.__UI_CONFIGURATION_EXTENDED_AZURE__.newFeatureModalConfiguration.isEnabled){
                // Fetch markdown data from GitHub.
                fetch(RuntimeConfigurationExtended.newFeatureModalConfiguration.sourceURL, {
                    method: 'GET',
                }).then(function(response) {
                    return response.text();
                })
                .then(function(response) {
                    setMarkdown(response);
                });
            }
        
        // Trigger confetti if version does not match.
        if(window.__UI_CONFIGURATION_EXTENDED_AZURE__.newFeatureModalConfiguration.isEnabled && 
            window.__UI_CONFIGURATION_EXTENDED_AZURE__.newFeatureModalConfiguration.version !== localStorage.getItem("New-Feature-Version")){
            setFeatureModalVisible(true);
            setConfettiVisible(true);
        }
        
    }, []);

    return (
    <>
        <NewFeatureConfetti/>
        <Modal
            isOpen={featureModalVisible}
            isBlocking={false}
            containerClassName={contentStyles.containerNewFeature}
            onDismiss={onDismiss}
            dragOptions={dragOptions}
        >
        <div className={contentStyles.header}>
          <span >Whats's New ?  </span>
          <IconButton
            style={{outline:"none"}}
            styles={iconButtonStyles}
            iconProps={{iconName:'Cancel'}}
            ariaLabel="Close popup modal"
            onClick={onDismiss}
          />
        </div>
        <div className={contentStyles.body}>
            <ReactMarkdown 
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
            >
                {markdown}
            </ReactMarkdown>
            <br/>
            <br/>
            <Stack horizontal reversed tokens={{childrenGap: 10}}>
                <DefaultButton onClick={onDismiss} text="Close" />  
                <PrimaryButton 
                    iconProps={{iconName:'news'}} 
                    text="Know more"
                    onClick={()=>{window.open(RuntimeConfigurationExtended.newFeatureModalConfiguration.redirectionURL,'_blank')}}
                />
            </Stack>
        </div>
        </Modal>
    </>);
};
