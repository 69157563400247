import * as React from 'react';
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';

export const PageLoadProgressIndicator: React.FunctionComponent = () => (
  <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100vh", width: "100vw"}}>
    <ProgressIndicator
      ariaValueText="Loading AzTS UI"
      barHeight={5}
      label="Loading..."
    />
  </div>
);
