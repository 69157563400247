import { getTheme, IStackStyles, IStyle, mergeStyleSets } from '@fluentui/react';
import { NeutralColors, SharedColors } from '@fluentui/theme';
import { getThemeForUI } from './Helper/ThemeLocalStorage';

const theme = getTheme();

const scrollablePaneStyles = {
    position: 'fixed',
    overflowY: 'auto',
    top: 48,
    bottom: 0,
    right: 0,
    outline: "none"
};

export const jsonTreeTheme = {
    base00: '#f3f2f1',
    base01: '#383830',
    base02: '#49483e',
    base03: '#75715e',
    base04: '#a59f85',
    base05: '#FF0000',
    base06: '#f5f4f1',
    base07: '#5d777e',
    base0B: '#cb4b16 ',
    base0E: '#ae81ff',
    base0F: '#cc6633',
};

export const ComplianceInitiativeStyle:IStackStyles ={
    root: {
        width:"100%",
        position: "absolute",
        top: 0, 
        bottom: 10, 
        right: 30, 
        left: 0 
    }
    
}
export const ComplianceInitiativeFilterStyle:IStackStyles ={
    root: {
        marginRight:40,
        paddingLeft:20,
        marginTop:25,
        paddingRight:20,
        backgroundColor:NeutralColors.gray20,
        paddingTop:10,
        outline:"none",
        boxShadow:theme.effects.elevation4,
        width:"30%",
        minWidth:"30%",
        overflowY:"auto",
        paddingBottom:10,
        bottom:10
    }
}


export const ComplianceInitiativeHeaderStyle:IStackStyles ={
    root: {
        marginLeft: 10,
        marginTop: 25,
        marginRight:20
    }
};

export const CmetControllfilterStyle: IStackStyles ={
    root: {
        marginLeft: 16,
        width:'48%'
    }
};

export const _ErrorToast = mergeStyleSets({
    toastClass: {
        color: '#d83b01 !important',
        background: '#fed9cc !important',
        minHeight: '0 !important',
    },
    toastProgress: {
        background: '#d83b01 !important',
        color:'#d83b01 !important',
        height:'4px !important'
    },
    toastBody: {
        padding: 5,
        fontFamily: '',
        color: NeutralColors.gray150
    }
});

export const _SuccessToast = mergeStyleSets({
    toastClass: {
        color: '#107c10 !important',
        background: '#dff6dd !important',
        minHeight: '0 !important',
    },
    toastProgress: {
        background: '#107c10 !important',
        color:'#dff6dd !important',
        height:'4px !important'
    },
});

export const _InfoToast = mergeStyleSets({
    toastClass: {
        color: '#484644 !important',
        background: '#fff4ce !important',
        minHeight: '0 !important',
    },
    toastProgress: {
        background: '#c19c00 !important',
        color:'#ca5010 !important',
        height:'4px !important'
    },
});


export const _WarningToast = mergeStyleSets({
    toastClass: {
        color: '#ffaa44 !important',
        background: '#fff4ce !important',
        minHeight: '0 !important',
    },
    toastProgress: {
        background: '#ffaa44 !important',
        color:'#fff4ce !important',
        height:'4px !important'
    },
});

export const _Styles = mergeStyleSets({
    scrollablePaneCollapsed: {
        ...scrollablePaneStyles,
        left: 65,
        transition: '0.5s'
    } as IStyle,
    scrollablePaneExpand: {
        ...scrollablePaneStyles,
        left: 240,
        transition: '0.5s'
    } as IStyle,
    rootDiv: {
        paddingRight: '30px',
        paddingLeft: '10px',
        outline: "none !important",
        maxWidth: '100%',
        overflowX: 'hidden',
    } as IStyle,
    tableRow: {
        minWidth:70,
        maxWidth:200,     
    }  as IStyle,
    dividerLine: {
        width: '100%',
        height: '1px',
        backgroundColor: NeutralColors.gray70
    } as IStyle,
    childElement: {
        paddingLeft: '10px',
        marginLeft: '10px',
        overflow: 'hidden',
    } as IStyle,
    semiChildElement: {
        paddingLeft: '20px',
    } as IStyle,
    diffElement: {
        paddingLeft: '20px'
    } as IStyle,
    innerChildElement: {
        paddingLeft: '35px',
    } as IStyle,
    scrollablePanelStyles:{
        overflow:"auto",
        overflowWrap: "break-word",
        padding:10,
        whiteSpace:"pre-wrap",
        backgroundColor: NeutralColors.gray20,
        borderLeft: `4px solid ${getThemeForUI().palette.themePrimary}`,
        maxHeight:"350px",
    } as IStyle,
    scrollablePanelStylesCopied:{
        overflow:"auto",
        overflowWrap: "break-word",
        padding:10,
        whiteSpace:"pre-wrap",
        backgroundColor: NeutralColors.gray20,
        borderLeft: `4px solid ${SharedColors.greenCyan10}`,
        maxHeight:"350px",
    } as IStyle,
    scrollablePanelStylesJson:{
        overflow:"auto",
        overflowWrap: "break-word",
        padding:10,
        whiteSpace:"pre-wrap",
        backgroundColor: NeutralColors.gray20,
        borderLeft: `4px solid ${getThemeForUI().palette.themePrimary}`,
    } as IStyle,
    fontSizeElement:{
        fontSize: "15px"
    } as IStyle,
    fontSizeHeading:{
        fontSize: "18px"
    } as IStyle,
    rowGap: {
        height: '10px'
    } as IStyle,
    rowGap2: {
        height: '1px'
    } as IStyle,
    rowGap3: {
        height: '30px'
    } as IStyle,
    fakeCheckbox: {
        visible: 'false'
    } as IStyle,
    topMargin: {
        marginTop:20
    } as IStyle,
    fontSize: {
        fontSize:"150%"
    } as IStyle,
    CIstyle:{
        borderLeft: `3px solid ${getThemeForUI().palette.themePrimary}`,
        paddingLeft:10
    } as IStyle
});