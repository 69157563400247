import React, { useContext }  from 'react';
import { PrimaryButton, DefaultButton, IconButton } from '@fluentui/react/lib/Button';
import { MessageBar, MessageBarType, Stack, Spinner, SpinnerSize, DetailsList, SelectionMode, IColumn, Modal } from '@fluentui/react';
import { _Styles } from '../Page.styles';
import { FormContext } from '../context/FormContext';
import { authProvider } from '../../authProvider';
import { DialogContext } from '../context/DialogContext';
import { contentStyles, dragOptions, iconButtonStyles } from '../Styles/Dialog/Common';
import { TenantScannerConfiguration } from '../Interface/IUnificationConfiguration';
import { TenantScannerRoutes } from '../Interface/IRouteManager';

export const ExportDialog: React.FunctionComponent = () => {
    const RuntimeConfigurationInitial = window.__UI_CONFIGURATION_INITIAL__;
    const RuntimeConfigurationExtended = window.__UI_CONFIGURATION_EXTENDED_AZURE__;
    const ScannerAPI = RuntimeConfigurationInitial.webAPIConf ? RuntimeConfigurationInitial.webAPIConf["AZURE"] : RuntimeConfigurationInitial.webAPI;

    const {attestationMode, selectedWorkItems, setSelectedWorkItems, setForceRefresh,
    control, resource, baseline, failed, complianceInitiative, excludeExternalControls} = useContext(FormContext);
    const {toggleExport, exportDialog} = useContext(DialogContext);
    const [submitted, setSubmitted] = React.useState<boolean>(false);
    const [spinner, setSpinner] = React.useState<boolean>(false);
    const [, setShowMessage] = React.useState<boolean>(false);
    const [showMessageError, setShowMessageError] = React.useState<boolean>(false);
    const [errorResponse, setErrorResponse] = React.useState<string>("");    

    const columnsScan: IColumn[] = [
        {
            key: 'workItemName',
            name: TenantScannerConfiguration.WorkItemName + " Name",
            fieldName: 'workItemName',
            minWidth: 150,
            isPadded: false,
            maxWidth: 200,
            isResizable: true
        },
        {
            key: 'workItemId',
            name: TenantScannerConfiguration.WorkItemName + " Id",
            fieldName: 'workItemId',
            minWidth: 100,
            isPadded: false,
            maxWidth: 200,
            isResizable: true
        }
    ];

    const SuccessExampleExport = () => (
      <MessageBar
        messageBarType={MessageBarType.info}
        isMultiline={true}
      >  
          <div>
              The request for export to CSV has been successfully submitted. 
          </div>
          <div>
              <b style={{fontWeight: 600}}>The download will begin shortly.</b>
          </div>
      </MessageBar>
    );

    const ErrorAPIMessage = () => (
      <MessageBar
        messageBarType={MessageBarType.error}
        isMultiline={true}
      >  
          <div>
            {errorResponse}
          </div>
      </MessageBar>
    );

    const onDismissExport = (): void => {
      toggleExport();
      if(submitted) {
        setSelectedWorkItems([]);
        setForceRefresh(true);
      }
      setSubmitted(false);
      setSpinner(false);
      setErrorResponse("");
      setShowMessageError(false);
      setShowMessage(false);
    }

    function _pad2(n : number): string {
      return n < 10 ? '0' + n.toString() : n.toString();
    }

    function _getDate() : string {
      var date = new Date();
      return(date.getFullYear().toString() + 
        _pad2(date.getMonth() + 1) +
        _pad2(date.getDate()) +
        _pad2(date.getHours()) +
        _pad2(date.getMinutes()) +
        _pad2(date.getSeconds()));
    }

    function _submitExport(): void {
      setSubmitted(true);
      setSpinner(true);
      var finalList : Array<string> = [];
      for(var i = 0; i < selectedWorkItems.length; i++) {
        finalList.push(selectedWorkItems[i]["workItemId"]);
      }
      
      const CallAPI = async () => {
        const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
        let body = {
          SubscriptionIDs: finalList,
          ControlIdList: control,
          ResourceNameList: resource,
          ExcludePassedControls: failed,
          GetControlsEligibleForException: attestationMode,
          FilterBaselineControls: baseline,
          ExcludeExternalControls: RuntimeConfigurationExtended.externalControlsConfiguration?.isEnabled ? excludeExternalControls : true,
          ComplianceInitiativeId: complianceInitiative,
          JobId:0
        }

        fetch(ScannerAPI + TenantScannerRoutes.ExportScanResultCsv, {
          headers: !authToken.accessToken ? {} : {
            'Authorization': `Bearer ${authToken.accessToken}`,
            'Content-Type': 'application/json',
            'u_Id': sessionStorage.getItem("u_Id")
          },
          method: 'POST',
          body: JSON.stringify(body)
        }).then(response => {
            if (response.ok) {
                response.blob().then(blob =>URL.createObjectURL(blob))
                .then(uril => {
                  var link = document.createElement("a");
                  link.href = uril;
                  var timestamp = _getDate();
                  link.download = 'SecurityControlReport-' + timestamp + ".csv";
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  setSpinner(false);
                });
            }
            else {
                response.text().then(errorMsg => {
                  setErrorResponse(errorMsg);
                  setShowMessageError(true);
                  setSpinner(false);
              });
            }
        });    
      }
      CallAPI();
    }

   return ( 
    <>
      <Modal
        isOpen={exportDialog}
        containerClassName={contentStyles.container2}
        onDismiss={onDismissExport}
        dragOptions={dragOptions}
      >
        <div className={contentStyles.header}>
          <span>Export to CSV</span>
          <IconButton
            style={{outline:"none"}}
            styles={iconButtonStyles}
            iconProps={{iconName:'Cancel'}}
            ariaLabel="Close popup modal"
            onClick={onDismissExport}
          />
        </div>
        <div className={contentStyles.body}>
          <div>
            Data from the following {TenantScannerConfiguration.WorkItemName}s will be downloaded:
          </div>
          <DetailsList 
            items={selectedWorkItems}
            columns={columnsScan}
            selectionMode={SelectionMode.none}
          />
          <div className={_Styles.rowGap3} />
          <div>
              {(selectedWorkItems.length > RuntimeConfigurationExtended.subCountInPage) &&
                <MessageBar
                  onDismiss={()=>{}}
                  dismissButtonAriaLabel="Close"
                  messageBarType={MessageBarType.error}
                >
                <b>Error:</b> Please select at most {RuntimeConfigurationExtended.subCountInPage} {TenantScannerConfiguration.WorkItemName}s for exporting to CSV.
                </MessageBar>                  
              }
          </div>
          <div>
              {(submitted) && <div><SuccessExampleExport/></div>}
          </div>
          <div>
              {(showMessageError) && <div><div className={_Styles.rowGap} /><ErrorAPIMessage/></div>}
          </div>
          <Stack {...{horizontal: true, verticalAlign: 'center'}} horizontalAlign={"space-between"}
            reversed style={{padding: 0, margin: 0}} tokens={{childrenGap: 10}}>
            <Stack horizontal reversed tokens={{childrenGap: 10}} style={{marginTop:20}}>
              <DefaultButton onClick={onDismissExport} text="Close" />  
              <PrimaryButton disabled={submitted || (selectedWorkItems.length > RuntimeConfigurationExtended.subCountInPage)} onClick={_submitExport} text="Download CSV" />
            </Stack>
            {spinner && <Spinner size={SpinnerSize.medium} /> } 
          </Stack>
        </div>    
        <div className={_Styles.rowGap} />     
      </Modal>
    </>
  );
};
