import { MessageBar, MessageBarType } from "@fluentui/react";
import React from "react";


export const ErrorAPIMessage = (props) => (
    <MessageBar
        messageBarType={MessageBarType.blocked}
    >  
        <div>
            {props.errorResponse}
        </div>
    </MessageBar>
);

export const SuccessMessage = (props) => (
    <MessageBar
      messageBarType={MessageBarType.success}
      isMultiline={true}
    >  
        <div>
            {props.successResponse}
        </div>

        <div>
            {props.successResponse1}
        </div>
    </MessageBar>
);

export const SuccessExampleException = () => (
    <MessageBar
      messageBarType={MessageBarType.info}
      isMultiline={false}
    >  
        <div>
            Click save to add the tag.
        </div>
    
    </MessageBar>
);

export const WarningAPIMessage = () => (
    <MessageBar
      messageBarType={MessageBarType.warning}
    >  
        <div>
        "Below management group mapping will also be deleted if you delete this compliance initiative"
        </div>
    </MessageBar>
  );

export const InfoAPIMessage = () => (
    <MessageBar
      messageBarType={MessageBarType.info}
    >  
        <div>
          No management group mapping found.Deleting the compliance initiative will not impact any management group mapping.
        </div>
    </MessageBar>
  );

export const ErrorAPIMessage1 = (props) => (
    <MessageBar
        messageBarType={MessageBarType.error}
        isMultiline={false}
    >  
        <div>
            {props.errorResponse}
        </div>
    </MessageBar>
);
  
export const SuccessMessage1 = (props) => (
    <MessageBar
    messageBarType={MessageBarType.success}
    isMultiline={false}
    >  
        <div>
            {props.successResponse}
        </div>
    </MessageBar>
);
  

