import React, { Component, createContext } from 'react';

export const NewFeatureContext = createContext();

export class NewFeatureContextProvider extends Component {
  state = {
    confettiVisible: false,
    featureModalVisible: false
  }

  // Set whether confetti is visible.
  setConfettiVisible = (value) => {
    this.setState({
      confettiVisible: value
    })
  }

  // Set whether modal is visible.
  setFeatureModalVisible = (value) => {
    this.setState({
      featureModalVisible: value
    })
  }
  
  render() { 
    return (
      <NewFeatureContext.Provider value={{
        ...this.state, 
        setConfettiVisible: this.setConfettiVisible,
        setFeatureModalVisible: this.setFeatureModalVisible
      }}
      >
        {this.props.children}
      </NewFeatureContext.Provider>
    );
  }
}
 
export default NewFeatureContextProvider;