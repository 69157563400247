import * as React from "react";
import {
  Link,
  IIconProps,
  Layer,
  Stack,
  Persona,
  PersonaInitialsColor,
  IconButton,
  FontIcon,
} from "@fluentui/react";
import { Text } from "@fluentui/react/lib/Text";
import { authProvider } from "./../authProvider";
import { CSSProperties, useContext, useEffect } from "react";
import { NeutralColors, SharedColors } from "@fluentui/theme";
import { PanelContext } from "./context/PanelContext";
import { getThemeForUI } from "./Helper/ThemeLocalStorage";
import { NewFeatureContext } from "./context/NewFeatureContext";
import { TenantScannerConfiguration } from "./Interface/IUnificationConfiguration";

const headerBanner: CSSProperties = {
  color: NeutralColors.white,
  fontWeight: 600,
  paddingLeft: 10,
};

// Icon props for Help in the header
const helpIcon: IIconProps = {
  iconName: "Help",
  style: {
    color: NeutralColors.white,
    background: "inherit !important",
  },
};

// Icon props for Did You Know in the header
const bulbIcon: IIconProps = {
  iconName: "Lightbulb",
  style: {
    color: NeutralColors.white,
    background: "inherit !important",
  },
};

// Icon props for New Feature Announcement
const newsIcon: IIconProps = {
  iconName: "News",
  style: {
    color: NeutralColors.white,
    background: "inherit !important",
  },
};

export const Header: React.FunctionComponent = () => {
  const RuntimeConfigurationExtended =
    window.__UI_CONFIGURATION_EXTENDED_AZURE__;

  var ui_heading = "Security Scanning Services";
  if (
    typeof RuntimeConfigurationExtended.customUIHeading != "undefined" &&
    RuntimeConfigurationExtended.customUIHeading
  ) {
    ui_heading = RuntimeConfigurationExtended.customUIHeading;
  }

  const theme = getThemeForUI();

  const layerDiv: CSSProperties = {
    height: 45,
    backgroundColor: sessionStorage.getItem("u_Id")
      ? SharedColors.blueMagenta30
      : theme.palette.themePrimary,
  };

  const {
    togglePersonaPanel,
    toggleHelpPanel,
    setUserImage,
    setUserDetails,
    blobURL,
    toggleDidYouKnowPanel,
  } = useContext(PanelContext);
  const { setFeatureModalVisible } = useContext(NewFeatureContext);

  useEffect(
    () => {
      const getAuthToken = async () => {
        let headers = new Headers();
        let token = (await authProvider.getAccessToken()).accessToken;
        headers.append("Authorization", token.toString());
        headers.append("x-content-type-options", "nosniff");
        return headers;
      };
      getAuthToken().then((headers) => {
        var api_image =
          RuntimeConfigurationExtended.graphAPI +
          "/v1.0/me/photos/120x120/$value";
        var api_details = RuntimeConfigurationExtended.graphAPI + "/v1.0/me";
        fetch(api_image, {
          headers: headers,
          method: "GET",
        })
          .then((response) => response.blob())
          .then((data) => {
            const url = window.URL || window.webkitURL;
            const blobUrl = url.createObjectURL(data);
            setUserImage(blobUrl);
          });

        fetch(api_details, {
          headers: headers,
          method: "GET",
        })
          .then((response) => response.json())
          .then((data) => {
            setUserDetails(
              data.displayName,
              data.userPrincipalName,
              data.jobTitle
            );
            localStorage.setItem("userPrincipalName", data.userPrincipalName);
          });
      });
    },
    // eslint-disable-next-line
    []
  );

  return (
    <div>
      <Layer>
        <Stack
          style={layerDiv}
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          <Stack horizontal>
            <FontIcon
              aria-label="Shield"
              iconName="Shield"
              style={{ fontSize: "120%", color: "white", marginLeft: 10 }}
            />

            <Link style={{ outline: "none" }}>
              <Text
                variant={"mediumPlus"}
                style={headerBanner}
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                {ui_heading}
              </Text>
            </Link>
          </Stack>

          <Stack
            horizontal
            horizontalAlign="start"
            reversed
            tokens={{ childrenGap: 15 }}
          >
            <Link style={{ outline: "none" }} aria-label="Open Persona Page">
              <Persona
                coinSize={28}
                imageUrl={blobURL}
                imageShouldFadeIn
                onClick={togglePersonaPanel}
                initialsColor={PersonaInitialsColor.magenta}
              />
            </Link>

            <IconButton
              iconProps={helpIcon}
              onClick={toggleHelpPanel}
              aria-label="Open help panel"
              styles={{
                rootHovered: {
                  background: "inherit !important",
                },
              }}
              style={{ background: "inherit !important", outline: "none" }}
            />

            <IconButton
              iconProps={bulbIcon}
              onClick={toggleDidYouKnowPanel}
              aria-label="Open help panel"
              styles={{
                rootHovered: {
                  background: "inherit !important",
                },
              }}
              style={{ background: "inherit !important", outline: "none" }}
            />

            {RuntimeConfigurationExtended.newFeatureModalConfiguration
              .isEnabled && (
              <IconButton
                iconProps={newsIcon}
                onClick={() => {
                  setFeatureModalVisible(true);
                }}
                aria-label="New Feature Announcement"
                styles={{
                  rootHovered: {
                    background: "inherit !important",
                  },
                }}
                style={{ background: "inherit !important", outline: "none" }}
              />
            )}
          </Stack>
        </Stack>
      </Layer>
    </div>
  );
};
