import * as React from 'react';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { PanelContext } from '../context/PanelContext';
import { useContext } from 'react';
import { panelClassNames } from './PersonaPanel';
import { ActionButton, Stack } from '@fluentui/react';

export const HelpPanel: React.FunctionComponent = () => {
    const RuntimeConfigurationExtended = window.__UI_CONFIGURATION_EXTENDED_AZURE__;
    const RuntimeConfigurationDevopsExtended = window.__UI_CONFIGURATION_EXTENDED_DEVOPS__;

    const { helpPanel, toggleHelpPanel } = useContext(PanelContext);

    return (
        <div>
            <Panel
                isOpen={helpPanel}
                isLightDismiss
                type={PanelType.custom}
                headerClassName={panelClassNames.headerClass}
                customWidth={'25%'}
                hasCloseButton={false}
                onDismiss={toggleHelpPanel}
                headerText="Help"
                // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                closeButtonAriaLabel="Close"
            >
                <Stack>
                    {localStorage.getItem("scanner") === "azure" &&
                        <>
                            <ActionButton
                                iconProps={{ iconName: 'IssueTracking' }}
                                style={{ outline: "none" }}
                                onClick={
                                    () => {
                                        window.open(RuntimeConfigurationExtended.issueTrackerURL);
                                    }
                                }
                            >
                                Known Issues
                            </ActionButton>

                            {RuntimeConfigurationExtended.tutorialConfiguration?.isEnabled === true &&
                                <ActionButton
                                    iconProps={{ iconName: 'D365TalentLearn' }}
                                    style={{ outline: "none" }}
                                    onClick={
                                        () => {
                                            window.open(RuntimeConfigurationExtended.tutorialConfiguration?.tutorialDocs);
                                        }
                                    }
                                >
                                    Video Tutorials
                                </ActionButton>

                            }

                            {Boolean(RuntimeConfigurationExtended.isExternalTenantSetup) === false &&
                                <ActionButton
                                    iconProps={{ iconName: 'Feedback' }}
                                    style={{ outline: "none" }}
                                    onClick={() => {
                                        window.open(RuntimeConfigurationExtended.controlRemediationHelpURL);
                                    }
                                    }
                                >
                                    Queries around controls remediation?
                                </ActionButton>
                            }

                            <ActionButton
                                iconProps={{ iconName: 'Feedback' }}
                                style={{ outline: "none" }}
                                onClick={() => {
                                    if (Boolean(RuntimeConfigurationExtended.isExternalTenantSetup) === true) { window.open("mailto:" + RuntimeConfigurationExtended.issueSupportMail); }
                                    else { window.open(RuntimeConfigurationExtended.issueSupportMail); }
                                }
                                }
                            >
                                Suggestions/Feedback on the UI
                            </ActionButton>

                            <ActionButton
                                iconProps={{ iconName: 'EntitlementPolicy' }}
                                style={{ outline: "none" }}
                                onClick={() => {
                                    window.open(RuntimeConfigurationExtended.privacyPolicyURL);
                                }
                                }
                            >
                                Privacy Policy
                            </ActionButton>
                        </>
                    }

                    {
                        localStorage.getItem("scanner") === "devops" &&
                        <>
                            <ActionButton
                                iconProps={{ iconName: 'IssueTracking' }}
                                style={{ outline: "none" }}
                                onClick={
                                    () => {
                                        window.open(RuntimeConfigurationDevopsExtended.issueTrackerURL);
                                    }
                                }
                            >
                                Known Issues
                            </ActionButton>

                            {Boolean(RuntimeConfigurationDevopsExtended?.isExternalTenantSetup) === false &&
                                <ActionButton
                                    iconProps={{ iconName: 'Feedback' }}
                                    style={{ outline: "none" }}
                                    onClick={() => {
                                        window.open(RuntimeConfigurationDevopsExtended.controlRemediationHelpURL);
                                    }
                                    }
                                >
                                    Queries around controls remediation?
                                </ActionButton>
                            }

                            <ActionButton
                                iconProps={{ iconName: 'Feedback' }}
                                style={{ outline: "none" }}
                                onClick={() => {
                                    window.open("mailto:" + RuntimeConfigurationDevopsExtended.issueSupportMail);
                                }
                                }
                            >
                                Suggestions/Feedback on the UI
                            </ActionButton>

                            <ActionButton
                                iconProps={{ iconName: 'EntitlementPolicy' }}
                                style={{ outline: "none" }}
                                onClick={() => {
                                    window.open(RuntimeConfigurationDevopsExtended.privacyPolicyURL);
                                }
                                }
                            >
                                Privacy Policy
                            </ActionButton>
                        </>
                    }
                </Stack>
            </Panel>
        </div>
    );
};
