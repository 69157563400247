import React  from 'react';
import { CommandBarButton, Text, getTheme, Stack } from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import { _SuccessToast } from '../../Page.styles';
import { UIControlObject } from '../../Interface/ITableSecureTemplate';

const theme = getTheme();

export const onRenderPlainCardControl = (item: UIControlObject): JSX.Element => {
    const RuntimeConfigurationExtended = window.__UI_CONFIGURATION_EXTENDED_AZURE__;
    var color = SharedColors.cyanBlue20;
    return (
        <div style={{background: theme.palette.neutralLighter, padding:20, maxWidth:'500px', borderTop: `4px solid ${color}`}}>
            <Stack>
                <h5 style={{color:color}}>Details</h5>
                <Text><b style={{fontWeight:600}}>Display Name: </b>{item.displayName}</Text>
                <Text><b style={{fontWeight:600}}>Rationale: </b>{item.rationale}</Text>
                <Stack horizontalAlign="start" horizontal tokens={{childrenGap:20}}>
                    <CommandBarButton 
                        iconProps={{iconName:'BookAnswers',style:{color:color}}}
                        text="Go to Remediation Guide"
                        onClick = {() => {
                            window.open(RuntimeConfigurationExtended.controlWikiBaseURL + item.controlId,'_blank');
                        }}
                        style={{padding:0, marginTop:15, outline:'none', background: theme.palette.neutralLighter}}
                    />
                </Stack>
            </Stack>            
        </div>
    );
};


