import React, { useContext }  from 'react';
import { PrimaryButton, DefaultButton, IconButton } from '@fluentui/react/lib/Button';
import { MessageBar, MessageBarType, Stack, Spinner, SpinnerSize, DetailsList, SelectionMode, IColumn, Modal } from '@fluentui/react';
import { _Styles } from '../Page.styles';
import { FormContext } from '../context/FormContext';
import { authProvider } from '../../authProvider';
import { DialogContext } from '../context/DialogContext';
import { contentStyles, dragOptions, iconButtonStyles } from '../Styles/Dialog/Common';
import { ADOScannerConfiguration } from '../Interface/IUnificationConfiguration';
import { TenantScannerRoutes } from '../Interface/IRouteManager';

export const SubmitDialogDevOps: React.FunctionComponent = () => {
    const RuntimeConfigurationInitial = window.__UI_CONFIGURATION_INITIAL__;
    const RuntimeConfigurationExtended = window.__UI_CONFIGURATION_EXTENDED_AZURE__;

    const {selectedWorkItems, setSelectedWorkItems, setForceRefresh} = useContext(FormContext);
    const {toggleSubmit, submitDialog} = useContext(DialogContext);
    const [submitted, setSubmitted] = React.useState<boolean>(false);
    const [spinner, setSpinner] = React.useState<boolean>(false);
    const [showMessage, setShowMessage] = React.useState<boolean>(false);
    const [showMessageError, setShowMessageError] = React.useState<boolean>(false);
    const [jobId, setJobId] = React.useState<string>("");
    const [errorResponse, setErrorResponse] = React.useState<string>("");

    const columnsScan: IColumn[] = [
        {
          key: 'workItemName',
          name: ADOScannerConfiguration.WorkItemName + " Name",
          fieldName: 'workItemName',
          minWidth: 60,
          isPadded: false,
          maxWidth: 200,
          isResizable: true
        },
        {
            key: 'workItemId',
            name: ADOScannerConfiguration.WorkItemName + " Id",
            fieldName: 'workItemId',
            minWidth: 40,
            isPadded: false,
            maxWidth: 200,
            isResizable: true
        }
    ];

    
    const columnsScanMultiTenant: IColumn[] = [
      {
        key: 'workItemName',
        name: ADOScannerConfiguration.WorkItemName + " Name",
        fieldName: 'workItemName',
        minWidth: 60,
        isPadded: false,
        maxWidth: 200,
        isResizable: true
      },
      {
          key: 'workItemId',
          name: ADOScannerConfiguration.WorkItemName + " Id",
          fieldName: 'workItemId',
          minWidth: 60,
          isPadded: false,
          maxWidth: 200,
          isResizable: true
      },
      {
        key: 'parent',
        name: ADOScannerConfiguration.ParentItemName,
        fieldName: 'parent',
        minWidth: 60,
        isPadded: false,
        maxWidth: 200,
        isResizable: true
      }
    ];

    

    const SuccessExample = () => (
      <MessageBar
        messageBarType={MessageBarType.success}
        isMultiline={true}
      >
          <div>
              The request for scan has been successfully submitted.
          </div>
          <div>
              <b style={{fontWeight: 600}}>Job ID: {jobId}</b>
          </div>
          <div>
              Please check the status after 1-2 minutes.
          </div>
        </MessageBar>
    );

    const ErrorAPIMessage = () => (
      <MessageBar
        messageBarType={MessageBarType.error}
        isMultiline={true}
      >  
          <div>
            {errorResponse}
          </div>
      </MessageBar>
    );

    const onDismiss = (): void => {
        toggleSubmit();
        if(submitted) {
          setSelectedWorkItems([]);
          setForceRefresh(true);
        }
        setSubmitted(false);
        setShowMessage(false);
        setErrorResponse("");
        setSpinner(false);
        setShowMessageError(false);
    }

    function _processJob(dataString : string): void {
        var data = JSON.parse(dataString);
        setJobId(data[0].jobID);
        setShowMessage(true);
        setSpinner(false);
    }

    function _submitScan(): void {
        setSubmitted(true);
        setSpinner(true);
        var finalList : Array<string> = [];
        for(var i = 0; i < selectedWorkItems.length; i++) {
          finalList.push(selectedWorkItems[i]["workItemId"]);
        }
        
        const CallAPI = async () => {
          const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
          let body = { SubscriptionIDList : finalList };
  
          fetch(RuntimeConfigurationInitial.webAPIConf["DEVOPS"] + TenantScannerRoutes.QueueWorkItemForScan, {
            headers: !authToken.accessToken ? {} : {
              'Authorization': `Bearer ${authToken.accessToken}`,
              'Content-Type': 'application/json',
              'u_Id': sessionStorage.getItem("u_Id")
            },
            method: 'POST',
            body: JSON.stringify(body)
          }).then(response => {
              if (response.ok) {
                  response.json().then(data => {
                    _processJob(JSON.stringify(data))
                  });
              }
              else {
                  response.text().then(errorMsg => {
                    setErrorResponse(errorMsg);
                    setShowMessageError(true);
                    setSpinner(false);
                });
              }
          });    
      }
  
      CallAPI();
    }

   return ( 
    <>
      <Modal
        isOpen={submitDialog}
        containerClassName={contentStyles.container2}
        onDismiss={onDismiss}
        dragOptions={dragOptions}
      >
        <div className={contentStyles.header}>
          <span >Request for Scan</span>
          <IconButton
            style={{outline:"none"}}
            styles={iconButtonStyles}
            iconProps={{iconName:'Cancel'}}
            ariaLabel="Close popup modal"
            onClick={onDismiss}
          />
        </div>
        <div className={contentStyles.body}>
          <div>
            The following {ADOScannerConfiguration.WorkItemName}(s) have been selected for scan. Please confirm.
          </div>
          <DetailsList 
            items={selectedWorkItems}
            columns={RuntimeConfigurationExtended.isMultiTenantSetup ? columnsScanMultiTenant : columnsScan}
            selectionMode={SelectionMode.none}
          />
          <div className={_Styles.rowGap3} />
          <div>
              {(showMessage) && <div><SuccessExample/><div className={_Styles.rowGap3} /></div>}
          </div>
          <div>
              {(showMessageError) && <div><ErrorAPIMessage/><div className={_Styles.rowGap3} /></div>}
          </div>
          <Stack {...{horizontal: true, verticalAlign: 'center'}} horizontalAlign={"space-between"}
            reversed style={{padding: 0, margin: 0}} tokens={{childrenGap: 10}}>
            <Stack horizontal reversed tokens={{childrenGap: 10}}>
              <DefaultButton onClick={onDismiss} text="Close" />  
              <PrimaryButton disabled={submitted} onClick={_submitScan} text="Confirm" />
            </Stack>
            {spinner && <Spinner size={SpinnerSize.medium} />}
          </Stack>
        </div>
        <div className={_Styles.rowGap} />     
      </Modal>
    </>
  );
};
