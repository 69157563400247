import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { panelClassNames } from './PersonaPanel';
import { DefaultButton, PrimaryButton, Stack, Text, getTheme, Panel, PanelType, IconButton, Modal, ConstrainMode, DetailsList, MarqueeSelection, Selection, SelectionMode, IColumn, TextField, Checkbox, Toggle, IDetailsListCheckboxProps, MessageBar, MessageBarType, Spinner } from '@fluentui/react';
import { AdminContext } from '../context/ControlEditor';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react-experiments';
import { SharedColors } from '@fluentui/theme';
import { authProvider } from '../../authProvider';
import { toast } from 'react-toastify';
import { _WarningToast } from '../Page.styles';
import { contentStyles, dragOptions, iconButtonStyles } from '../Styles/Dialog/CommonControlEditor';
import { debounce } from 'lodash';

const theme = getTheme();

interface ILabelObject {
    key: Number
    label: string;
    enabled: boolean;
}

interface IBulkEditObject {
    key: Number;
    bulkEdit: boolean;
    enabled: boolean;
}

interface ISchemaList {
    key: Number;
    label: ILabelObject;
    bulkEdit: IBulkEditObject;
}


export const EditAdditionalField: React.FunctionComponent = () => {
    const RuntimeConfigurationInitial = window.__UI_CONFIGURATION_INITIAL__;
    const ScannerAPI = RuntimeConfigurationInitial.webAPIConf ? RuntimeConfigurationInitial.webAPIConf["AZURE"] : RuntimeConfigurationInitial.webAPI;

    const {modelOpenAddField, toggleModelOpenAddField} = useContext(AdminContext);

    const [schema, setSchema] = useState([]);
    const [submit, setSubmit] = useState(0);
    const [schemaDisplayed, setSchemaDisplayed] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [changed, setChanged] = useState(false);
    const [isDialogVisible, setIsDialogVisible] = React.useState(false);
    const [selectedField, setSelectedField] = React.useState([]);
    const [deletedField, setDeletedField] = React.useState([]);


    const columns: IColumn[] = [
        {
            key: 'label',
            name: 'Label Name',
            fieldName: 'label',
            minWidth: 250,
            isPadded: true,
            isResizable: true,
        },
        {
            key: 'bulkEdit',
            name: 'Bulk Editable',
            fieldName: 'bulkEdit',
            minWidth: 250,
            isPadded: true,
            isResizable: true,
        },
    ]
    
    function AddRow(): void {
        var length  = schema.length;
        var labelObject : ILabelObject = {
            label: "Click to edit",
            key: length,
            enabled: true
        };
        var bulkEditObject : IBulkEditObject = {
            key: length,
            bulkEdit: false,
            enabled: true
        };
        var schemaNew = schema.concat({
            key: length,
            label: labelObject,
            bulkEdit: bulkEditObject
        });

        setSchema(schemaNew);
        setChanged(true);
    }

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }

    function DeleteRow(): void{
        var newDeletedField = deletedField.concat(selectedField);
        newDeletedField.filter(onlyUnique);
        setDeletedField(newDeletedField);
        setChanged(true);

    }

    function UpdateSchema(): void{
        const CallAPI = async () => {
            setSubmit(1);
            const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
            var schemaEdit = [];
            var deletedRows = [];
            var sortedDeletedFields = deletedField.sort(function(a, b){return b-a})
            for(var index in schema){
                schemaEdit = schemaEdit.concat({
                    "Label" : schema[index]["label"]["label"],
                    "BulkEdit" : schema[index]["bulkEdit"]["bulkEdit"]
                });
            }   
            for(var indexDelete in sortedDeletedFields){
                var row = Number(deletedField[indexDelete]);
                deletedRows = deletedRows.concat(row);          
            }

            let body = {
                Schema: schemaEdit,
                DeletedFields: deletedRows,
            }

            if(modelOpenAddField === true){
                fetch((ScannerAPI + '/controleditor/editfields/'), {
                    headers: !authToken.accessToken ? {} : {
                        'Authorization': `Bearer ${authToken.accessToken}`,
                        'u_Id': sessionStorage.getItem("u_Id"),
                        'Content-Type': 'application/json'},
                    method: 'POST',
                    body: JSON.stringify(body)
                }).then(response => {
                    if (response.ok) {
                        setChanged(false);
                        setSubmit(2);
                    }
                    else {
                        setSubmit(3);
                    }
                });
            }
        }
        CallAPI();

    }

    const renderOrderedCheckbox = (props?: IDetailsListCheckboxProps): JSX.Element => {
        return (
          // Checkbox handles the click through its props, so don't allow outside changes
          <div style={{ pointerEvents: 'none', marginTop:20 }}>
            <Checkbox
              checked={props!.checked}
            />
          </div>
        );
    };
    
    useEffect(() => {
        setSchemaDisplayed(schema.filter(t => !deletedField.includes(t.key)))
    }, [schema,deletedField])

    const onChangeLabel = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            try{
                var key = (event.target as HTMLInputElement).getAttribute("data-key");
                var newSchema = schema;
                if(newSchema !== undefined && newSchema[key]!== undefined){
                    newSchema[key]["label"]["label"] = newValue
                    setSchema(newSchema)
                    setChanged(true);
                }   
            }
            catch{
                //Continue
            }
        },
        [schema],
    );

    const onSearchLabel = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text?: string) => {
            text = text.toLowerCase();   
            var displayedItems = schema.filter(t => !deletedField.includes(t.key))
            displayedItems = displayedItems.filter(i => 
                i["label"]["label"].toLowerCase().includes(text)
            );
            setSchemaDisplayed(displayedItems)
        },
        [schema],
    );

    function onChangeBulkEdit(event: React.MouseEvent<HTMLElement>, checked?: boolean) {
        var key = (event.target as HTMLElement).getAttribute("data-key");
        var newSchema = schema;
        if(newSchema !== undefined && newSchema[key] !== undefined){
            newSchema[key]["bulkEdit"]["bulkEdit"] = checked
            setSchema(newSchema)
        }  
        setChanged(true);
    }
    
    function renderItemColumn(item: ISchemaList, index: Number, column: IColumn) : JSX.Element { 

        const fieldContent = item[column.fieldName as keyof ISchemaList];
        switch (column.key) {
            
            case 'label':
                var labelObject = fieldContent as ILabelObject;
                if(selectedField.includes(labelObject.key)){
                    return (
                        <TextField
                            style={{paddingLeft:0}}
                            styles={{
                                fieldGroup:{
                                    background: "!inherit !important"
                                }
                            }}
                            borderless
                            disabled={!labelObject.enabled}
                            iconProps={{iconName:"Edit", style:{color:theme.palette.themePrimary}}}
                            defaultValue={labelObject.label} 
                            data-key={labelObject.key.toString()}
                            onChange={onChangeLabel}
                        />
                    );
                }
                else{
                    return(
                        <div style={{marginTop:7}}>
                            <Text variant="medium">
                                {labelObject.label}
                            </Text>
                        </div>
                        
                    );
                }
                
            
            case 'bulkEdit':
                var bulkEditObject = fieldContent as IBulkEditObject;
                return (
                    <div style={{marginTop:7}}>
                        <Toggle 
                            label="" 
                            onText="On"
                            offText="Off"
                            data-key={bulkEditObject.key}
                            onChange={onChangeBulkEdit}
                            defaultChecked={bulkEditObject.bulkEdit}
                            disabled={!bulkEditObject.enabled}
                        />
                    </div>
                );

            default:
                return <span>{fieldContent.toString()}</span>;
        }
    }

    const _headerButtons: ICommandBarItemProps[] = [
        {
          key: 'addRow',
          text: 'Add Row',
          iconProps: { iconName: 'Add', style:{color: SharedColors.greenCyan10 }},
          onClick: AddRow,
        },
        {
          key: 'remove',
          text: selectedField.length > 0 ? 'Remove Row (' + selectedField.length + ')' : 'Remove Row',
          iconProps: { iconName: 'Delete', style:{color: SharedColors.redOrange10 }},
          disabled: selectedField.length > 0 ? false: true,
          onClick: DeleteRow,
        },
    ];
      


    const onRenderHeader = React.useCallback(
        () => (
            <Stack style={{marginBottom:0}}>
                <Stack style={{marginLeft:25,marginRight:25}} tokens={{childrenGap:-5}}>
                    <Text variant='xxLarge'>
                        Add/Edit Additional Fields
                    </Text>
                </Stack>
                <Stack horizontal>
                    <CommandBar
                        items={_headerButtons}
                        ariaLabel="Command Bar for adding/removing rows"
                    />
                </Stack>
            </Stack>
        ),
        // eslint-disable-next-line
        [schema,selectedField],
    );

    const onRenderFooterContent = React.useCallback(
        () => (
          <Stack horizontal reversed horizontalAlign='space-between'>
            <Stack horizontal tokens={{childrenGap:15}}>
                <PrimaryButton disabled={!changed || !loaded} onClick = {UpdateSchema} iconProps={{iconName:'Upload'}}>
                    Update
                </PrimaryButton>
                <DefaultButton onClick={toggleModelOpenAddField}>Close</DefaultButton>
            </Stack>
            <Stack horizontal style={{width:'50%', marginRight:40}}>
                {submit === 1 &&
                    <Spinner 
                        label="Submitting Request" 
                        ariaLive="assertive" 
                        labelPosition="right"
                    />
                }
                {submit === 2 &&
                    <MessageBar
                        messageBarType={MessageBarType.success}
                        isMultiline={false}
                        dismissButtonAriaLabel="Close"
                    >
                        Updates were successful.
                    </MessageBar>
                }
                {submit === 3 &&
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={false}
                        dismissButtonAriaLabel="Close"
                    >
                        Could not process the request.
                    </MessageBar>
                }
                                
            </Stack>
          </Stack>
        ),
        // eslint-disable-next-line
        [changed,schema,deletedField,submit],
    );

    const fillTable = (dataString : string) => {
        var data = JSON.parse(dataString);
        var schemaNew : ISchemaList[] = [];
        
        for (var i = 0; i < data.length; i++) {

            var labelObject : ILabelObject = {
                label: data[i].label,
                key: i,
                enabled: true
            };
            var bulkEditObject : IBulkEditObject = {
                key: i,
                bulkEdit: data[i].bulkEdit,
                enabled: true
            };
            schemaNew = schemaNew.concat({
                key: i,
                label: labelObject,
                bulkEdit: bulkEditObject
            });
        }

        setSchema(schemaNew);
    }

    useEffect(() => {
        const CallAPI = async () => {
            const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
            let body = {
                ResourceType: [],
                Control: [],
                Schema: true
            }
            if(modelOpenAddField === true){
                fetch((ScannerAPI + '/controleditor/fetchschema/'), {
                    headers: !authToken.accessToken ? {} : {
                        'Authorization': `Bearer ${authToken.accessToken}`,
                        'u_Id': sessionStorage.getItem("u_Id"),
                        'Content-Type': 'application/json'},
                    method: 'POST',
                    body: JSON.stringify(body)
                }).then(response => {
                    setLoaded(true);
                    if (response.ok) {
                        response.json().then(data => {
                            fillTable(JSON.stringify(data["fields"]));
                        });
                    }
                    else {
                        response.text().then(errorMsg => {
                            toast(errorMsg.slice(0, 500), {
                                autoClose: 5000,
                                progressClassName: _WarningToast.toastProgress,
                                className: _WarningToast.toastClass
                            });
                        });
                    }
                });
            }
        }
        CallAPI();
    },
    // eslint-disable-next-line
    [modelOpenAddField]);

    const onDismiss = () => {
        if(changed === true) {
            setIsDialogVisible(true);
        }
        else {
            hideDialogAndPanel();
        }
    }

    const hideDialog = React.useCallback(() => setIsDialogVisible(false), []);

    const hideDialogAndPanel = React.useCallback(() => {
        toggleModelOpenAddField();
        setChanged(false);
        setIsDialogVisible(false);
        setSchemaDisplayed([]);
        setSchema([]);
        setDeletedField([]);
        setSelectedField([]);
        setLoaded(false);
        setSubmit(0);
    },
    // eslint-disable-next-line
    []);
    

    function canSelectItem(item: any): boolean {
        if(deletedField.includes(item.key)){
            return false
        }
        return true
    }

    const selection = new Selection({
        canSelectItem: canSelectItem,
        onSelectionChanged: () => {
            setSelectedField(selection.getSelection().map(t=>t.key));
        }       
    })

    return (
        <div>
            <Modal
                isOpen={isDialogVisible}
                containerClassName={contentStyles.container3}
                onDismiss={hideDialog}
                dragOptions={dragOptions}
            >
                <div className={contentStyles.header}>
                <span >Quit Editing?</span>
                <IconButton
                    style={{outline:"none"}}
                    styles={iconButtonStyles}
                    iconProps={{iconName:'Cancel'}}
                    ariaLabel="Close popup modal"
                    onClick={hideDialog}
                />
                </div>
                <div className={contentStyles.body}>
                    <div>
                        The changes you made will be lost. Are you sure you want to quit?
                    </div>
                    <Stack {...{horizontal: true, verticalAlign: 'center'}} horizontalAlign={"space-between"}
                        reversed style={{padding: 0, margin: 0}} tokens={{childrenGap: 10}}>
                        <Stack horizontal reversed tokens={{childrenGap: 10}} style={{marginTop: 20}}>
                            <DefaultButton onClick={hideDialog} text="No" />
                            <PrimaryButton onClick={hideDialogAndPanel} text="Yes" />
                        </Stack>
                    </Stack>
                </div>
            </Modal>

            <Panel
                isOpen={modelOpenAddField}
                isLightDismiss
                type={PanelType.custom}
                headerClassName={panelClassNames.headerClass}
                customWidth={'45%'}
                onRenderFooterContent={onRenderFooterContent}
                onRenderHeader={onRenderHeader}
                onDismiss={onDismiss}
                hasCloseButton={false}
                headerText="Control Setting"
                isFooterAtBottom={true}
                // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                closeButtonAriaLabel="Close"
            >
                <Stack horizontal horizontalAlign="space-between" style={{marginLeft:10,marginTop:10}}>
                    <Text variant="large">
                        <b style={{fontWeight:600}}>{schemaDisplayed.length}</b> fields displayed
                    </Text>
                    <TextField 
                        placeholder="Search on label" 
                        onChange={debounce(onSearchLabel,1000)} 
                        iconProps={{iconName:'Search'}} 
                        style={{minWidth: 350}}
                    />

                </Stack>
                <MarqueeSelection selection={selection} isEnabled={false}>
                    <DetailsList
                        items={schemaDisplayed}
                        styles={{root:{paddingBottom:20}}}
                        selectionPreservedOnEmptyClick={true}
                        checkboxVisibility={1}
                        selectionMode={SelectionMode.multiple}
                        constrainMode={ConstrainMode.unconstrained}
                        enableUpdateAnimations={true}
                        isHeaderVisible={true}
                        selection={selection}
                        columns={columns}
                        compact
                        onRenderCheckbox={renderOrderedCheckbox }
                        ariaLabelForSelectAllCheckbox={"Selecting all controls is not viable"}
                        checkButtonAriaLabel={"Select this control"}
                        onRenderItemColumn={renderItemColumn}
                        // onRenderRow={renderRow}
                    />
                </MarqueeSelection>
            </Panel>
        </div>
    );
};
