import React from 'react';
import { ScrollablePane } from '@fluentui/react';
import { Stack, IStackTokens } from '@fluentui/react/lib/Stack';
import { _Styles } from './Page.styles';
import { PageProps } from './Page.types';
import { Filter } from './Homepage/Filter';
import { DetailsListGroupedExample } from './Homepage/Table'
import { FormContextProvider } from './context/FormContext';
import { TeachingContextProvider } from './context/TeachingContext';
import { DialogContextProvider } from './context/DialogContext';

const horizontalStackTokens: IStackTokens = {
    childrenGap: 5,
};

export class HomePage extends React.Component<PageProps, {}> {

    componentDidUpdate(){
        localStorage.setItem("theme","homepage");
        localStorage.setItem("scanner","azure");
        window.dispatchEvent(new Event("storage"));
    }

    render() {
        return (
            <TeachingContextProvider>
                <FormContextProvider>
                    <DialogContextProvider>                                
                        <ScrollablePane className={this.props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}>
                            <div className={_Styles.rootDiv}>
                                <Filter baselineType={this.props.baselineType} admin={this.props.admin} />
                                <Stack tokens={horizontalStackTokens} style={{margin: 15}}>
                                    <DetailsListGroupedExample baselineType={this.props.baselineType} admin={this.props.admin} />
                                    
                                </Stack>
                                <div className={_Styles.rowGap} />
                                <div className={_Styles.rowGap} />
                            </div>
                        </ScrollablePane>
                    </DialogContextProvider> 
                </FormContextProvider>
            </TeachingContextProvider>
        );
    }
}