import React from 'react';
import { Pagination, CollapsibleSection } from '@fluentui/react-experiments';
import { IControlObject, ITableIndividualRowStateDevOps, ITableGroupStateDevOps, IInternalStateDevOps, IStatusObject, IOrgProjectMapping, TableProps, IStatusReasonObject, IResourceObjectDevOps, IFormConfigurationsDevOps } from '../Interface/ITable';
import { GROUP_FOOTER_HEIGHT, GROUP_HEADER_HEIGHT, ROW_HEIGHT } from '../Styles/Table/Group';
import { onRenderDetailsHeader } from '../Helper/Table/renderHeaders';
import { tableStyles, horizontalStackTokens } from '../Styles/Table/Common';
import { copyAndSortDevOps } from '../Helper/Table/copyAndSort';
import { DetailsList, IColumn, IDetailsList, Text, IGroup, Selection, SelectionMode, IGroupDividerProps, IGroupRenderProps, Checkbox, ConstrainMode, Sticky, StickyPositionType, IDetailsListProps,
    IDetailsRowStyles, DetailsRow, MessageBar, MessageBarType, Fabric, loadTheme, BaseButton, Button, DefaultPalette } from '@fluentui/react';
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { SharedColors } from '@fluentui/theme';
import { FormContext } from '../context/FormContext';
import { authProvider } from '../../authProvider';
import { _InfoToast, _Styles} from '../Page.styles';
import { DialogCartDevOps } from './DialogCartDevOps';
import { InfoTable } from '../TeachingBubbles';
import { toast } from 'react-toastify';
import { ReactComponent as CatSvg } from '../assets/cat.svg';
import { ReactComponent as NoData } from '../assets/nodata.svg';
import 'react-toastify/dist/ReactToastify.css';
import { classNames } from '../Styles/Table/Group';
import { defaultTheme } from '../Themes/DefaultTheme';
import ReactTextRotator from '../Did-You-Know/ReactTextRotator';
import { IChartProps, IChartDataPoint, StackedBarChart } from '@fluentui/react-charting';
import { TenantScannerRoutes } from '../Interface/IRouteManager';
import * as util from "util";
import { RenderItemColumnDevOps } from '../Helper/Table/renderItemColumnDevOps';
import { NoControlResultFound, UnauthorizedHeader } from '../ComplianceInitiativeEditor/Constants';

const theme = loadTheme(defaultTheme);
const verticalGapStackTokens: IStackTokens = {
    childrenGap: 10,
  };

const RuntimeConfigurationInitial = window.__UI_CONFIGURATION_INITIAL__;

export class TableDevOps extends React.Component<TableProps, ITableGroupStateDevOps> {
    private _root = React.createRef<IDetailsList>();
    private _selection: Selection;

    private RuntimeConfigurationExtended = window.__UI_CONFIGURATION_EXTENDED_DEVOPS__;
    static contextType = FormContext;

    constructor(props?) {
        super(props);
        const formConf: IFormConfigurationsDevOps = {
            workItems: ['Loaded'],
            projects: [],
            resource: [],
            control: [],
            failed: true,
            baseline: true,
            excludeExternalControls: false,  
            complianceInitiative: "",  
        }
        
        const columns: IColumn[] = [
            {
                key: 'controlObject',
                name: 'Control Name',
                fieldName: 'controlObject',
                minWidth: 100,
                isSortedDescending: false,
                isSorted: true,
                isPadded: false,
                onColumnClick: this._onColumnClick,
                maxWidth: 400,
                isResizable: true,
            }, {
                key: 'status',
                name: 'Status',
                fieldName: 'status',
                minWidth: 70,
                // isSortedDescending: false,
                // isSorted: true,
                maxWidth: 100,
                isPadded: false,
                onColumnClick: this._onColumnClick,
                isResizable: true,
                
            }, {
                key: 'resource',
                name: 'Resource',
                fieldName: 'resourceObject',
                minWidth: 100,
                isPadded: false,
                // isSortedDescending: false,
                // isSorted: true,
                maxWidth: 200,
                // isFiltered: true,
                onColumnClick: this._onColumnClick,
                isResizable: true
            }, 
            {
                key: 'statusReason',
                name: 'Status Reason',
                fieldName: 'statusReason',
                minWidth: 50,
                isPadded: false,
                // isSortedDescending: false,
                // isSorted: true,
                // onColumnClick: this._onColumnClick,
                isResizable: true
            }
        ];

        // Function to control selection of item in table.
        this._selection = new Selection({
            onSelectionChanged: () => {
                try {
                    var anchorIndex = this._selection["_anchoredIndex"];
                    var selectedItems = this._selection.getSelection();
                    if(this._selection["_items"][anchorIndex].name !== "Control Name") {
                        if(this._selection.count === 0) {
                             this.context.setExceptionControls("", "");
                            this.context.setExceptionDataAdd([]);
                            this.context.setExceptionDataClear([]);
                        }
                        else {
                            var clear_items = [];
                            var add_items = [];
                            if(this.context.exceptionControls === "") {
                                this.context.setExceptionControls(this._selection["_items"][anchorIndex].controlObject["displayName"],
                                this._selection["_items"][anchorIndex].workItemId);
                                for(var i=0; i < selectedItems.length; i++) {
                                    if (selectedItems[i]["status"]["status"] === "Passed*") {
                                        clear_items.push(selectedItems[i]);
                                    }                                        
                                    if(selectedItems[i]["status"]["status"] === "Failed" || selectedItems[i]["status"]["status"] === "Exempted" ||
                                        (selectedItems[i]["status"]["status"] === "Passed*" && Number(selectedItems[i]["expiry"]) <= 30 &&
                                            Number(selectedItems[i]["expiry"]) > 0)) {
                                        add_items.push(selectedItems[i]);
                                    }
                                }
                                this.context.setExceptionDataAdd(add_items);
                                this.context.setExceptionDataClear(clear_items);
                            }
                            else {
                                if(this._selection["_items"][anchorIndex].controlObject["displayName"] !== this.context.exceptionControls || 
                                this._selection["_items"][anchorIndex].subscriptionId !== this.context.exceptionSub) {
                                    toast("You can select one control and subscription at a time.");
                                    this._selection.setIndexSelected(anchorIndex, false, true);
                                }
                                else {
                                    // eslint-disable-next-line
                                    for(var i=0; i < selectedItems.length; i++) {
                                           if (selectedItems[i]["status"]["status"] === "Passed*") {
                                            clear_items.push(selectedItems[i]);
                                        }
                                        if(selectedItems[i]["status"]["status"] === "Failed" || selectedItems[i]["status"]["status"] === "Exempted" ||
                                            (selectedItems[i]["status"]["status"] === "Passed*" &&
                                                Number(selectedItems[i]["expiry"]) <= 30 && Number(selectedItems[i]["expiry"]) > 0)) {
                                            add_items.push(selectedItems[i]);
                                        }
                                     }
                                    this.context.setExceptionDataAdd(add_items);
                                    this.context.setExceptionDataClear(clear_items);
                                }
                            }
                        }           
                    }
                    else {
                        this._selection.setIndexSelected(anchorIndex, false, true);
                    }        
                } catch (error) {
                    console.log(error.toString());
                }
            },
        });

        this.state = {
            items: [],
            filteredItems: [],
            groups: [],
            columns: columns,
            columns_exception: null,
            showItemIndexInView: false,
            isCompactMode: false,
            formConf: formConf,
            internalState: new Map<string, IInternalStateDevOps>(),
            selectedWorkItems: new Map<string, boolean>(),
            expandedWorkItems: new Map<string, boolean>(),
            workItemJobMap: new Map<string, string>(),
            isRecommend: true,
            textVisible: false,
            moreText: '',
            orgProjectMapping: new Map<string, IOrgProjectMapping>(),
            workItemCompliancePageNum: 0
        };
    }

    private _resolution() {
        var groups: IGroup[] = [];
        var items: ITableIndividualRowStateDevOps[] = [];
        var iter = 0;
        var expandedWorkItems : Map<string, boolean> = this.state.expandedWorkItems;
        this.state.internalState.forEach((value: IInternalStateDevOps, key: string) => {
            var isCollapsed = expandedWorkItems.get(key);
            groups = groups.concat({
                key: value.workItemId + "_" + value.projectName,
                name: value.workItemName,
                startIndex: iter,
                count: value.controls.length,
                level: 0,
                data: value,
                isCollapsed: isCollapsed
            });

            items = items.concat(value.controls);
            iter += value.controls.length;
        });
        this.setState({
            groups: groups,
            items: items,
            filteredItems: items,
            expandedWorkItems: expandedWorkItems
        });
        this.context.toggleShimmer(false);
    }

    private _processWorkItemData(dataString : string) {
        var data = JSON.parse(dataString);
        var internalState: Map<string, IInternalStateDevOps> = new Map<string, IInternalStateDevOps>();
        var selectedWorkItems: Map<string, boolean> = this.state.selectedWorkItems;
        var expandedWorkItems: Map<string, boolean> = this.state.expandedWorkItems;
        var workItemJobMap: Map<string, string> = this.state.workItemJobMap;
        var orgProjectMapping : Map<string, IOrgProjectMapping> = this.state.orgProjectMapping;
        for (var i = 0; i < data.length; i++) {
            var header : ITableIndividualRowStateDevOps[] = [];
            var stateData : IInternalStateDevOps = {
                parentId: data[i].tenantID,
                parentName: data[i].tenantName,
                workItemId: data[i].workItemName,
                projectName: data[i].projectName,
                workItemName: data[i].workItemName + " (" + data[i].projectName + ")",
                qStatus: data[i].queueStatus,
                lastScanned: data[i].lastModifiedDateTime,
                passingControls: data[i].passingControlCount,
                totalControls: data[i].totalControlCount,
                failingControls: data[i].failedControlCount,
                jobID: data[i].adhocScanJobId,
                compliance: data[i].compliancePercentage,
                controls: header,
                initial: true,
                scanningTime: data[i].scanningTime,
                scanningCount: data[i].scanningCount.toString(),
                isSelected: false,
                totalPages: data[i].totalPages,
                currentPage: 1,
                visibility: data[i].visibility,
                lastScan: data[i].lastScan,
                nextScan: data[i].nextScan
            }
            internalState.set(data[i].workItemName + "_" + data[i].projectName, stateData);
            selectedWorkItems.set(data[i].workItemName + "_" + data[i].projectName, false);
            expandedWorkItems.set(data[i].workItemName + "_" + data[i].projectName, true);
            workItemJobMap.set(data[i].workItemName + "_" + data[i].projectName,data[i].adhocScanJobId);

            var subEntry : IOrgProjectMapping = {
                organization: data[i].workItemName,
                project: data[i].projectName
            }
            orgProjectMapping.set(data[i].workItemName + "_" + data[i].projectName,subEntry);
        }
        this.setState({
            internalState: internalState,
            selectedWorkItems: selectedWorkItems,
            expandedWorkItems: expandedWorkItems,
            workItemJobMap: workItemJobMap,
            orgProjectMapping: orgProjectMapping
        });
        this._resolution();     
    }
    
    // Process individual row item in table.
    private _processItemData(dataString : string, workItemId : string) {
        var data = JSON.parse(dataString);
        var workItemEntry = this.state.internalState.get(workItemId);
        var new_items:ITableIndividualRowStateDevOps[] = [];
        var curr_length = this.state.items.length;
        for (var i = 0; i < data.length; i++) {
            if(data[i].controlName) {
                
                var statusObject : IStatusObject = {
                    status: data[i].status,
                    message: data[i].statusReason
                };

                var statusReasonObject : IStatusReasonObject = {
                    name: data[i].controlName,
                    expiry: data[i].exceptionExpiryInDays ? data[i].exceptionExpiryInDays : "",
                    statusReason: data[i].statusReason,
                    rg:data[i].resourceGroupName,
                    resource: data[i].resourceName,
                    additionalInformation: data[i].additionalInformation ? JSON.parse(data[i].additionalInformation) : {},
                    justification: data[i].justification
                };

                var controlObject : IControlObject = {
                    subscriptionId: '',
                    resourceId: '',
                    name: data[i].controlName,
                    displayName: data[i].controlDisplayName,
                    expiry: data[i].exceptionExpiryInDays ? data[i].exceptionExpiryInDays : "",
                    description: data[i].description,
                    remediation: data[i].remediation,
                    controlRequirements : data[i].controlRequirements,
                    category: data[i].category,
                    controlShortId: data[i].controlShortId,
                    actualDisplayName: data[i].actualDisplayName,
                    justification: data[i].justification,
                    statusReason: data[i].statusReason,
                    rg:data[i].resourceGroupName,
                    resource: data[i].resourceName,
                    additionalInformation: data[i].additionalInformation ? JSON.parse(data[i].additionalInformation) : {},
                    featureName: data[i].featureName,
                };

                var resourceObject: IResourceObjectDevOps = {
                    resource: data[i].resourceName,
                    resourceLink: data[i].resourceLink
                };

                new_items = new_items.concat({
                    key: (curr_length+i).toString(),
                    name: data[i].controlName,
                    status: statusObject,
                    resource: data[i].resourceName,
                    rg: data[i].resourceGroupName,
                    severity: data[i].severity,
                    baseline: data[i].isBaseline,
                    expiry: data[i].exceptionExpiryInDays ? data[i].exceptionExpiryInDays : "",
                    isEligibleForException: data[i].isEligibleForException,
                    workItemId: data[i].subscriptionId,
                    orgTenantId: data[i].orgTenantId,
                    resourceID: data[i].resourceID,
                    justification: data[i].justification,
                    allowedExceptionType: data[i].allowedExceptionType,
                    controlObject: controlObject,
                    resourceObject: resourceObject,
                    statusReason: statusReasonObject,
                    controlShortId: data[i].controlShortId,
                });
            }
        }
        workItemEntry.controls = new_items;
        workItemEntry.initial = false;
        this.state.internalState.set(workItemId,workItemEntry);
        this._resolution();
    }

    // Compare state and context to determine if page should reload.
    private _compareContextWithState() {
        if(JSON.stringify(this.context.workItems) === JSON.stringify(this.state.formConf.workItems) &&
            JSON.stringify(this.context.projects) === JSON.stringify(this.state.formConf.projects) &&
            JSON.stringify(this.context.control) === JSON.stringify(this.state.formConf.control) && 
            JSON.stringify(this.context.baseline) === JSON.stringify(this.state.formConf.baseline) &&
            JSON.stringify(this.context.complianceInitiative) === JSON.stringify(this.state.formConf.complianceInitiative) &&
            JSON.stringify(this.context.excludeExternalControls) === JSON.stringify(this.state.formConf.excludeExternalControls) &&
            JSON.stringify(this.context.failed) === JSON.stringify(this.state.formConf.failed)) {
            return 0;
        }
        return 1;
    }

    private _setWorkItemCompliancePageNum(index: number) {
        this.setState({
            workItemCompliancePageNum: index
        })
    }

    private _checkContextIsEmpty() {
        if(this.context.workItems.length &&
            this.context.control.length &&
            this.context.projects.length) {
            return 1;
        }
        return 0;
    }

    private _fetchIndividualRecords(workItemId : string, pageNumber : number = 1) {
        var workItemArray = workItemId.split("_");
        console.log(workItemId);
        var workItemName = workItemArray[0];
        var projectName = workItemArray[1];
        const CallAPI = async () => {
            const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
            let body = {                
                JobId: Number(this.state.workItemJobMap.get(workItemId)),
                Project: projectName,
                ControlIdList: this.context.control,
                ResourceNameList: this.context.resource,
                ExcludePassedControls: this.context.failed,
                GetControlsEligibleForException: this.context.attestationMode,
                FilterBaselineType: this.context.baseline,
                ExportToCSV: false,
                ExcludeExternalControls: this.RuntimeConfigurationExtended.externalControlsConfiguration?.isEnabled ? this.context.excludeExternalControls : true,
                PageNumber: pageNumber,
                ComplianceInitiativeId: this.context.complianceInitiative,
            }

            if(!workItemId){
                workItemId = "/"
            }

            fetch((RuntimeConfigurationInitial.webAPIConf["DEVOPS"] + util.format(TenantScannerRoutes.FetchWorkItemScanResult,workItemName)), {
                headers: !authToken.accessToken ? {} : {
                    'Authorization': `Bearer ${authToken.accessToken}`,
                    'u_Id': sessionStorage.getItem("u_Id"),
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(body)
            }).then(response => {
                if (response.ok) {
                    response.json().then(data => { this._processItemData(JSON.stringify(data), workItemId); });
                }
                else {
                    response.text().then(errorMsg => {
                        this.context.toggleShimmer(false);
                    });
                }
            });
        }
        CallAPI();
    }

    // Fetch summary for WorkItem ribbon
    private _fetchWorkItemSummary(pageNumber: number = 1) {
        const CallAPI = async () => {
            const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
            var workItemId = this.context.workItems;
            let body = {
                SubscriptionIDList: workItemId,
                ProjectList: this.context.projects,
                ControlIdList: this.context.control,
                ExcludePassedControls: this.context.failed,
                ExcludeExternalControls: this.RuntimeConfigurationExtended.externalControlsConfiguration?.isEnabled ? this.context.excludeExternalControls : true,
                GetControlsEligibleForException: this.context.attestationMode,
                FilterBaselineType: this.context.baseline,
            }

            fetch((RuntimeConfigurationInitial.webAPIConf["DEVOPS"] + TenantScannerRoutes.FetchWorkItemComplianceSummary), {
                headers: !authToken.accessToken ? {} : {
                    'Authorization': `Bearer ${authToken.accessToken}`,
                    'u_Id': sessionStorage.getItem("u_Id"),
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(body)
            }).then(response => {
                if (response.ok) {
                    response.json().then(data => {this._processWorkItemData(JSON.stringify(data))});
                    this.context.toggleShowErrorMessage(false);
                }
                else {
                    response.text().then(errorMsg => {
                        this.context.setErrorMessageToDisplay(errorMsg.slice(0,500));
                        this.context.setErrorResponse(response.status.toString());
                        this.context.toggleShowErrorMessage(true);
                        this.context.toggleShimmer(false);
                    });
                }
            });
        }
        CallAPI();
    }

    public _setStateWithContext() {
        var form : IFormConfigurationsDevOps = {
            workItems: this.context.workItems,
            projects: this.context.projects,
            resource: this.context.resource,
            control: this.context.control,
            failed: this.context.failed,
            baseline: this.context.baseline,
            excludeExternalControls: this.context.excludeExternalControls, 
            complianceInitiative: this.context.complianceInitiative
        }

        this.setState({
            formConf: form
        });
    }

    componentDidMount() {
        var timeForExpiry = Number(localStorage.getItem("DUK_Expiry")); 
        var todayTime = new Date().getTime();
        if(todayTime < timeForExpiry){
            this.setState({
                isRecommend: false 
            });
        }
    }

    public componentDidUpdate() {
        if((this._compareContextWithState() && this.context.visible && this._checkContextIsEmpty()) || this.context.forceRefresh) {
            
            this.context.setForceRefresh(false);
            this.context.setSelectedWorkItems([]);
            this.context.setExceptionDataAdd([]);
            this.context.setExceptionDataClear([]);
            this.context.setExceptionControls("", "");
            this.context.toggleShimmer(true);
            this._setStateWithContext();
            this._fetchWorkItemSummary();
            this._setWorkItemCompliancePageNum(0);
        }     
    }

    private _onChangeToggle = (ev: React.MouseEvent<HTMLElement>, checked: boolean) : void => {
        this.context.setAttestationMode(checked);
        this.context.setExceptionDataAdd([]);
        this.context.setExceptionDataClear([]);
        this.context.setExceptionControls("", "");
        this._refreshTable();
    }

    private _onDismissDUK = (ev?: React.MouseEvent<HTMLElement | BaseButton | Button>) => {

        // New time to expire is in 1 week.
        var newTimeForExpiry = new Date().getTime() + 604800000;
        localStorage.setItem("DUK_Expiry", newTimeForExpiry.toString());    
        this.setState({
            isRecommend: false
        });
        toast("Hiding section for one week. You can view all the suggestions from bulb icon at the top of the page.", {
            autoClose: 6000,
            progressClassName: _InfoToast.toastProgress,
            className: _InfoToast.toastClass
        });
    }

    private _onRenderGroupHeader: IGroupRenderProps['onRenderHeader'] = props => {
        var points: IChartDataPoint[] = [
            {
                legend: 'Passed', 
                data: props.group!.data.passingControls*100/props.group!.data.totalControls, 
                color: DefaultPalette.green,
                yAxisCalloutData : props.group!.data.passingControls
            },
            {
                legend: 'Failed', 
                data: props.group!.data.failingControls*100/props.group!.data.totalControls, 
                color: DefaultPalette.red,
                yAxisCalloutData: props.group!.data.failingControls
            },
        ];

        var data: IChartProps = {
            chartData: points,
            chartTitle: 'Compliance: ' + props.group!.data.compliance
        };

        return (                  
            <div
                className={this.state.selectedWorkItems.get(props.group!.key) ?
                    classNames.headerAndFooterSelected :
                    props.group!.isCollapsed ?
                        classNames.headerAndFooter :
                        classNames.headerAndFooterExpand}
                style={{
                    borderLeftColor: this.context.attestationMode ? this.state.selectedWorkItems.get(props.group!.key) ? '' : SharedColors.orange10 : '',
                }}
                >
                <Stack horizontal horizontalAlign="space-between">
                    <Stack>
                        <CollapsibleSection
                            key={1} 
                            defaultCollapsed={true} 
                            collapsed={props.group.isCollapsed}
                            title={{
                                text: ``,
                                "aria-label":'Collapse subscription',
                                chevron: {color: theme.palette.themePrimary, style:{outline:"none"}},
                                styles: {
                                    text:theme.fonts.xLarge,
                                    root: {
                                        outline: "none !important",
                                        selectors: {
                                            ':hover': {
                                                backgroundColor: 'inherit'
                                            }
                                        }
                                    }
                                },
                                onClick: this._onToggleCollapse(props),
                                disabled: props?.group!?.data?.totalControls > 0 ? false : true
                            }}
                        >
                        </CollapsibleSection>
                    </Stack>
                    <Stack style={{width:'100%'}}>
                        <div className={classNames.headerTitle}>

                            {props.group!.data.visibility === "Project" &&              
                                <Text variant='mediumPlus' style={{fontWeight: 600, color: theme.palette.themeDarker}}>{props.group!.name}</Text>
                            }
                            
                            {props.group!.data.visibility === "Organization" &&
                                <Text variant='mediumPlus' style={{fontWeight: 600, color: theme.palette.themeDarker}}>{props.group!.data.workItemId}</Text>
                            }
                        </div>
                        <Stack horizontal tokens={horizontalStackTokens} >
                            <Stack style={{width:"40%"}} className={classNames.bodyTitle}>
                                <Stack horizontal>
                                    <b style={{fontWeight: 600}}>Visibility:</b>&nbsp;{props.group!.data.visibility}
                                </Stack>
                                {props?.group!?.data?.totalControls > 0 && 
                                    <Stack horizontal>
                                        <b style={{fontWeight: 600}}>Total Controls:</b>&nbsp;{props.group!.data.totalControls}&nbsp;
                                        (<b style={{fontWeight: 600, color: SharedColors.green20}}>Passed: {props.group!.data.passingControls}</b>&nbsp;|&nbsp;
                                        <b style={{fontWeight: 600, color: SharedColors.red20}}> Failed: {props.group!.data.failingControls}</b>)
                                </Stack>
                                }
                                {props?.group!?.data?.totalControls === 0 && 
                                    <Stack horizontal>
                                        <b style={{fontWeight: 600}}>Total Controls:</b>&nbsp;
                                        <b style={{fontWeight: 600, color: SharedColors.red20}}> {NoControlResultFound}</b>
                                </Stack>
                                }
                            </Stack>

                            <Stack style={{width:"30%"}} className={classNames.bodyTitle}>
                                <Stack horizontal>
                                    <b style={{fontWeight: 600}}>Last Scan (UTC):</b>&nbsp;{props.group!.data.lastScan}
                                </Stack>
                                <Stack horizontal>
                                    <b style={{fontWeight: 600}}>Next Scan (UTC):</b>&nbsp;{props.group!.data.nextScan}
                                </Stack>
                            </Stack>
                            
                            {props?.group!?.data?.totalControls > 0 && 
                                <Stack style={{width:"30%"}} verticalAlign="end" className={classNames.bodyTitle}>
                                    <StackedBarChart    
                                        data={data}
                                        width={1200}
                                        hideLegend={true}
                                        ignoreFixStyle={true}
                                    />
                                </Stack>
                            }
                        </Stack>
                    </Stack>
                    <Stack reversed horizontal style={{width:'35%'}}>
                        <Stack verticalAlign="space-between" horizontalAlign="end">
                            <Checkbox 
                                checked={this.state.selectedWorkItems.get(props.group!.key)}
                                onChange={this._onToggleSelectGroup(props)}
                                label={
                                    this.state.selectedWorkItems.get(props.group!.key) ? 'Unselect' : 'Select '}
                                boxSide="end"
                            />
                            {!props.group!.isCollapsed && (props.group!.data.totalPages > 1) &&
                                <Pagination
                                    selectedPageIndex={props.group!.data.currentPage-1}
                                    pageCount={props.group!.data.totalPages}
                                    format={'comboBox'}
                                    comboBoxAriaLabel='Pagination Info'
                                    previousPageAriaLabel={'previous page'}
                                    nextPageAriaLabel={'next page'}
                                    firstPageAriaLabel={'first page'}
                                    lastPageAriaLabel={'last page'}
                                    pageAriaLabel={'page'}
                                    onPageChange={
                                        (index: number): void => {
                                            this._fetchIndividualRecords(props.group!.key, index + 1);
                                            var currentInternalState = this.state.internalState;
                                            currentInternalState["currentPage"] = index + 1;
                                            this.setState({internalState: currentInternalState});
                                            props.group!.data.currentPage = index + 1;
                                        }
                                    }
                                    styles={{
                                        previousNextPage: {
                                            background: 'inherit !important',
                                        },
                                        previousNextPageDisabled: {
                                            background: 'inherit !important',
                                        },
                                        comboBox: {
                                            height: '100%'
                                        }
                                    }}
                                />
                            }
                        </Stack>
                    </Stack>
                </Stack>  
            </div>
        );
    };

    private _onToggleSelectGroup(props: IGroupDividerProps): () => void {
        return () => {
            this.state.selectedWorkItems.set(props.group!.key, !this.state.selectedWorkItems.get(props.group!.key));
            props!.onToggleCollapse!(props!.group!);  
            var selectedWorkItems : Array<IOrgProjectMapping> = [];
            this.state.selectedWorkItems.forEach((value: boolean, key: string) => {
                if(value) {
                    var workItemEntry = this.state.orgProjectMapping.get(key);
                    selectedWorkItems = selectedWorkItems.concat({
                        organization: workItemEntry.organization,
                        project: workItemEntry.project
                    })
                }
            });
            this.context.setSelectedWorkItems(selectedWorkItems);
            props!.onToggleCollapse!(props!.group!);
        };
    }

    private _onToggleCollapse(props: IGroupDividerProps): () => void {
        return () => {
            if(props.group!.data.initial) {
                this.context.toggleShimmer(true);
                this._fetchIndividualRecords(props.group!.key);
            }
            props!.onToggleCollapse!(props!.group!);  
            this.state.expandedWorkItems.set(props.group!.key,props.group!.isCollapsed);      
        };
    }

    private _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (props) {
            props.checkboxVisibility = 1;
            return (
                <DetailsRow {...props} styles={customStyles} />
            );
        }
        return null;
      };

    private _getGroupTotalRowHeight = (group: IGroup): number => {
        return group.isCollapsed ? 0 : ROW_HEIGHT * group.count;
    };

    private _getGroupHeight = (group: IGroup, _groupIndex: number): number => {
        return GROUP_HEADER_HEIGHT + GROUP_FOOTER_HEIGHT + this._getGroupTotalRowHeight(group);
    };

    private _refreshTable = (): void => {
        this.context.toggleShimmer(true);
        this.context.setForceRefresh(true);
    }

    public render() {
        const {filteredItems, groups, columns, columns_exception } = this.state;
        return this.context.visible && !this.context.showErrorMessage ?
            (<div>
                {this.context.shimmer && <ProgressIndicator barHeight={4} label="" description="" className={classNames.progressBar} />}
                <div style = {tableStyles}>
                    <Stack horizontal horizontalAlign="space-between">
                        <Stack horizontal style={{marginLeft: 24,marginTop: 24, width:'38%'}}>
                            <InfoTable/>
                            {
                                this.state.isRecommend && 
                                <MessageBar
                                    messageBarType={MessageBarType.success}
                                    isMultiline={true}
                                    dismissButtonAriaLabel="Do not show for one week"
                                    onDismiss={this._onDismissDUK}                                    
                                    className={classNames.messageDUK}
                                >
                                    <div style={{width:'100%', height:'30'}}>
                                        <ReactTextRotator/>
                                    </div>
                                </MessageBar> 
                            }
                            
                        </Stack>
                        <Sticky stickyPosition={StickyPositionType.Header} stickyClassName={classNames.pane}>
                            <Stack horizontalAlign="end" horizontal style= {{marginRight: 24, marginTop: 24, width:'100%'}} tokens={{childrenGap:24}}>
                                {/* <Stack horizontal>
                                    {   window.__UI_CONFIGURATION_EXTENDED_AZURE__.exceptionFeatureConfiguration?.isEnabled === true &&
                                        (
                                            // For external customers who may want to use only "ByDesign" exceptions (Self Attestations).
                                            (window.__UI_CONFIGURATION_EXTENDED_AZURE__.exceptionFeatureConfiguration?.customExceptionTypes?.length === 0) ||
                                            window.__UI_CONFIGURATION_EXTENDED_AZURE__.exceptionFeatureConfiguration?.customExceptionTypes?.some(exceptionType =>
                                                (exceptionType.isEnabledForAllTags === true)) ||
                                            window.__UI_CONFIGURATION_EXTENDED_AZURE__.exceptionFeatureConfiguration?.customExceptionTypes?.some(exceptionType =>
                                                (exceptionType.eligibleTagsMetadata.some(tag => (tag.name === this.props.baselineType))))
                                        ) &&
                                        <Toggle 
                                            label="Exception Mode" 
                                            inlineLabel onText="On" 
                                            offText="Off" 
                                            onChange={this._onChangeToggle}
                                        />                                    
                                    }
                                </Stack>      */}
                                <Stack tokens={{childrenGap:24}} horizontal style={{marginRight:24}}>
                                    {/* <DefaultButton 
                                        iconProps={refreshIcon} 
                                        text="Refresh"
                                        style={{outline:"none", boxShadow: theme.effects.elevation4}}
                                        onClick={this._refreshTable}
                                    /> */}
                                    <DialogCartDevOps/>
                                </Stack> 
                            </Stack>
                        </Sticky>
                    </Stack>
                    <Fabric>
                        <Stack style={{marginLeft:30, marginRight:30}}>
                            {
                                <DetailsList
                                    componentRef={this._root}
                                    items={filteredItems}
                                    columns={columns}
                                    selectionPreservedOnEmptyClick={true}
                                    constrainMode={ConstrainMode.unconstrained}
                                    // enableShimmer={this.context.shimmer}
                                        onRenderItemColumn={RenderItemColumnDevOps}
                                    groups={groups}
                                    enableUpdateAnimations={true}
                                    isHeaderVisible={true}
                                    onRenderRow={this._onRenderRow}
                                    selectionMode={SelectionMode.none}
                                    groupProps={{
                                        showAllProps: {
                                            showAllLinkText: 'Load More'
                                        },
                                        showEmptyGroups: true,
                                        collapseAllVisibility: 0,
                                        isAllGroupsCollapsed: true,
                                        onRenderHeader: this._onRenderGroupHeader
                                    }}
                                    getGroupHeight={this._getGroupHeight}
                                    onRenderDetailsHeader={onRenderDetailsHeader}
                                />
                            }
                        </Stack>
                    </Fabric>
                    
                    <div className={_Styles.rowGap} />
                    {/* <Stack>
                        {this.context.workItems.length / this.RuntimeConfigurationExtended.subCountInPage > 1 &&
                            <Pagination
                                selectedPageIndex={this.state.workItemCompliancePageNum}
                                pageCount={Math.ceil(this.context.workItems.length / this.RuntimeConfigurationExtended.subCountInPage)}
                                itemsPerPage={this.RuntimeConfigurationExtended.subCountInPage}
                                totalItemCount={this.context.workItems.length}
                                format={'buttons'}
                                previousPageAriaLabel={'previous page'}
                                nextPageAriaLabel={'next page'}
                                firstPageAriaLabel={'first page'}
                                lastPageAriaLabel={'last page'}
                                pageAriaLabel={'page'}
                                selectedAriaLabel={'selected'}
                                onPageChange={(index: number): void => {
                                    this.context.toggleShimmer(true);
                                    this._fetchWorkItemSummary(index + 1);
                                    this._setWorkItemCompliancePageNum(index);
                                }}

                            />
                        }
                    </Stack> */}
                </div>
            </div>)
        :
        (<div>
            { this.context.showErrorMessage && 
            
                <Stack verticalAlign="center" tokens={verticalGapStackTokens}>
                    <div className={_Styles.rowGap} />
                    <div className={_Styles.rowGap} />
                    <div className={_Styles.rowGap} />
                    <div className={_Styles.topMargin}/>
                
                    <Stack.Item  align="center">
                
                        {this.context.errorResponse ==="404" && !this.context.errorHeaderToDisplay.includes(UnauthorizedHeader) &&
                            <NoData/>
                        }
                        {this.context.errorResponse ==="500" &&
                            <CatSvg/>  
                        }
                    </Stack.Item>
                    <div className={_Styles.rowGap} />                            
                    <div className={_Styles.rowGap} />
                
                    <Stack.Item className={_Styles.fontSize} align="center"><b>{this.context.errorHeaderToDisplay}</b></Stack.Item>
                    <Stack.Item  align="center">{this.context.errorMessageToDisplay}</Stack.Item>

                </Stack>   
            }
        </div>);
    }

    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const columns = this.context.attestationMode ? this.state.columns_exception : this.state.columns;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        this.state.internalState.forEach((value: IInternalStateDevOps, key: string) => {
            const newItems = copyAndSortDevOps(value.controls, currColumn.fieldName!, currColumn.isSortedDescending);
            value.controls = newItems;
            this.state.internalState.set(key, value);
        });
        this._resolution();
    };
}