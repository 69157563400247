import React, { Component, createContext } from 'react';

export const PanelContext = createContext();

// Array containing Did You Know messages.
const textDUKArrayAzureDefinition = ['The subscription compliance summary list is sorted on the basis of Failed control.','You can click on Control Name to view recommendations.', 
  'The subscription summary is in accordance to the filter criterion.', 
  'Non-Baseline controls are scanned on a weekly basis every Monday. Please schedule adhoc scan to view the Non-Baseline controls results.',
  'Compliance visibility is based on your access ("Owner", "Contributor", "ServiceAdministrator", "CoAdministrator", "AccountAdministrator", "Security Admin", "DevOps roles", "Security Reader") at subscription or resource group(s) level.',
  'AzTS maps SC-ALT account to your corporate account to determine which account you can see in AzTS UI.',
  'For newly granted permission to a subscription, it may take up to 24 hours in AzTS UI to reflect the changes.'
  ];

const textDUKArrayDevOpsDefinition = ['Compliance summary list is sorted based on the failed control(s).',
  'Hover on the control name to view the remediation guide.',
  'Compliance summary is in accordance with the filter criterion.',
  'Controls are scheduled to be scanned on daily basis',
  'Compliance visibility is based on your access ("Project Collection Administrator", "Project Administrator", "Build Administrator", "Endpoint Administrator", "Resource Administrator" etc.) at organization or project or individual resource level.',
  'To know more about remediation support or exceptions, visit https://aka.ms/RemediationSupport'];

export class PanelContextProvider extends Component {
  state = {
    personaPanel: false,
    helpPanel: false,
    didYouKnowPanel: false,
    blobURL: '',
    userPrincipalName: '',
    displayName: '',
    jobTitle: '',
    textDUKAzureArray: textDUKArrayAzureDefinition,
    textDUKDevOpsArray: textDUKArrayDevOpsDefinition,
    sidePanel: false,
    controlRow: {},
    resourceRow: {},
    panelMode: '',
    complianceInitiativePanel: false,
    complianceInitiativeId: '',
    complianceInitiativeDescription: '',
    defaultControlTagFilter : '',
    showControlDisplayName: '',
    controlTagList:[],
    logs:[],
    imageURL : {},
    visibleLogs: false

  }

  setUserImage = (value) => {
    this.setState({
      blobURL: value
    })
  }

  setVisibleLogs = (value) => {
    this.setState({
      visibleLogs: value
    })
  }
  setImageURL = (value) => {
    this.setState({
      imageURL:value
    })
  }

  setLogs = (value) => {
    this.setState({
      logs: value
    })
  }

  setUserDetails = (displayName, userPrincipalName, jobTitle) => {
    this.setState({
      displayName: displayName,
      userPrincipalName: userPrincipalName,
      jobTitle: jobTitle
    })
  }

  setTextDUKArray = (newTextArray) => {
    this.setState({
      textDUKAzureArray: newTextArray
    })
  }

  setPanelMode = (data) => {
    this.setState({
      panelMode: data
    })
  }

  setControlRow = (data) => {
    this.setState({
      controlRow: data
    })
  }


  setResourceRow = (data) => {
    this.setState({
      resourceRow: data
    })
  }

  setComplianceInitiativeId = (data) => {
    this.setState({
      complianceInitiativeId: data
    })
  }
  setComplianceInitiativeDescription = (data) => {
    this.setState({
      complianceInitiativeDescription: data
    })
  }
  setDefaultControlTagFilter = (data) => {
    this.setState({
      defaultControlTagFilter: data
    })
  }
  setShowControlDisplayName = (data) => {
    this.setState({
      showControlDisplayName: data
    })
  }

  setControlTagList = (data) => {
    this.setState({
      controlTagList: data
    })
  }

  togglePersonaPanel = () => {
    this.setState({
      personaPanel: !this.state.personaPanel
    })
  }
  
  toggleHelpPanel = () => {
    this.setState({
      helpPanel: !this.state.helpPanel
    })
  }

  toggleSidePanel = () => {
    this.setState({
      sidePanel: !this.state.sidePanel
    })
  }

  toggleComplianceInitiativePanel = () => {
      this.setState({
          complianceInitiativePanel: !this.state.complianceInitiativePanel
    })
  }

  toggleDidYouKnowPanel = () => {
    this.setState({
      didYouKnowPanel: !this.state.didYouKnowPanel
    })
  }
 
  render() { 
    return (
      <PanelContext.Provider value={{
        ...this.state, 
        togglePersonaPanel: this.togglePersonaPanel, 
        toggleHelpPanel: this.toggleHelpPanel,
        toggleDidYouKnowPanel: this.toggleDidYouKnowPanel,
        toggleComplianceInitiativePanel:this.toggleComplianceInitiativePanel,
        toggleSidePanel: this.toggleSidePanel,
        setControlRow: this.setControlRow,
        setPanelMode: this.setPanelMode,
        setResourceRow: this.setResourceRow,
        setUserDetails: this.setUserDetails,
        setUserImage: this.setUserImage,
        setTextDUKArray: this.setTextDUKArray,
        setComplianceInitiativeDescription:this.setComplianceInitiativeDescription,
        setComplianceInitiativeId:this.setComplianceInitiativeId,
        setDefaultControlTagFilter: this.setDefaultControlTagFilter,
        setShowControlDisplayName:this.setShowControlDisplayName,
        setControlTagList:this.setControlTagList,
        setLogs :this.setLogs,
        setImageURL: this.setImageURL,
        setVisibleLogs:this.setVisibleLogs
      }}
      >
        {this.props.children}
      </PanelContext.Provider>
    );
  }
}
 
export default PanelContextProvider;