import React from 'react';
import { IStackTokens, ScrollablePane, Stack } from '@fluentui/react';
import { _Styles } from './Page.styles';
import { FilterControlEditor } from './ControlEditor/FilterControlEditor';
import { TableControlEditor } from './ControlEditor/TableControlEditor';
import { AdminProps } from './Page.types';
import { ControlEditorPanel } from './Panels/ControlEditorPanel';
import { AdminContextProvider } from './context/ControlEditor';
import { UpdateButton } from './ControlEditor/Update';
import { NewControl } from './Panels/NewControl';
import { HelpContextProvider } from './context/HelpContextControlEditor';
import { BulkEditPanel } from './Panels/BulkEditPanel';
import { EditAdditionalField } from './Panels/EditAddField';
import { EnableOrDisableButton } from './ControlEditor/BulkEnableOrDisable';

const horizontalStackTokens: IStackTokens = {
    childrenGap: 5,
};

// loadTheme(controlEditorTheme)
export class ControlEditor extends React.Component<AdminProps, {}> {

    componentDidUpdate(){
        localStorage.setItem("theme","controlEditor");
        localStorage.setItem("scanner","azure");
    }

    render() {
        return ((window.__UI_CONFIGURATION_EXTENDED_AZURE__.controlEditorFeatureConfiguration.isEnabled === true) && (
            <AdminContextProvider>   
                <HelpContextProvider>
                        
                    <ScrollablePane className={this.props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}>
                        <div className={_Styles.rootDiv}>
                            <FilterControlEditor />
                            <div className={_Styles.rowGap} />
                            <div className={_Styles.rowGap} />
                            <Stack tokens={horizontalStackTokens} style={{margin: 15}}>
                                <TableControlEditor />
                            </Stack>
                            <div className={_Styles.rowGap} />
                            <div className={_Styles.rowGap} />
                            <ControlEditorPanel />
                            <NewControl />
                            <UpdateButton />
                            <EnableOrDisableButton/>
                            <BulkEditPanel />
                            <EditAdditionalField />
                        </div>
                    </ScrollablePane>
                </HelpContextProvider>   
            </AdminContextProvider>
        ));
    }
}