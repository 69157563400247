import React from 'react';
import { getTheme, IIconProps, mergeStyles, mergeStyleSets } from '@fluentui/react';
import { Icon } from '@fluentui/react/lib/Icon';
import { SharedColors } from '@fluentui/theme';

const theme = getTheme();

const iconClass = mergeStyles({
    marginRight: 7,
});

const iconClassStatusReason = mergeStyles({
    marginRight: 7,
    fontSize: '100%'
});


const iconClass2 = mergeStyles({
    fontSize: '150%'
});

const iconClass3 = mergeStyles({
    margin: '0'
});

export const iconNames = mergeStyleSets({
    verify: [{ color: SharedColors.yellow10 }, iconClass],
    default: [{ color: SharedColors.blueMagenta10 }, iconClass],
    controlAttested: [{ color: SharedColors.cyan10 }, iconClass],
    correct: [{ color: "#6bb700" }, iconClass],
    exempted: [{ color: SharedColors.gray20 }, iconClass],
    wrong: [{ color: SharedColors.red10 }, iconClass],
    high: [{ color: SharedColors.redOrange10 }, iconClass],
    critical: [{ color: SharedColors.pinkRed10 }, iconClass],
    med: [{ color: SharedColors.yellow10 }, iconClass],
    low: [{ color: SharedColors.cyanBlue10 }, iconClass],
    info: [{ color: SharedColors.orange10 }, iconClass2],
    expand: [{ color: theme.palette.themeTertiary }, iconClass3],
    reason:[{ color: '#cf680e' }, iconClassStatusReason],
    justification: [{ color: SharedColors.magenta20 }, iconClass],
    unknown: [{ color: SharedColors.gray40 }, iconClass],
});

export const PassedIcon = () => <Icon iconName="SkypeCircleCheck" className={iconNames.correct}/>;
export const UnknownIcon = () => <Icon iconName="FullCircleMask" className={iconNames.unknown}/>;
export const VerifyIcon = () => <Icon iconName="SkypeCircleSlash" className={iconNames.verify}/>;
export const DefaultIcon = () => <Icon iconName="CircleHalfFull" className={iconNames.default}/>;
export const FailedIcon = () => <Icon iconName="SkypeCircleMinus" className={iconNames.wrong}/>;
export const ControlAttestedIcon = () => <Icon iconName="SkypeCircleCheck" className={iconNames.controlAttested}/>;
export const ExemptedIcon = () => <Icon iconName="CirclePauseSolid" className={iconNames.exempted}/>;
export const LowIcon = () => <Icon iconName="FullCircleMask" className={iconNames.low}/>;
export const HighIcon = () => <Icon iconName="FullCircleMask" className={iconNames.high}/>;
export const HighIconTime = () => <Icon iconName="SkypeCircleClock" className={iconNames.high}/>;
export const CriticalIcon = () => <Icon iconName="FullCircleMask" className={iconNames.critical}/>;
export const MedIcon = () => <Icon iconName="FullCircleMask" className={iconNames.med}/>;
export const MedIconTime = () => <Icon iconName="SkypeCircleClock" className={iconNames.med}/>;
export const DefaultTimeIcon = () => <Icon iconName="SkypeCircleClock" className={iconNames.low}/>;
export const CompletedIcon = () => <Icon iconName="CompletedSolid" className={iconNames.low}/>;
export const refreshIcon: IIconProps = { iconName: 'Refresh' };
export const ExceptionIcon = () => <Icon iconName="StatusErrorFull" className={iconNames.wrong} />;
