import React, { CSSProperties, useCallback, useContext, useEffect, useRef, useState } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";
import {NewFeatureContext} from "../context/NewFeatureContext";

const canvasStyles : CSSProperties = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  zIndex:9999
};

// Animation style
function getAnimationSettings(angle, originX) {
  return {
    particleCount: 7,
    angle,
    spread: 100,
    origin: { x: originX }
  };
}

export default function NewFeatureConfetti() {
  const refAnimationInstance = useRef(null);
  const {confettiVisible} = useContext(NewFeatureContext);
  
  const [intervalId, setIntervalId] = useState(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const nextTickAnimation = useCallback(() => {
    if (refAnimationInstance.current) {
      refAnimationInstance.current(getAnimationSettings(60, 0));
      refAnimationInstance.current(getAnimationSettings(120, 1));
    }
  }, []);

  // Function to start animation.
  const startAnimation = useCallback(() => {
    if (!intervalId) {
      setIntervalId(setInterval(nextTickAnimation, 150));
    }
  }, [nextTickAnimation, intervalId]);

  // Function to pause animation.
  const pauseAnimation = useCallback(() => {
    clearInterval(intervalId);
    setIntervalId(null);
  }, [intervalId]);

  // Function to stop animation altogether.
  // const stopAnimation = useCallback(() => {
  //   clearInterval(intervalId);
  //   setIntervalId(null);
  //   refAnimationInstance.current && refAnimationInstance.current.reset();
  // }, [intervalId]);

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);

  useEffect(() => {
    if(confettiVisible === true){
      startAnimation();
    }
    else{
      pauseAnimation();
    }
  }, [confettiVisible])
  
  return (
    <>
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} useWorker={true} />
    </>
  );
  
}
