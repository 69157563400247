import * as React from 'react';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { PanelContext } from '../context/PanelContext';
import { ComplianceInitiative } from '../context/ComplianceInitiativeMappingEditor';
import { useContext } from 'react';
import { panelClassNames } from './PersonaPanel';
import { ComboBox, DefaultButton, DetailsList, DetailsListLayoutMode, Dropdown, IColumn, IComboBox, IComboBoxOption, IDropdownOption, IStackStyles, IStackTokens, ITooltipHostStyles, loadTheme, MessageBar, MessageBarType, NeutralColors, Persona, PersonaSize, PrimaryButton, Spinner, Stack, Text, TextField, TooltipHost,SelectionMode } from '@fluentui/react';
import { authProvider } from '../../authProvider';
import { _Styles } from '../Page.styles';
import { ChangeLog } from '../Interface/IAdminTable';
import { controlEditorTheme } from '../Themes/ControlEditorTheme';
import { ErrorAPIMessage1, InfoAPIMessage, SuccessMessage1, WarningAPIMessage ,ErrorAPIMessage} from '../ComplianceInitiativeEditor/MessageBar';
import { CIDescription, CITitle, CITitleAndTag, CreateCI, DefaultTagCI, DeleteCI, FetchLogs, FetchMGList, ShowDisplayName } from '../ComplianceInitiativeEditor/Constants';

const theme = loadTheme(controlEditorTheme);
export  interface ItagMapping
{
    key:string;
    ManagementGroupId:string;
    Tag:string;

}

export const AddComplianceInitiativePanel: React.FunctionComponent = () => {
  const RuntimeConfigurationInitial = window.__UI_CONFIGURATION_INITIAL__;
  const ScannerAPI = RuntimeConfigurationInitial.webAPIConf ? RuntimeConfigurationInitial.webAPIConf["AZURE"] : RuntimeConfigurationInitial.webAPI;
  const {complianceInitiativePanel,  toggleComplianceInitiativePanel,setComplianceInitiativeDescription,setDefaultControlTagFilter,setShowControlDisplayName,complianceInitiativeDescription,defaultControlTagFilter ,showControlDisplayName,controlTagList,panelMode,logs,imageURL,setLogs,visibleLogs} = useContext(PanelContext);
  const {complianceInitiativeList,complianceInitiativeMappingList,setLocalTag} = useContext(ComplianceInitiative);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [ComplianceInitiativeId,setComplianceInitiativeId] = React.useState<string>("");
  const [showSuccessMessage, setShowSuccessMessage] = React.useState<boolean>(false);
  const [showMessageError, setShowMessageError] = React.useState<boolean>(false);
  const [errorResponse, setErrorResponse] = React.useState<string>("");
  const [successResponse, setSuccessResponse] = React.useState<string>("");
  const [cIdState,setCIdState] = React.useState<boolean>(true);
  const [visible,setVisible] = React.useState<boolean>(true);
  const [label,setLabel] = React.useState<string>("");
  const [tagMappingList,setTagMappingList] = React.useState<ItagMapping[]>([]);
  
  React.useEffect(() => {
    setVisible(false);
    setTagMappingList([]);
    setLoaded(false);
    setShowMessageError(false);
    setShowSuccessMessage(false);
    setCIdState(true);
    setComplianceInitiativeId("");
    setComplianceInitiativeDescription("");
    setLocalTag("");
    setDefaultControlTagFilter("");
    setShowControlDisplayName(""); 
    setLogs([]);
  },[complianceInitiativePanel, setComplianceInitiativeDescription, setDefaultControlTagFilter, setLocalTag, setLogs, setShowControlDisplayName]);
  
  React.useEffect(()=>{
    if(ComplianceInitiativeId!=="")
    {
    setShowSuccessMessage(false);
    setShowMessageError(false);
    }
  },[ComplianceInitiativeId]);

  React.useEffect(()=>{
    
  },[visibleLogs]);

  const toolTipStyle: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

  const column :IColumn[] = [
    { key: 'column1', name: 'ManagementGroupId', fieldName: 'ManagementGroupId', minWidth: 100, maxWidth: 200, isResizable: true },
    { key: 'column2', name: 'Subscription Tag', fieldName: 'Tag', minWidth: 100, maxWidth: 200, isResizable: true },
  ];

  const onchangeComplianceInitiativeId = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => 
  {
    setComplianceInitiativeId(newValue);
  }

  const changeComplianceInitiativeId = (newValue: string) => 
  {
    setComplianceInitiativeId(newValue);
    for(var i=0;i<complianceInitiativeList.length;i++)
    {
      if(complianceInitiativeList[i].ComplianceInitiativeId.toLowerCase()
        === newValue.toLowerCase())
      {
        setCIdState(false);
        return "'" + newValue + "'" + "already exists.";
      }
    }
    setCIdState(true);
    return undefined;
  }
  
  const onChangecomplianceInitiativeDescription = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => 
  {
    setComplianceInitiativeDescription(newValue);
  }

  const cardStyle: IStackStyles = {
    root: {
      background: NeutralColors.gray30,
      boxShadow: theme.effects.elevation8,
      padding:20,
      borderLeft: '3px solid',
      borderColor: theme.palette.themePrimary
    },
  };

  const stackTokens: IStackTokens = { childrenGap: 20 };
   
  const onChangedefaultControlTagFilter = (event: React.FormEvent<IComboBox>, selectedOption?: IComboBoxOption): void =>
  {
    if(selectedOption)
    {
      setDefaultControlTagFilter(selectedOption.key.toString());
    }
    else
    {
      setDefaultControlTagFilter("");
    }
    
  }

  const onchangeShowControlDisplayName = (event: React.FormEvent<HTMLDivElement>, selectedOption?: IDropdownOption): void =>
  {
    setShowControlDisplayName(selectedOption.key.toString());
  }

  const changeComplianceInitiative = (event: React.FormEvent<HTMLDivElement>, selectedOption?: IDropdownOption): void =>
  {
    setVisible(false);
    setTagMappingList([]);
    setLoaded(false);
    setComplianceInitiativeId(selectedOption.key.toString());
    GetManagementGroupTagsMappingList(selectedOption.key.toString());
  }

  const ProcessTagsMappingList =(data:string) =>{
    var mappingList = JSON.parse(data);
    var tagMapping  = [];
    if(mappingList.length>0)
    {
      for(var i=0;i<mappingList.length;i++)
      {
        tagMapping.push({
        key: i,
        ManagementGroupId: mappingList[i].managementGroupId,
        Tag: mappingList[i].tags
        });

      }
      setTagMappingList(tagMapping);
    }
    
  } 

  const createLogs = async (comment:string) => {
    var changelog : ChangeLog[] = [];
    changelog = changelog.concat({
      user: "",
      date: "",
      comment: comment
    });

    const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
    fetch((ScannerAPI + '/complianceinitiatives/changelogs/update'), {
        headers: !authToken.accessToken ? {} : { 
            'Authorization': `Bearer ${authToken.accessToken}`,
            'u_Id': sessionStorage.getItem("u_Id"),
            'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(changelog)
    })
    .then(response => {
      if (response.ok) {
        console.log("Added logs..");
      }
      else {
          response.text().then(errorMsg => {
              setErrorResponse(errorMsg.slice(0, 500));
              setShowMessageError(true);
          });
      }
    });
  }

  const CreateComplianceInitiative = async () => {
    setLoaded(true);
    var body = {
        ComplianceInitiativeId : ComplianceInitiativeId,
        complianceInitiativeDescription: complianceInitiativeDescription,
        SubscriptionTags:defaultControlTagFilter,
        DefaultControlTagFilter: defaultControlTagFilter,
        ShowControlDisplayName: showControlDisplayName
    }
    const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
    fetch((ScannerAPI + '/complianceinitiatives/'), {
        headers: !authToken.accessToken ? {} : { 
            'Authorization': `Bearer ${authToken.accessToken}`,
            'u_Id': sessionStorage.getItem("u_Id"),
            'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(body)
    })
    .then(response => {
        if (response.ok) {
          setLoaded(false);
          setSuccessResponse("Successfully created the compliance initiative.");
          processAddCI();
          setShowSuccessMessage(true);
          createLogs(" [created new compliance initiative:"+ComplianceInitiativeId +"] ");
        }
        else {
          response.text().then(errorMsg => {
          setErrorResponse(errorMsg.slice(0, 500));
          setShowMessageError(true);
          setLoaded(false);
          });
        }
    });
  }

  const GetManagementGroupTagsMappingList = async (complianceInitiativeId:string) => {
      setLoaded(true);
      setLabel(FetchMGList);
      const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
      fetch((ScannerAPI + '/managementgroups/'+(complianceInitiativeId)+'/tagsmapping'), {
          headers: !authToken.accessToken ? {} : { 
              'Authorization': `Bearer ${authToken.accessToken}`,
              'u_Id': sessionStorage.getItem("u_Id"),
              'Content-Type': 'application/json'
          },
          method: 'GET',
      })
      .then(response => {
          if (response.ok) {
              response.json().then(data=>
                  ProcessTagsMappingList(JSON.stringify(data))
              )
              setVisible(true);
              setLoaded(false);
          }
          else {
              response.text().then(errorMsg => {
                  setLoaded(false);
                  setErrorResponse(errorMsg.slice(0, 500));
                  setShowMessageError(true);
              });
          }
      });
  }

  const DeleteComplianceInitiative = async () => {
      setLoaded(true);
      setLabel(DeleteCI)
      for(var i=0; i<complianceInitiativeList.length; i++)
      {
          if(complianceInitiativeList[i].ComplianceInitiativeId === ComplianceInitiativeId)
          {
              var body = {
                  ComplianceInitiativeId : complianceInitiativeList[i].ComplianceInitiativeId,
                  DefaultControlTagFilter: complianceInitiativeList[i].DefaultControlTagFilter
              }        
          }
          
      }
      
      const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
      fetch((ScannerAPI + '/complianceinitiatives/'), {
          headers: !authToken.accessToken ? {} : { 
              'Authorization': `Bearer ${authToken.accessToken}`,
              'u_Id': sessionStorage.getItem("u_Id"),
              'Content-Type': 'application/json'
          },
          method: 'DELETE',
          body: JSON.stringify(body)
      })
      .then(response => {
          if (response.ok) {
            setLoaded(false);
            setVisible(false);
            if(tagMappingList.length>0)
            {
              setSuccessResponse("Successfully deleted the compliance initiative and it's associated management group mapping.");
            }
            else
            {
              setSuccessResponse("Successfully deleted the compliance initiative.");
            }
            
            setShowSuccessMessage(true);
            createLogs(" [Deleted compliance initiative:"+ComplianceInitiativeId +"] ");
            setTagMappingList([]);
            setComplianceInitiativeId("");
            
          }
          else {
              response.text().then(errorMsg => {
                  setLoaded(false);
                  setVisible(false);
                  if(tagMappingList.length>0)
                  {
                    setErrorResponse("Error occured while deleting the compliance initiative or it's associated management group mapping.");
                  }
                  else
                  {
                    setErrorResponse("Error occured while deleting the compliance initiative.");
                  }
                  setShowMessageError(true);
                  setTagMappingList([]);
                  setComplianceInitiativeId("");
              });
          }
      });
  }

  const onClickSave = () => {
    CreateComplianceInitiative();
  }

  const onClickDelete = () => {
    DeleteComplianceInitiative();
  }
  
  const processAddCI = () =>
  {
    setComplianceInitiativeId("");
    setComplianceInitiativeDescription("");
    setLocalTag("");
    setDefaultControlTagFilter("");
    setShowControlDisplayName(""); 
  }

  const onClickClose = () => {
    toggleComplianceInitiativePanel();
    setComplianceInitiativeId("");
    setComplianceInitiativeDescription("");
    setLocalTag("");
    setDefaultControlTagFilter("");
    setShowControlDisplayName(""); 
    setShowMessageError(false);
    setShowSuccessMessage(false);   
  }

  const onRenderFooterContent = React.useCallback(
    () => (
      
      <Stack horizontal reversed horizontalAlign='space-between' >
        {panelMode==="complianceinitiative" &&
          <>
            <Stack horizontal tokens={{childrenGap:15}} style={{backgroundColor:"white"}}>
              <PrimaryButton onClick={onClickSave} disabled={complianceInitiativeDescription==="" || ComplianceInitiativeId==="" || showControlDisplayName==="" ||!cIdState} iconProps={{iconName:'BuildQueueNew'}}>
                Save
              </PrimaryButton>                   
              <DefaultButton 
                iconProps={{iconName:'ChromeClose'}}
                onClick={onClickClose}>
                  Close
              </DefaultButton>
            </Stack>

            <Stack>
              { loaded &&
                <Spinner label={CreateCI} ariaLive="assertive" labelPosition="right" />
              }
                        
            </Stack>
          </>
        }

        {panelMode==="DeleteComplianceInitiative" &&
          <>
            <Stack horizontal tokens={{childrenGap:15}} style={{backgroundColor:"white"}}>
                <PrimaryButton onClick={onClickDelete} disabled={ComplianceInitiativeId==="" || !visible} iconProps={{iconName:'Delete'}}>
                  Delete
                </PrimaryButton>                   
                
                <DefaultButton 
                  iconProps={{iconName:'ChromeClose'}}
                  onClick={onClickClose}>
                    Close
                </DefaultButton>
            </Stack>
            
            <Stack>
              { loaded &&
                <Spinner label={label} ariaLive="assertive" labelPosition="right" />
              }          
            </Stack>
          </>
        }

        {panelMode==="changelog" &&
            <Stack horizontal tokens={{childrenGap:15}} style={{backgroundColor:"white"}}>
                                  
                <DefaultButton 
                  iconProps={{iconName:'ChromeClose'}}
                  onClick={onClickClose}>
                    Close
                </DefaultButton>
            </Stack>
        }

      </Stack>

    ),
    // eslint-disable-next-line
    [ComplianceInitiativeId,complianceInitiativeDescription,defaultControlTagFilter,showControlDisplayName,loaded,panelMode,cIdState,label,showSuccessMessage,showMessageError]
  );

  
  return (
    <div>
      <Panel
        isOpen={complianceInitiativePanel}
        isLightDismiss
        type={PanelType.custom}
        headerClassName={panelClassNames.headerClass}
        customWidth={'40%'}
        hasCloseButton={false}
        isFooterAtBottom={true}
        onDismiss={toggleComplianceInitiativePanel}
        onRenderFooterContent={onRenderFooterContent}
        // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
        closeButtonAriaLabel="Close"
      >
        { panelMode ==="complianceinitiative" &&

          <Stack tokens={stackTokens}>
            <Text variant='xxLarge'>Add Compliance Initiative</Text>

            <TooltipHost
              content= {"Compliance initiative Id"}
              styles={toolTipStyle}
            >
              <TextField
                  label={CITitle}
                  value={ComplianceInitiativeId}
                  onChange={onchangeComplianceInitiativeId}
                  onGetErrorMessage={changeComplianceInitiativeId}
              />
            </TooltipHost>

            <TextField
              label={CIDescription}
              value={complianceInitiativeDescription}
              onChange={onChangecomplianceInitiativeDescription}
            />

              
            <TooltipHost
            content= {"Use this filter to add tag based on the control"}
            styles={toolTipStyle}
            >
              <ComboBox
                  label={DefaultTagCI}
                  selectedKey={defaultControlTagFilter}
                  options={controlTagList}
                  autoComplete="on"
                  allowFreeform={true}
                  onChange={onChangedefaultControlTagFilter}
                  useComboBoxAsMenuWidth={true}
                  placeholder = {"Select an Option"}
              />
            </TooltipHost>

            <TooltipHost
              content= {"Use this filter to show either Control Id or Control Display name in for representing control"}
              styles={toolTipStyle}
            >
              <Dropdown options={[{key:"True",text:"Display Name"},{key:"False",text:"Control Title"}]} selectedKey={showControlDisplayName} onChange={onchangeShowControlDisplayName} label={ShowDisplayName} placeholder = {"Select an Option"}></Dropdown>
            </TooltipHost>

            <div>
              {(showSuccessMessage) && <div><SuccessMessage1 successResponse={successResponse}/><div className={_Styles.rowGap3} /></div>}
              {(showMessageError) && <div><ErrorAPIMessage1 errorResponse={errorResponse}/><div className={_Styles.rowGap3} /></div>}
            </div>
          
          </Stack>
        }

        {panelMode === "changelog" &&
          <Stack>
              <Text variant='xxLarge'>Change Logs</Text>
              {
                logs.length === 0 && !visibleLogs &&
                  <>
                    <div className={_Styles.rowGap} />
                    <div className={_Styles.rowGap} />
                    <div className={_Styles.rowGap} />
                    <Spinner label={FetchLogs} ariaLive="assertive" labelPosition="right" />
                  </>
              }
              {
                logs.length === 0 && visibleLogs &&
                  <>
                    <div className={_Styles.rowGap} />
                    <div className={_Styles.rowGap} />
                    <div className={_Styles.rowGap} />
                    <ErrorAPIMessage errorResponse = {"No logs found."}/>
                  </>
              }
              {
                logs.length > 0 &&
                  logs.map((log, index) => {
                    return (
                      // key is required for the children to have an unique identifier.
                      <Stack key={index} styles={cardStyle} style={{marginTop:20}} tokens={{childrenGap:10}}>
                          <Persona
                            imageUrl={imageURL[log["user"]]}
                            size={PersonaSize.size48}
                            imageShouldFadeIn={true}
                            text={log["user"]}
                            secondaryText={"Edited on: " + log["date"]}
                          />
                          <Text><b style={{fontWeight:600}}>Comments:</b>{" "+log["comment"]}</Text>
                      </Stack>
                    );
                  })
              }
          </Stack>
        }

        {
          panelMode === "DeleteComplianceInitiative" &&
            <Stack>
              <Text variant='xxLarge'>Delete Compliance Initiative</Text>

              <div className={_Styles.rowGap} />
              <div className={_Styles.rowGap} />
              <div className={_Styles.rowGap} />
              <Dropdown
                placeholder="Select an option"
                ariaLabel='Please select the Compliance Initiative'
                label={CITitleAndTag}
                options={complianceInitiativeMappingList}
                selectedKey={ComplianceInitiativeId}
                onChange={changeComplianceInitiative}
              />
              <div>
                {(showSuccessMessage) && <div><div className={_Styles.rowGap} />
                    <div className={_Styles.rowGap} /><SuccessMessage1 successResponse={successResponse}/><div className={_Styles.rowGap3} /></div>}
                {(showMessageError) && <div><div className={_Styles.rowGap} />
                    <div className={_Styles.rowGap} /><ErrorAPIMessage1 errorResponse={errorResponse}/><div className={_Styles.rowGap3} /></div>}
              </div>

              {tagMappingList.length > 0 &&
                <Stack>
                  <div className={_Styles.rowGap} />
                  <div className={_Styles.rowGap} />
                  <div className={_Styles.rowGap} />
                  <Stack.Item>
                    <WarningAPIMessage/>
                  </Stack.Item>
                    
                  <Stack.Item>
                    <DetailsList
                      items={tagMappingList}
                      columns={column}
                      setKey="set"
                      layoutMode={DetailsListLayoutMode.justified}
                      ariaLabelForSelectionColumn="Toggle selection"
                      ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                      selectionMode={SelectionMode.none}
                    />
                  </Stack.Item>
                </Stack>
              }
              {
                tagMappingList.length===0 && visible &&
                  <Stack>
                    <div className={_Styles.rowGap} />
                    <div className={_Styles.rowGap} />
                    <InfoAPIMessage/>
                  </Stack>
              }   
            </Stack>
        }    
      </Panel>
    </div>
  );
};
