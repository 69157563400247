import * as React from 'react';
import { IconButton, ITeachingBubbleStyles, Text, TeachingBubble, NeutralColors } from '@fluentui/react';

export const ComplianceInitiativeInfo: React.FunctionComponent = () => {
    const RuntimeConfigurationExtended = window.__UI_CONFIGURATION_EXTENDED_AZURE__;
    const [teachingBubbleVisible, setTeachingBubbleVisible] = React.useState(false);
    const teachingStyles : Partial<ITeachingBubbleStyles> = {
        root:{
            transition:'0.5s'
        }
    }
  return (
    <div>
        {teachingBubbleVisible && (
            <TeachingBubble
                target="#complianceInitiative-Info"
                headline="Compliance Initiative"
                hasCondensedHeadline={true}
                styles={teachingStyles}
                hasCloseButton={true}
                closeButtonAriaLabel='Close Info'
                onDismiss={()=>{
                    setTeachingBubbleVisible(false)
                }}
            >
                <Text style={{color:NeutralColors.white}}>{RuntimeConfigurationExtended.complianceInitiativeConfiguration?.description.split("\n").map(p=><p>{p}</p>)}</Text>
            </TeachingBubble>
        )} 
        <IconButton 
            style={{outline:"none"}} 
            onClick={()=>{
                setTeachingBubbleVisible(true)
            }}
            id="complianceInitiative-Info"
            aria-label="Control Info"
            iconProps={{iconName:'Info'}}
        />
    </div>
    
  );
};
