import React, { useContext }  from 'react';
import { PrimaryButton, DefaultButton, IconButton } from '@fluentui/react/lib/Button';
import { MessageBar, MessageBarType, Stack, Spinner, SpinnerSize, DetailsList, SelectionMode, IColumn, Modal } from '@fluentui/react';
import { _Styles } from '../Page.styles';
import { FormContext } from '../context/FormContext';
import { getTheme } from '@fluentui/react';
import { authProvider } from '../../authProvider';
import { ITableIndividualRowState, IStatusObject } from '../Interface/ITable';
import { DialogContext } from '../context/DialogContext';
import { contentStyles, dragOptions, iconButtonStyles } from '../Styles/Dialog/Common';

const theme = getTheme();

export const ClearDialog: React.FunctionComponent = () => {
    const RuntimeConfigurationInitial = window.__UI_CONFIGURATION_INITIAL__;
    const ScannerAPI = RuntimeConfigurationInitial.webAPIConf ? RuntimeConfigurationInitial.webAPIConf["AZURE"] : RuntimeConfigurationInitial.webAPI;

    const {exceptionSub, exceptionControls, exceptionDataClear, selectedWorkItems, setExceptionDataClear,
      setExceptionDataAdd, setExceptionControls, setSelectedWorkItems, setForceRefresh} = useContext(FormContext);
    const {toggleClear, clearDialog} = useContext(DialogContext);
    const [submitted, setSubmitted] = React.useState<boolean>(false);
    const [spinner, setSpinner] = React.useState<boolean>(false);
    const [showMessage, setShowMessage] = React.useState<boolean>(false);
    const [showMessageError, setShowMessageError] = React.useState<boolean>(false);
    const [errorResponse, setErrorResponse] = React.useState<string>("");

    const columnsClear: IColumn[] = [
      {
          key: 'resource',
          name: 'Resource',
          fieldName: 'resource',
          minWidth: 20,
          isPadded: false,
          maxWidth: 200,
          isResizable: true
      },
      {
          key: 'rg',
          name: 'Resource Group',
          fieldName: 'rg',
          minWidth: 20,
          isPadded: false,
          maxWidth: 200,
          isResizable: true
      },
      {
          key: 'justification',
          name: 'Justification',
          fieldName: 'justification',
          minWidth: 20,
          isPadded: false,
          maxWidth: 200,
          isResizable: true
      }
  ];

    const SuccessExampleExceptionClear = () => (
      <MessageBar
        messageBarType={MessageBarType.success}
        isMultiline={true}
      >  
          <div>
              The exception you cleared has been successfully logged in the system.
          </div>
          <div>
              Future scan results will show the cleared controls.
          </div>
      </MessageBar>
    );

    const ErrorAPIMessage = () => (
      <MessageBar
        messageBarType={MessageBarType.error}
        isMultiline={true}
      >  
          <div>
            {errorResponse}
          </div>
      </MessageBar>
    );

    const onDismissExceptionClear = (): void => {
      toggleClear();
      if(submitted) {
        setExceptionDataClear([]);
        setExceptionDataAdd([]);
        setExceptionControls("", "");
        setForceRefresh(true);
      }
      setSpinner(false);
      setSubmitted(false);
      setShowMessage(false);
      setShowMessageError(false);
      setErrorResponse("");
    }

    function _submitExceptionClear(): void {
      setSpinner(true);
      setShowMessageError(false);
      const CallAPI = async () => {
        const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
        var body = [];
        var user = sessionStorage.getItem("userPrincipalName");
        for (var i=0; i < exceptionDataClear.length; i++) {
          body = body.concat({
            TenantId: exceptionDataClear[i].orgTenantId,
            SubscriptionId: exceptionDataClear[i].workItemId,
            ResourceId: exceptionDataClear[i].resourceID,
            controlName: exceptionDataClear[i].name,
            ControlShortId: exceptionDataClear[i].controlShortId,
            Scope: exceptionDataClear[i].resourceID,
            ExceptionStatus: "ByDesign",
            RequestedById: user,
            ApprovedBy: user,
            Justification: 'Clear exception'
          });
        }

        fetch(ScannerAPI + '/subscription/' + exceptionSub + '/exceptions/ClearException', {
          headers: !authToken.accessToken ? {} : {
            'Authorization': `Bearer ${authToken.accessToken}`,
            'Content-Type': 'application/json',
            'u_Id': sessionStorage.getItem("u_Id")
          },
          method: 'POST',
          body: JSON.stringify(body)
        }).then(response => {
            if (response.ok) {
                setSubmitted(true);
                setShowMessage(true);
                setSpinner(false);
                setSelectedWorkItems(selectedWorkItems.push(exceptionSub));
            }
            else {
              response.json().then(data => {
                setErrorResponse(data);
                setShowMessageError(true);
                setSpinner(false);
              });
            }
        });    
      }  
      CallAPI();
    }

    function _renderItemColumn(item: ITableIndividualRowState, index: Number, column: IColumn) {
      const fieldContent = item[column.fieldName as keyof ITableIndividualRowState];
      switch (column.key) {
        case 'status':
          var statusObject = fieldContent as IStatusObject;
          return (<span>{statusObject.message}</span>);
        default:
          return <span>{fieldContent.toString()}</span>;
      }
    }

   return ( 
    <>
      <Modal
        isOpen={clearDialog}
        containerClassName={contentStyles.container2}
        onDismiss={onDismissExceptionClear}
        dragOptions={dragOptions}
      >
        <div className={contentStyles.header}>
          <span>Clear Exception</span>
          <IconButton
            style={{outline:"none"}}
            styles={iconButtonStyles}
            iconProps={{iconName:'Cancel'}}
            ariaLabel="Close popup modal"
            onClick={onDismissExceptionClear}
          />
        </div>
        <div className={contentStyles.body}>
          <div>
            Control: <b style={{fontWeight: 600, color: theme.palette.neutralSecondary}}>{exceptionControls}</b> <br />
            Subscription: <b style={{fontWeight: 600, color: theme.palette.neutralSecondary}}>{exceptionSub}</b> <br />
            The following resources have been selected:
          </div>
          <DetailsList 
            items={exceptionDataClear}
            onRenderItemColumn={_renderItemColumn}
            columns={columnsClear}
            selectionMode={SelectionMode.none}
          />
          <div className={_Styles.rowGap3} />
          <div>
              {(showMessage) && <div><SuccessExampleExceptionClear/><div className={_Styles.rowGap3} /></div>}
          </div>
          <div>
              {(showMessageError) && <div><ErrorAPIMessage/><div className={_Styles.rowGap3} /></div>}
          </div>
          <Stack {...{horizontal: true, verticalAlign: 'center'}} horizontalAlign={"space-between"}
            reversed style={{padding: 0, margin: 0}} tokens={{childrenGap: 10}}>
            <Stack horizontal reversed tokens={{childrenGap: 10}}>
              <DefaultButton onClick={onDismissExceptionClear} text="Close" />  
              <PrimaryButton disabled={submitted} onClick={_submitExceptionClear} text="Confirm" />
            </Stack>
            {spinner && <Spinner size={SpinnerSize.medium} /> } 
          </Stack>
        </div>    
        <div className={_Styles.rowGap} />     
      </Modal>
    </>
  );
};
