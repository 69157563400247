import React from 'react';
import { IStackTokens, ScrollablePane, Stack } from '@fluentui/react';
import { _Styles } from './Page.styles';
import {TableSecureTemplate }  from './SecureTemplate/TableSecureTemplate';
import { AdminProps } from './Page.types';
import {SecureTemplateContextProvider} from './context/SecureTemplateContext';
import { SecureTemplatePanel } from './SecureTemplate/SecureTemplatePanel'
const horizontalStackTokens: IStackTokens = {
    childrenGap: 5,
};

// loadTheme(controlEditorTheme)
export class SecureTemplate extends React.Component<AdminProps, {}> {

    componentDidUpdate(){
        localStorage.setItem("theme","homepage");
        localStorage.setItem("scanner","azure");
    }

    render() {
        return ( 
            <SecureTemplateContextProvider>
                <ScrollablePane className={this.props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}>
                    <div className={_Styles.rootDiv}>
                        <div className={_Styles.rowGap} />  
                        <Stack style={{marginLeft: 15, marginTop: 15}}>
                            <Stack horizontal horizontalAlign="space-between">
                                <h4 id="initial-info">Secure Template Gallery</h4>
                            </Stack>
                            <Stack horizontal horizontalAlign="space-between">
                            </Stack>
                            <p>This gallery includes ARM templates for various resources which can be used as reference when creating your own templates. <br /> 
                                These 'compliant' templates have been created after passing applicable AzTS controls for corresponding resources.</p>
                        </Stack>
                        <Stack tokens={horizontalStackTokens} style={{margin: 15}}>
                            <TableSecureTemplate />
                        </Stack>
                        <SecureTemplatePanel/>
                        <div className={_Styles.rowGap} />
                        <div className={_Styles.rowGap} />
                    </div>
                </ScrollablePane>
            </SecureTemplateContextProvider>
        );
    }
}