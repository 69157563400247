import React, { Component, createContext } from 'react';

export const SecureTemplateContext = createContext();

export class SecureTemplateContextProvider extends Component {
  state = {
    templatePanel: false,
    selectedTemplate: {}
  }

  setSelectedTemplate = (value) => {
    this.setState({
      selectedTemplate: value
    })
  }

  toggleTemplatePanel = () => {
    this.setState({
      templatePanel: !this.state.templatePanel
    })
  }

  render() { 
    return (
      <SecureTemplateContext.Provider value={{
        ...this.state, 
        toggleTemplatePanel: this.toggleTemplatePanel,
        setSelectedTemplate: this.setSelectedTemplate
      }}
      >
        {this.props.children}
      </SecureTemplateContext.Provider>
    );
  }
}
 
export default SecureTemplateContextProvider;