import React, { Component, createContext } from 'react';

export const DialogContext = createContext();

export class DialogContextProvider extends Component {
  state = {
    addDialog: false,
    clearDialog: false,
    exportDialog: false,
    submitDialog: false,
    remediateDialog: false,
  }

  toggleAdd = () => {
    this.setState({
      addDialog : !this.state.addDialog
    })
  }

  toggleClear = () => {
    this.setState({
      clearDialog : !this.state.clearDialog
    })
  }

  toggleExport = () => {
    this.setState({
      exportDialog : !this.state.exportDialog
    })
  }

  toggleSubmit = () => {
    this.setState({
      submitDialog : !this.state.submitDialog
    })
  }

  toggleRemediate = () => {
    this.setState({
      remediateDialog: !this.state.remediateDialog
    })
  }
 
  render() { 
    return (
      <DialogContext.Provider value={{
        ...this.state, 
        toggleAdd: this.toggleAdd,
        toggleClear: this.toggleClear,
        toggleExport: this.toggleExport,
        toggleSubmit: this.toggleSubmit,
        toggleRemediate: this.toggleRemediate,
      }}
      >
        {this.props.children}
      </DialogContext.Provider>
    );
  }
}
 
export default DialogContextProvider;