import * as React from 'react';

import {
    ComboBox,
    DefaultButton,
    FontIcon,
    IComboBox,
    IComboBoxOption,
    IconButton,
    MessageBar,
    MessageBarType,
    Modal,
    Panel,
    PanelType,
    PrimaryButton,
    Spinner,
    Stack,
    Text,
    TextField,
    Toggle
} from '@fluentui/react';
import { contentStyles, dragOptions, iconButtonStyles } from '../Styles/Dialog/CommonControlEditor';
import { tagValidationErrorMessage, tagValidationRegex } from '../ControlEditor/constants';
import { useContext, useEffect, useState } from 'react';

import { AdminContext } from '../context/ControlEditor';
import { SharedColors } from '@fluentui/theme';
import { _WarningToast } from '../Page.styles';
import { authProvider } from '../../authProvider';
import { panelClassNames } from './PersonaPanel';

export const BulkEditPanel: React.FunctionComponent = () => {
    const RuntimeConfigurationInitial = window.__UI_CONFIGURATION_INITIAL__;
    const ScannerAPI = RuntimeConfigurationInitial.webAPIConf ? RuntimeConfigurationInitial.webAPIConf["AZURE"] : RuntimeConfigurationInitial.webAPI;

    const {modelBulkOpen, toggleModelBulkOpen, selectedRow, bulkEditRows} = useContext(AdminContext);
    const [editable, setEditable] = useState(false);
    const [schemaLoaded, setSchemaLoaded] = useState(false);
    const [schema, setSchema] = useState([]);
    const [ext, setExt] = useState([]);
    const [customTags, setCustomTags] = useState([]);
    const [customTagsOptions, setCustomTagsOptions] = useState<IComboBoxOption[]>([]);
    const [loaded, setLoaded] = useState(false);
    const [submit,setSubmit] = useState(0);
    const [version, setVersion] = useState<string>("");
    const [changed, setChanged] = useState(false);
    const [isDialogVisible, setIsDialogVisible] = React.useState(false);
    const [customTagsErrorMessage, setCustomTagsErrorMessage] = useState("");

    function onClickSave(): void {
        const BulkUpdate = async () => {
            setSubmit(1);
            let body = {}
            let featureControlMap = {};
            let customTagsRequest = [];
            let customTagsChecked = {};
            let customFieldData = [];

            customTags.forEach(element => {
                customTagsChecked[element] = 1;            
            });

            bulkEditRows.forEach(element => {
                try{
                    let check = featureControlMap[element.FeatureName];
                    check["Controls"] = [...check["Controls"],element.Id];
                    featureControlMap[element.FeatureName] = check;
                }
                catch (e){
                    let newFeature = {};
                    newFeature["Feature"] = element.FeatureName;
                    newFeature["Controls"] = [element.Id];
                    featureControlMap[element.FeatureName] = newFeature;
                }
            });

            let featureControlList = [];
            for (let key in featureControlMap) {
                featureControlList = [...featureControlList,featureControlMap[key]]
            }

            customTagsOptions.forEach(element => {
                var newCustomTag = {};
                newCustomTag["TagName"] = element.key;
                if(customTagsChecked[element.key] != undefined){
                    newCustomTag["Enabled"] = true;

                }
                else{
                    newCustomTag["Enabled"] = false;
                }         
                customTagsRequest.push(newCustomTag);
            });


            var index = 0
            schema.forEach(element => {
                if(element.bulkEdit){
                    var newField = {}
                    newField["Index"] = index;
                    newField["Value"] = ext[index];
                    newField["Label"] = element.label;
                    customFieldData.push(newField);
                }
                index+=1;
            })

            body["FeatureControlMap"] = featureControlList;
            body["CustomTagsList"] = customTagsRequest;
            body["Version"] = version;
            body["CustomFieldData"] = customFieldData
            
            const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
            fetch((ScannerAPI + '/controleditor/bulkupdate/'), {
                headers: !authToken.accessToken ? {} : {
                    'Authorization': `Bearer ${authToken.accessToken}`,
                    'u_Id': sessionStorage.getItem("u_Id"),
                    'Content-Type': 'application/json'},
                method: 'POST',
                body: JSON.stringify(body)
            }).then(response => {
                if (response.ok) {
                    setChanged(false);
                    setSubmit(2);
                }
                else {
                    setSubmit(3);
                }
            });
        }

        BulkUpdate();

    }

    const onRenderHeader = React.useCallback(
        () => (
            <Stack style={{marginBottom:10}}>
                <Stack style={{marginLeft:25,marginRight:25}} tokens={{childrenGap:-5}}>
                    <Stack horizontal tokens={{childrenGap:15}} horizontalAlign='space-between' >
                        <Stack horizontal tokens={{childrenGap:0}}>
                            <Text variant='xxLarge'>
                                Bulk Edit
                            </Text>
                            {
                                changed === true &&
                                <FontIcon iconName="AsteriskSolid" style={{color:SharedColors.redOrange10,fontSize:12}} />
                            }
                        </Stack>
                        <Toggle
                            label="Edit"
                            inlineLabel
                            defaultChecked={editable ? true : false}
                            onChange={
                                ()=>{
                                    setEditable(!editable);
                                }
                            }
                        />
                    </Stack>
                </Stack>
            </Stack>
        ),
        // eslint-disable-next-line
        [selectedRow, editable, changed],
    );

    const onRenderFooterContent = React.useCallback(
        () => (
          <Stack horizontal reversed horizontalAlign='space-between'>
            <Stack horizontal tokens={{childrenGap:15}}>
                <PrimaryButton onClick={onClickSave} disabled={!changed} iconProps={{iconName:'Upload'}}>
                    Update
                </PrimaryButton>
                <DefaultButton onClick={toggleModelBulkOpen}>Close</DefaultButton>
            </Stack>
            <Stack>
                    {!loaded && <Spinner label="Fetching the common Custom Tags" ariaLive="assertive" labelPosition="right" 
                    /> }
                    {submit === 1 &&
                        <Spinner 
                            label="Submitting Request" 
                            ariaLive="assertive" 
                            labelPosition="right"
                        />
                    }
                    {submit === 2 &&
                        <MessageBar
                            messageBarType={MessageBarType.success}
                            isMultiline={false}
                            dismissButtonAriaLabel="Close"
                        >
                            Bulk updates were successful.
                        </MessageBar>
                    }
                    {submit === 3 &&
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={false}
                            dismissButtonAriaLabel="Close"
                        >
                            Could not process the request. Please refresh the page.
                        </MessageBar>
                    }
            </Stack>
          </Stack>
        ),
        // eslint-disable-next-line
        [ext, changed, customTags, schema, customTagsOptions, bulkEditRows, version, loaded, submit],
    );

    
    const FetchSchema = async () => {
        const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
        let body = {
            ResourceType: selectedRow["FeatureName"],
            Control: selectedRow["ControlId"],
            Schema: true
        }
        fetch((ScannerAPI + '/controleditor/fetchschema/'), {
            headers: !authToken.accessToken ? {} : {
                'Authorization': `Bearer ${authToken.accessToken}`,
                'u_Id': sessionStorage.getItem("u_Id"),
                'Content-Type': 'application/json'},
            method: 'POST',
            body: JSON.stringify(body)
        }).then(response => {
            setSchemaLoaded(true);
            if (response.ok) {
                response.json().then(data => {
                    setSchema(data["fields"]);
                    setVersion(data["version"]);
                });
            }
        });
    }

    useEffect(() => {
        const FetchIntersectingCustomTags = async () => {
            setLoaded(false);
            const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
            let featureControlMap = {};
            bulkEditRows.forEach(element => {
                try{
                    let check = featureControlMap[element.FeatureName];
                    check["Controls"] = [...check["Controls"],element.Id];
                    featureControlMap[element.FeatureName] = check;
                }
                catch (e){
                    let newFeature = {};
                    newFeature["Feature"] = element.FeatureName;
                    newFeature["Controls"] = [element.Id];
                    featureControlMap[element.FeatureName] = newFeature;
                }
            });

            let body = [];
            for (let key in featureControlMap) {
                body = [...body,featureControlMap[key]]
                // Use `key` and `value`
            }

            fetch((ScannerAPI + '/controleditor/getCustomTagsIntersect/'), {
                headers: !authToken.accessToken ? {} : {
                    'Authorization': `Bearer ${authToken.accessToken}`,
                    'u_Id': sessionStorage.getItem("u_Id"),
                    'Content-Type': 'application/json'},
                method: 'POST',
                body: JSON.stringify(body)
            }).then(response => {
                var emptyArray = [];
                for (let index = 0; index < schema.length; index++) {
                    emptyArray = emptyArray.concat('');
                }

                if (response.ok) {
                    response.json().then(data => {
                        if(data != null && data.length > 0){
                            var customTagsOptions : IComboBoxOption[] = [];
                            var customTags = [];
                            for (let j = 0; j < data.length; j++) {
                                customTagsOptions = customTagsOptions.concat({
                                    key: data[j],
                                    text: data[j],
                                });
                                customTags = customTags.concat(data[j])
                            }
                            setCustomTagsOptions(customTagsOptions);
                            setCustomTags(customTags);
                            setLoaded(true);
                            setExt(emptyArray);
                        }
                        else{
                            setCustomTagsOptions([]);
                            setCustomTags([]);
                            setLoaded(true);
                        }
                    });
                    
                }
            });
        }

        if(modelBulkOpen === true){
            FetchSchema();
            FetchIntersectingCustomTags();
        }
    }, [modelBulkOpen])

    const onChangeText = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if(editable && newValue.length <= 1000) {
            var htmlElement = event.target as HTMLDivElement;
            var index = htmlElement.getAttribute('data-index');
            var currentFields = JSON.parse(JSON.stringify(ext));
            currentFields[index] = newValue;
            setExt(currentFields);
            setChanged(true);
        }
    };

    const onChangeCustomTags = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
        let selected = option?.selected;
        setCustomTagsErrorMessage("");
        if (!option && value) {
            if(!tagValidationRegex.test(value)) {
                setCustomTagsErrorMessage(tagValidationErrorMessage);
                return;
            }
        }
        if (!option && value) {
            selected = true;
            option = { key: value, text: value };
            setCustomTagsOptions(customTagsOptions => [...customTagsOptions, option!]);
        }
        if (option) {
            setCustomTags(prevSelectedKeys =>
                selected ? [...prevSelectedKeys, option!.text as string] : prevSelectedKeys.filter(k => k !== option!.text),
            );
        }
        setChanged(true);                  
    }

    const onDismiss = () => {
        if(changed === true) {
            setIsDialogVisible(true);
        }
        else {
            hideDialogAndPanel();
        }
    }

    const hideDialog = React.useCallback(() => setIsDialogVisible(false), []);

    const hideDialogAndPanel = React.useCallback(() => {
        toggleModelBulkOpen();
        setEditable(false);
        setChanged(false);
        setExt([]);
        setSubmit(0);
        setCustomTags([]);
        setCustomTagsOptions([]);
        setIsDialogVisible(false);
    },
    // eslint-disable-next-line
    []);

    return (
        <div>
            <Modal
                isOpen={isDialogVisible}
                containerClassName={contentStyles.container3}
                onDismiss={hideDialog}
                dragOptions={dragOptions}
            >
                <div className={contentStyles.header}>
                <span >Quit Editing?</span>
                <IconButton
                    style={{outline:"none"}}
                    styles={iconButtonStyles}
                    iconProps={{iconName:'Cancel'}}
                    ariaLabel="Close popup modal"
                    onClick={hideDialog}
                />
                </div>
                <div className={contentStyles.body}>
                    <div>
                        The changes you made will be lost. Are you sure you want to quit?
                    </div>
                    <Stack {...{horizontal: true, verticalAlign: 'center'}} horizontalAlign={"space-between"}
                        reversed style={{padding: 0, margin: 0}} tokens={{childrenGap: 10}}>
                        <Stack horizontal reversed tokens={{childrenGap: 10}} style={{marginTop: 20}}>
                            <DefaultButton onClick={hideDialog} text="No" />
                            <PrimaryButton onClick={hideDialogAndPanel} text="Yes" />
                        </Stack>
                    </Stack>
                </div>
            </Modal>

            <Panel
                isOpen={modelBulkOpen}
                isLightDismiss
                type={PanelType.custom}
                headerClassName={panelClassNames.headerClass}
                customWidth={'45%'}
                onRenderFooterContent={onRenderFooterContent}
                onRenderHeader={onRenderHeader}
                onDismiss={onDismiss}
                hasCloseButton={false}
                headerText="Control Setting"
                isFooterAtBottom={true}
                // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                closeButtonAriaLabel="Close"
            >
                <ComboBox
                    label={"Custom Tags"}
                    selectedKey={customTags}
                    options={customTagsOptions}
                    disabled={!editable}
                    multiSelect
                    autoComplete="on"
                    allowFreeform={true}
                    onChange={onChangeCustomTags}
                    style = {{marginBottom: 10}}
                    errorMessage={customTagsErrorMessage}
                    onMouseLeave={() => setCustomTagsErrorMessage("")}
                />
                <Stack tokens={{childrenGap:10}}>
                    {
                        schemaLoaded===true && schema.map((field, index) => {
                            if(field.advanced === true && field.bulkEdit) {
                                switch(field.type) {
                                    case "long-text":
                                        return (
                                            <TextField
                                                label={field.label + " (Max 1000 characters)"}
                                                value={ext[index]}
                                                disabled={!editable}
                                                multiline
                                                readOnly={!editable}
                                                errorMessage={ext[index] ? ext[index].length <= 1000 ? "" : "Character limit reached" : ""}
                                                rows={5}
                                                data-index={index}
                                                onChange={onChangeText}
                                            />
                                        );
                                    case "text":
                                        return (
                                            <TextField
                                                label={field.label + " (Max 1000 characters)"}
                                                value={ext[index]}
                                                disabled={!editable}
                                                data-index={index}
                                                readOnly={!editable}
                                                errorMessage={ext[index] ? ext[index].length <= 1000 ? "" : "Character limit reached" : ""}
                                                onChange={onChangeText}
                                            />
                                        );
                                }
                            }
                            return true;
                        })
                    }
                </Stack>
            </Panel>
        </div>
    );
};
