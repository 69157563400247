import React, { useEffect, useContext }  from 'react';
import { PrimaryButton, DefaultButton, IconButton } from '@fluentui/react/lib/Button';
import { MessageBar, MessageBarType, Stack, Spinner, SpinnerSize, Dropdown, DetailsList,
  SelectionMode, IColumn, Modal, ComboBox, IComboBoxOption, IComboBox, IDropdownOption, Text, Link } from '@fluentui/react';
import { _Styles } from '../Page.styles';
import { FormContext } from '../context/FormContext';
import { DialogContext } from '../context/DialogContext';
import { getTheme } from '@fluentui/react';
import { authProvider } from '../../authProvider';
import { ITableIndividualRowState, IStatusObject } from '../Interface/ITable';
import { contentStyles, dragOptions, iconButtonStyles } from '../Styles/Dialog/Common';
import { ICustomExceptionType, IExceptionsEligibleTagMetadata } from '../Interface/IUIConfigurationExtendedAzure';

const theme = getTheme();

export const AddDialog: React.FunctionComponent = () => {
    const RuntimeConfigurationInitial = window.__UI_CONFIGURATION_INITIAL__;
    const RuntimeConfigurationExtended = window.__UI_CONFIGURATION_EXTENDED_AZURE__;
    const ScannerAPI = RuntimeConfigurationInitial.webAPIConf ? RuntimeConfigurationInitial.webAPIConf["AZURE"] : RuntimeConfigurationInitial.webAPI;

    const {exceptionSub, exceptionControls, exceptionDataAdd, setExceptionDataClear,
      setExceptionDataAdd, setExceptionControls, setForceRefresh} = useContext(FormContext);
    const {toggleAdd, addDialog} = useContext(DialogContext);
    const [submitted, setSubmitted] = React.useState<boolean>(false);
    const [spinner, setSpinner] = React.useState<boolean>(false);
    const [showMessage, setShowMessage] = React.useState<boolean>(false);
    const [showMessageError, setShowMessageError] = React.useState<boolean>(false);
    const [justificationTextException, setJustificationTextException] = React.useState<string>('');
    const [, setJustificationKey] = React.useState<string | number | undefined>('');
    const [errorResponse, setErrorResponse] = React.useState<string>("");
    const [exceptionType, setExceptionType] = React.useState<string>("");
    const [customExceptionTypeMetadata, setCustomExceptionTypeMetadata] = React.useState<ICustomExceptionType>(null);
    const [exceptionTagMetadata, setExceptionTagMetadata] = React.useState<IExceptionsEligibleTagMetadata>(null);
    const [byDesignEligible, setByDesignEligible] = React.useState<boolean>(false);
    const [warning, setWarning] = React.useState<boolean>(true);
    const [instruct, setInstruct] = React.useState<boolean>(true);

    useEffect(() => {
      try {
        if(exceptionDataAdd[0]["allowedExceptionType"].includes("ByDesign")) {
          setByDesignEligible(true);
        }
        else {
          setByDesignEligible(false);
        }
      } catch (error) {
          setByDesignEligible(false);
      }
    }, [exceptionDataAdd]);

    const columns: IColumn[] = [
        {
            key: 'resource',
            name: 'Resource',
            fieldName: 'resource',
            minWidth: 20,
            isPadded: false,
            maxWidth: 200,
            isResizable: true
        }, {
            key: 'rg',
            name: 'Resource Group',
            fieldName: 'rg',
            minWidth: 20,
            isPadded: false,
            maxWidth: 200,
            isResizable: true
        }, {
            key: 'status',
            name: 'Status Reason',
            fieldName: 'status',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
        }
    ];

    const onChangeExceptionType = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
      setExceptionType(item.data);
      setInstruct(true);
      setCustomExceptionTypeMetadata(null);
      setExceptionTagMetadata(null);

      if(item.data !== "ByDesign") {
        const customExceptionTypeMetadata = RuntimeConfigurationExtended.exceptionFeatureConfiguration?.customExceptionTypes?.find(customExceptionType =>
          (customExceptionType.data === item.data));
        var exceptionTagMetadata : IExceptionsEligibleTagMetadata = customExceptionTypeMetadata?.eligibleTagsMetadata?.find(tag => (tag.name === (window.location.pathname.split('/')[1]).toLowerCase()));

        if(exceptionTagMetadata == null) {
          exceptionTagMetadata = { name: "", exceptionTarget: "", instructions: "", customNote: "", customNoteURL: "" };
        }

        if(exceptionTagMetadata?.instructions?.length === 0) {
          if(customExceptionTypeMetadata?.customInstructions?.length > 0) {
            exceptionTagMetadata.instructions = customExceptionTypeMetadata?.customInstructions;
          }
          else {
            exceptionTagMetadata.instructions = RuntimeConfigurationExtended.exceptionFeatureConfiguration?.defaultInstructions;
          }
        }

        exceptionTagMetadata.customNote = customExceptionTypeMetadata?.customNote;
        exceptionTagMetadata.customNoteURL = customExceptionTypeMetadata?.customNoteURL;

        setCustomExceptionTypeMetadata(customExceptionTypeMetadata);
        setExceptionTagMetadata(exceptionTagMetadata);
      }

      setShowMessage(false);
      setShowMessageError(false);
      setErrorResponse("");
    };

    const onChangeJustification = (event: React.FormEvent<IComboBox>, optionSel?: IComboBoxOption, index?: number, value?: string): void => {
      setShowMessageError(false);
      setErrorResponse("");

      if(optionSel) {
        setJustificationKey(optionSel.key);
        setJustificationTextException(optionSel.text); 
      }
      if(value) {
        setJustificationTextException(value);
      }
    }

    const SuccessExampleException = () => (
      <MessageBar
        messageBarType={MessageBarType.success}
        isMultiline={true}
      >  
          <div>
              The exception you submitted has been successfully logged in the system.
          </div>
          <div>
              Future scan results will show the exempted controls.
          </div>
      </MessageBar>
    );

    const ErrorAPIMessage = () => (
      <MessageBar
        messageBarType={MessageBarType.error}
        isMultiline={true}
      >  
          <div>
            {errorResponse}
          </div>
      </MessageBar>
    );

    const onDismissException = (): void => {
      toggleAdd();
      setExceptionType("");
      if(submitted) {
        setExceptionDataClear([]);
        setExceptionDataAdd([]);
        setExceptionControls("", "");
        setForceRefresh(true);
      }
      setSpinner(false);
      setSubmitted(false);
      setWarning(true);
      setInstruct(true);
      setCustomExceptionTypeMetadata(null);
      setExceptionTagMetadata(null);
      setShowMessage(false);
      setShowMessageError(false);
      setJustificationTextException("");
      setErrorResponse("");
    }

    function _submitException(): void {
      setSpinner(true);
      setShowMessageError(false);
      const CallAPI = async () => {
        const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
        var body = [];
        var user = localStorage.getItem("userPrincipalName");
        for (var i=0; i < exceptionDataAdd.length; i++) {
          body = body.concat({
            TenantId: exceptionDataAdd[i].orgTenantId,
            SubscriptionId: exceptionDataAdd[i].workItemId,
            ResourceId: exceptionDataAdd[i].resourceID,
            controlName: exceptionDataAdd[i].name,
            ControlShortId: exceptionDataAdd[i].controlShortId,
            Scope: exceptionDataAdd[i].resourceID,
            ExceptionStatus: exceptionType, //remove after this
            RequestedById: user,
            ApprovedBy: user,
            Justification: justificationTextException
          });
        }

        fetch(ScannerAPI + '/subscription/' + exceptionSub + '/exceptions/AddToException', {
          headers: !authToken.accessToken ? {} : {
            'Authorization': `Bearer ${authToken.accessToken}`,
            'Content-Type': 'application/json',
            'u_Id': sessionStorage.getItem("u_Id")
          },
          method: 'POST',
          body: JSON.stringify(body)
        }).then(response => {
            if (response.ok) {
                setSubmitted(true);
                setShowMessage(true);
                setSpinner(false);
            }
            else {
                response.json().then(data => {
                  setErrorResponse(data);
                  setShowMessageError(true);
                  setSpinner(false);
                });
            }
        });    
      }  
      if(!exceptionType) {
        setErrorResponse("Please select an exception type");
        setShowMessageError(true);
        setSpinner(false);
      }
      else if(!justificationTextException) {
        setErrorResponse("Please select or enter a valid justification");
        setShowMessageError(true);
        setSpinner(false);
      }
      else {
        CallAPI();
      }
    }

    function _renderItemColumn(item: ITableIndividualRowState, index:Number, column: IColumn) {
      const fieldContent = item[column.fieldName as keyof ITableIndividualRowState];
      switch (column.key) {
        case 'status':
          var statusObject = fieldContent as IStatusObject;
          return (<span>{statusObject.message}</span>);
        default:
          return <span>{fieldContent.toString()}</span>;
      }
    }

    // Submit a custom exception - For a given exception type (and, optionally, a tag), determine where and how to request an exception, and execute the action.
    function _submitCustomException() {
      /*
        Order of preference for choosing an exception target:
        1. Exception target for a specific exception-type/tag combination.
        2. Exception target specific for an exception type.
        3. Default exception target.
      */

      var target = RuntimeConfigurationExtended.exceptionFeatureConfiguration?.defaultExceptionTarget;
      const customTarget = exceptionTagMetadata?.exceptionTarget;

      if (customTarget?.length > 0) {
        target = customTarget;
      }
      else if (customExceptionTypeMetadata?.customExceptionTarget?.length > 0) {
        target = customExceptionTypeMetadata?.customExceptionTarget;
      }

      if (target?.length === 0) {
        setErrorResponse("Error requesting an exception! No valid link/mail configured. " +
          "Please check if all Exceptions related configurations have been set correctly.");
        setShowMessageError(true);
        return;
      }

      setShowMessageError(false);

      if (target.startsWith("mailto:")) {
        window.open(target);
      }
      else {
        const win = window.open(target, '_blank');
        win.focus();
      }
    }

    // Get the list of supported exception types.
    // "ByDesign" exceptions will be made available by default (subject to whether the individual controls themselves are eligible for
    // this type of exception).
    function _getExceptionTypes() {
      const exceptionTypes = [
        {
          key: 'ByDesign',
          text: 'Self Attestation',
          data: 'ByDesign',
          disabled: !byDesignEligible
        }
      ];

      // Get the list of custom exception types that are applicable for a given baseline/tag (available via `window.location.pathname`.)
      RuntimeConfigurationExtended.exceptionFeatureConfiguration?.customExceptionTypes?.map(function(customExceptionType) {
        // Enable this custom exception type, either when it is configured to support all tags, or when it is configured to support the tag in the URL.
        if((customExceptionType?.isEnabledForAllTags) ||
          (customExceptionType.eligibleTagsMetadata?.find(tag => tag.name === (window.location.pathname.split('/')[1]).toLowerCase()))) {
          if(customExceptionType.key?.length > 0 &&
            customExceptionType.text?.length > 0 &&
            customExceptionType.data?.length > 0) {
              exceptionTypes.push({
                key: customExceptionType?.key,
                text: customExceptionType?.text,
                data: customExceptionType?.data,
                disabled: false
              });
            }
        }

        return true;
      });

      return exceptionTypes;
    }

  return (
    <>
      <Modal
        isOpen={addDialog}
        containerClassName={contentStyles.container2}
        onDismiss={onDismissException}
        dragOptions={dragOptions}
      >
        <div className={contentStyles.header}>
          <span>Add/Renew Exception(s)</span>
          <IconButton
            style={{outline:"none"}}
            styles={iconButtonStyles}
            iconProps={{iconName:'Cancel'}}
            ariaLabel="Close popup modal"
            onClick={onDismissException}
          />
        </div>
        <div className={contentStyles.body}>
          <div>
            Control: <b style={{fontWeight: 600, color: theme.palette.neutralSecondary}}>{exceptionControls}</b> <br />
            Subscription: <b style={{fontWeight: 600, color: theme.palette.neutralSecondary}}>{exceptionSub}</b> <br />
            The following resources have been selected:
          </div>
          <DetailsList 
            items={exceptionDataAdd}
            columns={columns}
            onRenderItemColumn={_renderItemColumn}
            selectionMode={SelectionMode.none}
          />
          
          {warning && RuntimeConfigurationExtended.exceptionFeatureConfiguration?.warningText?.length > 0 &&
            <div>
              <div className={_Styles.rowGap3} />
              <MessageBar
                onDismiss={()=>{setWarning(false)}}
                dismissButtonAriaLabel="Close"
                messageBarType={MessageBarType.warning}
              >
                <b>Warning:</b>&nbsp;{ RuntimeConfigurationExtended.exceptionFeatureConfiguration?.warningText }
              </MessageBar>
            </div>
          }

          <div className={_Styles.rowGap} />
          <Dropdown
            required
            onChange={onChangeExceptionType}
            placeholder="Select type of exception"
            label="Select type of exception"
            options={_getExceptionTypes()}
          />  

          <div className={_Styles.rowGap} />
          {exceptionType === "ByDesign" && 
            <div>
              <ComboBox
                label="Justification"
                placeholder="Select a default justification from dropdown or type a custom justification"
                allowFreeform={true}
                text={justificationTextException}
                autoComplete={'on'}
                onChange={onChangeJustification}
                useComboBoxAsMenuWidth={true}
                required
                options={[
                  { key: 'code', text: 'Risk addressed elsewhere' },
                  { key: 'na', text: 'Risk not applicable' }
                ]}
              />
              <div className={_Styles.rowGap} />
            </div>
          }

          { instruct && customExceptionTypeMetadata && exceptionTagMetadata?.instructions?.length > 0 &&
            <div>
              <MessageBar
                onDismiss={()=>{setInstruct(false)}}
                dismissButtonAriaLabel="Close"
                messageBarType={MessageBarType.info}
              >
                <b>Please follow the below steps to request an exception:</b>
                <ol style={{marginLeft: -25, marginTop: 5, listStyleType: "none"}}>
                { exceptionTagMetadata?.instructions?.split("\n").map((step, index) => {
                    return <li key={index}>{step}</li>;
                  })
                }
                {
                  exceptionTagMetadata?.customNote &&
                  <li>
                    <Text style = {{fontWeight: 600}} variant="smallPlus">Note: </Text>
                    <Text variant="smallPlus">{exceptionTagMetadata.customNote}</Text>
                    <Link href={exceptionTagMetadata?.customNoteURL}>Learn More</Link>
                  </li>
                }
              </ol>
              </MessageBar>
              <div className={_Styles.rowGap} />
            </div>
          }
          <div>
              {(showMessage) && <div><SuccessExampleException/><div className={_Styles.rowGap3} /></div>}
          </div>
          <div>
              {(showMessageError) && <div><ErrorAPIMessage/><div className={_Styles.rowGap3} /></div>}
          </div>
          
          <Stack {...{horizontal: true, verticalAlign: 'center'}} horizontalAlign={"space-between"}
            reversed style={{padding: 0, margin: 0}} tokens={{childrenGap: 10}}>
            <Stack style={{marginTop: 10}} horizontal reversed tokens={{childrenGap: 10}}>
              <DefaultButton onClick={onDismissException} text="Close" />  
              {exceptionType === "ByDesign" && 
                <PrimaryButton 
                  disabled={submitted} 
                  onClick={_submitException} 
                  text="Confirm"
                />
              }
              { customExceptionTypeMetadata &&
                <PrimaryButton 
                  onClick={() => {
                    _submitCustomException();
                }}
                text={`Redirect`}
                />
              }
            </Stack>
            {spinner && <Spinner size={SpinnerSize.medium} /> } 
          </Stack>
        </div>    
        <div className={_Styles.rowGap} />     
      </Modal>
    </>
  );
};
