import React, { useContext, useEffect, useState } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Coachmark, ContextualMenu, DefaultButton, DetailsList, DirectionalHint, IColumn, IconButton, IContextualMenuProps, MessageBar, MessageBarType, Modal, PrimaryButton, SelectionMode, Spinner, SpinnerSize, TextField } from '@fluentui/react';
import { getTheme } from '@fluentui/react';
import { _Styles, _SuccessToast } from '../Page.styles';
import { AdminContext } from '../context/ControlEditor';
import { toast } from 'react-toastify';
import { authProvider } from '../../authProvider';
import { contentStyles, dragOptions, iconButtonStyles } from '../Styles/Dialog/CommonControlEditor';
import { useBoolean } from '@fluentui/react-hooks';
import { SharedColors } from '@fluentui/theme';

const theme = getTheme();

const columns: IColumn[] = [
    {
        key: 'FeatureName',
        name: 'Feature Name',
        fieldName: 'FeatureName',
        minWidth: 20,
        isPadded: false,
        maxWidth: 200,
        isResizable: true
    }, {
        key: 'ControlID',
        name: 'ControlID',
        fieldName: 'ControlID',
        minWidth: 20,
        isPadded: false,
        maxWidth: 200,
        isResizable: true
    }
];

export const ExportButton: React.FunctionComponent<{}> = () => {
    const RuntimeConfigurationInitial = window.__UI_CONFIGURATION_INITIAL__;
    const RuntimeConfigurationExtended = window.__UI_CONFIGURATION_EXTENDED_AZURE__;
    const ScannerAPI = RuntimeConfigurationInitial.webAPIConf ? RuntimeConfigurationInitial.webAPIConf["AZURE"] : RuntimeConfigurationInitial.webAPI;

    const {changedData,controlPanel,bulkEditRows,toggleModelOpenUpdate,toggleModelBulkOpen,toggleModelOpenAddField,toggleMDCModelOpenUpdate,Enabled,setIsEnabled,toggleModelOpenToEnable} = useContext(AdminContext);
    const [modelOpen, {toggle:toggleModelOpen}] = useBoolean(false);
    const [displayTable, setDisplayTable] = useState([]);

    const [spinner,] = React.useState<boolean>(false);
    const [showMessage, setShowMessage] = React.useState<boolean>(false);
    const [submitted, setSubmitted] = React.useState<boolean>(false);
    const [comments, setComments] = useState('');
    const [privileged, setPrivileged] = useState<boolean>(false);
    const [editorStatus, setEditorStatus] = useState<boolean>(false);
    const {resource, control, setLoadingTable, controlFiltered} = useContext(AdminContext);

    const SuccessExample = () => (
        <MessageBar
          messageBarType={MessageBarType.success}
          isMultiline={true}
        >  
            <div>
                The control metadata has been successfully updated in the system.
            </div>
        </MessageBar>
      );

    const menuProps: IContextualMenuProps = {
    // For example: disable dismiss if shift key is held down while dismissing
        items: [
            {
                key: 'update',
                text:  "Update",
                secondaryText : Object.keys(changedData).length > 0 ? Object.keys(changedData).length > 1 ? Object.keys(changedData).length.toString() + " features": Object.keys(changedData).length.toString() + " feature": "",
                iconProps: { iconName: 'Upload', style: { color: SharedColors.orange10 } },
                disabled: JSON.stringify(changedData) === JSON.stringify({}) ? true : false,
                hidden: !editorStatus,
                onClick: toggleModelOpenUpdate,
            },
            {
                key: 'addMDCControl',
                text:  "Add new Control",
                iconProps: { iconName: 'Add', style: { color: SharedColors.blueMagenta10 } },
                onClick: toggleMDCModelOpenUpdate,
                hidden: !privileged || RuntimeConfigurationExtended.controlEditorFeatureConfiguration.isAddNewControlEnabled === false
            },
            {
                key: 'exportExcel',
                text:  "Export to Excel",
                iconProps: { iconName: 'ExcelDocument', style: { color: "#217346"} },
                onClick: submitData,
            },
            {
                key: 'addField',
                text:  "Add/Edit Additional Fields",
                iconProps: { iconName: 'PageEdit', style: { color: SharedColors.magentaPink10 } },
                onClick: toggleModelOpenAddField,
                hidden: !editorStatus || RuntimeConfigurationExtended.controlEditorFeatureConfiguration.isEditCustomFieldsEnabled === false
            },
            {
                key: 'bulkEdit',
                text:  "Bulk Edit",
                iconProps: { iconName: 'Stack', style: { color: SharedColors.red10 } },
                hidden: RuntimeConfigurationExtended.controlEditorFeatureConfiguration.isBulkEditEnabled === false || bulkEditRows.length < 2 ? true : false,
                secondaryText : bulkEditRows.length < 2 ? "" : bulkEditRows.length.toString() + " controls",
                onClick: toggleModelBulkOpen,
            },
            {
                key: 'Enable',
                text:  "Enable",
                iconProps: { iconName: 'GlobalNavButtonActive', style: { color: SharedColors.red10 } },
                hidden: RuntimeConfigurationExtended.controlEditorFeatureConfiguration.isBulkEditEnabled === false || bulkEditRows.length < 2 || !privileged ? true : false,
                secondaryText : bulkEditRows.length < 2 ? "" : bulkEditRows.length.toString() + " controls",
                onClick: ()=>{
                    setIsEnabled(true);
                    toggleModelOpenToEnable();

                },
            },
            {
                key: 'Disable',
                text:  "Disable",
                iconProps: { iconName: 'DisableUpdates', style: { color: SharedColors.red10 } },
                hidden: RuntimeConfigurationExtended.controlEditorFeatureConfiguration.isBulkEditEnabled === false || bulkEditRows.length < 2|| !privileged ? true : false,
                secondaryText : bulkEditRows.length < 2 ? "" : bulkEditRows.length.toString() + " controls",
                onClick: ()=>{
                    setIsEnabled(false);
                    toggleModelOpenToEnable(); 
                },
            }
        ],
        directionalHintFixed: false,
    };


    
    function _pad2(n : number): string{ 
        return n < 10 ? '0' + n.toString() : n.toString()
    }
      
    function _getDate() : string {
        var date = new Date();
        return(date.getFullYear().toString() + 
            _pad2(date.getMonth() + 1) +
            _pad2(date.getDate()) +
            _pad2(date.getHours()) +
            _pad2(date.getMinutes()) +
            _pad2(date.getSeconds()));
    }

    // Function to check if the user has "Privileged Editor" access
    function _checkPrivilegedAccess() {
        const CallAPI = async () => {
            const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
            fetch((ScannerAPI + '/controleditor/privilegedcheckstatus'), {
                headers: !authToken.accessToken ? {} : {
                    'Authorization': `Bearer ${authToken.accessToken}`,
                    'u_Id': sessionStorage.getItem("u_Id"),
                    'Content-Type': 'application/json'
                },
                method: 'POST',
            }).then(response => {
                if (response.ok) {
                    setPrivileged(true);
                }
                else{
                    setPrivileged(false);
                }
            })
        }
        CallAPI();
    }

    // Function to check if the user has "Editor" access
    function _checkEditorAccess() {
        const CallAPI = async () => {
            const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
            fetch((ScannerAPI + '/controleditor/editorcheckstatus'), {
                headers: !authToken.accessToken ? {} : {
                    'Authorization': `Bearer ${authToken.accessToken}`,
                    'u_Id': sessionStorage.getItem("u_Id"),
                    'Content-Type': 'application/json'
                },
                method: 'POST',
            }).then(response => {
                if (response.ok) {
                    setEditorStatus(true);
                }
                else{
                    setEditorStatus(false);
                }
            })
        }
        CallAPI();
    }

    // Submits request for downloading excel
    function submitData(): void {
        const CallAPI = async () => {
            toast("Your download will commence shortly", {
                autoClose: 3000,
                progressClassName: _SuccessToast.toastProgress,
                className: _SuccessToast.toastClass
            });   
            setLoadingTable(true);
            const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
            let body = {
                ResourceType: resource,
                Ids: controlFiltered,
            }
            fetch((ScannerAPI + '/controleditor/exportToExcel/'), {
                headers: !authToken.accessToken ? {} : {
                    'Authorization': `Bearer ${authToken.accessToken}`,
                    'u_Id': sessionStorage.getItem("u_Id"),
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(body)
            }).then(response => {
                if (response.ok) {
                    response.blob().then(blob =>URL.createObjectURL(blob))
                    .then(uril => {
                        var downloadLinkExcel = document.createElement("a");
                        downloadLinkExcel.href = uril;
                        var timestamp = _getDate();
                        downloadLinkExcel.download = 'ControlData-' + timestamp + ".xlsx";
                        document.body.appendChild(downloadLinkExcel);
                        downloadLinkExcel.click();
                        document.body.removeChild(downloadLinkExcel);
                        setLoadingTable(false);
                    });                
                }
                else {
                    response.text().then(errorMsg => {
                        toast(errorMsg.slice(0, 500));
                    });
                    setLoadingTable(false);
                }
            });  
        }
        CallAPI();
    }

    useEffect(() => {
        var pool = [];
        var increment = 0;
        for (var feature in changedData) {
            for (var control in changedData[feature]) {
                pool = pool.concat({
                    "key": increment,
                    "FeatureName": feature,
                    "ControlID": control
                });
            }
        }
        setDisplayTable(pool);
    },
    // eslint-disable-next-line
    [modelOpen]);

    
    useEffect(() => {
        _checkPrivilegedAccess();
        _checkEditorAccess();
    },
    // eslint-disable-next-line
    []);
    
    const onChangeText = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if(newValue.length <= 1000) {
            setComments(newValue);
        }
    };

    const onDismissModal = (): void => {
        toggleModelOpen();
        if(submitted) {
            setShowMessage(false);
        }
        setSubmitted(false);        
    }
    
    function _getMenu(props: IContextualMenuProps): JSX.Element {
        // Customize contextual menu with menuAs
        return <ContextualMenu {...props} />;
    }

    return (
        <div>
            <DefaultButton
                iconProps={{iconName: 'LightningBolt'}} 
                menuProps={menuProps}
                id="control-editor-action"
                menuAs={_getMenu}
                text={"Action"} 
                persistMenu={true}
                style={{outline:"none",boxShadow:theme.effects.elevation4}} 
            /> 
            {Object.entries(changedData).length > 0 && !controlPanel &&
                <Coachmark
                    target="#control-editor-action"
                    positioningContainerProps={{directionalHint:DirectionalHint.rightCenter}}
                    beaconColorOne={SharedColors.orange20}
                    beaconColorTwo={SharedColors.redOrange20}
                    color={SharedColors.orange10}
                    ariaAlertText="Coachmark for updating control"
                    ariaDescribedBy="Coachmark for updating control"
                    ariaLabelledBy="Coachmark for updating control"
                    ariaDescribedByText="Press enter or alt + C to open the coachmark notification"
                    ariaLabelledByText="Coachmark notification"
                    styles={{
                        root:{
                            zIndex:"-100 !important"
                        }
                    }}
                >    
            </Coachmark>

            }
            
            <Modal
                isOpen={modelOpen}
                containerClassName={contentStyles.container2}
                onDismiss={onDismissModal}
                dragOptions={dragOptions}
            >
                <div className={contentStyles.header}>
                    <span>Update Control Data</span>
                    <IconButton
                        style={{outline:"none"}}
                        styles={iconButtonStyles}
                        iconProps={{iconName:'Cancel'}}
                        ariaLabel="Close popup modal"
                        onClick={onDismissModal}
                    />
                </div>
                <div className={contentStyles.body}>
                    <div>
                        The following Controls have been selected for update:
                    </div>
                    <DetailsList
                        items={displayTable}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                    />
                    <div className={_Styles.rowGap} />
                    <TextField
                        label={"Comments"}
                        multiline
                        value={comments}
                        errorMessage={comments ? comments.length <= 1000 ? "" : "Character limit reached" : ""}
                        rows={3}
                        onChange={onChangeText}
                    />
                    <div className={_Styles.rowGap3} />
                    <div>
                        {(showMessage) && <div><SuccessExample/><div className={_Styles.rowGap3} /></div>}
                    </div>
                    <Stack {...{horizontal: true, verticalAlign: 'center'}} horizontalAlign={"space-between"}
                        reversed style={{padding: 0, margin: 0}} tokens={{childrenGap: 10}}>
                        <Stack horizontal reversed tokens={{childrenGap: 10}}>
                            <DefaultButton onClick={onDismissModal} text="Close" />
                            <PrimaryButton onClick={submitData} text="Confirm" disabled={submitted} />
                        </Stack>
                        {spinner && <Spinner size={SpinnerSize.medium} />}
                    </Stack>
                </div>
                <div className={_Styles.rowGap} />     
            </Modal>
        </div>
    );
}