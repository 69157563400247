import { getTheme, IStackTokens } from "@fluentui/react";
import { NeutralColors } from "@fluentui/theme";
import { CSSProperties } from "react";

const theme = getTheme();

export const tableStyles: CSSProperties = {
    position: 'relative',
    width: '100%',
    background: NeutralColors.white,
    boxShadow: theme.effects.elevation16,
}

export const tableStylesSecure: CSSProperties = {
    position: 'relative',
    width: '100%',
    padding: 24,
    background: NeutralColors.white,
    boxShadow: theme.effects.elevation16,
}

export const horizontalStackTokens: IStackTokens = {
    childrenGap: 25,
};
