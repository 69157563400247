import React from 'react';
import { Header } from './components/Header';
import Layout from './components/Layout';
import { Router, Route, Switch } from 'react-router';
import NavMenu from './components/NavMenu';
import { PersonaPanel } from './components/Panels/PersonaPanel';
import { HelpPanel } from './components/Panels/HelpPanel';
import { HomePage } from './components/HomePage';
import { AzureAD, AuthenticationState, IAzureADFunctionProps } from 'react-aad-msal';
import { authProvider } from './authProvider';
import packageJson from '../package.json';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { PanelContextProvider } from './components/context/PanelContext';
import { ControlEditor } from './components/ControlEditor';
import { FontSizes } from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';
import { ToastContainer, toast } from 'react-toastify';
import history  from './history';
import { IUIConfigurationInitial } from './components/Interface/IUIConfigurationInitial';
import { IUIConfigurationExtendedAzure } from './components/Interface/IUIConfigurationExtendedAzure';
import { ConfigHelper } from './components/Helper/ConfigHelper';
import { PageLoadProgressIndicator } from './components/Helper/PageLoadProgressIndicator';
import { _ErrorToast } from './components/Page.styles';
import {SecureTemplate} from './components/SecureTemplate'
import 'react-toastify/dist/ReactToastify.css';
import { DidYouKnowPanel } from './components/Panels/DidYouKnowPanel';
import {NewFeatureModal} from './components/FeatureAnnouncement/NewFeatureModal'
import {RoutePaths} from './Route'
import NewFeatureContextProvider from './components/context/NewFeatureContext';
import { ControlDetailsPanel } from './components/Panels/ControlDetailPanel';
import { IUIConfigurationExtendedDevOps } from './components/Interface/IUIConfigurationExtendedDevOps';
import { DevOpsScanner } from './components/DevOpsScanner';
import { ComplianceInitiativeEditor } from './components/ComplianceInitiativeEditor';
import withAppInsights from './AppInsights';

const INITIAL_UI_CONFIGURATION_FILE = "runtime-configuration-initial.js";

// This is for tracking the state of the fetch (for the extended configurations) from the API's `/getconfig` endpoint.
enum RuntimeUIConfigurationFetchState {
    NotStarted = 0, // Indicates that the request is yet to be issued.
    InProgress = 1, // Indicates that the request is in progress.
    Complete = 2, // Indicates that the request is successfully complete.
    Failed = 3 // Indicates that the request failed.
}

initializeIcons();

// version from response - first param, local version second param
const semverGreaterThan = (versionA : string, versionB : string) => {
  const versionsA = versionA.split(/\./g);
  const versionsB = versionB.split(/\./g);

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());
    const b = Number(versionsB.shift());

    if (a === b) continue;

    return a > b || isNaN(b);
  }
  return false;
};

interface OwnState {
    isNavCollapsed: boolean;
    loading: boolean;
    isLatestVersion: boolean;
    uiInitialConfigFetchState: RuntimeUIConfigurationFetchState;
    uiExtendedConfigFetchState: RuntimeUIConfigurationFetchState;
}

declare global {
    interface Window {
        __UI_CONFIGURATION_INITIAL__: IUIConfigurationInitial;
        __UI_CONFIGURATION_EXTENDED_AZURE__: IUIConfigurationExtendedAzure;
        __UI_CONFIGURATION_EXTENDED_DEVOPS__: IUIConfigurationExtendedDevOps;
    }
}

export class App extends React.Component<{}, OwnState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            isNavCollapsed: false,
            loading: true,
            isLatestVersion: true,
            uiInitialConfigFetchState: RuntimeUIConfigurationFetchState.NotStarted,
            uiExtendedConfigFetchState: RuntimeUIConfigurationFetchState.NotStarted,
        }
    } 

    componentDidMount() {
        fetch(`/meta.json?${new Date().getTime()}`, { 
          cache: 'no-cache',
          headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           } })
            .then((response) => response.json())
            .then((meta) => {
                const latestVersion = meta.version;
                const currentVersion = packageJson.version;
                console.log(currentVersion, latestVersion);
        
                const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
                if (shouldForceRefresh) {
                    console.log(`We have a new version - ${latestVersion}. Should force refresh`);
                    this.setState({ loading: false, isLatestVersion: false });
                } else {
                    console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
                    this.setState({ loading: false, isLatestVersion: true });
                }
            });

        if(this.state.uiInitialConfigFetchState === RuntimeUIConfigurationFetchState.NotStarted) {
            this.setState({uiInitialConfigFetchState: RuntimeUIConfigurationFetchState.InProgress});

            fetch(`/${INITIAL_UI_CONFIGURATION_FILE}`, {
                cache: 'no-cache',
                headers : {
                    'Accept': 'text/javascript',
                    'Content-Type': 'text/javascript'
                }
            })
            .then((response) => {
                if(response.ok) {
                    this.setState({uiInitialConfigFetchState: RuntimeUIConfigurationFetchState.Complete});
                }
                else {
                    toast(<div>Error launching AzTS UI.<br /><b>{INITIAL_UI_CONFIGURATION_FILE}</b> file is not present.</div>);
                    this.setState({uiInitialConfigFetchState: RuntimeUIConfigurationFetchState.Failed});
                }
            });
        }
    }

    private async _refreshCacheAndReload() {
        console.log('Clearing cache and hard reloading...');
        if (caches) {
            const names = await caches.keys();
            await Promise.all(names.map(name => caches.delete(name)));
        }
    }

    // A callback that is fired when the extended configurations for the UI are fetched.
    _configFetchCallback = (config: IUIConfigurationExtendedAzure | IUIConfigurationExtendedDevOps, error: string, scanner: string) => {
        toast.dismiss();
        if(config) {
            var configName = "__UI_CONFIGURATION_EXTENDED_" + scanner + "__";
            window[configName] = config;
            if(scanner === "AZURE"){
                this.setState({uiExtendedConfigFetchState: RuntimeUIConfigurationFetchState.Complete});
            }            
        }
        else {
            toast(<div>Error fetching configurations for {scanner}. <br /><b>Reason: </b> {error.toString()} </div>);
            if(this.state.uiExtendedConfigFetchState === RuntimeUIConfigurationFetchState.NotStarted || 
                this.state.uiExtendedConfigFetchState === RuntimeUIConfigurationFetchState.Failed) {
                this.setState({uiExtendedConfigFetchState: RuntimeUIConfigurationFetchState.Failed});            
            }

        }
    }

    // Passed as a callback to AzureAD during authentication. This callback is fired when the login is complete.
    _accountInfoCallback = () => {
        if(this.state.uiExtendedConfigFetchState === RuntimeUIConfigurationFetchState.NotStarted) {
            this.setState({uiExtendedConfigFetchState: RuntimeUIConfigurationFetchState.InProgress});
        }
    }

    render() {
        const {loading, isLatestVersion} = this.state;

        if (loading) {
            return null;
        }
        if (!loading && !isLatestVersion) {
            this._refreshCacheAndReload();
            return (<p>Ctrl + Shift + R</p>);
        }

        // Proceed with authentication only if the required initial configurations for the UI are present.
        if(this.state.uiInitialConfigFetchState === RuntimeUIConfigurationFetchState.Failed) {
            return (
                <ToastContainer style={{fontSize: FontSizes.size14, padding: '0', color: NeutralColors.black}} progressClassName = {_ErrorToast.toastProgress}
                    toastClassName = {_ErrorToast.toastClass} bodyClassName = {_ErrorToast.toastBody} closeButton={false} position="bottom-right"
                    autoClose={false} closeOnClick={false} hideProgressBar={false} newestOnTop={false} rtl={false} pauseOnFocusLoss pauseOnHover />
            );
        }

        return (
            <div style={{background:NeutralColors.gray10}}>
                <AzureAD provider={authProvider} forceLogin={true} accountInfoCallback={this._accountInfoCallback}>
                {
                    ({login, authenticationState, error, accountInfo}:IAzureADFunctionProps) => {
                        switch (authenticationState) {
                            case AuthenticationState.Authenticated:
                                <p>Authenticated!</p>;
                                // Issue a request to `/getconfig`, only if no previous requests have been made, or, are in progress.
                                if(this.state.uiExtendedConfigFetchState === RuntimeUIConfigurationFetchState.NotStarted) {
                                    return (<ConfigHelper configFetchCallback={this._configFetchCallback} />);
                                }

                                if(this.state.uiExtendedConfigFetchState === RuntimeUIConfigurationFetchState.InProgress) {
                                    return (<PageLoadProgressIndicator />);
                                }

                                if(this.state.uiExtendedConfigFetchState === RuntimeUIConfigurationFetchState.Failed) {
                                    return (
                                        <ToastContainer style={{fontSize: FontSizes.size14, padding: '0', color: NeutralColors.black}}
                                            position="bottom-right" progressClassName = {_ErrorToast.toastProgress} toastClassName = {_ErrorToast.toastClass}
                                            bodyClassName = {_ErrorToast.toastBody} closeButton={false} autoClose={false} closeOnClick={false}
                                            hideProgressBar={false} newestOnTop={false} rtl={false} pauseOnFocusLoss pauseOnHover />
                                    );
                                }

                                return (
                                    <NewFeatureContextProvider>
                                        <PanelContextProvider>
                                            <NewFeatureModal/>
                                            <ToastContainer
                                                style={{fontSize: FontSizes.size14, padding: '0', color: NeutralColors.black}}
                                                progressClassName = {_ErrorToast.toastProgress}
                                                toastClassName = {_ErrorToast.toastClass}
                                                bodyClassName = {_ErrorToast.toastBody}
                                                position="bottom-right"
                                                autoClose={7000}
                                                hideProgressBar={false}
                                                newestOnTop={false}
                                                closeOnClick
                                                rtl={false}
                                                pauseOnFocusLoss
                                                draggable
                                                pauseOnHover
                                            />
                                            <Header/>
                                            <PersonaPanel/>
                                            <HelpPanel/>
                                            <DidYouKnowPanel />
                                            <ControlDetailsPanel />
                                            <NavMenu onNavCollapsed={this._onNavCollapsed}/>
                                            <Layout>
                                                <Router history={history}>
                                                    <Switch>
                                                        {
                                                            window.__UI_CONFIGURATION_EXTENDED_AZURE__.controlEditorFeatureConfiguration?.isEnabled === true &&
                                                            <Route exact path={RoutePaths.ControlMetadataEditorURL} render={(props) => 
                                                                <ControlEditor isNavCollapsed={this.state.isNavCollapsed} />} />
                                                        }
                                                        {
                                                            window.__UI_CONFIGURATION_EXTENDED_AZURE__.secureTemplateConfiguration?.isEnabled === true &&
                                                            <Route exact path={RoutePaths.SecureTemplatesURL} render={(props) => 
                                                                <SecureTemplate isNavCollapsed={this.state.isNavCollapsed} />} />
                                                        }
                                                        {
                                                            window.__UI_CONFIGURATION_EXTENDED_AZURE__.isDevOpsScannerEnabled &&
                                                            <Route exact path={RoutePaths.DevOpsScannerURL} render={(props) => 
                                                                <DevOpsScanner baselineType={props.match.params.type ?
                                                                    props.match.params.type.toString().toLowerCase() : props.match.params.type}
                                                                    admin={props.match.params.admin} isNavCollapsed={this.state.isNavCollapsed} 
                                                                />} 
                                                            />
                                                        }
                                                        {
                                                            window.__UI_CONFIGURATION_EXTENDED_AZURE__.complianceInitiativeFeatureConfiguration?.isEnabled &&
                                                            <Route exact path={RoutePaths.ComplianceInitiativeEditorURL} render={(props) => 
                                                                <ComplianceInitiativeEditor isNavCollapsed={this.state.isNavCollapsed} 
                                                                />} 
                                                            />
                                                        }

                                                        <Route exact path="/:type?/:admin?" render={(props) =>
                                                            <HomePage baselineType={props.match.params.type ?
                                                                props.match.params.type.toString().toLowerCase() : props.match.params.type}
                                                                admin={props.match.params.admin} isNavCollapsed={this.state.isNavCollapsed} />} />
                                                    </Switch>
                                                </Router>
                                            </Layout>
                                        </PanelContextProvider>
                                    </NewFeatureContextProvider>
                                );

                            case AuthenticationState.Unauthenticated:
                                return (
                                    <div>
                                        {error && <p><span>An error occurred during authentication, please try again!</span></p>}
                                        <p>
                                            <span>Hey stranger, you look new!</span>
                                            <button onClick={login}>Login</button>
                                        </p>
                                    </div>
                                );

                            case AuthenticationState.InProgress:
                                return (<p>Authenticating...</p>);
                        }
                    }
                }
                </AzureAD>
            </div>
        );
    }

    private _onNavCollapsed = () => {
        this.setState({ isNavCollapsed: !this.state.isNavCollapsed });
    }
};

export default withAppInsights(App);