// All configurations, apart from the initial configurations, that can be configured at runtime for the UI, are listed here.
interface IUnificationConfiguration {
    ScannerName: string;
    ScannerTitle: string;
    ScannerDescription: string;
    WorkItemName: string;
    ParentItemName: string;
    ScannerIcon: string;
}

export const TenantScannerConfiguration : IUnificationConfiguration = {
    ScannerName: "Azure Scanner",
    ScannerTitle: "Scan your subscriptions",
    ScannerDescription: "Use this tool to submit requests to scan Azure resources in your subscriptions.",
    WorkItemName: "Subscription",
    ParentItemName: "Tenant",
    ScannerIcon: "AzureLogo"
    //ScannerIcon: "VSTSLogo"
}


export const ADOScannerConfiguration : IUnificationConfiguration = {
    ScannerName: "Azure DevOps Scanner",
    ScannerTitle: "View your Azure DevOps Compliance",
    ScannerDescription: "Use this tool to view compliance summary for your Azure DevOps (ADO) resource(s).",
    WorkItemName: "Organization",
    ParentItemName: "Organization",
    ScannerIcon: "VSTSLogo"
}