import { getTheme, loadTheme, mergeStyleSets } from "@fluentui/react";
import { defaultTheme } from "../../Themes/DefaultTheme";
import { tableStyles } from "./Common";

const theme = loadTheme(defaultTheme);

export const ROW_HEIGHT: number = 42;
export const GROUP_HEADER_AND_FOOTER_SPACING: number = 24;
export const GROUP_HEADER_AND_FOOTER_BORDER_WIDTH: number = 0;
export const GROUP_HEADER_HEIGHT: number = 95;
export const GROUP_FOOTER_HEIGHT: number = GROUP_HEADER_AND_FOOTER_SPACING * 4 + GROUP_HEADER_AND_FOOTER_BORDER_WIDTH * 2;

export const classNames = mergeStyleSets({
    sticky: {
        position: 'relative',
        maxWidth: tableStyles.width,
        overflowWrap: 'break-word'
    },
    messageDUK: {
        position: 'relative',
        width: tableStyles.width,
        overflow: 'hidden',
        height: 90
    },
    pane: {
        marginRight: 45,
        marginLeft: 25
    },
    paneHeader: {
        marginRight: 65,
        marginLeft: 55
    },
    headerAndFooter: {
      borderTop: `${GROUP_HEADER_AND_FOOTER_BORDER_WIDTH}px solid ${theme.palette.neutralQuaternary}`,
      borderBottom: `${GROUP_HEADER_AND_FOOTER_BORDER_WIDTH}px solid ${theme.palette.neutralQuaternary}`,
      borderLeft: `4px solid ${theme.palette.themePrimary}`,
      padding: GROUP_HEADER_AND_FOOTER_SPACING,
      marginTop: `${GROUP_HEADER_AND_FOOTER_SPACING}px`,
      marginBottom: `${GROUP_HEADER_AND_FOOTER_SPACING}px`,
      paddingLeft: GROUP_HEADER_AND_FOOTER_SPACING - 8,
      background: theme.semanticColors.disabledBackground,
      position: 'relative',
      boxShadow: theme.effects.elevation4,
      transition:'0.5s'
    },
    headerAndFooterExpand: {
        borderTop: `${GROUP_HEADER_AND_FOOTER_BORDER_WIDTH}px solid ${theme.palette.neutralQuaternary}`,
        borderBottom: `${GROUP_HEADER_AND_FOOTER_BORDER_WIDTH}px solid ${theme.palette.neutralQuaternary}`,
        borderLeft: `4px solid ${theme.palette.themePrimary}`,
        padding: GROUP_HEADER_AND_FOOTER_SPACING,
        marginTop: `${GROUP_HEADER_AND_FOOTER_SPACING}px`,
        paddingLeft: GROUP_HEADER_AND_FOOTER_SPACING - 8,
        marginBottom: `${GROUP_HEADER_AND_FOOTER_SPACING}px`,
        background: theme.semanticColors.disabledBackground,
        position: 'relative',
        boxShadow: theme.effects.elevation4,
        transition:'0.5s'
    },
    headerAndFooterSelected: {
        borderTop: `${GROUP_HEADER_AND_FOOTER_BORDER_WIDTH}px solid ${theme.palette.neutralQuaternary}`,
        borderBottom: `${GROUP_HEADER_AND_FOOTER_BORDER_WIDTH}px solid ${theme.palette.neutralQuaternary}`,
        borderLeft: `4px solid ${theme.palette.themeDarker}`,
        padding: GROUP_HEADER_AND_FOOTER_SPACING,
        paddingLeft: GROUP_HEADER_AND_FOOTER_SPACING - 8,
        marginTop: `${GROUP_HEADER_AND_FOOTER_SPACING}px`,
        marginBottom: `${GROUP_HEADER_AND_FOOTER_SPACING}px`,
        background: theme.palette.themeLighter,
        position: 'relative',
        boxShadow: theme.effects.elevation16,
        transition:'0.5s'
    },
    progressBar: [
        {   
            padding: '0px'
        }
        
    ],
    headerTitle: [
      theme.fonts.mediumPlus,
      {
        marginLeft: '0px',
      },
    ],
    bodyTitle: [
        theme.fonts.smallPlus,
        {
          paddingRight: '20px'
        },
    ],
});