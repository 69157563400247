import React from 'react';
import { ScrollablePane} from '@fluentui/react';
import { _Styles } from './Page.styles';
import ComplianceInitiativeProvider from './context/ComplianceInitiativeMappingEditor';
import { AdminProps } from './Page.types';
import { TreeComplainceInitiativeEditor } from './ComplianceInitiativeEditor/TreeComplianceInitiativeEditor';
import { AddComplianceInitiativePanel } from './Panels/AddComplianceInitiativePanel';

// loadTheme(complianceInitiaveEditorTheme)
export class ComplianceInitiativeEditor extends React.Component<AdminProps,{}> {

    componentDidUpdate(){
        localStorage.setItem("theme","complianceInitiaveEditor");
        localStorage.setItem("scanner","azure");
    }

    render() {
        return ((window.__UI_CONFIGURATION_EXTENDED_AZURE__.complianceInitiativeFeatureConfiguration.isEnabled === true) && (
            <ComplianceInitiativeProvider>  
                    <ScrollablePane className={this.props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}>
                        <div className={_Styles.rootDiv}>
                            <TreeComplainceInitiativeEditor />
                            <AddComplianceInitiativePanel/>
                        </div>
                    </ScrollablePane> 
            </ComplianceInitiativeProvider>
        ));
    }
}