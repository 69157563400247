import React  from 'react';
import { CommandBarButton, Text, getTheme, Stack } from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { IControlObject, IResourceObject, IResourceObjectDevOps } from '../../Interface/ITable';
import { _SuccessToast } from '../../Page.styles';

const theme = getTheme();
export const onRenderPlainCardReason = (item: string): JSX.Element => {
    var color = '#cf680e';
    return (
        <div style={{background: theme.palette.neutralLighter, padding:20, maxWidth:'400px', borderTop: `4px solid ${color}`}}>
            <Stack>
                <h5 style={{color:color}}>Status Reason</h5>
                <Text>{item}</Text>
                <Stack horizontalAlign="start">
                    <CopyToClipboard text={item}>
                        <CommandBarButton 
                            iconProps={{iconName:'Copy',style:{color:color}}}
                            text="Copy Reason"
                            onClick = {() => {
                                toast("Copied reason to clipboard", {
                                    autoClose: 3000,
                                    progressClassName: _SuccessToast.toastProgress,
                                    className: _SuccessToast.toastClass,
                                });
                            }}
                            style={{padding:0, marginTop:15, outline:'none', background: theme.palette.neutralLighter}}
                        />
                    </CopyToClipboard>
                </Stack>
            </Stack>            
        </div>
    );
};

export const onRenderPlainCardJustification = (item: string): JSX.Element => {
    var color = SharedColors.magenta20;
    return (
        <div style={{background: theme.palette.neutralLighter, padding:20, maxWidth:'400px', borderTop: `4px solid ${color}`}}>
            <Stack>
                <h5 style={{color:color}}>Justification</h5>
                <Text>{item}</Text>
                <Stack horizontalAlign="start">
                    <CopyToClipboard text={item}>
                        <CommandBarButton 
                            iconProps={{iconName:'Copy',style:{color:color}}}
                            text="Copy Justification"
                            onClick = {() => {
                                toast("Copied justification to clipboard", {
                                    autoClose: 3000,
                                    progressClassName: _SuccessToast.toastProgress,
                                    className: _SuccessToast.toastClass
                                });
                            }}
                            style={{padding:0, marginTop:15, outline:'none', background: theme.palette.neutralLighter}}
                        />
                    </CopyToClipboard>
                </Stack>
            </Stack>            
        </div>
    );
};

export const onRenderPlainCard = (item: string): JSX.Element => {
    return (
        <div style={{padding:20, maxWidth:'500px'}}>
            {item}
        </div>
    );
};

export const onRenderPlainCardControl = (item: IControlObject): JSX.Element => {
    const RuntimeConfigurationExtended = window.__UI_CONFIGURATION_EXTENDED_AZURE__;
    var color = SharedColors.cyanBlue20;
    return (
        <div style={{background: theme.palette.neutralLighter, padding:20, maxWidth:'500px', borderTop: `4px solid ${color}`}}>
            <Stack>
                <h5 style={{color:color}}>Details</h5>
                <Text><b style={{fontWeight:600}}>Name: </b>{item.name}</Text>
                <Text><b style={{fontWeight:600}}>Description: </b>{item.description}</Text>
                <Stack horizontalAlign="start" horizontal tokens={{childrenGap:20}}>
                    <CopyToClipboard text={item.description}>
                        <CommandBarButton 
                            iconProps={{iconName:'Copy',style:{color:color}}}
                            text="Copy Description"
                            onClick = {() => {
                                toast("Copied description to clipboard", {
                                    autoClose: 3000,
                                    progressClassName: _SuccessToast.toastProgress,
                                    className: _SuccessToast.toastClass
                                });
                            }}
                            style={{padding:0, marginTop:15, outline:'none', background: theme.palette.neutralLighter}}
                        />
                    </CopyToClipboard>
                    <CommandBarButton 
                        iconProps={{iconName:'BookAnswers',style:{color:color}}}
                        text="Go to Remediation Guide"
                        onClick = {() => {
                            window.open(RuntimeConfigurationExtended.controlWikiBaseURL + item.name,'_blank');
                        }}
                        style={{padding:0, marginTop:15, outline:'none', background: theme.palette.neutralLighter}}
                    />
                </Stack>
            </Stack>            
        </div>
    );
};

export const onRenderPlainCardResource = (item: IResourceObject): JSX.Element => {
    var color = SharedColors.cyanBlue20;
    return (
        <div style={{background: theme.palette.neutralLighter, padding:5}}>
            <Stack>
                <CopyToClipboard text={item.resource}>
                    <CommandBarButton 
                        iconProps={{iconName:'Copy',style:{color:color}}}
                        text=""
                        onClick = {() => {
                            toast("Copied resource name to clipboard", {
                                autoClose: 3000,
                                progressClassName: _SuccessToast.toastProgress,
                                className: _SuccessToast.toastClass
                            });
                        }}
                        style={{padding:0, marginTop:4, marginBottom:4, outline:'none', background: theme.palette.neutralLighter}}
                    />
                </CopyToClipboard>
            </Stack>            
        </div>
    );
};

export const onRenderPlainCardResourceDevOps = (item: IResourceObjectDevOps): JSX.Element => {
    var color = SharedColors.cyanBlue20;
    return (
        <div style={{background: theme.palette.neutralLighter, padding:5}}>
            <Stack>
                <CopyToClipboard text={item.resource}>
                    <CommandBarButton 
                        iconProps={{iconName:'Copy',style:{color:color}}}
                        text=""
                        onClick = {() => {
                            toast("Copied resource name to clipboard", {
                                autoClose: 3000,
                                progressClassName: _SuccessToast.toastProgress,
                                className: _SuccessToast.toastClass
                            });
                        }}
                        style={{padding:0, marginTop:4, marginBottom:4, outline:'none', background: theme.palette.neutralLighter}}
                    />
                </CopyToClipboard>
            </Stack>            
        </div>
    );
};

export const onRenderPlainCardResourceGroup = (item: string): JSX.Element => {
    var color = SharedColors.cyanBlue20;
    return (
        <div style={{background: theme.palette.neutralLighter, padding:5}}>
            <Stack>
                <CopyToClipboard text={item}>
                    <CommandBarButton 
                        iconProps={{iconName:'Copy',style:{color:color}}}
                        text=""
                        onClick = {() => {
                            toast("Copied resource group name to clipboard", {
                                autoClose: 3000,
                                progressClassName: _SuccessToast.toastProgress,
                                className: _SuccessToast.toastClass
                            });
                        }}
                        style={{padding:0, marginTop:4, marginBottom:4, outline:'none', background: theme.palette.neutralLighter}}
                    />
                </CopyToClipboard>
            </Stack>            
        </div>
    );
};

export const onRenderPlainCardSubscription = (item: string): JSX.Element => {
    return (
        <div style={{background: theme.palette.neutralLighter, padding: 5 }} >
            <Stack>
                <CopyToClipboard text={item}>
                    <CommandBarButton 
                        iconProps={{iconName:'Copy'}}
                        text=""
                        onClick = {() => {
                            toast("Copied subscription Id to clipboard", {
                                autoClose: 3000,
                                progressClassName: _SuccessToast.toastProgress,
                                className: _SuccessToast.toastClass
                            });
                        }}
                        style={{background: theme.palette.neutralLighter,marginTop:5, marginBottom:5}}
                    />
                </CopyToClipboard>
            </Stack>            
        </div>
    );
};
