import * as React from 'react';
import { IconButton, ITeachingBubbleStyles, Link, TeachingBubble } from '@fluentui/react';

export const ExternalControlInfo: React.FunctionComponent = () => {
    const [teachingBubbleVisible, setTeachingBubbleVisible] = React.useState(false);
    const teachingStyles : Partial<ITeachingBubbleStyles> = {
        root:{
            transition:'0.5s'
        }
    }
  return (
    <div>
        {teachingBubbleVisible && (
            <TeachingBubble
                target="#externalControl-Info"
                headline="Non-AzTS based controls"
                hasCondensedHeadline={true}
                styles={teachingStyles}
                hasCloseButton={true}
                onDismiss={()=>{
                    setTeachingBubbleVisible(false)
                }}
                closeButtonAriaLabel='Close Info'
            >
                <ol style={{marginLeft:-20}}>
                    <li>There are certain controls which are not evaluated by AzTS and verification result of such controls is marked as 'ExtScanned'.</li>
                    <li>Controls with verification result 'ExtScanned' are excluded from compliance visibility and by default not shown in scan results list.</li>
                    <li>You can use this filter to toggle scan results view for externally scanned controls. What it mean by externally scanned controls, refer: <Link href='https://aka.ms/azts-docs/extscanned' underline style={{color:'#fff'}}>https://aka.ms/azts-docs/extscanned</Link></li>
                </ol>
            </TeachingBubble>
        )} 
        <IconButton 
            style={{outline:"none"}} 
            onClick={()=>{
                setTeachingBubbleVisible(true)
            }}
            id="externalControl-Info"
            aria-label="Control Info"
            iconProps={{iconName:'Info'}}
        />
    </div>
    
  );
};
