import React from "react";
import { HoverCard, HoverCardType, IColumn, IPlainCardProps, Link } from "@fluentui/react";
import { FailedIcon, PassedIcon} from './../../Styles/Table/IconStyles';
import { ISecureTemplateTableControlInfo, UIControlObject } from "../../Interface/ITableSecureTemplate";
import { onRenderPlainCardControl } from "../../Styles/TableSecureTemplate/HoverCardSecureTemplate";

export function renderItemColumnSecureTemplate(item: ISecureTemplateTableControlInfo, index: Number, column: IColumn) : JSX.Element {
    const RuntimeConfigurationExtended = window.__UI_CONFIGURATION_EXTENDED_AZURE__;
    const fieldContent = item[column.fieldName as keyof ISecureTemplateTableControlInfo];

    switch (column.key) {
        case 'isSupported':
            if(fieldContent === false) {
                return (<span><FailedIcon /> No</span>);
            }
            return (<span><PassedIcon /> Yes</span>);

        case 'uiControlObject':
            var controlObj = fieldContent as UIControlObject;
            const plainCardPropsControl: IPlainCardProps = {
                onRenderPlainCard: onRenderPlainCardControl,
                renderData: controlObj
            };
            return (
                <HoverCard cardOpenDelay={100} plainCardProps={plainCardPropsControl} instantOpenOnClick type={HoverCardType.plain}>
                    <Link href={RuntimeConfigurationExtended.controlWikiBaseURL + controlObj.controlId} target="_blank">{controlObj.controlId}</Link>
                </HoverCard>
            );

        default:
            return <span>{fieldContent.toString()}</span>;
    }
}