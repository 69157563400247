import React, { useContext, useEffect } from 'react';
import { Stack, IStackTokens } from '@fluentui/react/lib/Stack';
import { IComboBoxOption, ComboBox, IComboBox, ProgressIndicator } from '@fluentui/react';
import { getTheme } from '@fluentui/react';
import { authProvider } from '../../authProvider';
import { CmetControllfilterStyle, _Styles } from '../Page.styles';
import { AdminContext } from '../context/ControlEditor';
import { CollapsibleSection, ICollapsibleSectionComponent, ICollapsibleSectionStylesReturnType } from '@fluentui/react-experiments';
import { toast } from 'react-toastify';

const theme = getTheme();

const horizontalStackTokens: IStackTokens = {
    childrenGap: 25,
    padding: 15,
};

const getPropStyles: ICollapsibleSectionComponent['styles'] = (props, theme): ICollapsibleSectionStylesReturnType => ({
    root: [
      {
        background: theme.palette.themeLighter,
        marginLeft: 15,
        marginRight: 15,
        padding: 10,
        boxShadow: theme.effects.elevation8,
        outline: "none",
      },
    ],
    body: [
      theme.fonts.small,
      { 
        outline: "none",
        background: theme.palette.themeLighter,
        padding: 5,
      },
    ],
});

const combo_template:IComboBoxOption[] = [
    {
        key: 'Select All',
        text: 'Select All',
        styles: {
            optionText: {
                color : theme.palette.themePrimary,
                fontWeight : 600,
                fontSize : '110%',
            },
        }
    }
];

export const FilterControlEditor: React.FunctionComponent<{}> = () => {
    const RuntimeConfigurationInitial = window.__UI_CONFIGURATION_INITIAL__;
    const ScannerAPI = RuntimeConfigurationInitial.webAPIConf ? RuntimeConfigurationInitial.webAPIConf["AZURE"] : RuntimeConfigurationInitial.webAPI;

    const [ResourceOptions, setResourceOptions] = React.useState<IComboBoxOption[]>([]);
    const [ControlOptions, setControlOptions] = React.useState<IComboBoxOption[]>([]);
    const [scanSourceOptions, setScanSourceOptions] =  React.useState<IComboBoxOption[]>([]);

    const [ResourceAll, setResourceAll] = React.useState<string[]>([]);
    const [ControlAll, setControlAll] = React.useState<string[]>([]);
    const [scanSourceAll, setScanSourceAll] = React.useState<string[]>([]);

    const [selectedResource, setSelectedResource] = React.useState<string[]>([]);
    const [selectedControl, setSelectedControl] = React.useState<string[]>([]);
    const [selectedScanSource, setSelectedScanSource] = React.useState<string[]>([]);

    const [ResourceLength, setResourceLength] = React.useState<number>(0);
    const [ControlLength, setControlLength] = React.useState<number>(0);
    const [scanSourceLength, setScanSourceLength] = React.useState<number>(0);

    const [currentTime, setCurrentTime] = React.useState<number>(0);
    const [lastTime, setLastTime] = React.useState<number>(0);

    const {setFormDetails, setTableVisible, setControlFilteredDetails} = useContext(AdminContext);
    const [visible, setVisible] = React.useState<boolean>(false)

    const onChangeResource = (ev: React.FormEvent<IComboBox>, option?: IComboBoxOption): void => {
        if(option.key === 'Select All') {
            if(option.selected) {
                setSelectedResource([...ResourceAll, option.key as string]);
                setResourceLength(ResourceAll.length);
            }
            else {
                setSelectedResource([]);
                setResourceLength(0);
            }
        }
        else {
            setSelectedResource(option.selected ? [...selectedResource, option.key as string] : selectedResource.filter(key => key !== option.key));
            setResourceLength(option.selected ? ResourceLength + 1 : ResourceLength - 1);
        }
        setCurrentTime(Date.now()); 
    };

    const onChangeControl = (ev: React.FormEvent<IComboBox>, option?: IComboBoxOption): void => {
        if(option.key === 'Select All') {
            if(option.selected) {
                setSelectedControl([...ControlAll, option.key as string]);
                setControlLength(ControlAll.length);
            }
            else {
                setSelectedControl([]);
                setControlLength(0);
            }
        }
        else {
            setSelectedControl(option.selected ? [...selectedControl, option.key as string] : selectedControl.filter(key => key !== option.key));
            setControlLength(option.selected ? ControlLength + 1 : ControlLength - 1);
        }
        setCurrentTime(Date.now()); 
    };

    const onChangeScanSource = (ev: React.FormEvent<IComboBox>, option?: IComboBoxOption): void => {
        if(option.key === 'Select All') {
            if(option.selected) {
                setSelectedScanSource(scanSourceAll);
                setScanSourceLength(scanSourceAll.length);
            }
            else {
                setSelectedScanSource([]);
                setScanSourceLength(0);
            }
        }
        else {
            setSelectedScanSource(option.selected ? [...selectedScanSource, option.key as string] : selectedScanSource.filter(key => key !== option.key));
            setScanSourceLength(option.selected ? scanSourceLength + 1 : scanSourceLength - 1);
        }
        setCurrentTime(Date.now()); 
    };

    const fetchNewFilter = (filterType: string) => {
        const CallAPI = async () => {
            setVisible(false);
            const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
            let body = {
                ResourceType: selectedResource,
                Ids: selectedControl,
                ScanSource: selectedScanSource,
                FilterType: filterType,
            }
            fetch((ScannerAPI + '/controleditor/getcontrols/'), {
                headers: !authToken.accessToken ? {} : {
                    'Authorization': `Bearer ${authToken.accessToken}`,
                    'u_Id': sessionStorage.getItem("u_Id"),
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(body)
            }).then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        updateFilter(JSON.stringify(data));
                        setVisible(true);
                        setTableVisible(true);
                    });
                }
                else {
                    response.text().then(errorMsg => {
                        toast(errorMsg.slice(0, 500));
                    });
                }
            });
        }
        CallAPI();
    }

    const sendData = () => {
        if(currentTime !== lastTime) {
            setLastTime(currentTime);
            fetchNewFilter("ResourceType");
        }   
    }

    const sendData2 = () => {
        if(currentTime !== lastTime) {
            setLastTime(currentTime);
            setFormDetails(selectedResource,selectedControl,selectedScanSource);
            setControlFilteredDetails(selectedControl)
        }   
    }

    const sendScanSourceTypeData = () => {
        if(currentTime !== lastTime) {
            setLastTime(currentTime);
            fetchNewFilter("ScanSource");
        }   
    }

    const updateFilter = (dataString : string) => {
        var data = JSON.parse(dataString);
        var allControls : string[] = [];

        for (var i = 0; i < data.controls.length; i++) {
            allControls = allControls.concat(data.controls[i].id);
        }

        setSelectedResource(data.resourceType);
        setSelectedControl(allControls);
        setSelectedScanSource(data.scanSource);

        setResourceLength(data.resourceType.length);
        setControlLength(allControls.length);
        setScanSourceLength(data.scanSource.length);

        setFormDetails(data.resourceType,allControls,data.scanSource);
        setControlFilteredDetails(allControls);
    }

    const fillCombo = (dataString : string) => {
        var data = JSON.parse(dataString);
        var allControls : string[] = [];

        for (var i = 0; i < data.controls.length; i++) {
            allControls = allControls.concat(data.controls[i].id);
        }

        setResourceAll(data.resourceType);
        setControlAll(allControls);
        setScanSourceAll([...data.scanSource, "Select All"]);

        setSelectedResource(data.resourceType);
        setSelectedControl(allControls);
        setSelectedScanSource(data.scanSource);

        setResourceLength(data.resourceType.length);
        setControlLength(allControls.length);
        setScanSourceLength(data.scanSource.length);

        var new_items = [...combo_template];
        // eslint-disable-next-line
        for (var i = 0; i < data.resourceType.length; i++) {
            new_items = new_items.concat({
                key: data.resourceType[i],
                text: data.resourceType[i]
            });
        }
        setResourceOptions(new_items);

        new_items = [...combo_template];
        // eslint-disable-next-line
        for (var i = 0; i < data.scanSource.length; i++) {
            new_items = new_items.concat({
                key: data.scanSource[i],
                text: data.scanSource[i]
            });
        }
        setScanSourceOptions(new_items);

        new_items = [...combo_template];
        // eslint-disable-next-line
        for (var i = 0; i < data.controls.length; i++) {
            new_items = new_items.concat({
                key: data.controls[i].id,
                text: data.controls[i].controlId,
            });
        }
        setControlOptions(new_items);
        setFormDetails(data.resourceType, allControls,data.scanSource);
        setControlFilteredDetails(allControls);
    }

    useEffect(()=> {
        const CallAPI = async () => {
            setVisible(false);
            const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
            fetch((ScannerAPI + '/controleditor/getallcontrols/'), {
                headers: !authToken.accessToken ? {} : {
                    'Authorization': `Bearer ${authToken.accessToken}`,
                    'u_Id': sessionStorage.getItem("u_Id"),
                    'Content-Type': 'application/json'
                },
                method: 'POST',
            }).then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        fillCombo(JSON.stringify(data))
                        setVisible(true);
                        setTableVisible(true);
                    });
                }
                else {
                    response.text().then(errorMsg => {
                        toast(errorMsg.slice(0, 500));
                    });
                }
            });
        }
        CallAPI();
    },
    // eslint-disable-next-line
    []);

    return (
        <Stack>
            <div className={_Styles.rowGap} />  
            <Stack style={{marginLeft: 15, marginTop: 15}}>
                <Stack horizontal horizontalAlign="space-between">
                    <h4 id="initial-info">Control Metadata Editor Tool</h4>
                </Stack>
                <Stack horizontal horizontalAlign="space-between">
                </Stack>
                <p>Use this tool to edit control metadata.</p>
            </Stack>
            <div className={_Styles.rowGap} />
            {!visible && <ProgressIndicator barHeight={4} label="" description="" styles={{root:{marginLeft:15,marginRight:15}}}/>}
            <CollapsibleSection 
                key={1} 
                defaultCollapsed={true} 
                title={{
                    text: 'Filters',
                    styles: {
                        text:theme.fonts.xLarge,
                        root: {
                            outline: "none !important",
                            selectors: {
                                ':hover': {
                                    backgroundColor: 'inherit'
                                }
                            }
                        }
                    },
                }}
                styles={getPropStyles}
            >
                <Stack horizontal horizontalAlign="space-between" tokens={horizontalStackTokens}>
                    <Stack style={{width:'50%'}}>
                        <ComboBox
                            placeholder="Select resource type"
                            label={"Resource Type (" + ResourceLength + " selected)"}
                            options={ResourceOptions}
                            style={{width:'100%'}}
                            selectedKey={selectedResource}
                            onChange={onChangeResource}
                            onMenuDismissed={sendData}
                            multiSelect
                            allowFreeform={true}
                        />
                    </Stack>
                    <Stack style={{width:'50%'}}>
                        <ComboBox
                            placeholder="Select scan source"
                            label={"Scan Source (" + scanSourceLength + " selected)"}
                            options={scanSourceOptions}
                            style={{width:'100%'}}
                            selectedKey={selectedScanSource}
                            allowFreeform={true}
                            onMenuDismissed={sendScanSourceTypeData}
                            onChange={onChangeScanSource}
                            multiSelect
                        />
                    </Stack>
                </Stack>
                <Stack horizontal horizontalAlign="space-between" styles={CmetControllfilterStyle}>
                        <ComboBox
                            placeholder="Select controls"
                            label={"Control-ID (" + ControlLength + " selected)"}
                            options={ControlOptions}
                            style={{width:'100%'}}
                            selectedKey={selectedControl}
                            allowFreeform={true}
                            onMenuDismissed={sendData2}
                            onChange={onChangeControl}
                            multiSelect
                        />
                    </Stack>
            </CollapsibleSection>
        </Stack>
    );
}