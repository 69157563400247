import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";

const RuntimeConfigurationInitial = window.__UI_CONFIGURATION_INITIAL__;

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const browserHistory = createBrowserHistory({ basename: baseUrl });

var appInsightsConfiguration = RuntimeConfigurationInitial.appInsights;
if (!appInsightsConfiguration) {
    appInsightsConfiguration = {};
}

var instrumentationKey = appInsightsConfiguration["instrumentationKey"]?.trim();
var loggingLevelConsole = appInsightsConfiguration["loggingLevelConsole"];
var loggingLevelTelemetry = appInsightsConfiguration["loggingLevelTelemetry"];
var maxBatchInterval = appInsightsConfiguration["maxBatchInterval"];
var enableRequestHeaderTracking = appInsightsConfiguration["enableRequestHeaderTracking"];
var enableResponseHeaderTracking = appInsightsConfiguration["enableResponseHeaderTracking"];
var autoTrackPageVisitTime = appInsightsConfiguration["autoTrackPageVisitTime"];
var disableDataLossAnalysis = appInsightsConfiguration["disableDataLossAnalysis"];
var enableCorsCorrelation = appInsightsConfiguration["enableCorsCorrelation"];
var enableAutoRouteTracking = appInsightsConfiguration["enableAutoRouteTracking"];
var enableAjaxPerfTracking = appInsightsConfiguration["enableAjaxPerfTracking"];
var enableAjaxErrorStatusText = appInsightsConfiguration["enableAjaxErrorStatusText"];
var enableUnhandledPromiseRejectionTracking = appInsightsConfiguration["enableUnhandledPromiseRejectionTracking"];
var disableInstrumentationKeyValidation = appInsightsConfiguration["disableInstrumentationKeyValidation"];
var componentName = appInsightsConfiguration["ComponentName"];

const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
    config: {
        instrumentationKey: instrumentationKey ? instrumentationKey : '',
        loggingLevelConsole:  loggingLevelConsole ? loggingLevelConsole : 2,
        loggingLevelTelemetry:  loggingLevelTelemetry ? loggingLevelTelemetry : 2,
        maxBatchInterval: maxBatchInterval ? maxBatchInterval : 15000,
        enableRequestHeaderTracking: enableRequestHeaderTracking ? enableRequestHeaderTracking : true,
        enableResponseHeaderTracking:  enableResponseHeaderTracking ? enableResponseHeaderTracking : true,
        autoTrackPageVisitTime:  autoTrackPageVisitTime ? autoTrackPageVisitTime : true,
        disableDataLossAnalysis:  disableDataLossAnalysis ? disableDataLossAnalysis : false,
        enableCorsCorrelation:  enableCorsCorrelation ? enableCorsCorrelation : true,
        enableAutoRouteTracking:  enableAutoRouteTracking ? enableAutoRouteTracking : true,
        enableAjaxPerfTracking:  enableAjaxPerfTracking ? enableAjaxPerfTracking : true,
        enableAjaxErrorStatusText:  enableAjaxErrorStatusText ? enableAjaxErrorStatusText : true,
        enableUnhandledPromiseRejectionTracking: enableUnhandledPromiseRejectionTracking ? enableUnhandledPromiseRejectionTracking : true,
        disableInstrumentationKeyValidation: disableInstrumentationKeyValidation ? disableInstrumentationKeyValidation : true,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: {
                history: browserHistory
            }
        }
    }
});

if (RuntimeConfigurationInitial.appInsights && instrumentationKey)
{
    ai.loadAppInsights();
    ai.addTelemetryInitializer((envelope) => {
        envelope.data.ComponentName = componentName ? componentName : 'AzTS UI';
    });
    ai.trackPageView();
    ai.trackTrace({ message: 'Initialized AppInsights.' });
}

export default (Component) => withAITracking(reactPlugin, Component);
export const appInsights = ai.appInsights;