import { getTheme, loadTheme } from "@fluentui/react";
import { defaultTheme } from "../Themes/DefaultTheme";
import { controlEditorTheme } from "./../Themes/ControlEditorTheme";

export function getThemeForUI(){
    var theme = loadTheme(defaultTheme);
    if(localStorage.getItem("theme") === "controlEditor"){
        theme = loadTheme(controlEditorTheme)
    }
    return theme 
}