import * as React from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import {
  ISidebar,
  ISidebarStyles,
  Sidebar,
  SidebarButton,
} from "@fluentui/react-experiments";
import { IButtonStyles, mergeStyles, mergeStyleSets } from "@fluentui/react";
import { NeutralColors } from "@fluentui/theme";
import { matchPath } from "react-router-dom";
import history from "../history";
import { authProvider } from "../authProvider";
import { toast } from "react-toastify";
import { getThemeForUI } from "./Helper/ThemeLocalStorage";
import { RoutePaths } from "../Route";

export interface NavMenuProps {
  onNavCollapsed: () => void;
}

const initSidebar = (sideBar: ISidebar) => {
  try {
    sideBar.setCollapsed(true);
  } catch (error) {
    console.log("Error initializing sideBar, error: " + error);
  }
};

const classNames = mergeStyleSets({
  navClass: {
    transition: "0.5s",
    backgroundColor: "#fff !important",
  },
});

const navStyles: ISidebarStyles = {
  root: {
    outline: "none",
    backgroundColor: "#fff !important",
  },
  content: {
    fontWeight: 600,
    backgroundColor: NeutralColors.gray30,
  },
  contentCollapsed: {
    fontWeight: 600,
    backgroundColor: NeutralColors.gray30,
  },
};

const navButtonStyles: IButtonStyles = {
  root: {
    outline: "none",
    paddingLeft: 4,
  },
  rootPressed: {
    outline: "none",
  },
};

const hideActionItemCSS = mergeStyles({ display: "none" });

interface NavState {
  location: string;
  controlEditorVisible: boolean;
  checkedVisibility: boolean;
  complianceInitiativeEditorVisible: boolean;
  checkedCIVisibility: boolean;
}

const RuntimeConfigurationInitial = window.__UI_CONFIGURATION_INITIAL__;
const AzureScannerAPI = RuntimeConfigurationInitial.webAPIConf
  ? RuntimeConfigurationInitial.webAPIConf["AZURE"]
  : RuntimeConfigurationInitial.webAPI;

class NavMenu extends React.Component<
  NavMenuProps & RouteComponentProps<{}>,
  NavState
> {
  private RuntimeConfigurationExtended =
    window.__UI_CONFIGURATION_EXTENDED_AZURE__;
  private RuntimeConfigurationExtendedDevOps =
    window.__UI_CONFIGURATION_EXTENDED_DEVOPS__;

  constructor(props?) {
    super(props);
    this.state = {
      location: "",
      controlEditorVisible: false,
      checkedVisibility: false,
      complianceInitiativeEditorVisible: false,
      checkedCIVisibility: false,
    };
  }

  private _matchPath(path: string, exact: boolean, strict: boolean): boolean {
    const match = !!matchPath(this.props.location.pathname, {
      path: path,
      exact: exact,
      strict: strict,
    });
    return match;
  }

  // TODO: Maintain path of routes in a single file.
  // Reference would be made from that file. Easier to change route.
  // READ: Enum-MagicString concept
  private _checkLocation() {
    var pageLocation = "/";

    if (
      this.RuntimeConfigurationExtended.controlEditorFeatureConfiguration
        ?.isEnabled === true &&
      this._matchPath(RoutePaths.ControlMetadataEditorURL, true, false)
    ) {
      pageLocation = RoutePaths.ControlMetadataEditorURL;
    } else if (
      this.RuntimeConfigurationExtended.secureTemplateConfiguration
        ?.isEnabled === true &&
      this._matchPath(RoutePaths.SecureTemplatesURL, true, false)
    ) {
      pageLocation = RoutePaths.SecureTemplatesURL;
    } else if (
      this.RuntimeConfigurationExtended.isDevOpsScannerEnabled &&
      this._matchPath(RoutePaths.DevOpsScannerURL, true, false)
    ) {
      pageLocation = RoutePaths.DevOpsScannerURL;
    } else if (
      this.RuntimeConfigurationExtended.complianceInitiativeFeatureConfiguration
        .isEnabled &&
      this._matchPath(RoutePaths.ComplianceInitiativeEditorURL, true, false)
    ) {
      pageLocation = RoutePaths.ComplianceInitiativeEditorURL;
    }
    this.setState({
      location: pageLocation,
    });
  }

  private async _checkEditorStatus() {
    const authToken = await authProvider.getAccessToken({
      scopes: [
        "api://" +
          RuntimeConfigurationInitial.apiClientId +
          "/user_impersonation",
      ],
    });
    fetch(AzureScannerAPI + "/controleditor/checkstatus/", {
      headers: !authToken.accessToken
        ? {}
        : {
            Authorization: `Bearer ${authToken.accessToken}`,
            u_Id: sessionStorage.getItem("u_Id"),
            "Content-Type": "application/json",
          },
      method: "POST",
    }).then((response) => {
      if (response.ok) {
        this.setState({
          controlEditorVisible: true,
          checkedCIVisibility: true,
        });
      } else {
        this.setState({
          checkedCIVisibility: true,
        });
      }
    });
  }

  private async _checkComplianceInitiativeStatus() {
    const authToken = await authProvider.getAccessToken({
      scopes: [
        "api://" +
          RuntimeConfigurationInitial.apiClientId +
          "/user_impersonation",
      ],
    });
    fetch(AzureScannerAPI + "/complianceInitiatives/status/", {
      headers: !authToken.accessToken
        ? {}
        : {
            Authorization: `Bearer ${authToken.accessToken}`,
            u_Id: sessionStorage.getItem("u_Id"),
            "Content-Type": "application/json",
          },
      method: "GET",
    }).then((response) => {
      if (response.ok) {
        this.setState({
          complianceInitiativeEditorVisible: true,
          checkedCIVisibility: true,
        });
      } else {
        this.setState({
          checkedCIVisibility: true,
        });
      }
    });
  }

  componentDidMount() {
    this._checkLocation();
    if (
      this.RuntimeConfigurationExtended.controlEditorFeatureConfiguration
        ?.isEnabled === true
    ) {
      this._checkEditorStatus();
    }

    if (
      this.RuntimeConfigurationExtended.complianceInitiativeFeatureConfiguration
        ?.isEnabled === true
    ) {
      this._checkComplianceInitiativeStatus();
    }
  }

  componentDidUpdate() {
    if (
      this.RuntimeConfigurationExtended.controlEditorFeatureConfiguration
        ?.isEnabled === true &&
      this.state.location === RoutePaths.ControlMetadataEditorURL &&
      this.state.controlEditorVisible === false &&
      this.state.checkedVisibility === true
    ) {
      toast("You are not authorized to view this page.");
    }

    if (
      this.RuntimeConfigurationExtended.complianceInitiativeFeatureConfiguration
        ?.isEnabled === true &&
      this.state.location === RoutePaths.ComplianceInitiativeEditorURL &&
      this.state.complianceInitiativeEditorVisible === false &&
      this.state.checkedCIVisibility === true
    ) {
      toast("You are not authorized to view this page.");
    }
  }

  render() {
    const self = this;
    const {
      location,
      controlEditorVisible,
      complianceInitiativeEditorVisible,
    } = self.state;
    function _renderFunction(
      item: any,
      dismissMenu: (ev?: any, dismissAll?: boolean) => void
    ) {
      if (item.key === "control-editor" && !controlEditorVisible) {
        return <div key="emptyDiv1"></div>;
      }
      if (
        item.key === "complianceInitiative-editor" &&
        !complianceInitiativeEditorVisible
      ) {
        return <div key="emptyDiv1"></div>;
      }

      if (
        item.key === "scanADO" &&
        !self.RuntimeConfigurationExtended.isDevOpsScannerEnabled
      ) {
        return <div key="emptyDiv1"></div>;
      }

      if (
        item.key === "secure-templates" &&
        !self.RuntimeConfigurationExtended.secureTemplateConfiguration
          ?.isEnabled
      ) {
        return <div key="emptyDiv1"></div>;
      }

      if (
        item.key === "policies" &&
        (!self.RuntimeConfigurationExtended.policiesConfiguration?.isEnabled ||
          self.RuntimeConfigurationExtended.isExternalTenantSetup)
      ) {
        return <div key="emptyDiv1"> </div>;
      }
      if (
        item.key === "aad" &&
        (!self.RuntimeConfigurationExtended.entraIdConfiguration?.isEnabled ||
          self.RuntimeConfigurationExtended.isExternalTenantSetup)
      ) {
        return <div key="emptyDiv1"> </div>;
      }

      return (
        <SidebarButton
          key={item.key}
          iconProps={item.iconProps}
          text={item.name}
          role="menuitem"
          aria-label={item.ariaLabel}
          theme={getThemeForUI()}
          onClick={() => {
            if (item.href === RoutePaths.PoliciesURL) {
              window.open(
                self.RuntimeConfigurationExtended.policiesConfiguration
                  .policiesRedirectionLink,
                "_blank"
              );
            } else {
              if (item.href === RoutePaths.EntraIdURL) {
                window.open(
                  self.RuntimeConfigurationExtended.entraIdConfiguration
                    .entraIdRedirectionLink,
                  "_blank"
                );
              } else {
                history.push(item.href);
                self.setState({
                  location: item.href,
                });
              }
            }
          }}
          styles={{
            root: {
              backgroundColor: item.active
                ? NeutralColors.gray50
                : NeutralColors.gray30,
              borderLeft: item.active
                ? `4px solid ${getThemeForUI().palette.themePrimary}`
                : "none",
              paddingLeft: item.active ? 0 : 4,
              outline: "none",
            },
            rootHovered: {
              backgroundColor: NeutralColors.gray50,
              transition: "0.2s",
              color: getThemeForUI().palette.themePrimary,
            },
            iconHovered: {
              color: getThemeForUI().palette.themePrimary,
              transition: "0.2s",
            },
            label: {
              fontWeight: 600,
            },
          }}
        />
      );
    }

    return (
      <div style={{ marginTop: 45, height: "93.5vh", overflowY: "hidden" }}>
        <Sidebar
          theme={getThemeForUI()}
          collapseButtonStyles={navButtonStyles}
          style={{ height: "100vh" }}
          collapsible={true}
          styles={navStyles}
          buttonStyles={{
            root: {
              backgroundColor: NeutralColors.gray30,
            },
          }}
          className={classNames.navClass}
          componentRef={initSidebar}
          onCollapseChanged={this.props.onNavCollapsed}
          collapseButtonAriaLabel={"sitemap"}
          items={[
            {
              key: "scan",
              name: "Azure",
              iconProps: { iconName: "AzureLogo" },
              active: location === "/",
              href: "/",
              onRender: _renderFunction,
              ariaLabel: "Azure",
            },
            {
              key: "scanADO",
              name: "Azure DevOps",
              iconProps: { iconName: "VSTSLogo" },
              active: location === RoutePaths.DevOpsScannerURL,
              href: RoutePaths.DevOpsScannerURL,
              onRender: _renderFunction,
              className: this.RuntimeConfigurationExtendedDevOps
                ? hideActionItemCSS
                : "",
                ariaLabel: "Azure DevOps",
            },
            {
              key: "control-editor",
              name: "Control Metadata Tool",
              iconProps: { iconName: "BullseyeTargetEdit" },
              href: RoutePaths.ControlMetadataEditorURL,
              active: location === RoutePaths.ControlMetadataEditorURL,
              onRender: _renderFunction,
              className:
                this.RuntimeConfigurationExtended
                  .controlEditorFeatureConfiguration?.isEnabled !== true
                  ? hideActionItemCSS
                  : "",
              ariaLabel: "Control Metadata Tool", 
            },
            {
              key: "complianceInitiative-editor",
              name: "Compliance Initiative Metadata Tool",
              iconProps: { iconName: "Toolbox" },
              href: RoutePaths.ComplianceInitiativeEditorURL,
              active: location === RoutePaths.ComplianceInitiativeEditorURL,
              onRender: _renderFunction,
              className:
                this.RuntimeConfigurationExtended
                  .complianceInitiativeFeatureConfiguration?.isEnabled !== true
                  ? hideActionItemCSS
                  : "",
              ariaLabel: "Compliance Initiative Metadata Tool",
            },
            {
              key: "secure-templates",
              name: "Azure Secure Templates",
              iconProps: { iconName: "LaptopSecure" },
              href: RoutePaths.SecureTemplatesURL,
              active: location === RoutePaths.SecureTemplatesURL,
              onRender: _renderFunction,
              className:
                this.RuntimeConfigurationExtended.secureTemplateConfiguration
                  ?.isEnabled !== true
                  ? hideActionItemCSS
                  : "",
              ariaLabel: "Azure Secure Templates",
            },
            {
              key: "policies",
              name: "Policies",
              iconProps: { iconName: "EntitlementPolicy" },
              href: RoutePaths.PoliciesURL,
              active: location === RoutePaths.PoliciesURL,
              onRender: _renderFunction,
              className:
                this.RuntimeConfigurationExtended.policiesConfiguration
                  ?.isEnabled !== true ||
                this.RuntimeConfigurationExtended?.isExternalTenantSetup ===
                  true
                  ? hideActionItemCSS
                  : "",
              ariaLabel: "Policies",
            },
            {
              key: "aad",
              name: "Entra ID",
              iconProps: { iconName: "AADLogo" },
              href: RoutePaths.EntraIdURL,
              active: location === RoutePaths.EntraIdURL,
              onRender: _renderFunction,
              className:
                this.RuntimeConfigurationExtended.entraIdConfiguration
                  ?.isEnabled !== true ||
                this.RuntimeConfigurationExtended?.isExternalTenantSetup ===
                  true
                  ? hideActionItemCSS
                  : "",
              ariaLabel: "Entra ID",
            },
          ]}
        />
      </div>
    );
  }
}

export default withRouter(NavMenu);
