import * as React from "react";
import { TextField } from "@fluentui/react/lib/TextField";
import { PrimaryButton, Spinner, SpinnerSize, Text } from '@fluentui/react';
import { Image, ImageFit } from "@fluentui/react/lib/Image";
import { List } from "@fluentui/react/lib/List";
import {ISecureTemplate} from '../Interface/ITableSecureTemplate'
import {
    mergeStyleSets,
    getFocusStyle,
    loadTheme,
} from "@fluentui/react/lib/Styling";
import { defaultTheme } from "../Themes/DefaultTheme";
import { Stack } from "@fluentui/react";
import { tableStylesSecure } from "../Styles/Table/Common";
import { useContext, useEffect } from "react";
import { SecureTemplateContext } from "../context/SecureTemplateContext";
import { authProvider } from "../../authProvider";
import { toast } from "react-toastify";
import { _Styles } from "../Page.styles";

const theme = loadTheme(defaultTheme);
const { palette, fonts } = theme;

const classNames = mergeStyleSets({
    itemCell: [
        getFocusStyle(theme, { inset: -1 }),
        {
            minHeight: 54,
            padding: 15,
            marginTop: 24,
            backgroundColor: theme.semanticColors.disabledBackground,
            boxShadow: theme.effects.elevation4,
            boxSizing: "border-box",
            borderLeft: `4px solid ${theme.palette.themePrimary}`,
            display: "flex",
            selectors: {
                "&:hover": { 
                    background: theme.palette.themeLighter, 
                    boxShadow: theme.effects.elevation8,
                    borderColor: theme.palette.themeDarker
                },
            },
        },
    ],
    itemImage: {
        flexGrow: 1,
    },
    itemContent: {
        marginLeft: 10,
        overflow: "hidden",
        flexShrink: 1,
        paddingRight: 30
    },
    itemName: [
        fonts.xLarge,
        {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
    ],
    itemIndex: {
        fontSize: fonts.small.fontSize,
        color: palette.neutralTertiary,
        marginBottom: 10,
    },
    chevron: {
        alignSelf: "center",
        marginLeft: 10,
        color: palette.neutralTertiary,
        fontSize: fonts.large.fontSize,
        flexShrink: 0,
    },
});


                    
export const TableSecureTemplate: React.FunctionComponent = () => {

    const RuntimeConfigurationInitial = window.__UI_CONFIGURATION_INITIAL__;
    const ScannerAPI = RuntimeConfigurationInitial.webAPIConf ? RuntimeConfigurationInitial.webAPIConf["AZURE"] : RuntimeConfigurationInitial.webAPI;

    const [items, setItems] = React.useState([]);
    const [filteredItems, setFilteredItems] = React.useState([]);
    const [isLoaded, setIsLoaded] = React.useState(false);

    const {setSelectedTemplate, toggleTemplatePanel} = useContext(SecureTemplateContext);

    const onRenderCell = (
        item: ISecureTemplate,
        index: number | undefined
    ): JSX.Element => {
        return (
            <Stack horizontal horizontalAlign="space-between" className={classNames.itemCell} data-is-focusable={true}>
                <Stack horizontal tokens={{childrenGap: 10}}>
                    <Stack>
                        <Image
                            className={classNames.itemImage}
                            src={item.imageLink}
                            width={85}
                            height={85}
                            imageFit={ImageFit.contain}
                            role="none"
                        />

                    </Stack>
                    
                    <Stack className={classNames.itemContent}>
                        <div className={classNames.itemName}>{item.name}</div>
                        <div>{item.description}</div>
                    </Stack>
    
                </Stack>
                <Stack verticalAlign="space-between" horizontalAlign="end" >
                    <PrimaryButton 
                        onClick={()=>{
                            setSelectedTemplate(item);
                            toggleTemplatePanel();
                        }} 
                        iconProps={{iconName:"SeeDo"}} 
                        text="Explore" 
                    />
                </Stack>
            </Stack>            
        );
    };

    function GetSecureTemplates(){
        const CallAPI = async () => {
            const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
            
            fetch((ScannerAPI + "/securetemplates/getmetadata"), {
                headers: !authToken.accessToken ? {} : {
                    'Authorization': `Bearer ${authToken.accessToken}`,
                    'u_Id': sessionStorage.getItem("u_Id"),
                    'Content-Type': 'application/json'
                },
                method: 'GET',
            }).then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        setItems(data);
                        setFilteredItems(data);
                        setIsLoaded(true);
                    });
                }
                
                else {
                    toast("This service is not available.")
                }
            });
        }
        CallAPI();
    }
    
    useEffect(() => {
        GetSecureTemplates();
    },
    // eslint-disable-next-line
    []);

    const onFilterChanged = (_: any, text: string): void => {
        setFilteredItems(
            items.filter(
                (item) => item.name.toLowerCase().indexOf(text.toLowerCase()) >= 0
            )
        );
    };
    
    return (
        <div style={tableStylesSecure}>
            {!isLoaded &&
                <Stack>
                    <div className={_Styles.rowGap3} />
                    <div className={_Styles.rowGap3} />
                    <Spinner size={SpinnerSize.large} label="Fetching Secure Templates ..." ariaLive="assertive" labelPosition="right" />
                    <div className={_Styles.rowGap3} />
                    <div className={_Styles.rowGap3} />
                </Stack>
            }
            
            {isLoaded &&
                <Stack>
                    <Stack horizontal horizontalAlign='space-between'>
                        <Text variant="large">
                            Showing <b style={{fontWeight:600}}>{filteredItems.length}</b> templates
                        </Text>
                        <TextField 
                            placeholder="Search on Azure Services" 
                            onChange={onFilterChanged} 
                            iconProps={{iconName:'Search'}} 
                            style={{minWidth: 320}}
                        />
                    </Stack>
                    <List items={filteredItems} onRenderCell={onRenderCell} />
                </Stack>
            }   
        </div>
    );
};
                                    