import { ContextualMenu, FontWeights, IDragOptions, loadTheme, mergeStyleSets } from "@fluentui/react";
import { controlEditorTheme } from "../../Themes/ControlEditorTheme";

const theme = loadTheme(controlEditorTheme)

export const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

export const contentStyles = mergeStyleSets({
  container3: {
    minWidth: 400,
    maxWidth: 500,
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    transition:'0.5s !important'
  },
  container2: {
    minWidth: 700,
    maxWidth: 800,
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    transition:'0.5s !important'
  },
  container4: {
    minWidth: 700,
    maxWidth: 3000,
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    transition:'0.5s !important'
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.themePrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
});

export const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};
