import * as React from 'react';
/* 
  <diff-v 8.14.143: Importing from @fluentui/react/lib/Utilities instead of ../../Utilities>
*/
import { styled } from '@fluentui/react/lib/Utilities';
import { getStyles } from './Pagination.styles';
import { PaginationBase } from './Pagination.base';
import type { IPaginationProps, IPaginationStyleProps, IPaginationStyles } from './Pagination.types';

export const Pagination: React.FunctionComponent<IPaginationProps> = styled<
  IPaginationProps,
  IPaginationStyleProps,
  IPaginationStyles
>(PaginationBase, getStyles, undefined, { scope: 'Pagination' });
