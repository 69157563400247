import * as React from 'react';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { useContext } from 'react';
import { Image, ImageFit } from "@fluentui/react/lib/Image";
import { panelClassNames } from '../Panels/PersonaPanel';
import { DefaultButton, DetailsList, DetailsListLayoutMode, IColumn, IconButton, Link, MessageBar, PrimaryButton, SelectionMode, Stack, Text } from '@fluentui/react';
import { SecureTemplateContext } from '../context/SecureTemplateContext';
import { authProvider } from '../../authProvider';
import { toast } from 'react-toastify';
import { renderItemColumnSecureTemplate } from '../Helper/TableSecureTemplate/renderItemColumn';
import { getThemeForUI } from '../Helper/ThemeLocalStorage';


export const SecureTemplatePanel: React.FunctionComponent = () => {
    const RuntimeConfigurationInitial = window.__UI_CONFIGURATION_INITIAL__;
    const RuntimeConfigurationExtended = window.__UI_CONFIGURATION_EXTENDED_AZURE__;
    const ScannerAPI = RuntimeConfigurationInitial.webAPIConf ? RuntimeConfigurationInitial.webAPIConf["AZURE"] : RuntimeConfigurationInitial.webAPI;

    const theme = getThemeForUI();

    const columns: IColumn[] = [
        {
          key: 'uiControlObject',
          name: 'Control',
          fieldName: 'uiControlObject',
          minWidth: 300,
          isPadded: false,
          maxWidth: 500,
          isResizable: true
        },
        {
            key: 'isSupported',
            name: 'Supported',
            fieldName: 'isSupported',
            minWidth: 40,
            isPadded: false,
            maxWidth: 200,
            isResizable: true
        }
    ];
    

    const {templatePanel, selectedTemplate, toggleTemplatePanel} = useContext(SecureTemplateContext);
       
    const onRenderHeader = React.useCallback(
        () => (
            <Stack style={{marginLeft:25,marginRight:25,marginBottom:25}} horizontal horizontalAlign="space-between">
                <Stack horizontal tokens={{childrenGap: 10}}>
                    <Image
                        src={selectedTemplate.imageLink}
                        width={40}
                        height={40}
                        imageFit={ImageFit.cover}
                        role="none"
                    />
                    <Text variant='xxLarge'>
                        {selectedTemplate.name}
                    </Text>
                        
        
                </Stack>
                <IconButton onClick={toggleTemplatePanel} style={{color:"black"}} iconProps={{iconName:'ChromeClose'}} ariaLabel='Close Button' />
            </Stack>
            
        ),
        // eslint-disable-next-line
        [selectedTemplate],
    );

    // Function to post activity to API whenever user clicks on "Deploy to Azure"
    function PostActivityEvent(resourceType : string){
        const CallAPI = async () => {
            const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
            
            fetch((ScannerAPI + "/securetemplates/postactivity"), {
                headers: !authToken.accessToken ? {} : {
                    'Authorization': `Bearer ${authToken.accessToken}`,
                    'u_Id': sessionStorage.getItem("u_Id"),
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(resourceType)
            }).then(response => {
                if (response.ok) {
                }
                else {
                    response.text().then(errorMsg => {
                        toast(errorMsg.slice(0, 500));
                    });
                }
            });
        }
        CallAPI();
    }

    const onRenderFooterContent = React.useCallback(
        () => (
            <Stack tokens={{childrenGap:20}}>
                <MessageBar>
                    You can learn about ARM templates from  
                    <Link href={RuntimeConfigurationExtended.azureResourceManagerHelpURL} target="_blank" underline>
                     here.
                    </Link>
                </MessageBar>
            
                <Stack horizontal horizontalAlign='space-between'>
                    <Stack horizontal tokens={{childrenGap:20}}>
                        <PrimaryButton 
                            iconProps={{iconName:'AzureLogo'}} 
                            text="Deploy To Azure"
                            onClick={()=>{
                                var link = RuntimeConfigurationExtended.azureAPI + "/#create/Microsoft.Template/uri/" + encodeURIComponent(selectedTemplate.link);
                                PostActivityEvent(selectedTemplate.name)
                                window.open(link,'_blank');
                            }}
                        />
                        <PrimaryButton 
                            iconProps={{iconName:'GitGraph'}} 
                            text="View in GitHub"
                            onClick={()=>{window.open(selectedTemplate.link,'_blank')}}
                        />
                    </Stack>
                    <DefaultButton iconProps={{iconName:'ChromeClose'}} onClick={toggleTemplatePanel} text="Close"/>
                </Stack>
            </Stack>
        ),
        // eslint-disable-next-line
        [selectedTemplate]
    );

    return (
        <div>
            <Panel
                isOpen={templatePanel}
                isLightDismiss
                type={PanelType.custom}
                headerClassName={panelClassNames.headerClass}
                customWidth={'45%'}
                onRenderFooterContent={onRenderFooterContent}
                onRenderHeader={onRenderHeader}
                onDismiss={toggleTemplatePanel}
                hasCloseButton={false}
                headerText="Control Setting"
                isFooterAtBottom={true}
                // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                closeButtonAriaLabel="Close Panel"
            >
            <Stack>
                <Text variant='medium'>
                    {selectedTemplate.description} 
                    <br/>
                    <br/>
                    The following security controls are compliant as per this template:
                    <br/>
                    
                    <DetailsList
                        items={selectedTemplate.controlInfoList}
                        compact={false}
                        columns={columns}
                        onRenderItemColumn={renderItemColumnSecureTemplate}
                        selectionMode={SelectionMode.none}
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                    />
                </Text>
                
                
            </Stack>
            
            
            </Panel>
        </div>
    );
};
