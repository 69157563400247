import React, {
  CSSProperties,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import {
  ComboBox,
  IComboBoxOption,
  IComboBox,
  ActionButton,
  Text,
  VirtualizedComboBox,
  TextField,
  PrimaryButton,
  IOnRenderComboBoxLabelProps,
  IRenderFunction,
  Panel,
  PanelType,
  Spinner,
  SpinnerSize,
  DefaultButton,
  FontIcon,
} from "@fluentui/react";
import { getTheme } from "@fluentui/react";
import { Toggle } from "@fluentui/react/lib/Toggle";
import debounce from "lodash.debounce";
import { FormContext } from "../context/FormContext";
import { TeachingContext } from "../context/TeachingContext";
import { authProvider } from "../../authProvider";
import { useBoolean } from "@fluentui/react-hooks";
import { _Styles } from "../Page.styles";
import { toast } from "react-toastify";
import { ICustomViewConfiguration } from "./../Interface/IUIConfigurationExtendedAzure";
import packageJson from "../../../package.json";
import { ComplianceInitiativeInfo } from "./../InfoButtons/ComplianceInitiativeInfo";
import { ExternalControlInfo } from "./../InfoButtons/ExternalControls";
import { ReactComponent as CatSvg } from "../assets/cat.svg";
import { ReactComponent as NoData } from "../assets/nodata.svg";
import { ReactComponent as Unauthorized } from "../assets/unauthorizedImage.svg";
import { panelClassNames } from "../Panels/PersonaPanel";
import { TenantScannerRoutes } from "./../Interface/IRouteManager";
import { TenantScannerConfiguration } from "./../Interface/IUnificationConfiguration";
import {
  AccessMessage,
  EndPointNotFoundHeader,
  FailedOnlyControlsKey,
  InternalServerErrorHeader,
  InvalidClientAppRequestingDataHeader,
  MGKey,
  NoDataFound,
  NoDataHeader,
  SubscriptionComplianceHeader,
  UnauthorizedHeader,
  WorkItemKey,
} from "../ComplianceInitiativeEditor/Constants";

const theme = getTheme();

const verticalGapStackTokens: IStackTokens = {
  childrenGap: 10,
};

export function onRenderComplianceLabel(
  props: IOnRenderComboBoxLabelProps,
  _defaultRender?: IRenderFunction<IOnRenderComboBoxLabelProps>
) {
  if (!props) {
    return null;
  }

  return (
    <Stack horizontal verticalAlign="center">
      <Text style={{ fontWeight: 600 }}>Compliance Initiative</Text>
      <ComplianceInitiativeInfo />
    </Stack>
  );
}

export interface FilterProps {
  baselineType: string;
  admin: string;
}

export const Filter: React.FunctionComponent<FilterProps> = (props) => {
  const RuntimeConfigurationInitial = window.__UI_CONFIGURATION_INITIAL__;
  const RuntimeConfigurationExtended =
    window.__UI_CONFIGURATION_EXTENDED_AZURE__;
  const ScannerAPI = RuntimeConfigurationInitial.webAPIConf
    ? RuntimeConfigurationInitial.webAPIConf["AZURE"]
    : RuntimeConfigurationInitial.webAPI;

  const { toggleTeachingBubbleVisibleInitial } = useContext(TeachingContext);

  const onChangeUserImpersonation = React.useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      sessionStorage.setItem("u_Id", newValue);
    },
    []
  );

  const [FilterPanelVisible, setFilterPanelVisible] =
    React.useState<boolean>(false);

  const [FilterOptions, setFilterOptions] = React.useState({});
  const [ParentOptions, setParentOptions] = React.useState<IComboBoxOption[]>(
    []
  );
  const [ManagementGroupOptions, setManagementGroupOptions] = React.useState<
    IComboBoxOption[]
  >([]);
  const [WorkItemOptions, setWorkItemOptions] = React.useState<
    IComboBoxOption[]
  >([]);
  const [ControlOptions, setControlOptions] = React.useState<IComboBoxOption[]>(
    []
  );

  const [workItemInput, setWorkItemInput] = React.useState<string>("");
  const [ControlBaselineOptions, setControlBaselineOptions] = React.useState<
    IComboBoxOption[]
  >([]);
  const [ComplianceInitiativeOptions, setComplianceInitiativeOptions] =
    React.useState<IComboBoxOption[]>([]);

  const [FilterAll, setFilterAll] = React.useState({});
  const [ParentAll, setParentAll] = React.useState<string[]>([]);
  const [WorkItemAll, setWorkItemAll] = React.useState<string[]>([]);
  const [ManagementGroupAll, setManagementGroupAll] = React.useState<string[]>(
    []
  );
  const [ControlAll, setControlAll] = React.useState<string[]>([]);
  const [ControlBaselineAll, setControlBaselineAll] = React.useState<string[]>(
    []
  );

  const [Filter, setFilter] = React.useState({});
  const [Parent, setParent] = React.useState<string[]>([]);
  const [ManagementGroup, setManagementGroup] = React.useState<string[]>([]);
  const [WorkItem, setWorkItem] = React.useState<string[]>([]);
  const [Control, setControl] = React.useState<string[]>([]);
  const [ComplianceInitiative, setComplianceInitiative] =
    React.useState<string>("");
  const [ComplianceInitiativeEnabled, setComplianceInitiativeEnabled] =
    React.useState<boolean>(false);
  const [Is_Baseline, setIs_Baseline] = React.useState<string>(
    props.baselineType
      ? props.baselineType
      : RuntimeConfigurationExtended.defaultBaseline
  );
  const [Failed_Only, setFailed_Only] = React.useState<boolean>(false);
  const [LoadingFilter, setLoadingFilter] = React.useState<boolean>(false);

  const [FilterLength, setFilterLength] = React.useState({});

  const [changed, setChanged] = React.useState<string>("");
  const [currentTime, setCurrentTime] = React.useState<number>(0);
  const [lastTime, setLastTime] = React.useState<number>(0);
  const [changedAll, { toggle: toggleChangedAll }] = useBoolean(true);
  const [changedControls, { toggle: setChangedControls }] = useBoolean(true);

  const [customView, setCustomView] =
    React.useState<ICustomViewConfiguration>(null);
  const [filterFetchError, setFilterFetchError] =
    React.useState<boolean>(false);

  const {
    setFailed,
    changeFormData,
    toggleVisible,
    visible,
    setExcludeExternalControlsContext,
    setErrorMessageToDisplay,
    toggleShowErrorMessage,
    setErrorHeaderToDisplay,
    setErrorResponse,
    errorHeaderToDisplay,
    errorResponse,
    errorMessageToDisplay,
    showErrorMessage,
  } = useContext(FormContext);

  const stackFilterWorkItemFilter: CSSProperties = {
    boxShadow: theme.effects.elevation8,
    padding: 15,
    backgroundColor: theme.palette.neutralLighter,
  };

  const onChangeParent = (
    ev: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ): void => {
    if (option.key === "Select All") {
      if (option.selected) {
        setParent([...ParentAll, option.key as string]);
      } else {
        setParent([]);
      }
    } else {
      setParent(
        option.selected
          ? [...Parent, option.key as string]
          : Parent.filter((key) => key !== option.key)
      );
    }
    setChanged("tenant");
    setCurrentTime(Date.now());
  };
  const onChangeMG = (
    ev: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ): void => {
    var allFilterDataLength = FilterLength;
    var specificFilterLength = allFilterDataLength["ManagementGroup"];

    if (option.key === "Select All") {
      if (option.selected) {
        setManagementGroup([...ManagementGroupAll, option.key as string]);
        specificFilterLength = ManagementGroupAll.length;
      } else {
        setManagementGroup([]);
        specificFilterLength = 0;
      }
    } else {
      setManagementGroup(
        option.selected
          ? [...ManagementGroup, option.key as string]
          : ManagementGroup.filter((key) => key !== option.key)
      );
      specificFilterLength = option.selected
        ? specificFilterLength + 1
        : specificFilterLength - 1;
    }

    allFilterDataLength["ManagementGroup"] = specificFilterLength;
    setFilterLength(allFilterDataLength);
    setChanged("managementGroup");
    setCurrentTime(Date.now());
  };
  const onChangeFilter = (
    ev: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ): void => {
    var element = ev.target as HTMLInputElement;
    var filterName = element.id.split("-")[0];
    var allFilterData = Filter;
    var allFilterDataLength = FilterLength;
    var specificFilter = allFilterData[filterName];
    var specificFilterLength = allFilterDataLength[filterName];

    if (option.key === "Select All") {
      if (option.selected) {
        specificFilter = [...FilterAll[filterName], option.key as string];
        specificFilterLength = FilterAll[filterName].length;
      } else {
        specificFilter = [];
        specificFilterLength = 0;
      }
    } else {
      if (option.selected) {
        specificFilter = [...specificFilter, option.key as string];
        specificFilterLength += 1;
      } else {
        specificFilter = specificFilter.filter((key) => key !== option.key);
        specificFilterLength -= 1;
      }
    }

    allFilterData[filterName] = specificFilter;
    allFilterDataLength[filterName] = specificFilterLength;
    setFilter(allFilterData);
    setFilterLength(allFilterDataLength);
    setChanged(filterName.toLowerCase());
    setCurrentTime(Date.now());
  };

  const onInputValueChange = (item: string) => {
    setWorkItemInput(item);
  };

  const onChangeWorkItem = (
    ev: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ): void => {
    var allFilterDataLength = FilterLength;
    var specificFilterLength = allFilterDataLength["WorkItem"];

    if (option.key === "Select All") {
      if (option.selected) {
        setWorkItem([...WorkItemAll, option.key as string]);
        specificFilterLength = WorkItemAll.length;
      } else {
        setWorkItem([]);
        specificFilterLength = 0;
      }
    } else {
      setWorkItem(
        option.selected
          ? [...WorkItem, option.key as string]
          : WorkItem.filter((key) => key !== option.key)
      );
      specificFilterLength = option.selected
        ? specificFilterLength + 1
        : specificFilterLength - 1;
    }

    allFilterDataLength["WorkItem"] = specificFilterLength;
    setFilterLength(allFilterDataLength);
    setChanged("workitem");
    setCurrentTime(Date.now());
    if (workItemInput) {
      toggleChangedAll();
      setWorkItemInput("");
    }
  };

  const onChangeControl = (
    ev: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ): void => {
    if (option.key === "Select All") {
      if (option.selected) {
        setControl(
          Is_Baseline === "false"
            ? [...ControlAll, option.key as string]
            : [...ControlBaselineAll, option.key as string]
        );
      } else {
        setControl([]);
      }
    } else {
      setControl(
        option.selected
          ? [...Control, option.key as string]
          : Control.filter((key) => key !== option.key)
      );
    }
  };

  const onChangeComplianceInitiative = (
    ev: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ): void => {
    setComplianceInitiative(option.key as string);
    setChanged("complianceInitiative");
    setCurrentTime(Date.now());
  };

  const onChangeBaseline = (
    event: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ): void => {
    setIs_Baseline(
      checked
        ? props.baselineType
          ? props.baselineType
          : RuntimeConfigurationExtended.defaultBaseline
        : "false"
    );
    setControl(checked ? ControlBaselineAll : ControlAll);
    setCurrentTime(Date.now());
  };

  const onChangeFailed = (
    event: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ): void => {
    setFailed_Only(checked ? true : false);
    setCurrentTime(Date.now());
    setFailed(checked ? true : false);
    setFailedOnlyInLocalStorage(checked ? true : false);
  };

  const onChangeExcludedControls = (
    event: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ): void => {
    setExcludeExternalControlsContext(!checked);
  };

  const combo_template: IComboBoxOption[] = [
    {
      key: "Select All",
      text: "Select All",
      styles: {
        optionText: {
          color: theme.palette.themePrimary,
          fontWeight: 600,
          fontSize: "110%",
        },
      },
    },
  ];

  // Set selected subscription in local storage to save user preferences.
  function setWorkItemInLocalStorage(WorkItems: string[]) {
    localStorage.setItem(WorkItemKey, JSON.stringify(WorkItems));
  }

  // Set Failed_Only in local storage to save user preferences.
  function setFailedOnlyInLocalStorage(failedOnlyPreference: boolean) {
    localStorage.setItem(
      FailedOnlyControlsKey,
      JSON.stringify(failedOnlyPreference)
    );
  }

  // Set selected subscription in local storage to save user preferences.
  function setmgInLocalStorage(ManagementGroup: string[]) {
    localStorage.setItem(MGKey, JSON.stringify(ManagementGroup));
  }

  // Fill filter data on initial load.
  const fillFilter = (dataString: string) => {
    var data = JSON.parse(dataString);

    var baselineControls: string[] = [];
    var allControls: string[] = [];

    for (var i = 0; i < data.control_id.length; i++) {
      if (data.control_id[i].isBaseline) {
        baselineControls = baselineControls.concat(
          data.control_id[i].controlName
        );
      }
      allControls = allControls.concat(data.control_id[i].controlName);
    }

    // Set the complete data that is available for particular user.
    // This data is stored separately so that whenever filtering happens, the complete data is always available.
    var filterAll = {};
    setParentAll(data.tenant);
    setWorkItemAll(data.subscription);
    setManagementGroupAll(data.managementGroup);
    setControlAll(allControls);
    setControlBaselineAll(baselineControls);
    setComplianceInitiativeEnabled(data.enableComplianceInitiativeOption);

    filterAll["WorkItem"] = data.subscription;
    filterAll["ManagementGroup"] = data.managementGroup;
    filterAll["Division"] = data.division;
    filterAll["Organization"] = data.organization;
    filterAll["ServiceGroup"] = data.service_group;
    filterAll["TeamGroup"] = data.team_group;
    filterAll["Service"] = data.service;
    setFilterAll(filterAll);

    var workItemProcessed = [];
    var mgProcessed = [];
    var failedOnlyProcessed = false;

    if (!visible) {
      setParent(data.tenant);
      setControl(baselineControls);

      var filter = {};
      filter["Division"] = data.division;
      filter["Organization"] = data.organization;
      filter["ServiceGroup"] = data.service_group;
      filter["TeamGroup"] = data.team_group;
      filter["Service"] = data.service;
      setFilter(filter);

      var filterLength = {};
      filterLength["Division"] = data.division.length;
      filterLength["Organization"] = data.organization.length;
      filterLength["ServiceGroup"] = data.service_group.length;
      filterLength["TeamGroup"] = data.team_group.length;
      filterLength["Service"] = data.service.length;

      try {
        // Get data from localStorage
        var mgFromLocalStorage = JSON.parse(
          localStorage.getItem("Filters_ManagementGroup")
        );

        // Get intersection of workitems stored in local and ones fetched from API.
        mgProcessed = mgFromLocalStorage.filter((value) =>
          data.managementGroup.includes(value)
        );
        if (mgProcessed == null || mgProcessed.length <= 0) {
          mgProcessed = data.managementGroup;
        }
      } catch {
        mgProcessed = data.managementGroup;
      }

      try {
        // Get data from localStorage
        var workItemFromLocalStorage = JSON.parse(
          localStorage.getItem("Filters_WorkItem")
        );

        // Get intersection of workitems stored in local and ones fetched from API.
        workItemProcessed = workItemFromLocalStorage.filter((value) =>
          data.subscription.includes(value)
        );
        if (workItemProcessed == null || workItemProcessed.length <= 0) {
          workItemProcessed = data.subscription;
        }
      } catch {
        workItemProcessed = data.subscription;
      }

      try {
        var Failed_OnlyFromLocalStorage = JSON.parse(
          localStorage.getItem("Filters_Failed_Only")
        );
        if (Failed_OnlyFromLocalStorage === true) {
          failedOnlyProcessed = true;
        }
      } catch {
        console.log("Could not find previous preference in localStorage.");
      }

      setManagementGroup(mgProcessed);
      filterLength["ManagementGroup"] = mgProcessed.length;
      setWorkItem(workItemProcessed);
      filterLength["WorkItem"] = workItemProcessed.length;
      setFailed_Only(failedOnlyProcessed);
      setFilterLength(filterLength);
    }

    changeFormData(
      workItemProcessed,
      baselineControls,
      Is_Baseline,
      data.defaultComplianceInitiativeId,
      failedOnlyProcessed
    );

    var filterOptions = {};

    var emptyOptionsList: IComboBoxOption[] = [];
    for (var i = 0; i < data.complianceInitiativeOptions.length; i++) {
      emptyOptionsList = emptyOptionsList.concat({
        key: data.complianceInitiativeOptions[i],
        text: data.complianceInitiativeOptions[i],
      });
    }

    setComplianceInitiative(data.defaultComplianceInitiativeId);
    setComplianceInitiativeOptions(emptyOptionsList);

    var new_items = [...combo_template];
    // eslint-disable-next-line

    if (RuntimeConfigurationExtended.isMultiTenantSetup) {
      for (var i = 0; i < data.tenantList.length; i++) {
        new_items = new_items.concat({
          key: data.tenantList[i].tenantId,
          text:
            data.tenantList[i].tenantName +
            " (" +
            data.tenantList[i].tenantId +
            ")",
        });
      }
    } else {
      for (var i = 0; i < data.tenant.length; i++) {
        new_items = new_items.concat({
          key: data.tenant[i],
          text: data.tenant[i],
        });
      }
    }

    setParentOptions(new_items);
    filterOptions["Parent"] = new_items;

    var new_items = [...combo_template];
    // eslint-disable-next-line
    for (var i = 0; i < data.division.length; i++) {
      new_items = new_items.concat({
        key: data.division[i],
        text: data.division[i],
      });
    }
    filterOptions["Division"] = new_items;

    new_items = [...combo_template];
    // eslint-disable-next-line
    for (var i = 0; i < data.organization.length; i++) {
      new_items = new_items.concat({
        key: data.organization[i],
        text: data.organization[i],
      });
    }
    filterOptions["Organization"] = new_items;

    new_items = [...combo_template];
    // eslint-disable-next-line
    for (var i = 0; i < data.service_group.length; i++) {
      new_items = new_items.concat({
        key: data.service_group[i],
        text: data.service_group[i],
      });
    }
    filterOptions["ServiceGroup"] = new_items;

    new_items = [...combo_template];
    // eslint-disable-next-line
    for (var i = 0; i < data.service.length; i++) {
      new_items = new_items.concat({
        key: data.service[i],
        text: data.service[i],
      });
    }
    filterOptions["Service"] = new_items;

    new_items = [...combo_template];
    // eslint-disable-next-line
    for (var i = 0; i < data.team_group.length; i++) {
      new_items = new_items.concat({
        key: data.team_group[i],
        text: data.team_group[i],
      });
    }
    filterOptions["TeamGroup"] = new_items;

    new_items = [...combo_template];
    // eslint-disable-next-line
    for (var i = 0; i < data.subscriptionList.length; i++) {
      new_items = new_items.concat({
        key: data.subscriptionList[i].subscriptionId,
        text:
          data.subscriptionList[i].subscriptionDisplayName +
          " (" +
          data.subscriptionList[i].subscriptionId +
          ")",
      });
    }
    setWorkItemOptions(new_items);
    filterOptions["WorkItem"] = new_items;

    new_items = [...combo_template];
    // eslint-disable-next-line
    for (var i = 0; i < data.managementGroupList.length; i++) {
      new_items = new_items.concat({
        key: data.managementGroupList[i].managementGroupId,
        text:
          data.managementGroupList[i].managementGroupName +
          " (" +
          data.managementGroupList[i].managementGroupId +
          ")",
      });
    }
    setManagementGroupOptions(new_items);
    filterOptions["ManagementGroup"] = new_items;

    var baselineControlsCombo = [...combo_template];
    var allControlsCombo = [...combo_template];

    // eslint-disable-next-line
    for (var i = 0; i < data.control_id.length; i++) {
      if (data.control_id[i].isBaseline) {
        baselineControlsCombo = baselineControlsCombo.concat({
          key: data.control_id[i].controlName,
          text: data.control_id[i].controlDisplayName,
        });
      }
      allControlsCombo = allControlsCombo.concat({
        key: data.control_id[i].controlName,
        text: data.control_id[i].controlDisplayName,
      });
    }

    setFilterOptions(filterOptions);
    setControlBaselineOptions(baselineControlsCombo);
    setControlOptions(allControlsCombo);
    toggleVisible(true);
    setLoadingFilter(false);
  };

  // Update filter data on subsequent selection/filtering
  const updateFilter = (
    dataString: string,
    Control: string[],
    Is_Baseline: string,
    ComplianceInitiative: string,
    Failed_Only: boolean
  ) => {
    var data = JSON.parse(dataString);
    var baselineControls: string[] = [];
    var allControls: string[] = [];

    for (var i = 0; i < data.control_id.length; i++) {
      if (data.control_id[i].isBaseline) {
        baselineControls = baselineControls.concat(
          data.control_id[i].controlName
        );
      }
      allControls = allControls.concat(data.control_id[i].controlName);
    }

    var baselineControlsCombo = [...combo_template];
    var allControlsCombo = [...combo_template];

    // eslint-disable-next-line
    for (var i = 0; i < data.control_id.length; i++) {
      if (data.control_id[i].isBaseline) {
        baselineControlsCombo = baselineControlsCombo.concat({
          key: data.control_id[i].controlName,
          text: data.control_id[i].controlDisplayName,
        });
      }
      allControlsCombo = allControlsCombo.concat({
        key: data.control_id[i].controlName,
        text: data.control_id[i].controlDisplayName,
      });
    }

    setParent(data.tenant);

    var filter = {};
    filter["Division"] = data.division;
    filter["Organization"] = data.organization;
    filter["ServiceGroup"] = data.service_group;
    filter["TeamGroup"] = data.team_group;
    filter["Service"] = data.service;
    setFilter(filter);

    var filterLength = {};
    filterLength["Division"] = data.division.length;
    filterLength["Organization"] = data.organization.length;
    filterLength["ServiceGroup"] = data.service_group.length;
    filterLength["TeamGroup"] = data.team_group.length;
    filterLength["Service"] = data.service.length;
    filterLength["WorkItem"] = data.subscription.length;
    filterLength["ManagementGroup"] = data.managementGroup.length;
    setFilterLength(filterLength);

    setWorkItem(data.subscription);
    setManagementGroup(data.managementGroup);
    setControl(Is_Baseline === "false" ? allControls : baselineControls);
    setControlBaselineAll(baselineControls);
    setControlBaselineOptions(baselineControlsCombo);
    setControlOptions(allControlsCombo);

    changeFormData(
      data.subscription,
      Is_Baseline === "false" ? allControls : baselineControls,
      Is_Baseline,
      ComplianceInitiative,
      Failed_Only
    );

    setmgInLocalStorage(data.managementGroup);
    setWorkItemInLocalStorage(data.subscription);
    setLoadingFilter(false);
  };

  // eslint-disable-next-line
  const onChangeControlData = useCallback(
    debounce(
      (WorkItem, Control, Is_Baseline, ComplianceInitiative, Failed_Only) => {
        changeFormData(
          WorkItem,
          Control,
          Is_Baseline,
          ComplianceInitiative,
          Failed_Only
        );
      },
      500
    ),
    // eslint-disable-next-line
    []
  );

  // Called on initial load of page, fetches initial data.
  const GetMetadata = async () => {
    setLoadingFilter(true);
    var userSelectedSubs: string[] = [];
    const authToken = await authProvider.getAccessToken({
      scopes: [
        "api://" +
          RuntimeConfigurationInitial.apiClientId +
          "/user_impersonation",
      ],
    });
    var baselineOnly = Is_Baseline;
    if (JSON.parse(localStorage.getItem("Filters_WorkItem")) != null) {
      userSelectedSubs = JSON.parse(localStorage.getItem("Filters_WorkItem"));
    }
    var userSelectedSubscriptions = {
      SelectedSubscriptionIds: userSelectedSubs,
    };
    fetch(
      ScannerAPI + TenantScannerRoutes.FetchMetadata + (baselineOnly + "/"),
      {
        headers: !authToken.accessToken
          ? {}
          : {
              Authorization: `Bearer ${authToken.accessToken}`,
              u_Id: sessionStorage.getItem("u_Id"),
              "Content-Type": "application/json",
              WebAppVersion: packageJson.version,
            },
        method: "POST",
        body: JSON.stringify(userSelectedSubscriptions),
      }
    ).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          fillFilter(JSON.stringify(data));
        });
      } else {
        response.text().then((errorMsg) => {
          setLoadingFilter(false);
          setErrorMessageToDisplay(errorMsg.slice(0, 500));
          setErrorResponse(response.status.toString());
          setFilterFetchError(true);
        });
      }
    });
  };

  // eslint-disable-next-line
  const onChangeWorkItemAndRelevantData = useCallback(
    debounce(
      (
        Parent,
        Filter,
        WorkItem,
        ManagementGroup,
        Control,
        Is_Baseline,
        ComplianceInitiative,
        Failed_Only,
        changed
      ) => {
        setLoadingFilter(true);
        const UpdateMetadata = async () => {
          const authToken = await authProvider.getAccessToken({
            scopes: [
              "api://" +
                RuntimeConfigurationInitial.apiClientId +
                "/user_impersonation",
            ],
          });

          let body = {
            tenant: Parent,
            division: Filter["Division"],
            organization: Filter["Organization"],
            service_Group: Filter["ServiceGroup"],
            service: Filter["Service"],
            team_group: Filter["TeamGroup"],
            subscription: WorkItem,
            ManagementGroup: ManagementGroup,
            field: changed,
            DefaultComplianceInitiativeId: ComplianceInitiative,
          };

          fetch(
            ScannerAPI +
              TenantScannerRoutes.FetchFilterMetadata +
              (Is_Baseline + "/"),
            {
              headers: !authToken.accessToken
                ? {}
                : {
                    Authorization: `Bearer ${authToken.accessToken}`,
                    u_Id: sessionStorage.getItem("u_Id"),
                    "Content-Type": "application/json",
                    WebAppVersion: packageJson.version,
                  },
              method: "POST",
              body: JSON.stringify(body),
            }
          ).then((response) => {
            if (response.ok) {
              response.json().then((data) => {
                updateFilter(
                  JSON.stringify(data),
                  Control,
                  Is_Baseline,
                  ComplianceInitiative,
                  Failed_Only
                );
              });
            } else {
              response.text().then((errorMsg) => {
                setErrorMessageToDisplay(errorMsg.slice(0, 500));
                setErrorResponse(response.status.toString());
                setFilterFetchError(true);
                toast(errorMsg.slice(0, 500));
              });
            }
          });
        };
        UpdateMetadata();
      },
      500
    ),
    []
  );

  // Triggered on initial load of the page.
  useEffect(
    () => {
      GetMetadata();
      setCustomView(
        RuntimeConfigurationExtended.customViewConfigurations?.find(
          (customView) => customView.name === props.baselineType
        )
      );
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (errorResponse === "401") {
        setErrorHeaderToDisplay(UnauthorizedHeader);
      }
      if (errorResponse === "403") {
        setErrorHeaderToDisplay(InvalidClientAppRequestingDataHeader);
      }
      if (errorResponse === "404") {
        if (errorMessageToDisplay.includes(AccessMessage)) {
          setErrorHeaderToDisplay(UnauthorizedHeader);
        } else if (errorMessageToDisplay.includes(EndPointNotFoundHeader)) {
          setErrorHeaderToDisplay(EndPointNotFoundHeader);
        } else if (errorMessageToDisplay.includes(NoDataHeader)) {
          setErrorHeaderToDisplay(NoDataHeader);
        } else if (errorMessageToDisplay.includes(NoDataFound)) {
          setErrorHeaderToDisplay(SubscriptionComplianceHeader);
        }
      }
      if (errorResponse === "500") {
        setErrorHeaderToDisplay(InternalServerErrorHeader);
      }
    },
    // eslint-disable-next-line
    [errorResponse]
  );

  // Called when workitem or service tree filter is changed.
  // Separate useEffect for this because the filter data need to be reconciled.
  useEffect(
    () => {
      if (visible && currentTime !== lastTime) {
        setLastTime(currentTime);
        onChangeWorkItemAndRelevantData(
          Parent,
          Filter,
          WorkItem,
          ManagementGroup,
          Control,
          Is_Baseline,
          ComplianceInitiative,
          Failed_Only,
          changed
        );
      }
    },
    // eslint-disable-next-line
    [changedAll, Is_Baseline]
  );

  // Called when control filter is changed.
  useEffect(
    () => {
      if (visible) {
        onChangeControlData(
          WorkItem,
          Control,
          Is_Baseline,
          ComplianceInitiative,
          Failed_Only
        );
      }
    },
    // eslint-disable-next-line
    [changedControls]
  );

  const onRenderFooterContent = React.useCallback(
    () => (
      <Stack horizontal reversed horizontalAlign="space-between">
        <Stack horizontal tokens={{ childrenGap: 15 }}>
          {/* <PrimaryButton 
                color={theme.palette.themePrimary}
                iconProps={{iconName:'Refresh'}}
                aria-label="Reset Filters"
                >
                    Reset Filters
                </PrimaryButton> */}
          <DefaultButton
            iconProps={{ iconName: "ChromeClose" }}
            onClick={() => {
              setFilterPanelVisible(false);
            }}
          >
            Close
          </DefaultButton>
        </Stack>

        <Stack>
          <Spinner
            label="Fetching filter data..."
            ariaLive="assertive"
            labelPosition="right"
            style={{ visibility: LoadingFilter ? "visible" : "hidden" }}
          />
        </Stack>
      </Stack>
    ),
    // eslint-disable-next-line
    [LoadingFilter]
  );

  return (
    <Stack>
      <div className={_Styles.rowGap} />
      <Stack style={{ marginLeft: 15, marginTop: 15 }}>
        <Stack horizontal horizontalAlign="space-between">
          <Stack horizontal tokens={{ childrenGap: 15 }}>
            <FontIcon
              aria-label="AzureLogo"
              iconName={TenantScannerConfiguration.ScannerIcon}
              style={{
                fontSize: "400%",
                color: theme.palette.themePrimary,
                marginTop: -10,
              }}
            />
            <Stack>
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <h4 id="initial-info">
                  {TenantScannerConfiguration.ScannerTitle}
                </h4>
                <ActionButton
                  style={{ outline: "none" }}
                  onClick={toggleTeachingBubbleVisibleInitial}
                  iconProps={{ iconName: "Glasses" }}
                  aria-label="Take a tour"
                >
                  Take a tour
                </ActionButton>
              </Stack>
              <Text variant="medium">
                {TenantScannerConfiguration.ScannerDescription}
              </Text>
            </Stack>
          </Stack>
          {!filterFetchError && (
            <PrimaryButton
              iconProps={{ iconName: "FilterSettings" }}
              text="Filters"
              style={{
                outline: "none",
                boxShadow: theme.effects.elevation4,
                marginRight: 15,
              }}
              id="filter-info"
              onClick={() => {
                setFilterPanelVisible(true);
              }}
            />
          )}
        </Stack>
        {RuntimeConfigurationExtended.userImpersonationFeatureConfiguration
          ?.isEnabled === true &&
          RuntimeConfigurationExtended.userImpersonationFeatureConfiguration
            ?.endpoint?.length > 0 &&
          RuntimeConfigurationExtended.userImpersonationFeatureConfiguration?.endpoint?.toString() !==
            "/" &&
          RuntimeConfigurationExtended.userImpersonationFeatureConfiguration
            ?.endpoint ===
            "/" + props.admin && (
            <Stack horizontal tokens={{ childrenGap: 15 }} verticalAlign="end">
              <TextField
                label="Add alias email-ID for user impersonation"
                styles={{ fieldGroup: { width: 300 } }}
                iconProps={{ iconName: "Search" }}
                onChange={debounce(onChangeUserImpersonation, 500)}
              />
              <PrimaryButton
                text="Confirm"
                onClick={() => {
                  window.location.reload();
                }}
              />
              {sessionStorage.getItem("u_Id") && (
                <b style={{ fontWeight: 600 }}>
                  Impersonating: <br /> {sessionStorage.getItem("u_Id")}
                </b>
              )}
            </Stack>
          )}

        {!visible && !filterFetchError && (
          <Stack>
            <div className={_Styles.rowGap3} />
            <div className={_Styles.rowGap3} />
            <Spinner
              size={SpinnerSize.large}
              label="Fetching filter data ..."
              ariaLive="assertive"
              labelPosition="right"
            />
          </Stack>
        )}
        {filterFetchError && (
          <Stack verticalAlign="center" tokens={verticalGapStackTokens}>
            <div className={_Styles.rowGap} />
            <div className={_Styles.rowGap} />
            <div className={_Styles.rowGap} />
            <div className={_Styles.topMargin} />

            <Stack.Item align="center">
              {errorResponse === "401" && <Unauthorized />}

              {errorResponse === "403" && <Unauthorized />}

              {errorResponse === "404" &&
                errorHeaderToDisplay.includes(UnauthorizedHeader) && (
                  <Unauthorized />
                )}
              {errorResponse === "404" &&
                !errorHeaderToDisplay.includes(UnauthorizedHeader) && (
                  <NoData />
                )}
              {errorResponse === "500" && <CatSvg />}
            </Stack.Item>
            <div className={_Styles.rowGap} />
            <div className={_Styles.rowGap} />

            <Stack.Item className={_Styles.fontSize} align="center">
              <b>{errorHeaderToDisplay}</b>
            </Stack.Item>
            <Stack.Item align="center">{errorMessageToDisplay}</Stack.Item>
          </Stack>
        )}
      </Stack>

      <Panel
        isOpen={FilterPanelVisible}
        isLightDismiss
        type={PanelType.custom}
        headerClassName={panelClassNames.headerClass}
        customWidth={"45%"}
        onDismiss={() => {
          setFilterPanelVisible(false);
        }}
        hasCloseButton={true}
        headerText="Filters"
        isFooterAtBottom={true}
        onRenderFooterContent={onRenderFooterContent}
        styles={{
          main: {
            overflow: "hidden",
          },
        }}
        closeButtonAriaLabel="Close Filters"
      >
        <Stack tokens={{ childrenGap: 25 }}>
          {RuntimeConfigurationExtended.isMultiTenantSetup && (
            <Stack style={stackFilterWorkItemFilter}>
              <Stack horizontal>
                <h5>{TenantScannerConfiguration.ParentItemName} Filter</h5>
              </Stack>
              <Stack tokens={{ childrenGap: 15 }}>
                <ComboBox
                  id="division-label"
                  label={
                    TenantScannerConfiguration.ParentItemName +
                    " (" +
                    Parent.length +
                    " selected)"
                  }
                  selectedKey={Parent}
                  onChange={onChangeParent}
                  multiSelect
                  onMenuDismissed={toggleChangedAll}
                  autoComplete="on"
                  options={ParentOptions}
                  allowFreeform={true}
                  style={{ width: "100%" }}
                />
              </Stack>
            </Stack>
          )}

          {RuntimeConfigurationExtended.isServiceTreeDataAvailable && (
            <Stack style={stackFilterWorkItemFilter}>
              <Stack horizontal>
                <h5>Service Filter</h5>
              </Stack>
              <Stack tokens={{ childrenGap: 15 }}>
                <ComboBox
                  id="Division"
                  label={"Division (" + FilterLength["Division"] + " selected)"}
                  selectedKey={Filter["Division"]}
                  onChange={onChangeFilter}
                  multiSelect
                  onMenuDismissed={toggleChangedAll}
                  autoComplete="on"
                  options={FilterOptions["Division"]}
                  allowFreeform={true}
                  style={{ width: "100%" }}
                />
                <ComboBox
                  id="Organization"
                  label={
                    "Organization (" +
                    FilterLength["Organization"] +
                    " selected)"
                  }
                  selectedKey={Filter["Organization"]}
                  onChange={onChangeFilter}
                  multiSelect
                  autoComplete="on"
                  options={FilterOptions["Organization"]}
                  onMenuDismissed={toggleChangedAll}
                  allowFreeform={true}
                  style={{ width: "100%" }}
                />
                <ComboBox
                  id="ServiceGroup"
                  label={
                    "Service Group (" +
                    FilterLength["ServiceGroup"] +
                    " selected)"
                  }
                  selectedKey={Filter["ServiceGroup"]}
                  onChange={onChangeFilter}
                  multiSelect
                  autoComplete="on"
                  allowFreeform={true}
                  onMenuDismissed={toggleChangedAll}
                  options={FilterOptions["ServiceGroup"]}
                  style={{ width: "100%" }}
                />
                <ComboBox
                  id="TeamGroup"
                  label={
                    "Team Group (" + FilterLength["TeamGroup"] + " selected)"
                  }
                  selectedKey={Filter["TeamGroup"]}
                  onChange={onChangeFilter}
                  multiSelect
                  autoComplete="on"
                  allowFreeform={true}
                  options={FilterOptions["TeamGroup"]}
                  onMenuDismissed={toggleChangedAll}
                  style={{ width: "100%" }}
                />
                <ComboBox
                  id="Service"
                  label={"Service (" + FilterLength["Service"] + " selected)"}
                  selectedKey={Filter["Service"]}
                  onChange={onChangeFilter}
                  multiSelect
                  autoComplete="on"
                  allowFreeform={true}
                  options={FilterOptions["Service"]}
                  onMenuDismissed={toggleChangedAll}
                  style={{ width: "100%" }}
                />
              </Stack>
            </Stack>
          )}
          {RuntimeConfigurationExtended.isManagementGroupFilterEnabled && (
            <Stack style={stackFilterWorkItemFilter}>
              <Stack horizontal>
                <h5> Management Group Filter</h5>
              </Stack>

              <ComboBox
                label={
                  "Management Group (" +
                  FilterLength["ManagementGroup"] +
                  " selected)"
                }
                selectedKey={ManagementGroup}
                onChange={onChangeMG}
                multiSelect
                id="ManagementGroup"
                autoComplete="on"
                onMenuDismissed={toggleChangedAll}
                options={ManagementGroupOptions}
                allowFreeform={true}
                useComboBoxAsMenuWidth={true}
              />
            </Stack>
          )}

          <Stack style={stackFilterWorkItemFilter}>
            <Stack horizontal>
              <h5>{TenantScannerConfiguration.WorkItemName} Filter</h5>
            </Stack>

            <VirtualizedComboBox
              label={
                TenantScannerConfiguration.WorkItemName +
                " (" +
                FilterLength["WorkItem"] +
                " selected)"
              }
              selectedKey={WorkItem}
              onChange={onChangeWorkItem}
              multiSelect
              id="WorkItem"
              autoComplete="on"
              onMenuDismissed={toggleChangedAll}
              options={WorkItemOptions}
              allowFreeform={true}
              useComboBoxAsMenuWidth={true}
              onInputValueChange={onInputValueChange}
              allowFreeInput={true}
              onMenuOpen={() => {
                setWorkItemInput("");
              }}
              persistMenu={true}
            />
          </Stack>

          <Stack style={stackFilterWorkItemFilter}>
            <Stack horizontal>
              <h5>Control Filter</h5>
            </Stack>
            <Stack>
              <Stack.Item>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                  <Toggle
                    label={
                      <Stack
                        horizontal
                        verticalAlign="center"
                        style={{ paddingTop: 5, paddingBottom: 5 }}
                      >
                        <Text style={{ fontWeight: 600 }}>
                          {customView?.baselineControlFilterLabel?.length > 0
                            ? customView?.baselineControlFilterLabel
                            : "Baseline Only"}
                        </Text>
                      </Stack>
                    }
                    disabled={
                      customView?.isBaselineControlFilterToggleEnabled === false
                        ? true
                        : false
                    }
                    onChange={onChangeBaseline}
                    checked={Is_Baseline === "false" ? false : true}
                    onText="On"
                    offText="Off"
                  />
                  <Toggle
                    label={
                      <Stack
                        horizontal
                        verticalAlign="center"
                        style={{ paddingTop: 5, paddingBottom: 5 }}
                      >
                        <Text style={{ fontWeight: 600 }}>
                          Hide passing controls
                        </Text>
                      </Stack>
                    }
                    checked={Failed_Only}
                    onText="On"
                    onChange={onChangeFailed}
                    offText="Off"
                  />
                  {RuntimeConfigurationExtended.externalControlsConfiguration
                    ?.isEnabled && (
                    <Toggle
                      label={
                        <Stack horizontal verticalAlign="center">
                          <Text style={{ fontWeight: 600 }}>
                            Non-AzTS controls
                          </Text>
                          <ExternalControlInfo />
                        </Stack>
                      }
                      onText="On"
                      offText="Off"
                      onChange={onChangeExcludedControls}
                    />
                  )}
                  {RuntimeConfigurationExtended
                    .complianceInitiativeConfiguration?.isEnabled &&
                    ComplianceInitiativeEnabled && (
                      <ComboBox
                        selectedKey={ComplianceInitiative}
                        onChange={onChangeComplianceInitiative}
                        onMenuDismissed={toggleChangedAll}
                        autoComplete="on"
                        useComboBoxAsMenuWidth={true}
                        onRenderLabel={onRenderComplianceLabel}
                        options={ComplianceInitiativeOptions}
                        allowFreeform={true}
                        style={{ width: "45%" }}
                        ariaLabel="Compliance Initiative"
                      />
                    )}
                </Stack>
              </Stack.Item>
              <Stack>
                <ComboBox
                  label={"Control (" + Control.length + " selected)"}
                  selectedKey={Control}
                  onChange={onChangeControl}
                  onMenuDismissed={setChangedControls}
                  multiSelect
                  autoComplete="on"
                  options={
                    Is_Baseline === "false"
                      ? ControlOptions
                      : ControlBaselineOptions
                  }
                  allowFreeform={true}
                  useComboBoxAsMenuWidth={true}
                  style={{ width: "100%" }}
                  ariaLabel="Controls Filter"
                />
              </Stack>
            </Stack>
          </Stack>
          <div style={{ height: 5 }}></div>
        </Stack>
      </Panel>
    </Stack>
  );
};
