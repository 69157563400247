import React, { Component, createContext } from 'react';

export const FormContext = createContext();

export class FormContextProvider extends Component {
  state = {
    shimmer: true,
    visible: false,
    isError: false,
    errorMessage: "",
    workItems: [],
    resource: [],
    control: [],
    failed: true,
    baseline: "",
    complianceInitiative: "",
    selectedWorkItems: [],
    exceptionDataAdd: [],
    exceptionControls: "",
    exceptionSub: "",
    exceptionDataClear: [],
    forceRefresh: false,
    attestationMode: false,
    excludeExternalControls: true,
    remediationMode: false,
    remediateControls: [],
    remediableControlsFetched: [],
    remediateControlsCount: 0,
    projects: [],
    showErrorMessage:false,
    errorMessageToDisplay:"",
    errorHeaderToDisplay:"",
    errorResponse:""
  }

  setProjects = (value) => {
    this.setState({
      projects: value
    })
  }

  setErrorMessageToDisplay = (value) => {
    this.setState({
      errorMessageToDisplay: value
    })
  }

  setErrorHeaderToDisplay = (value) => {
    this.setState({
      errorHeaderToDisplay: value
    })
  }

  setErrorResponse = (value) => {
    this.setState({
      errorResponse: value
    })
  }

  setAttestationMode = (value) => {
    this.setState({
      attestationMode: value
    })
  }

  setExcludeExternalControlsContext = (value) =>{
    this.setState({
      excludeExternalControls: value
    })
  }

  setForceRefresh = (value) => {
    this.setState({
      forceRefresh: value
    })
  }

  setSelectedWorkItems = (value) => {
    this.setState({
      selectedWorkItems: value
    })
  }

  setExceptionDataAdd = (value) => {
    this.setState({
      exceptionDataAdd: value
    })
  }

  setExceptionControls = (value,valueSub) => {
    this.setState({
      exceptionControls: value,
      exceptionSub: valueSub
    })
  }

  setExceptionDataClear = (value) => {
    this.setState({
      exceptionDataClear: value
    })
  }

  setRemediationMode = (value) => {
    this.setState({
      remediationMode: value
    })
  }
  
  setRemediateControls = (value) => {
    this.setState({
      remediateControls: value
    })
  }

  setremediableControlsFetched = (value) => {
    this.setState({
      remediableControlsFetched:value
    })
  }

  setRemediateControlsCount = (value) => {
    this.setState({
      remediateControlsCount:value
    })
  }

  toggleError = (bool,value) => {
    this.setState({
      isError: bool,
      errorMessage: value
    })
  }
  
  setFailed = (value) => {
    this.setState({
      failed: value
    })
  }

  toggleShimmer = (value) => {
      this.setState({
          shimmer: value
      })
  }

  toggleVisible = (value) => {
    this.setState({
        visible: value
    })
  }

  toggleShowErrorMessage = (value) => {
    this.setState({
        showErrorMessage: value
    })
  }

  toggleFirstSubscription = (value) => {
    this.setState({
      firstSubscription: value
    })
  }

  changeControls = (WorkItems, Control, Is_Baseline, firstSubscription) => {
    this.setState({
        workItems: WorkItems, 
        control: Control,
        baseline: Is_Baseline,  
        firstSubscription: firstSubscription, 
    });
  }

  changeFormData = (WorkItems, Control, Is_Baseline, Compliance_Initiative, Failed_Only, Projects) => {
    this.setState({ 
        workItems: WorkItems, 
        control: Control, 
        baseline: Is_Baseline, 
        complianceInitiative: Compliance_Initiative,
        failed: Failed_Only,
        projects: Projects
    });
  }

  render() { 
    return (
      <FormContext.Provider value={{...this.state,
        toggleFirstSubscription: this.toggleFirstSubscription,
        toggleVisible: this.toggleVisible,
        setSelectedWorkItems: this.setSelectedWorkItems,
        toggleShimmer: this.toggleShimmer,
        changeFormData: this.changeFormData,
        toggleShowErrorMessage:this.toggleShowErrorMessage,
        toggleError: this.toggleError,
        changeControls: this.changeControls,
        setExceptionControls: this.setExceptionControls,
        setExceptionDataAdd: this.setExceptionDataAdd,
        setExceptionDataClear: this.setExceptionDataClear,
        setForceRefresh: this.setForceRefresh,
        setAttestationMode: this.setAttestationMode,
        setFailed: this.setFailed,
        setExcludeExternalControlsContext: this.setExcludeExternalControlsContext,
        setRemediateControls: this.setRemediateControls,
        setRemediationMode: this.setRemediationMode,
        setRemediateControlsCount: this.setRemediateControlsCount,
        setremediableControlsFetched: this.setremediableControlsFetched,
        setProjects: this.setProjects,
        setErrorMessageToDisplay:this.setErrorMessageToDisplay,
        setErrorHeaderToDisplay:this.setErrorHeaderToDisplay,
        setErrorResponse:this.setErrorResponse
      }}>
        {this.props.children}
      </FormContext.Provider>
    );
  }
}
 
export default FormContextProvider;