import React from "react";
import { IDetailsHeaderProps, IRenderFunction, Sticky, StickyPositionType } from "@fluentui/react";
import { classNames } from "../../Styles/Table/Group";

export function onRenderDetailsHeaderAttestation(props: IDetailsHeaderProps, _defaultRender?: IRenderFunction<IDetailsHeaderProps>) {
    props.selectAllVisibility = 1
    if (!props) {
        return null;
    }

    return (
        <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced stickyClassName={classNames.paneHeader}>
            {_defaultRender!({
            ...props,
            styles: {
                root:{
                    selectors:{
                        '.ms-DetailsHeader-cellSizer':{
                            cursor : "col-resize !IMPORTANT"
                        }
                    }
                    
                }
            }
            })}
        </Sticky>
    );
}

export function onRenderDetailsHeaderControlEditor(props: IDetailsHeaderProps, _defaultRender?: IRenderFunction<IDetailsHeaderProps>) {
    props.selectAllVisibility = 2
    if (!props) {
        return null;
    }

    return (
        <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced stickyClassName={classNames.paneHeader}>
            {_defaultRender!({
            ...props,
            })}
        </Sticky>
    );
}

export function onRenderDetailsHeaderRemediation(props: IDetailsHeaderProps, _defaultRender?: IRenderFunction<IDetailsHeaderProps>) {
    props.selectAllVisibility = 1
    if (!props) {
        return null;
    }

    return (
        <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced stickyClassName={classNames.paneHeader}>
            {_defaultRender!({
            ...props,
            styles: {
                root:{
                    selectors:{
                        '.ms-DetailsHeader-cellSizer':{
                            cursor : "col-resize !IMPORTANT"
                        }
                    }      
                }
            }
            })}
        </Sticky>
    );
}

export function onRenderDetailsHeader(props: IDetailsHeaderProps, _defaultRender?: IRenderFunction<IDetailsHeaderProps>) {
    if (!props) {
        return null;
    }

    return (
        <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced stickyClassName={classNames.paneHeader}>
            {_defaultRender!({
            ...props,
            styles: {
                root:{
                    selectors:{
                        '.ms-DetailsHeader-cellSizer':{
                            cursor : "col-resize !IMPORTANT"
                        }
                    }
                    
                }
            }
            })}
        </Sticky>
    );
}