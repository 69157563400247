// All common routes used by scanner
interface IRouteManager {
    FetchMetadata: string;
    FetchFilterMetadata: string;
    FetchWorkItemComplianceSummary: string;
    FetchWorkItemScanResult: String;
    ExportScanResultCsv: string;
    QueueWorkItemForScan: string;
    
}

export const TenantScannerRoutes : IRouteManager = {
    FetchMetadata: "/userdetails/metadata/",
    FetchFilterMetadata: "/userdetails/usermetadata/",
    FetchWorkItemComplianceSummary: '/subscription/SubscriptionComplianceSummary/',
    ExportScanResultCsv: '/subscription/scanResults/exportToCSV',
    QueueWorkItemForScan: '/queuesubscription/AddToQueue',
    FetchWorkItemScanResult: "/subscription/%s/scanResults"
}