import React, { useContext } from 'react';
import { PrimaryButton, DefaultButton, IconButton } from '@fluentui/react/lib/Button';
import { MessageBar, MessageBarType, Stack, Spinner, SpinnerSize, Modal } from '@fluentui/react';
import { _Styles } from '../Page.styles';
import { FormContext } from '../context/FormContext';
import { getTheme } from '@fluentui/react';
import { authProvider } from '../../authProvider';
import { DialogContext } from '../context/DialogContext';
import { contentStyles, dragOptions, iconButtonStyles } from '../Styles/Dialog/Common';

const theme = getTheme();

export const RemediateDialog: React.FunctionComponent = () => {
    const RuntimeConfigurationInitial = window.__UI_CONFIGURATION_INITIAL__;
    const RuntimeConfigurationExtended = window.__UI_CONFIGURATION_EXTENDED_AZURE__;

    const { setForceRefresh, remediateControls, setRemediateControls, control, resource, attestationMode, failed, remediationMode, baseline, excludeExternalControls, complianceInitiative, selectedWorkItems, setRemediateControlsCount } = useContext(FormContext);
    const { toggleRemediate, remediateDialog } = useContext(DialogContext);
    const [submitted, setSubmitted] = React.useState<boolean>(false);
    const [spinner, setSpinner] = React.useState<boolean>(false);
    const [showMessage, setShowMessage] = React.useState<boolean>(false);
    const [showMessageError, setShowMessageError] = React.useState<boolean>(false);
    const [errorResponse, setErrorResponse] = React.useState<string>("");
    
    const SuccessExampleRemediate = () => (
        <MessageBar
            messageBarType={MessageBarType.success}
            isMultiline={true}
        >
            <div>
                Files required for remediation are being downloaded.
            </div>
        </MessageBar>
    );

    const ErrorAPIMessage = () => (
        <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={true}
        >
            <div>
                {errorResponse}
            </div>
        </MessageBar>
    );

    const onDismissRemediate = (): void => {
        toggleRemediate();
        if (submitted) {
            setRemediateControls([]);
            setRemediateControlsCount(0);
            setForceRefresh(true);
        }
        setSpinner(false);
        setSubmitted(false);
        setShowMessage(false);
        setShowMessageError(false);
        setErrorResponse("");
    }
    function _pad2(n: number): string {
        return n < 10 ? '0' + n.toString() : n.toString();
    }
    function _getDate(): string {
        var date = new Date();
        return (date.getFullYear().toString() +
            _pad2(date.getMonth() + 1) +
            _pad2(date.getDate()) +
            _pad2(date.getHours()) +
            _pad2(date.getMinutes()) +
            _pad2(date.getSeconds()));
    }
    function _submitRemediate(): void {
        console.log(remediateControls);
        setSubmitted(true);
        setSpinner(true);
        setShowMessageError(false);
        const CallAPI = async () => {
            const authToken = await authProvider.getAccessToken({ scopes: ["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"] });
            var body = [];
            let filterbody = {
                JobId: 0,
                ControlIdList: control,
                ResourceNameList: resource,
                ExcludePassedControls: failed | remediationMode,
                GetControlsEligibleForException: attestationMode,
                FilterBaselineType: baseline,
                ExportToCSV: false,
                ExcludeExternalControls: RuntimeConfigurationExtended.externalControlsConfiguration?.isEnabled ? excludeExternalControls : true,
                PageNumber: 1,
                ComplianceInitiativeId: complianceInitiative,
            }
            if (remediateControls != null && remediateControls.size > 0) {
                remediateControls.forEach((subVal, subId) => {
                    var bodyElement = {};
                    bodyElement["SubscriptionId"] = subId;
                    bodyElement["TotalNumberOfPages"] = subVal.pageNumber;

                    var controlsList = [];
                    var pages = [];
                    if (subVal != null && subVal.controls != null && subVal.visitedPages != null) {
                        subVal.controls.forEach((controls, page) => {
                            pages = pages.concat(page);
                            controls.forEach(control => {
                                controlsList = controlsList.concat({
                                    TenantId: control["orgTenantId"],
                                    SubscriptionId: control["workItemId"],
                                    ResourceId: control["resourceID"],
                                    ResourceGroupName: control["rg"],
                                    ResourceName: control["resource"],
                                    controlName: control["name"],
                                });
                            });
                        });
                    }
                    bodyElement["ControlResults"] = controlsList;
                    bodyElement["VisitedPages"] = pages;
                    bodyElement["FilterObject"] = filterbody;
                    bodyElement["IsSubscriptionSelected"] = false;
                    selectedWorkItems.forEach(subobj => {
                        if (subobj.workItemId === subId) {
                            bodyElement["IsSubscriptionSelected"] = true;
                        }
                    });
                    body = body.concat(bodyElement);
                });
            }
            fetch((RuntimeConfigurationInitial.webAPI + '/remediation/download'), {
                headers: !authToken.accessToken ? {} : {
                    'Authorization': `Bearer ${authToken.accessToken}`,
                    'Content-Type': 'application/json',
                    'u_Id': sessionStorage.getItem("u_Id"),
                    'X-Remediation-Mode': 'true',
                },
                method: 'POST',
                body: JSON.stringify(body)
            }).then(response => {
                if (response.ok) {
                    response.blob().then(blob => URL.createObjectURL(blob))
                        .then(uril => {
                            var link = document.createElement("a");
                            link.href = uril;
                            var timestamp = _getDate();
                            link.download = 'AzTSRemediation' + timestamp + '.zip';
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            setSpinner(false);
                        });
                    setShowMessage(true);
                }
                else {
                    setErrorResponse("There are no controls to remediate.");
                    setShowMessageError(true);
                    setSpinner(false);
                }
            });
        }
        CallAPI();
    }

    return (
        <>
            <Modal
                isOpen={remediateDialog}
                containerClassName={contentStyles.container2}
                onDismiss={onDismissRemediate}
                dragOptions={dragOptions}
            >
                <div className={contentStyles.header}>
                    <span>Download Remediation Package</span>
                    <IconButton
                        style={{ outline: "none" }}
                        styles={iconButtonStyles}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel="Close popup modal"
                        onClick={onDismissRemediate}
                    />
                </div>
                <div className={contentStyles.body}>
                    <div>
                        <div>A .zip file will be downloaded along with remediation scripts and instructions required for remediation.  </div>
                        <div>Click on Download to get AzTS Remediation Package. </div>
                        <div>Note: Depending on the selections, it may take some time to download. </div>
                    </div>
                    <div className={_Styles.rowGap3} />
                    <div>
                        {(showMessage) && <div><SuccessExampleRemediate /><div className={_Styles.rowGap3} /></div>}
                    </div>
                    <div>
                        {(showMessageError) && <div><ErrorAPIMessage /><div className={_Styles.rowGap3} /></div>}
                    </div>
                    <Stack {...{ horizontal: true, verticalAlign: 'center' }} horizontalAlign={"space-between"}
                        reversed style={{ padding: 0, margin: 0 }} tokens={{ childrenGap: 10 }}>
                        <Stack horizontal reversed tokens={{ childrenGap: 10 }}>
                            <DefaultButton onClick={onDismissRemediate} text="Close" />
                            <PrimaryButton disabled={submitted} onClick={_submitRemediate} text="Download" />
                        </Stack>
                        {spinner && <Spinner size={SpinnerSize.medium} />}
                    </Stack>
                </div>
                <div className={_Styles.rowGap} />
            </Modal>
        </>
    );
};