import * as React from 'react';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { PanelContext } from '../context/PanelContext';
import { useContext } from 'react';
import { ActionButton, Icon, IIconStyles, IPersonaProps, mergeStyleSets, Persona, PersonaInitialsColor, PersonaSize, Stack } from '@fluentui/react';
import AzureAD, { IAzureADFunctionProps } from 'react-aad-msal';
import { authProvider } from '../../authProvider';
import { getThemeForUI } from '../Helper/ThemeLocalStorage';

export const panelClassNames = mergeStyleSets({
  headerClass: {
      fontSize: '200%',
      marginTop: 10,
      marginBottom: 30
  },
})

export const PersonaPanel: React.FunctionComponent = () => {
  const theme = getThemeForUI();

  
  const iconStyles: Partial<IIconStyles> = {
      root: { 
      color: theme.palette.themePrimary,
      fontSize: '100%',
      marginRight: 5, 
      verticalAlign: 'bottom'
    } 
  };
 
  function _onRenderTertiaryText(props: IPersonaProps): JSX.Element {
    // Not all accounts have this field populated.
    if (props.tertiaryText?.length > 0) {
      return (
        <div>
          <Icon iconName="Suitcase" styles={iconStyles} />
          {props.tertiaryText}
        </div>
      );
    }
    return (<div></div>);
  }
  
  function _onRenderSecondaryText(props: IPersonaProps): JSX.Element {
    return (
      <div>
        <Icon iconName="Mail" styles={iconStyles} />
        {props.secondaryText}
      </div>
    );
  }

  const {blobURL, displayName, userPrincipalName, personaPanel, jobTitle, togglePersonaPanel} = useContext(PanelContext);
  const onRenderFooterContent = React.useCallback(
    () => (
      <AzureAD provider={authProvider}>
        {
            ({logout}:IAzureADFunctionProps) => {
                return (
                  <Stack horizontal horizontalAlign='end'>
                    <ActionButton 
                      iconProps={{iconName:'SignOut'}} 
                      style={{outline:"none"}}
                      onClick={
                        ()=>{
                          logout();
                          sessionStorage.setItem("u_Id","")
                        }
                      }
                    >
                      Sign Out
                    </ActionButton>
                  </Stack>                           
                );
            }}
      </AzureAD>
    ),
    [],
  );

  return (
    <div>
      <Panel
        isOpen={personaPanel}
        isLightDismiss
        type={PanelType.custom}
        customWidth={'27%'}
        headerClassName={panelClassNames.headerClass}
        hasCloseButton={false}
        onDismiss={togglePersonaPanel}
        headerText="Profile"
        // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
        closeButtonAriaLabel="Close"
        onRenderFooterContent={onRenderFooterContent}
        isFooterAtBottom={true}
      >
        <Persona
          imageUrl={blobURL}
          size={PersonaSize.size100}
          imageShouldFadeIn
          text={displayName}
          secondaryText={userPrincipalName}
          tertiaryText={jobTitle}
          onRenderTertiaryText={_onRenderTertiaryText}
          onRenderSecondaryText={_onRenderSecondaryText}
          initialsColor={PersonaInitialsColor.darkRed}
        />
      </Panel>
    </div>
  );
};

