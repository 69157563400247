import { ITableIndividualRowState, ITableIndividualRowStateDevOps } from '../../Interface/ITable';

// eslint-disable-next-line
export function copyAndSort<T>(items: ITableIndividualRowState[], columnKey: string, isSortedDescending?: boolean): ITableIndividualRowState[] {
    const key = columnKey as keyof ITableIndividualRowState;
    if(key === "status") {
        return items.slice(0).sort((a: ITableIndividualRowState, b: ITableIndividualRowState) => (
            (isSortedDescending ?
                a[key]["status"].toString().toLowerCase() < b[key]["status"].toString().toLowerCase() :
                a[key]["status"].toString().toLowerCase() > b[key]["status"].toString().toLowerCase()) ? 1 : -1));
    }

    return items.slice(0).sort((a: ITableIndividualRowState, b: ITableIndividualRowState) => (
            (isSortedDescending ?
                a[key].toString().toLowerCase() < b[key].toString().toLowerCase() :
                a[key].toString().toLowerCase() > b[key].toString().toLowerCase()) ? 1 : -1));
}

export function copyAndSortDevOps<T>(items: ITableIndividualRowStateDevOps[], columnKey: string, isSortedDescending?: boolean): ITableIndividualRowStateDevOps[] {
    const key = columnKey as keyof ITableIndividualRowState;
    if(key === "status") {
        return items.slice(0).sort((a: ITableIndividualRowStateDevOps, b: ITableIndividualRowStateDevOps) => (
            (isSortedDescending ?
                a[key]["status"].toString().toLowerCase() < b[key]["status"].toString().toLowerCase() :
                a[key]["status"].toString().toLowerCase() > b[key]["status"].toString().toLowerCase()) ? 1 : -1));
    }

    return items.slice(0).sort((a: ITableIndividualRowStateDevOps, b: ITableIndividualRowStateDevOps) => (
            (isSortedDescending ?
                a[key].toString().toLowerCase() < b[key].toString().toLowerCase() :
                a[key].toString().toLowerCase() > b[key].toString().toLowerCase()) ? 1 : -1));
}