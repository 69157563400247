
export const TenantLevel = "Please select the Tenant";
export const FetchTenant = "fetching tenants";
export const MGLevelRoot = "Please select the MG at Root Level";
export const MGLevel1 = "Please select the MG at Level 1";
export const MGLevel2 = "Please select the MG at Level 2";
export const MGLevel3 = "Please select the MG at Level 3";
export const MGLevel4 = "Please select the MG at Level 4";
export const MGLevel5 = "Please select the MG at Level 5";
export const MGLevel6 = "Please select the MG at Level 6";
export const FetchMG = "Fetching management group";
export const AddMG = "Adding tag to management group";
export const DeleteMGTagLabel = "Deleting tag from management group";
export const FetchMGTag = "Fetching management group tag";
export const FetchMGList = "Fetching list of management group mapped to selected compliance initiative";
export const DeleteCI = "Deleting compliance initiative.";
export const CreateCI = "Creating compliance initiative" ;
export const FetchLogs = "Fetching change logs";
export const CITitle = "Compliance Initiative Title";
export const CIDescription = "Compliance Initiative Description";
export const DefaultTagCI = "Default Control Tag Filter";
export const ShowDisplayName = "What do you want to show on UI?";
export const CITitleAndTag = 'Compliance Initiative/ DefaultControlTag';
export const SubsTag = "Subscription Tag";
export const MGKey = "Filters_ManagementGroup";
export const FailedOnlyControlsKey = "Filters_Failed_Only";
export const WorkItemKey = "Filters_WorkItem";

export const UnauthorizedHeader = "Access required."
export const NoDataHeader = "Scan results are not yet available."
export const EndPointNotFoundHeader = "Requested endpoint not found."
export const InvalidClientAppRequestingDataHeader = "Unauthorized Request."
export const InternalServerErrorHeader = "Internal server error."
export const SubscriptionComplianceHeader = "Compliance summary not available."
export const NoControlResultFound = "No data found for selected filter criteria. Please try changing the filters."
export const AccessMessage = "Please verify that you have the required access";
export const NoDataFound = "No data found for applied filters.";