import React from 'react';
import { CollapsibleSection} from '@fluentui/react-experiments';
import { Pagination} from "../React-Experiments-Extensions/Pagination";
import { RenderItemColumn } from '../Helper/Table/renderItemColumn';
import { refreshIcon, CompletedIcon,ExceptionIcon } from '../Styles/Table/IconStyles';
import { IControlObject, ITableIndividualRowState, ITableGroupState, IFormConfigurations,
    IInternalState, IStatusObject, IWorkItemList, IWorkItemParentMap, TableProps, IResourceObject, IStatusReasonObject, IGroupedRemediableControl } from '../Interface/ITable';
import { GROUP_FOOTER_HEIGHT, GROUP_HEADER_HEIGHT, ROW_HEIGHT } from '../Styles/Table/Group';
import { onRenderDetailsHeader, onRenderDetailsHeaderAttestation, onRenderDetailsHeaderRemediation } from '../Helper/Table/renderHeaders';
import { tableStyles, horizontalStackTokens } from '../Styles/Table/Common';
import { copyAndSort } from '../Helper/Table/copyAndSort';
import { DefaultButton, DetailsList, IColumn, IDetailsList, Text, IGroup, Selection, SelectionMode, Toggle,
    IGroupDividerProps, IGroupRenderProps, Checkbox, ConstrainMode, Sticky, StickyPositionType, IDetailsListProps,
IDetailsRowStyles, DetailsRow, MessageBar, MessageBarType, MarqueeSelection, loadTheme, BaseButton, Button, DefaultPalette, SpinnerSize, Spinner, ITooltipHostStyles, ITeachingBubbleStyles, IObjectWithKey, TooltipHost, TeachingBubble, IconButton, Link, ICheckboxStyles, IDetailsListStyles, DetailsListLayoutMode, CommandBarButton, DirectionalHint, IPlainCardProps, HoverCard, HoverCardType, SharedColors, IStyleFunctionOrObject, IHoverCardStyleProps, IHoverCardStyles,
ThemeProvider} from '@fluentui/react';
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { FormContext } from '../context/FormContext';
import { authProvider } from '../../authProvider';
import { _InfoToast, _Styles, _SuccessToast} from '../Page.styles';
import { DialogCart } from './Dialog';
import { InfoTable } from '../TeachingBubbles';
import { toast } from 'react-toastify';
import { ReactComponent as CatSvg } from '../assets/cat.svg';
import { ReactComponent as NoData } from '../assets/nodata.svg';
import 'react-toastify/dist/ReactToastify.css';
import { classNames } from '../Styles/Table/Group';
import { defaultTheme } from '../Themes/DefaultTheme';
import ReactTextRotator from '../Did-You-Know/ReactTextRotator';
import { IChartProps, IChartDataPoint, StackedBarChart } from '@fluentui/react-charting';
import { TenantScannerConfiguration } from '../Interface/IUnificationConfiguration';
import { TenantScannerRoutes } from '../Interface/IRouteManager';
import * as util from "util";
import { NoControlResultFound, UnauthorizedHeader } from '../ComplianceInitiativeEditor/Constants';
import untruncateJson from 'untruncate-json';
import { onRenderPlainCardSubscription } from '../Styles/Table/HoverCard';

const theme = loadTheme(defaultTheme);
const toolTipStyle: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };
const teachingStyles: Partial<ITeachingBubbleStyles> = {
    root: {
        transition: '0.5s'
    }
}

const verticalGapStackTokens: IStackTokens = {
    childrenGap: 10,
  };

const RuntimeConfigurationInitial = window.__UI_CONFIGURATION_INITIAL__;
const ScannerAPI = RuntimeConfigurationInitial.webAPIConf ? RuntimeConfigurationInitial.webAPIConf["AZURE"] : RuntimeConfigurationInitial.webAPI;
const gridStyles: Partial<IDetailsListStyles> = {
    root: {
      overflowX: 'scroll',
      // for wrapping the content in a column cell
      '.ms-HoverCard-host':{
          whiteSpace:'normal',
          display: '-webkit-box',
          'max-height': '5.2em',
          '-webkit-line-clamp': '4',
          '-webkit-box-orient': 'vertical',
      }
    },
  };
const checkboxStyle : Partial<ICheckboxStyles>={
    label:{
        marginTop:'12%'
    }
}
export class DetailsListGroupedExample extends React.Component<TableProps, ITableGroupState> {
    private _root = React.createRef<IDetailsList>();
    private _selection: Selection;

    private RuntimeConfigurationExtended = window.__UI_CONFIGURATION_EXTENDED_AZURE__;
    static contextType = FormContext;

    constructor(props?) {
        super(props);
        const formConf: IFormConfigurations = {
            workItems: ['Loaded'],
            resource: [],
            control: [],
            failed: true,
            baseline: true,
            excludeExternalControls: false,  
            complianceInitiative: "",  
        }
        const viewportWidth = (visualViewport.width)/24;
        const columns: IColumn[] = [
            {
                key: 'controlObject',
                name: 'Control Name',
                fieldName: 'controlObject',
                minWidth: viewportWidth*5,
                //isSortedDescending: false,
                //isSorted: true,
                isPadded: false,
                onColumnClick: this._onColumnClick,
                maxWidth: viewportWidth*5,
                isResizable: true,
            }, {
                key: 'status',
                name: 'Status',
                fieldName: 'status',
                minWidth: viewportWidth*2,
                isSortedDescending: false,
                isSorted: true,
                maxWidth: viewportWidth*2,
                isPadded: false,
                onColumnClick: this._onColumnClick,
                isResizable: true,
                
            }, {
                key: 'resource',
                name: 'Resource',
                fieldName: 'resourceObject',
                minWidth: viewportWidth*3,
                isPadded: false,
                // isSortedDescending: false,
                // isSorted: true,
                maxWidth: viewportWidth*3,
                // isFiltered: true,
                onColumnClick: this._onColumnClick,
                isResizable: true
            }, {
                key: 'rg',
                name: 'Resource Group',
                fieldName: 'rg',
                minWidth: viewportWidth*3,
                isPadded: false,
                // isSortedDescending: false,
                // isSorted: true,
                maxWidth: viewportWidth*3,
                onColumnClick: this._onColumnClick,
                isResizable: true
            },
            {
                key: 'statusReason',
                name: 'Status Reason',
                fieldName: 'statusReason',
                minWidth: viewportWidth*5,
                maxWidth: viewportWidth*5,
                isPadded: false,
                // isSortedDescending: false,
                // isSorted: true,
                // onColumnClick: this._onColumnClick,
                isResizable: true
            }
            
        ];

        const columns_exception: IColumn[] = [
            {
                key: 'controlObject',
                name: 'Control Name',
                fieldName: 'controlObject',
                minWidth: viewportWidth*5,
                //isSortedDescending: false,
                //isSorted: true,
                isPadded: false,
                onColumnClick: this._onColumnClick,
                maxWidth: viewportWidth*5,
                isResizable: true
            }, {
                key: 'status',
                name: 'Status',
                fieldName: 'status',
                minWidth: viewportWidth*(1.5),
                isSortedDescending: false,
                isSorted: true,
                maxWidth: viewportWidth*(1.5),
                isPadded: false,
                onColumnClick: this._onColumnClick,
                isResizable: true,
            }, {
                key: 'resource',
                name: 'Resource',
                fieldName: 'resourceObject',
                minWidth: viewportWidth*(2),
                isPadded: false,
                // isSortedDescending: false,
                // isSorted: true,
                maxWidth: viewportWidth*(2),
                // isFiltered: true,
                onColumnClick: this._onColumnClick,
                isResizable: true
            }, {
                key: 'rg',
                name: 'Resource Group',
                fieldName: 'rg',
                minWidth: viewportWidth*(2),
                isPadded: false,
                // isSortedDescending: false,
                // isSorted: true,
                maxWidth: viewportWidth*(2),
                onColumnClick: this._onColumnClick,
                isResizable: true
            },
            {
                key: 'expiry',
                name: 'Expiry (in days)',
                fieldName: 'expiry',
                minWidth: viewportWidth*(1.5),
                isPadded: false,
                // isSortedDescending: false,
                // isSorted: true,
                onColumnClick: this._onColumnClick,
                maxWidth: viewportWidth*(1.5),
                isResizable: true
            },
            {
                key: 'statusReason',
                name: 'Status Reason',
                fieldName: 'statusReason',
                minWidth: viewportWidth*(2.5),
                maxWidth: viewportWidth*(2.5),
                isPadded: false,
                // isSortedDescending: false,
                // isSorted: true,
                // onColumnClick: this._onColumnClick,
                isResizable: true
            },
            {
                key: 'justification',
                name: 'Justification',
                fieldName: 'justification',
                minWidth: viewportWidth*1.5,
                isPadded: false,
                maxWidth: viewportWidth*1.5,
                // isSortedDescending: false,
                // isSorted: true,
                // onColumnClick: this._onColumnClick,
                isResizable: true
            }
        ];

        const columns_remediation: IColumn[] = columns;

        // Function to control selection of item in table.
        this._selection = new Selection({
            canSelectItem: this._canSelectItem.bind(this),
            onSelectionChanged: () => {
                try {
                    var anchorIndex = this._selection["_anchoredIndex"];
                    var selectedItems = this._selection.getSelection();
                    if (this.context.attestationMode && this.context.remediationMode) {
                        toast("Both exception Mode and Remediation Mode cannot be True at once");
                        this._selection.setIndexSelected(anchorIndex, false, true);
                    }
                    // Below code block is executed if remediation mode toggle is on.
                    if (this.context.remediationMode) {
                        if (this.state.controlledSelectionValue === 1) {
                            // if (an item (control) under selected subscription has been clicked), then 
                            // toast that it can't be deselected.
                            var anchorIndexSubscriptionId = this._selection["_items"][anchorIndex].workItemId;
                            // var anchorIndexControlId = this._selection["_items"][anchorIndex].name;
                            if (this.state.selectedWorkItems.get(anchorIndexSubscriptionId)) {
                                this._selection.setChangeEvents(false, true);
                                for (var i = 0; i < this.state.items.length; i++) {
                                    if (this.state.items[i].workItemId === anchorIndexSubscriptionId) this._selection.setIndexSelected(i, true, false);
                                }
                                this._selection.setChangeEvents(true, true);
                                toast("To manually select/deselect controls, uncheck the subscription");
                            }
                            // else, allow the item to be deselected or selected
                            else {
                                // subPageMap, to store the current page number against subscriptions
                                // subControlsMap, to store current selected controls against subcriptions
                                var subPageMap = new Map<string, number>();
                                var subControlsMap = new Map<string, IObjectWithKey[]>();
                                selectedItems.forEach((value) => {
                                    var subID = value["workItemId"];
                                    var intState = this.state.internalState.get(subID);
                                    subPageMap.set(subID, intState.currentPage);
                                    if (!subControlsMap.has(subID)) subControlsMap.set(subID, [value]);
                                    else subControlsMap.set(subID, [...subControlsMap.get(subID), value]);
                                });
                                var remediateControls: Map<string, IGroupedRemediableControl> = this.state.remediateControls;
                                remediateControls.forEach((value, key) => {
                                    if (subControlsMap.has(key)) {
                                        value.controls.set(this.state.internalState.get(key).currentPage, subControlsMap.get(key));
                                    }
                                    else value.controls.set(this.state.internalState.get(key).currentPage, []);
                                });
                                this.setState({
                                    remediateControls: remediateControls
                                });
                                if (this._selection.count === 0) {
                                    this.context.setRemediateControls(remediateControls);
                                }
                                else this.context.setRemediateControls(this.state.remediateControls);

                                // Count the number of remediable controls, in case the subscription is not selected.
                                var remediateControlsCount = 0;
                                if (remediateControls != null && remediateControls.size > 0) {
                                    remediateControlsCount = 0;
                                    remediateControls.forEach((value) => {
                                        if (value != null && value.controls != null) {
                                            value.controls.forEach((value1) => {
                                                remediateControlsCount = remediateControlsCount + value1["length"];
                                            });
                                        }
                                    });
                                }
                                this.context.setRemediateControlsCount(remediateControlsCount)
                            }
                        }
                    }
                    else if(this._selection["_items"][anchorIndex].name !== "Control Name") {
                        if(this._selection.count === 0) {
                             this.context.setExceptionControls("", "");
                            this.context.setExceptionDataAdd([]);
                            this.context.setExceptionDataClear([]);
                        }
                        else {
                            var clear_items = [];
                            var add_items = [];
                            if(this.context.exceptionControls === "") {
                                this.context.setExceptionControls(this._selection["_items"][anchorIndex].controlObject["displayName"],
                                this._selection["_items"][anchorIndex].workItemId);
                                for(var i=0; i < selectedItems.length; i++) {
                                    if (selectedItems[i]["status"]["status"] === "Passed*") {
                                        clear_items.push(selectedItems[i]);
                                    }                                        
                                    if(selectedItems[i]["status"]["status"] === "Failed" || selectedItems[i]["status"]["status"] === "Exempted" ||
                                        (selectedItems[i]["status"]["status"] === "Passed*" && Number(selectedItems[i]["expiry"]) <= 30 &&
                                            Number(selectedItems[i]["expiry"]) > 0)) {
                                        add_items.push(selectedItems[i]);
                                    }
                                }
                                this.context.setExceptionDataAdd(add_items);
                                this.context.setExceptionDataClear(clear_items);
                            }
                            else {
                                if(this._selection["_items"][anchorIndex].controlObject["displayName"] !== this.context.exceptionControls || 
                                this._selection["_items"][anchorIndex].workItemId !== this.context.exceptionSub) {
                                    toast("You can select one control and subscription at a time.");
                                    this._selection.setIndexSelected(anchorIndex, false, true);
                                }
                                else {
                                    // eslint-disable-next-line
                                    for(var i=0; i < selectedItems.length; i++) {
                                           if (selectedItems[i]["status"]["status"] === "Passed*") {
                                            clear_items.push(selectedItems[i]);
                                        }
                                        if(selectedItems[i]["status"]["status"] === "Failed" || selectedItems[i]["status"]["status"] === "Exempted" ||
                                            (selectedItems[i]["status"]["status"] === "Passed*" &&
                                                Number(selectedItems[i]["expiry"]) <= 30 && Number(selectedItems[i]["expiry"]) > 0)) {
                                            add_items.push(selectedItems[i]);
                                        }
                                     }
                                    this.context.setExceptionDataAdd(add_items);
                                    this.context.setExceptionDataClear(clear_items);
                                }
                            }
                        }           
                    }
                    else {
                        this._selection.setIndexSelected(anchorIndex, false, true);
                    }        
                } catch (error) {
                    console.log(error.toString());
                }
            },
        });

        this.state = {
            items: [],
            filteredItems: [],
            groups: [],
            columns: columns,
            columns_exception: columns_exception,
            columns_remediation: columns_remediation,
            showItemIndexInView: false,
            isCompactMode: false,
            formConf: formConf,
            internalState: new Map<string, IInternalState>(),
            selectedWorkItems: new Map<string, boolean>(),
            expandedWorkItems: new Map<string, boolean>(),
            workItemJobMap: new Map<string, string>(),
            isRecommend: true,
            textVisible: false,
            moreText: '',
            workItemIdMap: new Map<string, IWorkItemParentMap>(),
            workItemCompliancePageNum: 0,
            remediableControlsFetched: new Map<string, boolean>(),
            remediateControls: new Map<string, IGroupedRemediableControl>(),
            controlledSelectionValue: 1,
            prevItems: [],
            teachingBubbleRemediationMode: false,

        };
    }

    private _canSelectItem(item: any): boolean {
        if (this.context.remediationMode) {
            if (this.state.remediableControlsFetched.has(item.name)) {
                if (this.state.remediableControlsFetched.get(item.name)) {
                    return true;
                }
            }
            return false;
        }
        return true;
    }

    private _resolution() {
        var groups: IGroup[] = [];
        var items: ITableIndividualRowState[] = [];
        var prevItems: ITableIndividualRowState[] = [];
        var iter = 0;
        var expandedWorkItems : Map<string, boolean> = this.state.expandedWorkItems;
        this.state.internalState.forEach((value: IInternalState, key: string) => {
            var isCollapsed = expandedWorkItems.get(key);
                        
            groups = groups.concat({
                key: value.workItemId,
                name: value.workItemName,
                startIndex: iter,
                count: value.controls.length,
                level: 0,
                data: value,
                isCollapsed: isCollapsed
            });
            items = items.concat(value.controls);
            iter += value.controls.length;
        });
        prevItems = this.state.items;
        this.setState({
            prevItems: prevItems,
            groups: groups,
            items: items,
            filteredItems: items,
            expandedWorkItems: expandedWorkItems
        });
        // diffItems is used to controls the updation of selected when page of a subscription is changed.
        // if diffItems is greater than 0, it means that the page of any one subscription has been changed or a subscription has been expanded but here we 
        // are only handling the case of page change of subscription
        var diffItems = items.filter(x=> !prevItems.includes(x));
        if(diffItems.length>0){
            this.setState({controlledSelectionValue:1})
        }

        this.context.toggleShimmer(false);

        var rmc = this.context.remediateControls;
        // When remediation mode is on, iterate through each of the elements of the items.
        if (this.context.remediationMode) {
            for (let i = 0; i < items.length; i++) {
                // if (when box is checked and item hasn't been fetched (initial rendering (else it will be selected in "_onToggleSelectGroupRemediation" function)) or when the page of the subscription is changed)
                // then, select the remediable controls. 
                if (this.state.selectedWorkItems.get(items[i].workItemId)) {
                    this.setState({ controlledSelectionValue: 2 });
                    this._selection.setIndexSelected(i, true, false);
                    this.setState({ controlledSelectionValue: 1 });
                }
                // Else (When the subscription is not selected (checked) and page of a subscription is changes to a page which has been visited), then
                // renders the previously selected controls in UI.
                else {
                    if (rmc.get(items[i].workItemId).visitedPages.get(this.state.internalState.get(items[i].workItemId).currentPage) === true) {
                        this._selection.setChangeEvents(false, true);
                        var a: IObjectWithKey = items[i];
                        var temp: ITableIndividualRowState[] = rmc.get(items[i].workItemId).controls.get(this.state.internalState.get(items[i].workItemId).currentPage);
                        var tempLength = (temp === undefined || temp === null) ? 0 : temp.length;
                        for (var j = 0; j < tempLength; j++) {
                            if (temp[j]["name"] === a["name"] && temp[j]["rg"] === a["rg"] && temp[j]["resource"] === a["resource"]) {
                                this._selection.setIndexSelected(i, true, false);
                            }
                        }
                        this._selection.setChangeEvents(true, true);
                    }
                }
            }
        }
    }

    private _processWorkItemData(dataString : string) {
        var response = JSON.parse(dataString);
        var isRefreshNeeded = false;
        if(response.subscriptionLimitReached){
            toast("Limited " + TenantScannerConfiguration.WorkItemName + "(s) have been loaded in the view. Please modify the filters to view the other subscriptions.", {
                autoClose: 6000,
                progressClassName: _InfoToast.toastProgress,
                className: _InfoToast.toastClass
            });
        }

        var data = response.subscriptionComplianceSummaryList;

        var internalState: Map<string, IInternalState> = new Map<string, IInternalState>();
        var selectedWorkItems: Map<string, boolean> = this.state.selectedWorkItems;
        var expandedWorkItems: Map<string, boolean> = this.state.expandedWorkItems;
        var workItemJobMap: Map<string, string> = this.state.workItemJobMap;
        var workItemIdMap: Map<string, IWorkItemParentMap> = this.state.workItemIdMap;
        var remediateControls: Map<string, IGroupedRemediableControl> = this.state.remediateControls;
        for (var i = 0; i < data.length; i++) {
            var header : ITableIndividualRowState[] = [];
            var stateData : IInternalState = {
                parentId: data[i].tenantID,
                parentName: data[i].tenantName,
                workItemId: data[i].subscriptionID,
                workItemName: data[i].subscriptionName,
                qStatus: data[i].queueStatus,
                lastScanned: data[i].lastModifiedDateTime,
                passingControls: data[i].passingControlCount,
                totalControls: data[i].totalControlCount,
                failingControls: data[i].failedControlCount,
                jobID: data[i].adhocScanJobId,
                compliance: data[i].compliancePercentage,
                controls: header,
                initial: true,
                scanningTime: data[i].scanningTime,
                scanningCount: data[i].scanningCount.toString(),
                isSelected: false,
                totalPages: data[i].totalPages,
                currentPage: 1,
                visibility: data[i].callerAccessLevel,
                scanCompleted: data[i].scanCompleted,
                latestScanRequestedTime: data[i].latestScanRequestedTime,
                lastScanRequestedAgo: data[i].lastScanRequestedAgo,
                scanFailed: data[i].scanFailed
            }

            if (data[i].scanFailed == false) {
                if ((this.RuntimeConfigurationExtended.onDemandScanProgressIndicatorConfiguration.isEnabled === true) && (data[i].scanCompleted === false)) {
                    isRefreshNeeded = true;
                }
            }

            var subscriptionRemediateControls: IGroupedRemediableControl = {
                pageNumber: data[i].totalPages,
                controls: new Map<number, IObjectWithKey[]>(),
                visitedPages: new Map<number, boolean>(),
            }
            internalState.set(data[i].subscriptionID, stateData);
            selectedWorkItems.set(data[i].subscriptionID, false);
            expandedWorkItems.set(data[i].subscriptionID, true);
            workItemJobMap.set(data[i].subscriptionID,data[i].adhocScanJobId);

            var subEntry : IWorkItemParentMap = {
                workItemId: data[i].subscriptionID,
                workItemName: data[i].subscriptionName,
                parentId: data[i].tenantID,
                parentName: data[i].tenantName
            }
            workItemIdMap.set(data[i].subscriptionID,subEntry);
            remediateControls.set(data[i].subscriptionID, subscriptionRemediateControls);
        }
        this.setState({
            internalState: internalState,
            selectedWorkItems: selectedWorkItems,
            expandedWorkItems: expandedWorkItems,
            workItemJobMap: workItemJobMap,
            workItemIdMap: workItemIdMap,
            remediateControls: remediateControls
        });
        this.context.setRemediateControls(remediateControls);
        if(isRefreshNeeded===true) this._refreshIt();
        this._resolution();     
    }

    /* 
    The below function function process the controls remediation specification file which is used ,to allow,
    to select only the control which are supported by remediation framework.
    */
    private _processRemediationSpecification(dataString: string) {
        var data = JSON.parse(dataString);
        var controlRemediationSpefication: Map<string, boolean> = new Map<string, boolean>();
        var remediableControlsFetched = data["RemediationSpecification"];
        for (var i = 0; i < remediableControlsFetched.length; i++) {
            controlRemediationSpefication.set(remediableControlsFetched[i].ControlId, remediableControlsFetched[i].EnableRemediation);
        }
        this.setState({
            remediableControlsFetched: controlRemediationSpefication
        })
        this.context.setremediableControlsFetched(remediableControlsFetched);
    }

    // Process individual row item in table.
    private _processItemData(dataString : string, workItemId : string) {
        var data = JSON.parse(dataString);
        var workItemEntry = this.state.internalState.get(workItemId);
        var new_items:ITableIndividualRowState[] = [];
        var curr_length = this.state.items.length;
        for (var i = 0; i < data.length; i++) {
            if(data[i].controlName) {
                
                var statusObject : IStatusObject = {
                    status: data[i].status,
                    message: data[i].statusReason
                };

                var statusReasonObject : IStatusReasonObject = {
                    name: data[i].controlName,
                    expiry: data[i].exceptionExpiryInDays ? data[i].exceptionExpiryInDays : "",
                    statusReason: data[i].statusReason,
                    rg:data[i].resourceGroupName,
                    resource: data[i].resourceName,
                    additionalInformation: data[i].additionalInformation ? 
                    (this._isJson(data[i].additionalInformation)? JSON.parse(data[i].additionalInformation):{"Message":data[i].additionalInformation}) : {} ,
                    justification: data[i].justification
                };

                var controlObject : IControlObject = {
                    subscriptionId: data[i].subscriptionId,
                    resourceId: data[i].resourceID,
                    name: data[i].controlName,
                    displayName: data[i].controlDisplayName,
                    expiry: data[i].exceptionExpiryInDays ? data[i].exceptionExpiryInDays : "",
                    description: data[i].description,
                    remediation: data[i].remediation,
                    controlRequirements : data[i].controlRequirements,
                    category: data[i].category,
                    controlShortId: data[i].controlShortId,
                    actualDisplayName: data[i].actualDisplayName,
                    justification: data[i].justification,
                    statusReason: data[i].statusReason,
                    rg:data[i].resourceGroupName,
                    resource: data[i].resourceName,
                    featureName: data[i].featureName,
                    additionalInformation: data[i].additionalInformation ? 
                    (this._isJson(data[i].additionalInformation)? 
                    JSON.parse(data[i].additionalInformation):
                    ((this._isJson(untruncateJson(data[i].additionalInformation))) ? 
                    ({"Message": "The Additional Information data is huge and thus truncated here. We recommend using the 'Download' feature to get the complete data.","Truncated Data":JSON.parse(untruncateJson(data[i].additionalInformation))}) :{"Message":data[i].additionalInformation})) : {}
                };

                var resourceObject: IResourceObject = {
                    resource: data[i].resourceName,
                    resourceID: data[i].resourceID,
                    parentName: data[i].tenantName
                };

                new_items = new_items.concat({
                    key: (curr_length+i).toString(),
                    name: data[i].controlName,
                    status: statusObject,
                    resource: data[i].resourceName,
                    rg: data[i].resourceGroupName,
                    severity: data[i].severity,
                    baseline: data[i].isBaseline,
                    expiry: data[i].exceptionExpiryInDays ? data[i].exceptionExpiryInDays : "",
                    isEligibleForException: data[i].isEligibleForException,
                    workItemId: data[i].subscriptionId,
                    orgTenantId: data[i].orgTenantId,
                    resourceID: data[i].resourceID,
                    justification: data[i].justification,
                    allowedExceptionType: data[i].allowedExceptionType,
                    controlObject: controlObject,
                    resourceObject: resourceObject,
                    statusReason: statusReasonObject,
                    controlShortId: data[i].controlShortId,
                });
            }
        }
        workItemEntry.controls = new_items;
        workItemEntry.initial = false;
        this.state.internalState.set(workItemId,workItemEntry);
        this._resolution();
        this.state.remediateControls.get(workItemId).visitedPages.set(workItemEntry.currentPage, true);
    }

    private _isJson(additionalInfo : string)
    {
        try{
            var o = JSON.parse(additionalInfo)
            if(o && typeof o == "object") return true;
        }
        catch (e) {}
        return false;
    }

    // Compare state and context to determine if page should reload.
    private _compareContextWithState() {
        if(JSON.stringify(this.context.workItems) === JSON.stringify(this.state.formConf.workItems) &&
            JSON.stringify(this.context.control) === JSON.stringify(this.state.formConf.control) && 
            JSON.stringify(this.context.baseline) === JSON.stringify(this.state.formConf.baseline) &&
            JSON.stringify(this.context.complianceInitiative) === JSON.stringify(this.state.formConf.complianceInitiative) &&
            JSON.stringify(this.context.excludeExternalControls) === JSON.stringify(this.state.formConf.excludeExternalControls) &&
            JSON.stringify(this.context.failed) === JSON.stringify(this.state.formConf.failed)) {
            return 0;
        }
        return 1;
    }

    private _setWorkItemCompliancePageNum(index: number) {
        this.setState({
            workItemCompliancePageNum: index
        })
    }

    private _checkContextIsEmpty() {
        if(this.context.workItems.length &&
            this.context.complianceInitiative.length &&
            this.context.control.length) {
            return 1;
        }
        return 0;
    }

    /*
    The below function sends request to Web API and get control remediation specification as a response, which then process by
    _processRemediationSpecification function
    TODO: If data is already present, there no need to recall this API.
    */
    private _fetchRemediationSpecification() {
        const CallAPI = async () => {
            const authToken = await authProvider.getAccessToken({ scopes: ["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"] });
            fetch((RuntimeConfigurationInitial.webAPI + '/remediation/control-specification'), {
                headers: !authToken.accessToken ? {} : {
                    'Authorization': `Bearer ${authToken.accessToken}`,
                    'u_Id': sessionStorage.getItem("u_Id"),
                    'Content-Type': 'application/json',
                    'X-Remediation-Mode': 'true'
                },
                method: 'GET',
            }).then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        this._processRemediationSpecification(JSON.stringify(data));
                    });
                }
                else {
                    response.text().then(errorMsg => {
                        toast(errorMsg.slice(0, 500));
                        this.context.toggleShimmer(false);
                    });
                }
            });
        }
        CallAPI();
    }

    private _fetchIndividualRecords(workItemId : string, pageNumber : number = 1) {
        const CallAPI = async () => {
            const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
            let body = {                
                JobId: Number(this.state.workItemJobMap.get(workItemId)),
                ControlIdList: this.context.control,
                ResourceNameList: this.context.resource,
                ExcludePassedControls: this.context.failed,
                GetControlsEligibleForException: this.context.attestationMode,
                FilterBaselineType: this.context.baseline,
                ExportToCSV: false,
                ExcludeExternalControls: this.RuntimeConfigurationExtended.externalControlsConfiguration?.isEnabled ? this.context.excludeExternalControls : true,
                PageNumber: pageNumber,
                ComplianceInitiativeId: this.context.complianceInitiative,
                IncludeOnlyFailedControls: this.context.remediationMode
            }

            if(!workItemId){
                workItemId = "/"
            }

            fetch((ScannerAPI + util.format(TenantScannerRoutes.FetchWorkItemScanResult,workItemId)), {
                headers: !authToken.accessToken ? {} : {
                    'Authorization': `Bearer ${authToken.accessToken}`,
                    'u_Id': sessionStorage.getItem("u_Id"),
                    'Content-Type': 'application/json',
                    'X-Remediation-Mode': this.context.remediationMode.toString()
                },
                method: 'POST',
                body: JSON.stringify(body)
            }).then(response => {
                if (response.ok) {
                    response.json().then(data => { this._processItemData(JSON.stringify(data), workItemId); });
                }
                else {
                    response.text().then(errorMsg => {

                        this.context.toggleShimmer(false);
                    });
                }
            });
        }
        CallAPI();
    }

    // Fetch summary for WorkItem ribbon
    private _fetchWorkItemSummary(pageNumber: number = 1) {
        const CallAPI = async () => {
            const authToken = await authProvider.getAccessToken({scopes:["api://" + RuntimeConfigurationInitial.apiClientId + "/user_impersonation"]});
            var workItemId = this.context.workItems;
            let body = {
                SubscriptionIDList: workItemId,
                ControlIdList: this.context.control,
                ExcludePassedControls: this.context.failed,
                ExcludeExternalControls: this.RuntimeConfigurationExtended.externalControlsConfiguration?.isEnabled ? this.context.excludeExternalControls : true,
                GetControlsEligibleForException: this.context.attestationMode,
                FilterBaselineType: this.context.baseline,
                SubCompliancePageNum: pageNumber
            }

            fetch((ScannerAPI + TenantScannerRoutes.FetchWorkItemComplianceSummary), {
                headers: !authToken.accessToken ? {} : {
                    'Authorization': `Bearer ${authToken.accessToken}`,
                    'u_Id': sessionStorage.getItem("u_Id"),
                    'Content-Type': 'application/json',
                    'X-Remediation-Mode': this.context.remediationMode.toString()
                },
                method: 'POST',
                body: JSON.stringify(body)
            }).then(response => {
                if (response.ok) {
                    response.json().then(data => {this._processWorkItemData(JSON.stringify(data))});
                    this.context.toggleShowErrorMessage(false);
                }
                else {
                    response.text().then(errorMsg => {
                        this.context.setErrorMessageToDisplay(errorMsg.slice(0,500));
                        this.context.setErrorResponse(response.status.toString());
                        this.context.toggleShowErrorMessage(true);
                        this.context.toggleShimmer(false);
                    });
                }
            });
        }
        CallAPI();
    }

    public _setStateWithContext() {
        var form : IFormConfigurations = {
            workItems: this.context.workItems,
            resource: this.context.resource,
            control: this.context.control,
            failed: this.context.failed,
            baseline: this.context.baseline,
            excludeExternalControls: this.context.excludeExternalControls, 
            complianceInitiative: this.context.complianceInitiative
        }

        this.setState({
            formConf: form
        });
    }

    componentDidMount() {
        var timeForExpiry = Number(localStorage.getItem("DUK_Expiry")); 
        var todayTime = new Date().getTime();
        if(todayTime < timeForExpiry){
            this.setState({
                isRecommend: false 
            });
        }
    }

    public componentDidUpdate() {
        if((this._compareContextWithState() && this.context.visible && this._checkContextIsEmpty()) || this.context.forceRefresh) {
            this.context.setForceRefresh(false);
            this.context.setSelectedWorkItems([]);
            this.context.setExceptionDataAdd([]);
            this.context.setExceptionDataClear([]);
            this.context.setExceptionControls("", "");
            this.context.toggleShimmer(true);
            this._setStateWithContext();
            this._fetchWorkItemSummary();
            this._setWorkItemCompliancePageNum(0);
        }     
    }

    private _onChangeToggle = (ev: React.MouseEvent<HTMLElement>, checked: boolean) : void => {
        this.context.setAttestationMode(checked);
        this.context.setRemediationMode(false);
        this.context.setExceptionDataAdd([]);
        this.context.setExceptionDataClear([]);
        this.context.setExceptionControls("", "");
        this._refreshTable();
    }

    //The function gets called when remediation mode button is toggled.
    private _onChangeRemediationToggle = (ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
        this.context.setRemediationMode(checked);
        this.context.setAttestationMode(false);
        this.context.setRemediateControls([]);
        this._fetchRemediationSpecification();
        this._refreshTable();
    }

    private _onDismissDUK = (ev?: React.MouseEvent<HTMLElement | BaseButton | Button>) => {

        // New time to expire is in 1 week.
        var newTimeForExpiry = new Date().getTime() + 604800000;
        localStorage.setItem("DUK_Expiry", newTimeForExpiry.toString());    
        this.setState({
            isRecommend: false
        });
        toast("Hiding section for one week. You can view all the suggestions from bulb icon at the top of the page.", {
            autoClose: 6000,
            progressClassName: _InfoToast.toastProgress,
            className: _InfoToast.toastClass
        });
    }

    private _onRenderGroupHeader: IGroupRenderProps['onRenderHeader'] = props => {
        var points: IChartDataPoint[] = [
            {
                legend: 'Passed', 
                data: props.group!.data.passingControls*100/props.group!.data.totalControls, 
                color: DefaultPalette.green,
                yAxisCalloutData : props.group!.data.passingControls
            },
            {
                legend: 'Failed', 
                data: props.group!.data.failingControls*100/props.group!.data.totalControls, 
                color: DefaultPalette.red,
                yAxisCalloutData: props.group!.data.failingControls
            },
        ];

        const plainCardPropsSubscription: IPlainCardProps = {
            onRenderPlainCard: onRenderPlainCardSubscription,
            renderData:props.group!.data.workItemId,
            directionalHint: DirectionalHint.rightCenter,
            styles:{
                root: {
                    boxShadow:'none !important',
                }
            }
        };
            
        var data: IChartProps = {
            chartData: points,
            chartTitle: 'Compliance: ' + props.group!.data.compliance
        };

        return (                  
            <div role="row"
                className={this.state.selectedWorkItems.get(props.group!.key) ?
                    classNames.headerAndFooterSelected :
                    props.group!.isCollapsed ?
                        classNames.headerAndFooter :
                        classNames.headerAndFooterExpand}
                style={{
                    borderLeftColor: this.context.attestationMode ? this.state.selectedWorkItems.get(props.group!.key) ? '' : SharedColors.orange10 : this.context.remediationMode ? this.state.selectedWorkItems.get(props.group!.key) ? '' : SharedColors.green20 : '',
                }}
                >
                <Stack horizontal horizontalAlign="space-between" role="rowheader">
                    <Stack>
                        <CollapsibleSection
                            key={1} 
                            defaultCollapsed={true} 
                            collapsed={props.group.isCollapsed}
                            title={{
                                text: ``,
                                "aria-label":'Collapse subscription',
                                chevron: {color: theme.palette.themePrimary, style:{outline:"none"}},
                                styles: {
                                    text:theme.fonts.xLarge,
                                    root: {
                                        outline: "none !important",
                                        selectors: {
                                            ':hover': {
                                                backgroundColor: 'inherit'
                                            }
                                        }
                                    }
                                },
                                onClick: this._onToggleCollapse(props),
                                disabled: props?.group!?.data?.totalControls > 0 ? false : true
                                
                            }}  
                        >
                        </CollapsibleSection>
                    </Stack>
                    <Stack style={{width:"100%"}}>
                        <div className={classNames.headerTitle}>
                            <Stack horizontal horizontalAlign='start'>
                                <Stack>
                                    {(window.__UI_CONFIGURATION_EXTENDED_AZURE__.userImpersonationFeatureConfiguration?.endpoint !== ("/" + this.props.admin)) &&
                                        <TooltipHost
                                            content= {this.state.selectedWorkItems.get(props.group!.key) ? 'Unselect' : 'Select ' + TenantScannerConfiguration.WorkItemName}
                                            styles={toolTipStyle}
                                        >
                                            <Checkbox 
                                                checked={this.state.selectedWorkItems.get(props.group!.key)}
                                                onChange={this.context.remediationMode? this._onToggleSelectGroupRemediation(props) : this._onToggleSelectGroup(props)}
                                                styles={checkboxStyle}
                                                ariaLabel='Select Subscription'
                                            />
                                        </TooltipHost>
                                    }
                                </Stack>
                                <Stack horizontal>
                                    <Text variant='mediumPlus' style={{fontWeight: 600, color: theme.palette.themeDarker}}>{props.group!.name}</Text>
                                    &nbsp;
                                    <HoverCard cardOpenDelay={50} plainCardProps={plainCardPropsSubscription} instantOpenOnClick type={HoverCardType.plain}>               
                                        {<Text variant='mediumPlus' style={{fontWeight: 400, color: theme.palette.neutralSecondary}}>({props.group!.data.workItemId})</Text>}
                                    </HoverCard>
                                    </Stack>
                            </Stack>
                        </div>
                        <Stack horizontal tokens={horizontalStackTokens} >
                            <Stack style={{width:"50%"}} className={classNames.bodyTitle}>
                                {
                                    this.RuntimeConfigurationExtended.isMultiTenantSetup &&
                                    <Stack horizontal >
                                        <Text variant='smallPlus' nowrap block>
                                            <b style={{fontWeight: 600}}>Tenant:</b>&nbsp;{props.group!.data.parentName} ({props.group!.data.parentId})
                                        </Text>
                                    </Stack>
                                }       
                                <Stack horizontal>
                                    <Text variant='smallPlus' style={{fontWeight: 600}}>Last Job ID:</Text>&nbsp;{props.group!.data.jobID}
                                </Stack>
                                {this.RuntimeConfigurationExtended.onDemandScanProgressIndicatorConfiguration.isEnabled === true ?
                                (<>
                                    <Stack horizontal >
                                        <Text variant='smallPlus' style={{fontWeight: 600}}>{props.group!.data.scanCompleted===true? "Last Scan Completed:":"Last Scan Requested:"}</Text>&nbsp;{props.group!.data.lastScanRequestedAgo}
                                    </Stack>
                                        <Stack horizontal >
                                        
                                            {props.group.data.scanFailed === true && props.group.data.scanCompleted === false ? <ExceptionIcon /> : <></>}
                                            {props.group.data.scanCompleted === true && props.group.data.scanFailed === false ? <CompletedIcon/> : <></>}
                                            {props.group.data.scanCompleted === false && props.group.data.scanFailed === false ? <Spinner size={SpinnerSize.xSmall}></Spinner> : <></>}

                                            <TooltipHost
                                                id="ScanFailedToolTip"
                                                styles={toolTipStyle}
                                                content="Scan could not be completed successfully, Please try again. If issue persists, please reach out to dsraztssup@microsoft.com"
                                            >
                                                {props.group.data.scanFailed === true && props.group.data.scanCompleted === false ?
                                                    <Text variant='smallPlus' style={{ fontWeight: 500 }}>Scan Failed</Text> :
                                                    <></>}
                                            </TooltipHost>

                                            {props.group.data.scanCompleted === true && props.group.data.scanFailed === false ?
                                                <Text variant='smallPlus' style={{fontWeight: 500}}>Scan Completed</Text>:
                                                <></>}

                                            {props.group.data.scanCompleted === false && props.group.data.scanFailed === false ?
                                                <Text variant='smallPlus' style={{ fontWeight: 500 }}>&nbsp;Scan in Progress</Text> :
                                                <></>}
                                        </Stack>
                                </>)
                                :
                                (<>
                                    <Stack horizontal >
                                        <Text variant='smallPlus' style={{fontWeight: 600}}>Last Scanned (UTC):</Text>&nbsp;{props.group!.data.lastScanned} ({props.group!.data.scanningTime})
                                    </Stack>
                                </>)
                                }
                            </Stack>
                            <Stack style={{width:"50%"}} className={classNames.bodyTitle}>
                                <Stack horizontal>
                                    <b style={{fontWeight: 600}}>Scan requests today:</b>&nbsp;{props.group!.data.scanningCount}
                                </Stack>
                                <Stack horizontal>
                                    <b style={{fontWeight: 600}}>Access level:</b>&nbsp;{props.group!.data.visibility}
                                </Stack>
                                {props?.group!?.data?.totalControls > 0 && 
                                    <Stack horizontal>
                                        <b style={{fontWeight: 600}}>Total Controls:</b>&nbsp;{props.group!.data.totalControls}&nbsp;
                                        (<b style={{fontWeight: 600, color: SharedColors.green20}}>Passed: {props.group!.data.passingControls}</b>&nbsp;|&nbsp;
                                        <b style={{fontWeight: 600, color: SharedColors.red20}}> Failed: {props.group!.data.failingControls}</b>)
                                </Stack>
                                }
                                {props?.group!?.data?.totalControls === 0 && 
                                    <Stack horizontal>
                                        <b style={{fontWeight: 600}}>Total Controls:</b>&nbsp;
                                        <b style={{fontWeight: 600, color: SharedColors.red20}}> {NoControlResultFound}</b>
                                </Stack>
                                }
                            </Stack>
                            {props?.group!?.data?.totalControls > 0 && 
                                <Stack style={{width:"30%"}} verticalAlign="end" className={classNames.bodyTitle}>
                                    <StackedBarChart    
                                        data={data}
                                        width={1200}
                                        hideLegend={true}
                                        ignoreFixStyle={true}
                                    />
                                </Stack>
                            }
                        </Stack>
                    </Stack>
                    <Stack reversed horizontal style={{width:'35%'}}>
                        <Stack verticalAlign="space-between" horizontalAlign="end">
                            {!props.group!.isCollapsed && (props.group!.data.totalPages > 1) &&
                                <Pagination
                                    selectedPageIndex={props.group!.data.currentPage-1}
                                    pageCount={props.group!.data.totalPages}
                                    format={'comboBox'}
                                    comboBoxAriaLabel='Pagination Info'
                                    previousPageAriaLabel={'previous page'}
                                    nextPageAriaLabel={'next page'}
                                    firstPageAriaLabel={'first page'}
                                    lastPageAriaLabel={'last page'}
                                    pageAriaLabel={'page'}
                                    onPageChange={
                                        (index: number): void => {
                                            this._fetchIndividualRecords(props.group!.data.workItemId, index + 1);
                                            var currentInternalState = this.state.internalState;
                                            currentInternalState["currentPage"] = index + 1;
                                            this.setState({internalState: currentInternalState});
                                            props.group!.data.currentPage = index + 1;
                                            this.setState({ controlledSelectionValue: 0 });
                                        }
                                    }
                                    styles={{
                                        previousNextPage: {
                                            background: 'inherit !important',
                                        },
                                        previousNextPageDisabled: {
                                            background: 'inherit !important',
                                        },
                                        comboBox: {
                                            height: '100%'
                                        },
                                        comboBoxCallout: {
                                            width: "fit-content"
                                        }
                                    }}
                                />
                            }
                        </Stack>
                    </Stack>
                </Stack>  
            </div>
        );
    };

    /*
   The below function is called when remediation mode is on and any of the subscription is checked (selected)
   It maintains the number of subscription currently checked and calls _onToggleCollapseRemediation function and 
   selects all the controls which can be remediated.
   */
   private _onToggleSelectGroupRemediation(props: IGroupDividerProps): () => void {
    return () => {
        this.state.selectedWorkItems.set(props.group!.key, !this.state.selectedWorkItems.get(props.group!.key));
        props!.onToggleCollapse!(props!.group!);
        var selectedWorkItems: Array<IWorkItemList> = [];
        this.state.selectedWorkItems.forEach((value: boolean, key: string) => {
            if (value) {
                var workItem:IWorkItemList = {
                    workItemId :key,
                    workItemName: this.state.workItemIdMap.get(key).workItemName,
                    parent:this.state.workItemIdMap.get(key).parentName
                }
                selectedWorkItems = selectedWorkItems.concat(
                    workItem
                )
                props.group!.isCollapsed = true;
                this._onToggleCollapseRemediation(props)
            }
            else {
                props.group!.isCollapsed = true;
                this._onToggleCollapseRemediation(props)
            }
        });
        this.context.setSelectedWorkItems(selectedWorkItems);

        // This is case when the items have been already fetched and selection needs to be done here instead of resolution when the subscription is checked.
        // if(a subscription is selected (checked)), then select all the controls which can be remediated (on that page). Since only the controls which can be remediated are allowed to
        // be selected, just iterate and select.
        if (this.state.selectedWorkItems.get(props.group!.key) && this.state.items.length > 0) {
            this._selection.setChangeEvents(false, true);
            for (var i = 0; i < this.state.items.length; i++) {
                if (this.state.items[i].workItemId === props.group!.key) {
                    this._selection.setIndexSelected(i, true, false);
                }
            }
            this._selection.setChangeEvents(true, true);
        }
        //else (a subscription is unselected (unchecked)), then deselect all the controls on that page and also reset the state for that subscription.
        else {
            for (var i = 0; i < this.state.items.length; i++) {
                if (this.state.items[i].workItemId === props.group!.key) {
                    this._selection.setIndexSelected(i, false, false);
                }
                var subscriptionRemediateControls: IGroupedRemediableControl = {
                    pageNumber: this.state.remediateControls.get(props.group!.key).pageNumber,
                    controls: new Map<number, IObjectWithKey[]>(),
                    visitedPages: new Map<number, boolean>(),
                }
                this.state.remediateControls.set(props.group!.key, subscriptionRemediateControls);
                //Do we need to use set state?
                this.context.setRemediateControls(this.state.remediateControls);
            }
        }
        //set the current page as visited when the subscription is selected
        if (this.state.selectedWorkItems.get(props.group!.key) && this.state.items.length > 0) {
            this.state.remediateControls.get(props.group!.key).visitedPages.set(this.state.internalState.get(props.group!.key).currentPage, true);
        }
    };
}

    private _onToggleSelectGroup(props: IGroupDividerProps): () => void {
        return () => {
            this.state.selectedWorkItems.set(props.group!.key, !this.state.selectedWorkItems.get(props.group!.key));
            props!.onToggleCollapse!(props!.group!);  
            var selectedWorkItems : Array<IWorkItemList> = [];
            this.state.selectedWorkItems.forEach((value: boolean, key: string) => {
                if(value) {
                    var workItemEntry = this.state.workItemIdMap.get(key);
                    selectedWorkItems = selectedWorkItems.concat({
                        workItemId: key,
                        workItemName: workItemEntry.workItemName,
                        parent: workItemEntry.parentName + " (" + workItemEntry.parentId + ")"
                    })
                }
            });
            this.context.setSelectedWorkItems(selectedWorkItems);
            props!.onToggleCollapse!(props!.group!);
        };
    }

    /*
    The below function called when any subscription box is toggled in remediation mode.
    It shows the description containing all the failed controls - both those which can be remediated and those which cannot.
    */
    private _onToggleCollapseRemediation(props: IGroupDividerProps): () => void {

        if (props.group!.data.initial) {
            this.context.toggleShimmer(true);
            this._fetchIndividualRecords 
            (props.group!.key);
        }

        props!.onToggleCollapse!(props!.group!);
        this.state.expandedWorkItems.set(props.group!.key, props.group!.isCollapsed);
        return () => {
            this.state.expandedWorkItems.set(props.group!.key, props.group!.isCollapsed);
        };
    }

    private _onToggleCollapse(props: IGroupDividerProps): () => void {
        return () => {
            if(props.group!.data.initial) {
                this.context.toggleShimmer(true);
                this._fetchIndividualRecords(props.group!.key);
            }
            props!.onToggleCollapse!(props!.group!);  
            this.state.expandedWorkItems.set(props.group!.key,props.group!.isCollapsed);      
        };
    }

    private _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (props) {
            props.checkboxVisibility = 1;
            return (
                <DetailsRow {...props} styles={customStyles} />
            );
        }
        return null;
      };

    private _getGroupTotalRowHeight = (group: IGroup): number => {
        return group.isCollapsed ? 0 : ROW_HEIGHT * group.count;
    };

    private _getGroupHeight = (group: IGroup, _groupIndex: number): number => {
        return GROUP_HEADER_HEIGHT + GROUP_FOOTER_HEIGHT + this._getGroupTotalRowHeight(group);
    };

    private _refreshTable = (): void => {
        this.context.toggleShimmer(true);
        this.context.setForceRefresh(true);
    }
    private _refreshIt = (): void => {
        setTimeout(() => {
            this.context.setForceRefresh(true);
        }, 90000);
    }

    private _onToggleTeachingBubbleRemediationMode: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement> = () => {
        this.setState({ teachingBubbleRemediationMode: !this.state.teachingBubbleRemediationMode })
    }


    public render() {
        const {filteredItems, groups, columns, columns_exception, columns_remediation } = this.state;
        return this.context.visible  && !this.context.showErrorMessage  ?
            (<div>
                {this.context.shimmer && <ProgressIndicator barHeight={4} label="" description="" className={classNames.progressBar} />}
                <div style = {tableStyles}>
                    <Stack horizontal horizontalAlign="space-between">
                        <Stack horizontal style={{marginLeft: 24,marginTop: 24, width:'38%'}}>
                            <InfoTable/>
                            {
                                this.state.isRecommend && 
                                <MessageBar
                                    messageBarType={MessageBarType.success}
                                    isMultiline={true}
                                    dismissButtonAriaLabel="Do not show for one week"
                                    onDismiss={this._onDismissDUK}                                    
                                    className={classNames.messageDUK}
                                >
                                    <div style={{width:'100%', height:'30'}}>
                                        <ReactTextRotator/>
                                    </div>
                                </MessageBar> 
                            }
                            
                        </Stack>
                        <Sticky stickyPosition={StickyPositionType.Header} stickyClassName={classNames.pane}>
                            <Stack horizontalAlign="end" horizontal style= {{marginRight: 2, marginTop: 24, width:'100%'}} tokens={{childrenGap:24}}>
                                
                                <Stack horizontal>
                                    <TooltipHost
                                    content= {this.context.remediationMode ? "To enable it, first disable remediation mode":""}
                                    id="AttestationMode"
                                    styles={toolTipStyle}
                                    >
                                        {window.__UI_CONFIGURATION_EXTENDED_AZURE__.exceptionFeatureConfiguration?.isEnabled === true &&
                                            (
                                                // For external customers who may want to use only "ByDesign" exceptions (Self Attestations).
                                                (window.__UI_CONFIGURATION_EXTENDED_AZURE__.exceptionFeatureConfiguration?.customExceptionTypes?.length === 0) ||
                                                window.__UI_CONFIGURATION_EXTENDED_AZURE__.exceptionFeatureConfiguration?.customExceptionTypes?.some(exceptionType =>
                                                    (exceptionType.isEnabledForAllTags === true)) ||
                                                window.__UI_CONFIGURATION_EXTENDED_AZURE__.exceptionFeatureConfiguration?.customExceptionTypes?.some(exceptionType =>
                                                    (exceptionType.eligibleTagsMetadata.some(tag => (tag.name === this.props.baselineType))))
                                            ) &&
                                            (window.__UI_CONFIGURATION_EXTENDED_AZURE__.userImpersonationFeatureConfiguration?.endpoint !== ("/" + this.props.admin)) 
                                            &&
                                            <Toggle
                                                label="Exception Mode"
                                                inlineLabel onText="On"
                                                offText="Off"
                                                disabled= {this.context.remediationMode}
                                                onChange={this._onChangeToggle}
                                            />
                                        }
                                    </TooltipHost>
                                </Stack>
                                <Stack horizontal>
                                    <>
                                    { window.__UI_CONFIGURATION_EXTENDED_AZURE__.userImpersonationFeatureConfiguration?.endpoint !== ("/" + this.props.admin) &&
                                    <>
                                    {(window.__UI_CONFIGURATION_EXTENDED_AZURE__.remediationFeatureConfiguration?.isEnabled === true) && <IconButton
                                        style={{ outline: "none" }}
                                        id="remediationMode-info"
                                        iconProps={{ iconName: 'Info' }}
                                        aria-label="Remediation Mode Info"
                                        onClick={this._onToggleTeachingBubbleRemediationMode}
                                    />}
                                    {this.state.teachingBubbleRemediationMode &&
                                        (window.__UI_CONFIGURATION_EXTENDED_AZURE__.remediationFeatureConfiguration?.isEnabled === true) &&
                                        (<TeachingBubble
                                        target="#remediationMode-info"
                                        headline="Remediation Mode"
                                        hasCondensedHeadline={true}
                                        styles={teachingStyles}
                                        hasCloseButton={true}
                                        onDismiss={this._onToggleTeachingBubbleRemediationMode}
                                        closeButtonAriaLabel='Close Info'
                                    >
                                        <ul style={{ marginLeft: -20 }}>
                                            <li>Remediation mode gives the capability to remediate the failing controls by allowing you to take a snapshot of the failing controls that can be remediated in bulk using PowerShell scripts. Metadata about the failing controls is made available in a format that the individual remediation scripts can use to go about with the remediation.</li>
                                            <li>One can either select an entire Subscription to remediate all failing controls there, or selectively pick one or more failing controls to remediate just them.</li>
                                            <li><b>Note</b>: Currently only few controls can be selected for remediation. Additional controls will be supported on a periodic basis. Supported controls can be found <Link href="https://aka.ms/azts/remediation/SupportedControls" target='_blank' underline style={{ color: '#fff' }}>here</Link>.</li>
                                        </ul>
                                    </TeachingBubble>)}
                                    <TooltipHost
                                        content={this.context.attestationMode ? "To enable it, first disable exception mode" : ""}
                                        id="RemediationMode"
                                        styles={toolTipStyle}
                                    >
                                        {window.__UI_CONFIGURATION_EXTENDED_AZURE__.remediationFeatureConfiguration?.isEnabled === true 
                                        &&
                                            <Toggle
                                                label="Remediation Mode"
                                                inlineLabel onText="On"
                                                offText="Off"
                                                disabled={this.context.attestationMode}
                                                onChange={this._onChangeRemediationToggle}
                                            />
                                        }

                                    </TooltipHost>
                                    </>
                                    }
                                    </>
                                </Stack>
                                <Stack tokens={{ childrenGap:10 }} horizontal style={{ marginRight: 2 }}>
                                {window.__UI_CONFIGURATION_EXTENDED_AZURE__.userImpersonationFeatureConfiguration?.endpoint !== ("/" + this.props.admin) && 
                                   <> <DefaultButton
                                        iconProps={refreshIcon}
                                        text="Refresh"
                                        style={{outline:"none", boxShadow: theme.effects.elevation4}}
                                        onClick={this._refreshTable}
                                    />

                                
                                    <DialogCart/>
                                    </>
                                }
                                </Stack> 
                            </Stack>
                        </Sticky>
                    </Stack>
                    <ThemeProvider>
                        <Stack style={{marginLeft:30, marginRight:30}}>
                            {
                                this.context.remediationMode ?
                                    (<MarqueeSelection selection={this._selection} isEnabled={false}>
                                        <DetailsList
                                            componentRef={this._root}
                                            items={filteredItems}
                                            columns={columns_remediation}
                                            selectionPreservedOnEmptyClick={true}
                                            constrainMode={ConstrainMode.unconstrained}
                                            layoutMode={DetailsListLayoutMode.fixedColumns}
                                            // enableShimmer={this.context.shimmer}
                                            onRenderItemColumn={RenderItemColumn}
                                            disableSelectionZone={false}
                                            groups={groups}
                                            enableUpdateAnimations={true}
                                            isHeaderVisible={true}
                                            onRenderRow={this._onRenderRow}
                                            selectionMode={SelectionMode.multiple}
                                            selection={this._selection}
                                            styles={gridStyles}
                                            groupProps={{
                                                showAllProps: {
                                                    showAllLinkText: 'Load More'
                                                },
                                                showEmptyGroups: true,
                                                collapseAllVisibility: 0,
                                                isAllGroupsCollapsed: true,
                                                onRenderHeader: this._onRenderGroupHeader
                                            }}
                                            getGroupHeight={this._getGroupHeight}
                                            onRenderDetailsHeader={onRenderDetailsHeaderRemediation}
                                            ariaLabelForSelectionColumn='selection checkbox'
                                            ariaLabelForSelectAllCheckbox='Toggle selection for all items'
                                        />
                                    </MarqueeSelection>)
                                    :
                                    this.context.attestationMode ?
                                    (<MarqueeSelection selection={this._selection} isEnabled={false}>
                                        <DetailsList
                                            componentRef={this._root}
                                            items={filteredItems}
                                            columns={columns_exception}
                                            selectionPreservedOnEmptyClick={true}
                                            constrainMode={ConstrainMode.unconstrained}
                                            layoutMode={DetailsListLayoutMode.fixedColumns}
                                            // enableShimmer={this.context.shimmer}
                                            onRenderItemColumn={RenderItemColumn}
                                            groups={groups}
                                            enableUpdateAnimations={true}
                                            isHeaderVisible={true}
                                            onRenderRow={this._onRenderRow}
                                            selectionMode={SelectionMode.multiple}
                                            selection={this._selection}
                                            styles={gridStyles}
                                            groupProps={{
                                                showAllProps: {
                                                    showAllLinkText: 'Load More'
                                                },
                                                showEmptyGroups: true,
                                                collapseAllVisibility: 0,
                                                isAllGroupsCollapsed: true,
                                                onRenderHeader: this._onRenderGroupHeader
                                            }}
                                            getGroupHeight={this._getGroupHeight}
                                            onRenderDetailsHeader={onRenderDetailsHeaderAttestation}
                                            ariaLabelForSelectionColumn='selection checkbox'
                                        />
                                    </MarqueeSelection>)
                                :
                                    <DetailsList
                                        componentRef={this._root}
                                        items={filteredItems}
                                        columns={columns}
                                        selectionPreservedOnEmptyClick={true}
                                        constrainMode={ConstrainMode.unconstrained}
                                        // enableShimmer={this.context.shimmer}
                                        layoutMode={DetailsListLayoutMode.fixedColumns}
                                        onRenderItemColumn={RenderItemColumn}
                                        groups={groups}
                                        enableUpdateAnimations={true}
                                        isHeaderVisible={true}
                                        onRenderRow={this._onRenderRow}
                                        selectionMode={SelectionMode.none}
                                        styles={gridStyles}
                                        groupProps={{
                                            showAllProps: {
                                                showAllLinkText: 'Load More'
                                            },
                                            showEmptyGroups: true,
                                            collapseAllVisibility: 0,
                                            isAllGroupsCollapsed: true,
                                            onRenderHeader: this._onRenderGroupHeader
                                        }}
                                        getGroupHeight={this._getGroupHeight}
                                        onRenderDetailsHeader={onRenderDetailsHeader}
                                        ariaLabelForSelectionColumn='selection checkbox'
                                        ariaLabelForSelectAllCheckbox='Toggle selection for all items'                                        
                                    />
                            }
                        </Stack>
                    </ThemeProvider>
                    
                    <div className={_Styles.rowGap} />
                    <Stack>
                        {this.context.workItems.length / this.RuntimeConfigurationExtended.subCountInPage > 1 &&
                            <Pagination
                                selectedPageIndex={this.state.workItemCompliancePageNum}
                                pageCount={Math.ceil(this.context.workItems.length / this.RuntimeConfigurationExtended.subCountInPage)}
                                itemsPerPage={this.RuntimeConfigurationExtended.subCountInPage}
                                totalItemCount={this.context.workItems.length}
                                format={'buttons'}
                                previousPageAriaLabel={'previous page'}
                                nextPageAriaLabel={'next page'}
                                firstPageAriaLabel={'first page'}
                                lastPageAriaLabel={'last page'}
                                pageAriaLabel={'page'}
                                selectedAriaLabel={'selected'}
                                onPageChange={(index: number): void => {
                                    this.context.toggleShimmer(true);
                                    this._fetchWorkItemSummary(index + 1);
                                    this._setWorkItemCompliancePageNum(index);
                                }}

                            />
                        }
                    </Stack>
                    
                </div>
            </div>)
        :
        (<div >
            {this.context.showErrorMessage && 
            
            <Stack verticalAlign="center" tokens={verticalGapStackTokens}>
            <div className={_Styles.rowGap} />
            <div className={_Styles.rowGap} />
            <div className={_Styles.rowGap} />
            <div className={_Styles.topMargin}/>
            
            <Stack.Item  align="center">
            
            {this.context.errorResponse ==="404" && !this.context.errorHeaderToDisplay.includes(UnauthorizedHeader) &&
                <NoData/>
            }
            {this.context.errorResponse ==="500" &&
                <CatSvg/>  
            }
            </Stack.Item>
            <div className={_Styles.rowGap} />                            
            <div className={_Styles.rowGap} />
            
            <Stack.Item className={_Styles.fontSize} align="center"><b>{this.context.errorHeaderToDisplay}</b></Stack.Item>
            <Stack.Item  align="center">{this.context.errorMessageToDisplay}</Stack.Item>

        </Stack>   
                }
        </div>);
    }

    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const columns = this.context.remediationMode ? this.state.columns_remediation : this.context.attestationMode ? this.state.columns_exception : this.state.columns;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        this.state.internalState.forEach((value: IInternalState, key: string) => {
            const newItems = copyAndSort(value.controls, currColumn.fieldName!, currColumn.isSortedDescending);
            value.controls = newItems;
            this.state.internalState.set(key, value);
        });
        this._resolution();
    };
}