import React, { Component, createContext } from 'react';

export const ComplianceInitiative = createContext();
const RuntimeConfigurationInitial = window.__UI_CONFIGURATION_INITIAL__;
export class ComplianceInitiativeProvider extends Component {
  state = {
    tagsList: [],
    tag:'',
    ManagementGroupId: RuntimeConfigurationInitial.tenantId,
    action:'',
    initialTag: '',
    complianceInitiativeList:[],
    complianceInitiativeMappingList:[],
    ciState: '',
    localTag: '',
    masterTagList: [],
    defaultCITagList:[]
  }

  setLoadingTable= (value) => {
    this.setState({
      loadingTable: value
    })
  }

  setDefaultCITagList= (value) => {
    this.setState({
      defaultCITagList: value
    })
  }

  setInitialTag= (value) => {
    this.setState({
      initialTag: value
    })
  }

  setMasterTagList= (value) => {
    this.setState({
      masterTagList: value
    })
  }

  setLocalTag= (value) => {
    this.setState({
      localTag: value
    })
  }

  setCIState= (value) => {
    this.setState({
      ciState: value
    })
  }

  setComplianceInitiativeList= (value) => {
    this.setState({
      complianceInitiativeList: value
    })
  }

  setComplianceInitiativeMappingList= (value) => {
    this.setState({
      complianceInitiativeMappingList: value
    })
  }

  setAction= (value) => {
      this.setState({
          action: value
    })
  }

  setManagementGroupId= (value) => {
    this.setState({
      ManagementGroupId: value
    })
  }

  setTagList = (value) => {
    this.setState({
      tagsList: value
    })
  } 

  setTag = (value) => {
    this.setState({
      tag: value
    })
  } 

  render() { 
    return (
      <ComplianceInitiative.Provider value={{
        ...this.state, 
        setTag: this.setTag,
        setTagList: this.setTagList,
        setManagementGroupList: this.setManagementGroupList,
        setInitialTag :this.setInitialTag,
        setAction:this.setAction,
        setManagementGroupId:this.setManagementGroupId,
        setComplianceInitiativeList:this.setComplianceInitiativeList,
        setCIState : this.setCIState,
        setLocalTag : this.setLocalTag,
        setComplianceInitiativeMappingList: this.setComplianceInitiativeMappingList,
        setMasterTagList: this.setMasterTagList,
        setDefaultCITagList:this.setDefaultCITagList
      }}
        
      >
        {this.props.children}
      </ComplianceInitiative.Provider>
    );
  }
}
 
export default ComplianceInitiativeProvider;