import { createTheme } from '@fluentui/react';

export const controlEditorTheme = createTheme({
  palette: {
    themePrimary: '#007a8c',
    themeLighterAlt: '#f0f9fa',
    themeLighter: '#c7e8ed',
    themeLight: '#9ad4dd',
    themeTertiary: '#4aabba',
    themeSecondary: '#12889a',
    themeDarkAlt: '#006d7e',
    themeDark: '#005c6b',
    themeDarker: '#00444f',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
}});